class Constants {
  validation = {
    rules: {
      amount: 'AmountValidator',
      email: 'EmailValidator',
      fieldCompare: 'FieldCompareValidator',
      numeric: 'NumericValidator',
      required: 'RequiredValidator',
      stringLength: 'StringLengthValidator',
      url: 'UrlValidator',
      password: 'PasswordComplexityValidator',
      postCode: 'PostCodeGenericPatternValidator'
    }
  };

  storageKeys = {
    currentCustomer: 'chess.oup.customer',
    currentAdmin: 'chess.oup.admin',
    requestedUrl: 'chess.oup.path',
    validationRules: 'chess.oup.validation.',
    countries: 'chess.oup.countries',
    periods: 'chess.oup.periods',
    universities: 'chess.oup.universities',
    orderStatuses: 'chess.oup.orderstatuses',
    orderForm: 'chess.oup.orderform',
    logForm: 'chess.oup.logform',
    basket: 'chess.oup.basket',
    logTypes: 'chess.oup.logtypes',
    isValid: 'chess.oup.isValid'
  };

  stores = {
    subscriptionsUk: 4,
    subscriptionsUs: 5,
    ebooksUs: 6,
    ebooksUk: 7,
    getSubscriptionStoreIdFromName: (name: string) => {
      let storeId = 0;

      switch (name) {
        case 'uk':
          storeId = this.stores.subscriptionsUk;
          break;
        case 'us':
          storeId = this.stores.subscriptionsUs;
          break;
      }

      return storeId;
    },
    getEbooksStoreIdFromName: (name: string) => {
      let storeId = 0;

      switch (name) {
        case 'uk':
          storeId = this.stores.ebooksUk;
          break;
        case 'us':
          storeId = this.stores.ebooksUs;
          break;
      }

      return storeId;
    },
    getStoreRegionName: (storeId: number) => {
      let name = '';

      switch (storeId) {
        case constants.stores.subscriptionsUk:
        case constants.stores.ebooksUk:
          name = 'UK';
          break;

        case constants.stores.subscriptionsUs:
        case constants.stores.ebooksUs:
          name = 'US';
          break;
      }

      return name;
    }
  };

  orderStatuses = {
    created: 100,
    failedAtCheckout: 110,
    checkout: 120,
    failedAtPaymentSession: 130,
    paymentSessionCreated: 140,
    paymentInvoked: 150,
    failedAtPaymentDeclined: 160,
    failedAtPaymentError: 170,
    failedAtPaymentStatusCheck: 180,
    paymentSuccessful: 190,
    failedAtSigma: 200,
    failedAtVitalSource: 210,
    failedAtCompletion: 220,
    completed: 230,
    failedAtRefund: 240,
    partiallyRefunded: 250,
    fullyRefunded: 260
  };

  usCountryId = 175;
}

const constants = new Constants();

export default constants;
