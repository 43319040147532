import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { ValidationArea } from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { FC, useEffect } from 'react';
import Upload from 'rc-upload';
import { CsvFile } from 'models/generic/csvFile.model';

interface IFileUploadProps {
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   * @property {string} title Set the title attribute
   */
  title?: string;
  /**
   * @property {boolean} required Optional. Set the field to required
   */
  required?: boolean;
  /**
   *@property {string} labelText Set the text to appear in the label
   */
  labelText: string;
  /**
   *@property {string} elementId Set the ID attribute
   */
  elementId: string;
  /**
   *@property {CsvFile} value Set the value attribute
   */
  value?: CsvFile | null;
  /**
   *@property {string} placeholder Set the placeholder
   */
  placeholder: string;
  /**
   *@property {boolean} labelHidden Optional. Hide the label. labelText will be used for screenreaders. Default is false.
   */
  labelHidden?: boolean;
  /**
   *@property {(falconFile: CsvFile | null, file: File | null) => void} handleChange Set the action onChange
   */
  handleChange: (falconFile: CsvFile | null, file: File | null) => void;
  /**
   * @property {string} customInputClass Optional. Use a custom class on the input - use styles from the current component or appStyles
   */
  customInputClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {string[]} validationErrors Optional. Any validation messages related to this field
   */
  validationErrors?: string[];
}

/**
 * @description Add a file upload element with a label. Optional properties: customInputClass, customLabelClass,
 * title, required, validationError
 */
export const FileUpload: FC<IFileUploadProps> = (props: IFileUploadProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let instance: any | null = null;

  useEffect(() => {
    // remove role=button attribute to make file upload accessible
    const span = document.getElementsByClassName('rc-upload');
    for (let i = 0; i < span.length; i++) {
      span[i].removeAttribute('role');
    }
  }, []);

  // const clearFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   e.preventDefault();

  //   props.handleChange(null, null);
  // };

  return (
    <>
      <label
        htmlFor={props.name}
        className={classNames(
          appStyles.form__label,
          { [appStyles.form__label_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
          { [props.customLabelClass as string]: props.customLabelClass }, // add custom class if defined
          { [appStyles.srOnly]: props.labelHidden } // add srOnly class if label hidden
        )}>
        {props.labelText}
      </label>

      <div className={appStyles.form__fileUpload_div}>
        <Upload
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={(ref: any) => {
            instance = ref;
          }}
          onStart={(file: File) => {
            instance.abort(file);

            const reader = new FileReader();

            reader.onload = () => {
              const data = reader.result as string;
              const falconFile = new CsvFile(file.name, data);
              props.handleChange(falconFile, file);
            };

            reader.readAsDataURL(file);
          }}>
          <input
            className={classNames(
              appStyles.form__input,
              appStyles.form__input_text,
              appStyles.form__input_fileUpload,
              { [appStyles.form__input_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
              { [appStyles.form__fileUpload_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
              { [props.customInputClass as string]: props.customInputClass } // add custom class if defined
            )}
            id={props.name}
            name={props.name}
            type="text"
            value={props.value ? props.value.fileName : ''}
            placeholder={props.placeholder}
            title={props.title || 'Click to upload a file'}
            required={props.required || undefined}
            readOnly={true}
            aria-required={props.required || undefined}
          />
        </Upload>

        {/* {props.value ? (
          <button onClick={clearFile} className={appStyles.form__fileUpload_button}>
            <Icon icon={Icons.cross} iconName="Remove selected file" color="gabBlue" size="xs" />
          </button>
        ) : (
          <Icon customClass={appStyles.fileUpload__icon} icon={Icons.plus} iconName="Add a file" color="gabBlue" size="xs" />
        )} */}

        <Icon customClass={appStyles.fileUpload__icon} icon={Icons.plus} iconName="Add a file" color="gabBlue" size="xs" />

        <ValidationArea sectionId={props.elementId} validationErrors={props.validationErrors} />
      </div>
    </>
  );
};

export default FileUpload;
