import { apiContext } from 'App';
import { EditCustomerAsAdminForm } from 'models/forms/editCustomerAsAdminForm.model';
import { AppError } from 'models/generic/appError.model';
import { Customer } from 'models/responses/customer.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import { useContext } from 'react';

export interface ICustomersHook {
  getEditCustomerValidationRules: () => Promise<ValidationRule[]>;
  getCustomers: () => Promise<Customer[]>;
  getCustomerById: (customerId: number) => Promise<Customer | null>;
  updateCustomer: (form: EditCustomerAsAdminForm, customerId: number) => Promise<Customer | AppError>;
}

export function useCustomers(): ICustomersHook {
  const api = useContext(apiContext);

  const getEditCustomerValidationRules = async (): Promise<ValidationRule[]> => api.getValidationRules('admin/customer/validation');

  const getCustomers = async (): Promise<Customer[]> => {
    const response = await api.get<Customer[]>('admin/customer', 'Loading customers failed.');
    if (response instanceof AppError) {
      return [];
    } else {
      return response;
    }
  };

  const getCustomerById = async (customerId: number): Promise<Customer | null> => {
    const customer = null;
    const response = await api.get<Customer>(`admin/customer/${customerId}`, 'There was an error loading this customer.');

    if (response instanceof AppError) {
    } else {
      return response;
    }

    return customer;
  };

  const updateCustomer = async (form: EditCustomerAsAdminForm, customerId: number): Promise<Customer | AppError> => {
    const response = await api.put<Customer>(`admin/customer/${customerId}`, form, 'Updating customer failed.');

    return response;
  };

  return {
    getEditCustomerValidationRules,
    getCustomers,
    getCustomerById,
    updateCustomer
  };
}
