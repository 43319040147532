export function useEnvironment() {
  const name = process.env.REACT_APP_ENVIRONMENT_NAME;

  return {
    name: name,
    isDev: name === 'Development',
    isUat: name === 'UAT',
    isProduction: name === 'Production'
  };
}
