import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class LoadEbooksForm extends BaseValidatedObject {
  storeId: number;

  constructor(storeId: number) {
    super();

    this.storeId = storeId;
  }
}
