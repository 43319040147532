import { DateOnly } from 'models/generic/dateOnly';
import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class LoadOrdersForm extends BaseValidatedObject {
  storeId: number | null = null;
  startDate: DateOnly = DateOnly.fromDate(new Date());
  endDate: DateOnly = DateOnly.fromDate(new Date());
  statusId: number | null = null;
  countryId: number | null = null;
}
