import { Period } from 'models/responses/period.model';
import { Product } from 'models/responses/product.model';
import { ProductPrice } from 'models/responses/productPrice.model';
import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';
import constants from 'utils/constants';

export class EditProductForm extends BaseValidatedObject {
  productId: number | null = null;
  code = '';
  name = '';
  renewalUrl = '';
  siteUrl = '';
  purchaseUrl = '';
  autoRenewalEnabled: boolean | null = null;
  prices: ProductPrice[] = [];

  constructor(product: Product | null) {
    super();

    if (product) {
      this.productId = product.productId;
      this.code = product.code;
      this.name = product.name;
      this.renewalUrl = product.renewalUrl;
      this.siteUrl = product.siteUrl;
      this.purchaseUrl = product.purchaseUrl;
      this.autoRenewalEnabled = product.autoRenewalEnabled;
      this.prices = product.prices;
    }
  }

  setBlankPrices = async (periods: Period[]) => {
    this.prices = [];

    periods.forEach((period) => {
      this.prices.push({ storeId: constants.stores.subscriptionsUk, periodCode: period.code, price: null });
      this.prices.push({ storeId: constants.stores.subscriptionsUs, periodCode: period.code, price: null });
    });
  };
}
