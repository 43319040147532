import { IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import cookie from 'react-cookies';
import appInsights from 'App';
import { AppError } from 'models/generic/appError.model';
import { Administrator } from 'models/responses/administrator.model';
import { Customer } from 'models/responses/customer.model';

export interface IInsightsHook {
  logErrorToAppInsights: (error: AppError) => void;
}

export interface IInsightsHookProps {
  administrator: Administrator | null;
  customer: Customer | null;
}

export function useInsights(props: IInsightsHookProps): IInsightsHook {
  const currentAdminUser: Administrator | null = props.administrator;
  const currentCustomerUser: Customer | null = props.customer;

  const logErrorToAppInsights = (error: AppError) => {
    if (process.env.REACT_APP_INSIGHTS_KEY) {
      const errorObject: IExceptionTelemetry = {
        exception: new global.Error(error.errorMessages[0]),
        severityLevel: SeverityLevel.Error
      };

      // log error code, status and messages
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const properties: any = {};
      if (error.code) {
        properties.code = error.code;
      }
      if (error.statusText) {
        properties.statusText = error.statusText;
      }
      properties.messages = error.errorMessages;

      // log current URL and user
      properties.url = document.location.href;

      if (currentCustomerUser) {
        properties.userId = currentCustomerUser.customerId;
        properties.firstName = currentCustomerUser.firstName;
        properties.lastName = currentCustomerUser.lastName;
        properties.userEmail = currentCustomerUser.email;
      }

      if (currentAdminUser) {
        properties.userId = currentAdminUser.administratorId;
        properties.userEmail = currentAdminUser.loginName;
      }

      // log session ID
      const cookies = cookie.loadAll();
      const sessionValue = cookies['ai_session'];
      if (sessionValue) {
        properties.session = sessionValue;
      }

      errorObject.properties = properties;

      appInsights.trackException(errorObject);
    }
  };

  return {
    logErrorToAppInsights
  };
}
