import { Customer } from 'models/responses/customer.model';
import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class EditCustomerForm extends BaseValidatedObject {
  title: string | null = null;
  firstName = '';
  lastName = '';
  address1 = '';
  address2 = '';
  city = '';
  postCode = '';
  county = '';
  countryId: number | null = null;
  provinceId: number | null = null;
  telephone = '';
  fax = '';
  mobileCountryCode = '';
  mobileNumber = '';

  constructor(user: Customer | null) {
    super();

    if (user) {
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.title = user.title;

      if (user.address) {
        this.address1 = user.address.address1;
        this.address2 = user.address.address2;
        this.city = user.address.city;
        this.postCode = user.address.postCode;
        this.county = user.address.county;
        this.countryId = user.address.countryId;
        this.provinceId = user.address.provinceId;
        this.telephone = user.address.telephone;
        this.fax = user.address.fax;
        this.mobileCountryCode = user.address.mobileCountryCode;
        this.mobileNumber = user.address.mobileNumber;
      }
    }
  }
}
