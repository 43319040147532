import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { useBasket } from 'hooks/useBasket';
import { useOrders } from 'hooks/useOrders';
import { CreatePaymentSessionForm } from 'models/forms/createPaymentSessionForm.model';
import { LogPaymentFailedForm } from 'models/forms/logPaymentFailedForm.model';
import { LogPaymentInvokedForm } from 'models/forms/logPaymentInvokedForm.model';
import { AppError } from 'models/generic/appError.model';
import { PaymentSession } from 'models/responses/paymentSession.model';
import { PaymentSummary } from 'models/responses/paymentSummary.model';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import utils from 'utils/utils';
import visaLogo from 'assets/images/Visa.svg';
import mastercardLogo from 'assets/images/Mastercard.svg';
import discoverLogo from 'assets/images/Discover.svg';
import amexLogo from 'assets/images/Amex.svg';
import jcbLogo from 'assets/images/JCB.svg';
import { useGooglePay } from 'hooks/useGooglePay';
import { useApplePay } from 'hooks/useApplePay';
import classNames from 'classnames';
import styles from './Basket.module.scss';

export const Payment: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<'card' | 'google' | 'apple' | null>(null);
  const [details, setDetails] = useState<PaymentSession>(location.state as PaymentSession);

  let paymentConfig: unknown = null;
  let countryCode = 'UK';

  const basket = useBasket();
  const orders = useOrders();
  const googlePay = useGooglePay();
  const applePay = useApplePay();
  const currentBasket = basket.currentBasket;

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    // reset any potential previous error messages when a payment method changes
    setErrorMessage('');
  }, [paymentMethod]);

  useEffect(() => {
    if (errorMessage) {
      // scroll to error
      const errorElement = document.getElementById('paymentError');
      if (errorElement && errorElement.scrollIntoView) {
        errorElement.scrollIntoView();
      }
    }
  }, [errorMessage]);

  const init = async () => {
    window.scrollTo(0, 0);

    setLoading(true);

    loadPaymentForm();

    // check if judo details passed
    const judo = utils.judopay.getJudo(details);
    if (!judo) {
      // if no details are passed then go to basket
      navigate('/basket');
    }

    // add Google Pay button if enabled
    googlePay.init(details, 'paymentMethodButtons', logPaymentFailedAndShowErrorMessage);

    // add Apple Pay button if enabled
    applePay.init(details, 'paymentMethodButtons', logPaymentFailedAndShowErrorMessage);

    setLoading(false);
  };

  const loadPaymentForm = () => {
    // update country code based on currency
    if (details.currencyCode === 'GBP') {
      countryCode = 'UK';
    } else if (details.currencyCode === 'USD') {
      countryCode = 'US';
    }

    const config = {
      iframe: {
        language: 'en',
        errorFieldId: 'judopay__errors',
        showCardTypeIcons: true,
        cardTypeIconRight: '10px',
        cardTypeIconTop: '-2px',
        backgroundColor: '#f8fcff;', // $cardBlue
        layout: 'compact',
        enabledPaymentMethods: ['CARD'],
        defaultCountryCode: countryCode,
        isCountryAndPostcodeVisible: false,
        isCardHolderNameVisible: true,
        errorsDisplay: 'HIDE_UNDER_FIELDS',
        disableUnderline: true,
        styles: {
          default: {
            field: {
              fontFamily: "'Roboto', sans-serif",
              fontSize: '16px',
              backgroundColor: 'white',
              color: '#2b2b2c',
              border: 'solid 1px #c3c4c6', // $gabMidGrey
              margin: '0',
              padding: '8px 8px 4px 8px'
            },
            label: {
              color: '#2b2b2c',
              fontFamily: "'Roboto', sans-serif",
              opacity: '0.7',
              height: '16px',
              fontSize: '12px',
              display: 'inline-block',
              transition: 'opacity 0.6s',
              margin: '2px 0 0 8px',
              position: 'relative',
              bottom: '-10px',
              zIndex: '100'
            }
          },
          focus: {
            field: {
              backgroundColor: 'white',
              color: '#2b2b2c', // $gabDarkGrey
              padding: '8px 8px 4px 8px',
              fontSize: '16px'
            },
            label: {
              opacity: '0'
            }
          },
          error: {
            field: {
              backgroundColor: '#fff5f5', // $errorLight
              color: '#c70000', // $error
              padding: '8px 8px 4px 8px'
            },
            label: {
              opacity: '0'
            }
          },
          valid: {
            field: {
              backgroundColor: 'white',
              color: '#2b2b2c', // $gabDarkGrey
              padding: '8px 8px 4px 8px'
            },
            label: {
              opacity: '0'
            }
          }
        }
      },
      isGeoLocationGatheringAllowed: false
    };

    const judo = utils.judopay.getJudo(details);
    judo.createCardDetails('judopay__form', config);

    // set title for iframe for accessibility
    const iframe = document.getElementsByTagName('iframe');
    if (iframe && iframe[0]) {
      iframe[0].title = 'Judopay';
    }
  };

  const processPayment = async () => {
    setPaymentInProgress(true);

    let paymentSession = details.session;
    let consumerReference = details.consumerReference;
    let paymentReference = details.paymentReference;

    // if an error message occurred then create a new payment session
    if (errorMessage) {
      const createPaymentSessionForm = new CreatePaymentSessionForm();
      createPaymentSessionForm.basketId = details.basketId;
      createPaymentSessionForm.universityId = details.universityId;

      const result = await orders.createPaymentSession(createPaymentSessionForm);
      if (result instanceof AppError) {
      } else {
        // if new session is returned then update the details for next payment attempt
        paymentSession = result.session;
        consumerReference = result.consumerReference;
        paymentReference = result.paymentReference;

        setDetails(result);
      }

      // also reset error message on new payment attempt
      setErrorMessage('');
    }

    const config = {
      judoId: details.judoId,
      amount: currentBasket.totalWithTax,
      currency: details.currencyCode,
      yourConsumerReference: consumerReference,
      yourPaymentReference: paymentReference,
      challengeRequestIndicator: 'challengeAsMandate',
      // billingAddress: {
      //   address1: details.address.address1,
      //   address2: details.address.address2,
      //   town: details.address.city,
      //   postcode: details.address.postCode,
      //   countryCode: +details.countryCode,
      //   state: details.stateCode
      // },
      // mobileNumber: details.address.mobileNumber || '0',
      // phoneCountryCode: details.address.mobileCountryCode || '0',
      emailAddress: details.email
    };

    // save config for logging
    paymentConfig = config;

    // log payment invoked event
    const logForm = new LogPaymentInvokedForm(details.orderId, 'card', paymentSession, config);
    await orders.logPaymentInvoked(logForm);

    const judo = utils.judopay.getJudo(details);
    judo.invokePayment(paymentSession, config).then(handleSuccess).catch(handleError);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSuccess = async (result: any) => {
    setPaymentInProgress(false);

    // success callback sometimes come back as Declined or Error, so check this
    if (result.result === 'Success') {
      // payment successful

      // reset global judo object after successful payment
      utils.judopay.resetJudo();

      // go to order confirmation page
      navigate('/confirmation', { state: new PaymentSummary(details.orderId, result.message || null, 'success', 'card', result) });
    } else {
      // payment declined or error
      const logForm = new LogPaymentFailedForm(details, 'card', 'success', paymentConfig, null, result);

      await logPaymentFailedAndShowErrorMessage(logForm);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = async (error: any) => {
    setPaymentInProgress(false);

    // payment failed, so log this and show an error message
    const logForm = new LogPaymentFailedForm(details, 'card', 'error', paymentConfig, error, null);

    await logPaymentFailedAndShowErrorMessage(logForm);
  };

  const logPaymentFailedAndShowErrorMessage = async (logForm: LogPaymentFailedForm) => {
    // log payment failed event
    await orders.logPaymentFailed(logForm);

    // display returned error message
    setErrorMessage(logForm.errorMessage || logForm.responseMessage || 'Unknown error occurred.');
  };

  const backToBasket = () => {
    navigate('/basket');
  };

  return (
    <div className={appStyles.container} id="Payment">
      <div className={appStyles.pageHeading}>
        <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
          <Icon icon={Icons.card} size="heading" color="darkBlue" iconName="Payment" customClass={appStyles.heading__icon} />
          <h1 className={appStyles.heading__text}>Payment</h1>
        </div>
      </div>

      <h2>
        Total: {currentBasket.currencySymbol}
        {currentBasket.totalWithTax.toFixed(2)}
      </h2>

      <form className={appStyles.row}>
        <div className={appStyles.col_md_6}>
          {/* Payment method panel visible until selected */}
          {paymentMethod === null && (
            <div id="paymentMethod" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form, styles.payment)}>
              <Legend type="large" text="Payment method" />

              {loading && <Alert alertType="info">Loading payment methods...</Alert>}

              <div id="paymentMethodButtons" className={appStyles.payment__buttonsContainer}>
                <Button
                  id="buttonPayByCard"
                  customClass={styles.payment__button}
                  buttonStyle="primary"
                  size="lg"
                  handleClick={() => {
                    setPaymentMethod('card');
                  }}>
                  Pay by card
                </Button>
              </div>

              {details.showAutoRenewWarning && (
                <>
                  <br />
                  <p>
                    Please note auto renew is not supported when paying with Google Pay or Apple Pay, so with those payment options the purchase will be for the
                    initial term only.
                  </p>
                </>
              )}

              <Legend customClass={appStyles.paymentCard__title} type="large" text="Accepted cards" />
              <div className={appStyles.paymentCardIcon__container}>
                <img className={appStyles.paymentCard__icon} title="Visa" src={visaLogo} alt="Visa logo" />
                <img className={appStyles.paymentCard__icon} title="Mastercard" src={mastercardLogo} alt="Mastercard logo" />
                <img className={appStyles.paymentCard__icon} title="American Express" src={amexLogo} alt="American Express logo" />
                <img className={appStyles.paymentCard__icon} title="Discover" src={discoverLogo} alt="Discover logo" />
                <img className={appStyles.paymentCard__icon} title="JCB" src={jcbLogo} alt="JCB logo" />
              </div>
            </div>
          )}

          {/* Payment by card panel */}
          <div
            id="paymentDetailsCard"
            className={classNames({ [styles.payment_hidden]: paymentMethod !== 'card' }, appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
            <fieldset>
              <Legend type="large" text="Payment details" />
              <div className={appStyles.form__row}>
                <div id="judopay__form" className={styles.judopay__form} />
              </div>
              <div id="judopay__errors" className={classNames(styles.judopay__errors, 'judopay__errors')} />
            </fieldset>
          </div>

          {errorMessage && (
            <div id="paymentError" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form, styles.payment)}>
              <Legend type="large" text="Payment error" />

              <Alert alertType="error" id="alertFailedPayment">
                Processing payment failed.
                {errorMessage && (
                  <>
                    <br />
                    <ul>
                      <li>{errorMessage}</li>
                    </ul>
                  </>
                )}
                <br />
                Please check your details and try again or use a different payment method or card.
              </Alert>
            </div>
          )}
        </div>
      </form>

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button id="buttonBack" buttonStyle="secondary" size="lg" handleClick={backToBasket}>
          Back to basket
        </Button>

        {/* Pay now button visible only when payment by card is selected */}
        {paymentMethod === 'card' && (
          <Button id="submit-payment-button" buttonStyle="primary" size="lg" handleClick={processPayment} inProgress={paymentInProgress}>
            Pay now
          </Button>
        )}
      </BottomButtonContainer>
    </div>
  );
};
