import { apiContext } from 'App';
import { CompleteOrderForm } from 'models/forms/completeOrderForm.model';
import { CreatePaymentSessionForm } from 'models/forms/createPaymentSessionForm.model';
import { LogPaymentFailedForm } from 'models/forms/logPaymentFailedForm.model';
import { LogPaymentInvokedForm } from 'models/forms/logPaymentInvokedForm.model';
import { LoadOrdersForm } from 'models/forms/loadOrdersForm.model';
import { RefundForm } from 'models/forms/refundForm.model';
import { AppError } from 'models/generic/appError.model';
import { Order } from 'models/responses/order.model';
import { OrderConfirmation } from 'models/responses/orderConfirmation.model';
import { OrderLoggedConfirmation } from 'models/responses/orderLoggedConfirmation.model';
import { PaymentSession } from 'models/responses/paymentSession.model';
import { Refund } from 'models/responses/refund.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import { useContext } from 'react';

export interface IOrdersHook {
  getOrders: (form: LoadOrdersForm) => Promise<Order[] | AppError>;
  getOrderById: (orderId: number) => Promise<Order | null>;
  logPaymentInvoked: (form: LogPaymentInvokedForm) => Promise<OrderLoggedConfirmation | AppError>;
  logPaymentFailed: (form: LogPaymentFailedForm) => Promise<OrderLoggedConfirmation | AppError>;
  completeOrder: (form: CompleteOrderForm) => Promise<OrderConfirmation | AppError>;
  refundOrder: (form: RefundForm) => Promise<Refund | AppError>;
  sendOrderConfirmaton: (orderId: number) => Promise<boolean | AppError>;
  createPaymentSession: (form: CreatePaymentSessionForm) => Promise<PaymentSession | AppError>;
  getRefundValidationRules: () => Promise<ValidationRule[]>;
}

export function useOrders(): IOrdersHook {
  const api = useContext(apiContext);

  const getOrders = async (form: LoadOrdersForm): Promise<Order[] | AppError> => {
    const response = await api.post<Order[]>('order', form, 'Loading orders failed.');
    if (response instanceof AppError) {
    } else {
      response.forEach((item) => {
        item.orderDate = new Date(item.orderDate);
      });
    }

    return response;
  };

  const getOrderById = async (orderId: number): Promise<Order | null> => {
    let order = null;
    const response = await api.get<Order>(`order/${orderId}`, 'There was an error loading order.');

    if (response instanceof AppError) {
    } else {
      response.orderDate = new Date(response.orderDate);
      order = response;

      if (order.statusUpdates) {
        order.statusUpdates.forEach((statusUpdate) => {
          statusUpdate.dateUpdated = new Date(statusUpdate.dateUpdated);
        });
      }
    }

    return order;
  };

  const logPaymentInvoked = async (form: LogPaymentInvokedForm): Promise<OrderLoggedConfirmation | AppError> =>
    api.post<OrderLoggedConfirmation>(`order/invoked`, form, 'There was an error logging payment invoked event.');

  const logPaymentFailed = async (form: LogPaymentFailedForm): Promise<OrderLoggedConfirmation | AppError> =>
    api.post<OrderLoggedConfirmation>(`order/failed`, form, 'There was an error logging payment failed event.');

  const completeOrder = async (form: CompleteOrderForm): Promise<OrderConfirmation | AppError> =>
    api.post<OrderConfirmation>(`order/complete`, form, 'There was an error completing order.');

  const refundOrder = async (form: RefundForm): Promise<Refund | AppError> => await api.post<Refund>('order/refund', form, 'Refund for order failed.');

  const sendOrderConfirmaton = async (orderId: number): Promise<boolean | AppError> =>
    api.get<boolean>(`order/${orderId}/sendorderemail`, 'Sending order confirmation email failed.');

  const createPaymentSession = async (form: CreatePaymentSessionForm): Promise<PaymentSession | AppError> =>
    api.post<PaymentSession>(`order/payment`, form, 'There was an error creating payment session.');

  const getRefundValidationRules = async (): Promise<ValidationRule[]> => api.getValidationRules('order/refund/validation');

  return {
    getOrders,
    getOrderById,
    logPaymentInvoked,
    logPaymentFailed,
    completeOrder,
    refundOrder,
    sendOrderConfirmaton,
    createPaymentSession,
    getRefundValidationRules
  };
}
