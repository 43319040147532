import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class UpdateAutoRenewalForm extends BaseValidatedObject {
  msdOrderId: number | null = null;
  autoRenew: boolean | null = null;

  constructor(msdOrderId: number, autoRenew: boolean) {
    super();

    this.msdOrderId = msdOrderId;
    this.autoRenew = autoRenew;
  }
}
