import { adminContext, customerContext, messageContext } from 'App';
import appStyles from 'App.module.scss';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { FormWrapper } from 'components/Generic/FormWrapper/FormWrapper';
import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import utils from 'utils/utils';

export const Logout: FC = () => {
  const [redirectToUrl, setRedirectToUrl] = useState('/login');

  const messages = useContext(messageContext);

  const isAdminSite = utils.routing.isAdminSite;
  const auth = isAdminSite ? useContext(adminContext) : useContext(customerContext);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (utils.routing.isAdminSite) {
      setRedirectToUrl('/admin/login');
    }

    if (window.location.search.includes('expired')) {
      messages.addWarningAsString('Your session expired. You have been logged out.');
    }

    auth.logOut();
  };

  return (
    <div className={appStyles.form__container} id="LogoutForm">
      <FormWrapper formTitle="Logout" className={appStyles.form__wrapper}>
        <div className={appStyles.form__row}>
          <p className={appStyles.form__wrapperText}>You have signed out of your account </p>
        </div>
        <div className={appStyles.form__row}>
          <Link to={redirectToUrl} title="Click here to login again">
            <Button buttonStyle="primary" size="lg" title="Click to go back to login form">
              Click here to log in again
            </Button>
          </Link>
        </div>
      </FormWrapper>
    </div>
  );
};
