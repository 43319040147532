import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { ValidationArea } from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { ChangeEvent, FC } from 'react';

interface ITextAreaProps {
  /**
   * @property {string} elementId Set the id attribute
   */
  elementId: string;
  /**
   * @property {string} labelText Set the name attribute
   */
  labelText: string;
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   * @property {string} resize Set the type of the textarea resize - none, vertical, horizontal, both
   */
  resize?: 'none' | 'vertical' | 'horizontal' | 'both';
  /**
   * @property {number} rows Set the rows attribute
   */
  rows?: number;
  /**
   * @property {number} cols Set the cols attribute
   */
  cols?: number;
  /**
   * @property {string} value Set the value attribute
   */
  value: string | null;
  /**
   * @property {string} placeholder Set the placeholder attribute
   */
  placeholder: string;
  /**
   * @property {(event: ChangeEvent<HTMLInputElement>) => void} handleChange Set the action onChange
   */
  handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  /**
   * @property {string} customTextAreaClass Optional. Use a custom class on the textarea element - use styles from the current component or appStyles
   */
  customTextAreaClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {string[]} validationErrors Optional. Any validation messages related to this field
   */
  validationErrors?: string[];
}
/**
 * @description Add a textarea element with a label. Optional properties: resize, rows, cols, customTextAreaClass, customLabelClass
 */
export const TextArea: FC<ITextAreaProps> = (props: ITextAreaProps) => (
  <>
    <label
      htmlFor={props.name}
      className={classNames(
        appStyles.form__label,
        { [appStyles.form__label_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
        { [props.customLabelClass as string]: props.customLabelClass } // add custom class if defined
      )}>
      {props.labelText}
    </label>
    <textarea
      className={classNames(
        appStyles.form__input,
        appStyles.form__input_textarea,
        { [appStyles[`resize_${props.resize}`]]: props.resize }, // add resize if specified
        { [appStyles.resize_vertical]: !props.resize }, // add resizeVertical if resize not specified
        { [appStyles.form__input_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
        { [props.customTextAreaClass as string]: props.customTextAreaClass } // add custom class if defined
      )}
      id={props.elementId}
      name={props.name}
      rows={props.rows}
      cols={props.cols}
      value={props.value || ''}
      placeholder={props.placeholder}
      onChange={props.handleChange}
    />

    <ValidationArea sectionId={props.elementId} validationErrors={props.validationErrors} />
  </>
);

export class TextAreaHelper {
  static baseHandleString<T>(e: ChangeEvent<HTMLTextAreaElement>, obj: T): T {
    const value = e.target.value;
    const fieldName = e.target.name;

    obj = {
      ...obj,
      [fieldName]: value
    };

    return obj;
  }
}
