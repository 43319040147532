import appStyles from 'App.module.scss';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { Input, InputHelper } from 'components/Generic/FormElements/Input/Input';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import { Select, SelectHelper } from 'components/Generic/FormElements/Select/Select';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { customerContext, messageContext } from 'App';
import { EditCustomerForm } from 'models/forms/editCustomerForm.model';
import { AppError } from 'models/generic/appError.model';
import { ListItem } from 'models/generic/listItem.model';
import { Country } from 'models/responses/country.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLookups } from 'hooks/useLookups';
import validation from 'utils/validation';
import utils from 'utils/utils';
import constants from 'utils/constants';
import classNames from 'classnames';
import styles from './EditCustomer.module.scss';

export const EditCustomer: FC = () => {
  const navigate = useNavigate();

  const messages = useContext(messageContext);
  const lookups = useLookups();
  const customerAuth = useContext(customerContext);
  const currentUser = customerAuth.getCurrentUser();

  const [form, setForm] = useState(new EditCustomerForm(currentUser));
  const [inProgress, setInProgress] = useState(false);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [titles, setTitles] = useState<ListItem[]>([]);
  const [countries, setCountries] = useState<ListItem[]>([]);
  const [country, setCountry] = useState<Country | null>(null);
  const [provinceItems, setProvinceItems] = useState<ListItem[]>([]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    // reload provinces if country changed
    if (country) {
      setProvinceItems(lookups.getProvincesAsListItems(country.provinces));
    }
  }, [country]);

  useEffect(() => {
    // update form if provinces changed (and country already loaded)
    if (country) {
      if (provinceItems.length) {
        // reset textbox value to blank if list is used
        form.county = '';
        setForm(form);
      } else {
        // reset list value to null if textbox is used
        form.provinceId = null;
        setForm(form);
      }
    }
  }, [provinceItems]);

  const init = async () => {
    if (!currentUser) {
      throw new Error('Current user is null');
    }

    setTitles(lookups.getTitles());
    setCountries(await lookups.getCountriesAsListItems());

    await loadCountry(form.countryId);

    setValidationRules(await customerAuth.getEditCustomerValidationRules());

    // run save and full validation if loaded user is not valid to show any errors on load
    if (customerAuth.isValid === false) {
      save();
    }
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = InputHelper.baseHandleString(e, form);

    // convert post code to upper case
    if (e.target.name === 'postCode') {
      updatedForm.postCode = updatedForm.postCode.toUpperCase();
    }

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const updatedForm = SelectHelper.baseHandleString(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleSelectAsNumber = async (e: ChangeEvent<HTMLSelectElement>) => {
    const updatedForm = SelectHelper.baseHandleNumber(e, form);

    // reload country and its provinces if selected countryId changed
    if (e.target.name === 'countryId') {
      const countryId = +e.target.value;
      if (countryId !== country?.countryId) {
        await loadCountry(+e.target.value);
      }
    }

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const loadCountry = async (countryId: number | null) => {
    if (countryId) {
      setCountry(await lookups.getCountryById(countryId));
    }
  };

  const validate = (form: EditCustomerForm): boolean => validation.validateForm(form, validationRules, setForm);

  const back = () => {
    navigate('/');
  };

  const save = async () => {
    setSubmitAttempted(true);

    const isValid = validate(form);
    if (isValid) {
      setInProgress(true);
      const result = await customerAuth.updateCustomer(form);
      // if result is error than check for validation messages
      if (result instanceof AppError) {
        form.fieldMessages = result.fieldMessages;
        setForm(form);
        setInProgress(false);
      } else {
        if (currentUser) {
          // update current user object
          result.rawToken = currentUser.rawToken;
          result.tokenExpiry = currentUser.tokenExpiry;
          customerAuth.setCurrentUser(result);

          // also set customer as valid after save
          customerAuth.setIsValid(true);
          utils.localStorage.saveToLocalStorage(constants.storageKeys.isValid, true);
        }
        messages.addSuccessAsString('Your details have been saved.');
        // go back to dashboard
        navigate('/');
      }
    }
  };

  return (
    <>
      <div className={appStyles.container} id="EditDetailsForm">
        <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
          <Icon icon={Icons.user} size="lg" color="darkBlue" iconName="User" customClass={appStyles.heading__icon} />
          <h1 className={appStyles.heading__text}>Edit details</h1>
        </div>

        <form className={appStyles.row}>
          <div className={appStyles.col_md_6}>
            <div id="personalDetailsCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
              <fieldset>
                <Legend type="large" text="Personal details" />
                <div className={appStyles.form__row}>
                  <Select
                    elementId="title"
                    labelText="Title"
                    name="title"
                    options={titles}
                    placeholder="Select your title"
                    disablePlaceholder={true}
                    value={form.title}
                    handleChange={handleSelect}
                    validationErrors={form.fieldMessages['title']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="firstName"
                    inputType="text"
                    labelText="First name"
                    name="firstName"
                    placeholder="Enter your first name"
                    handleChange={handleInput}
                    value={form.firstName}
                    autocomplete="given-name"
                    validationErrors={form.fieldMessages['firstName']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="lastName"
                    inputType="text"
                    labelText="Last name"
                    name="lastName"
                    placeholder="Enter your last name"
                    handleChange={handleInput}
                    value={form.lastName}
                    autocomplete="family-name"
                    validationErrors={form.fieldMessages['lastName']}
                  />
                </div>
              </fieldset>
            </div>

            <div id="phoneDetailsCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
              <fieldset>
                <Legend type="large" text="Phone details" />

                <div className={appStyles.form__row}>
                  <Input
                    elementId="telephone"
                    inputType="text"
                    labelText="Telephone number"
                    name="telephone"
                    placeholder="Telephone number"
                    handleChange={handleInput}
                    value={form.telephone}
                    autocomplete="tel"
                    validationErrors={form.fieldMessages['telephone']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="mobileCountryCode"
                    inputType="text"
                    labelText="Mobile number with country code (e.g. 44 1234567890)"
                    name="mobileCountryCode"
                    placeholder="Code"
                    handleChange={handleInput}
                    value={form.mobileCountryCode}
                    customInputClass={styles.code}
                    validationErrors={form.fieldMessages['mobileCountryCode']}
                  />
                  <Input
                    elementId="mobileNumber"
                    inputType="text"
                    labelText=""
                    name="mobileNumber"
                    placeholder="Mobile number"
                    handleChange={handleInput}
                    value={form.mobileNumber}
                    autocomplete="tel"
                    customInputClass={styles.number}
                    customLabelClass={styles.hidden}
                    validationErrors={form.fieldMessages['mobileNumber']}
                  />
                </div>
              </fieldset>
            </div>
          </div>

          <div className={appStyles.col_md_6}>
            <div id="addressDetailsCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
              <fieldset>
                <Legend type="large" text="Address details" />
                <div className={appStyles.form__row}>
                  <Select
                    elementId="countryId"
                    labelText="Country"
                    name="countryId"
                    options={countries}
                    placeholder="Select your country"
                    disablePlaceholder={true}
                    value={form.countryId}
                    handleChange={handleSelectAsNumber}
                    validationErrors={form.fieldMessages['countryId']}
                  />
                </div>
                {form.countryId &&
                  (provinceItems.length > 0 ? (
                    <div className={appStyles.form__row}>
                      <Select
                        elementId="provinceId"
                        labelText="State/Province"
                        name="provinceId"
                        options={provinceItems}
                        placeholder="Select your state/province"
                        disablePlaceholder={true}
                        value={form.provinceId}
                        handleChange={handleSelectAsNumber}
                        validationErrors={form.fieldMessages['provinceId']}
                      />
                    </div>
                  ) : (
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="county"
                        inputType="text"
                        labelText="County/State/Province"
                        name="county"
                        placeholder="Enter your county"
                        handleChange={handleInput}
                        value={form.county}
                        validationErrors={form.fieldMessages['county']}
                      />
                    </div>
                  ))}
                <div className={appStyles.form__row}>
                  <Input
                    elementId="address1"
                    inputType="text"
                    labelText="Address line 1"
                    name="address1"
                    placeholder="Enter your address line 1"
                    handleChange={handleInput}
                    value={form.address1}
                    autocomplete="address-line1"
                    validationErrors={form.fieldMessages['address1']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="address2"
                    inputType="text"
                    labelText="Address line 2"
                    name="address2"
                    placeholder="Enter your address line 2"
                    handleChange={handleInput}
                    value={form.address2}
                    autocomplete="address-line2"
                    validationErrors={form.fieldMessages['address2']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="city"
                    inputType="text"
                    labelText="Town/City"
                    name="city"
                    placeholder="Enter your city"
                    handleChange={handleInput}
                    value={form.city}
                    autocomplete="address-line3"
                    validationErrors={form.fieldMessages['city']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="postCode"
                    inputType="text"
                    labelText={country?.postCodeSample ? 'Post/Zip code (please use format: ' + country.postCodeSample + ')' : 'Post/Zip code'}
                    name="postCode"
                    placeholder="Enter your post code"
                    handleChange={handleInput}
                    value={form.postCode}
                    autocomplete="postal-code"
                    validationErrors={form.fieldMessages['postCode']}
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </form>
      </div>

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button buttonStyle="secondary" size="lg" handleClick={back}>
          Back to dashboard
        </Button>

        <Button id="saveButton" buttonStyle="primary" size="lg" handleClick={save} inProgress={inProgress} title="Click to save your details">
          Save changes
        </Button>
      </BottomButtonContainer>
    </>
  );
};
