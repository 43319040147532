import { DateOnly } from 'models/generic/dateOnly';
import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class LoadLogsForm extends BaseValidatedObject {
  orderId: number | null = null;
  customerId: number | null = null;
  startDate: DateOnly = DateOnly.fromDate(new Date());
  endDate: DateOnly = DateOnly.fromDate(new Date());
  logTypeId: number | null = null;
}
