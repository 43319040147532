import appStyles from 'App.module.scss';
import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './BottomButtonContainer.module.scss';

interface IBottomButtonContainerProps {
  /**
   * @property {string} backgroundColor Set the background colour of the container
   */
  backgroundColor?: 'white' | 'oxfordBlue' | 'paleBlue' | 'gabBlue';
  /**
   * @property {string} layout Set the layout of the container content (based on flexbox properties set in style.scss). Default is spaceBetween
   */
  layout?: 'left' | 'right' | 'spaceBetween';
  /**
   * @property {ReactNode} children Add elements inside this container
   */
  children: ReactNode;
}

/**
 * @description Add a bottom button container. Optional properties: backgroundColor (default is transparent), layout (default is spaceBetween)
 */
export const BottomButtonContainer: FC<IBottomButtonContainerProps> = (props: IBottomButtonContainerProps) => (
  <>
    <div className={classNames(styles.containerBottom, appStyles[`background_${props.backgroundColor || 'transparent'}_tablet`])}>
      <div className={classNames(appStyles.container, styles.containerBottom__content, styles[`layout_${props.layout || 'spaceBetween'}`])}>
        {props.children}
      </div>
    </div>
    <div className={styles.containerBottom__spacer} />
  </>
);
