import { Administrator } from 'models/responses/administrator.model';
import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class EditAdministratorForm extends BaseValidatedObject {
  name = '';
  login = '';
  password = '';
  confirmPassword = '';
  isDisabled = false;
  dateFormat = '';

  constructor(administrator: Administrator | null) {
    super();

    if (administrator) {
      this.name = administrator.displayName;
      this.login = administrator.loginName;
      this.isDisabled = administrator.disabled;
      this.dateFormat = administrator.dateFormat;
    }
  }
}
