import { ITokenResponse } from 'models/responses/tokenResponse.model';
import moment, { Moment } from 'moment';

export class Administrator {
  administratorId: number | null = null;
  loginName = '';
  displayName = '';
  disabled = false;
  dateFormat: 'uk' | 'us' = 'uk';
  isActive = false;

  rawToken = '';
  tokenExpiry: Moment | null = null;

  static fromTokenResponse(tokenResponse: ITokenResponse): Administrator {
    if (tokenResponse.admin && tokenResponse.token) {
      tokenResponse.admin.rawToken = tokenResponse.token;
      tokenResponse.admin.tokenExpiry = moment(tokenResponse.expiry);

      return tokenResponse.admin;
    } else {
      throw new Error('Admin response is null');
    }
  }
}
