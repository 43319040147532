import appStyles from 'App.module.scss';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { useLogs } from 'hooks/useLogs';
import { Log } from 'models/responses/log.model';
import { FC, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { Link, useNavigate, useParams } from 'react-router-dom';
import utils from 'utils/utils';
import classNames from 'classnames';
import LoadingAlert from 'components/Generic/LoadingAlert/LoadingAlert';
import styles from './Logs.module.scss';

export const LogDetails: FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [log, setLog] = useState<Log | null>(null);
  const logs = useLogs();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const logId = params.id ? +params.id : null;
    if (logId) {
      setLog(await logs.getLogById(logId));
    } else {
      navigate('/admin/logs');
    }
    setIsLoaded(true);
  };

  const back = () => {
    navigate('/admin/logs');
  };

  const getOptionalLines = (log: Log) => (
    <>
      {log.emailType && (
        <div className={appStyles.card__row}>
          <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Email type</div>
          <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{log.emailType}</div>
        </div>
      )}

      {log.customerId && (
        <div className={appStyles.card__row}>
          <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Customer</div>
          <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>
            {log.customerName}, ID {log.customerId}
          </div>
        </div>
      )}

      {log.adminId && (
        <div className={appStyles.card__row}>
          <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Admin</div>
          <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>
            {log.adminName}, ID {log.adminId}
          </div>
        </div>
      )}

      {log.orderId && (
        <div className={appStyles.card__row}>
          <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Order ID</div>
          <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>
            <Link to={'/admin/orders/' + log.orderId}>{log.orderId}</Link>
          </div>
        </div>
      )}
    </>
  );

  const notFound = isLoaded && log === null;

  return (
    <div className={appStyles.container} id="AdminLogDetails">
      {isLoaded === false ? (
        <LoadingAlert />
      ) : notFound ? (
        <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
          <Icon icon={Icons.warning} size="lg" color="darkBlue" iconName="Warning" customClass={appStyles.heading__icon} />
          <h1 id="NotFound" className={appStyles.heading__text}>
            Log not found
          </h1>
        </div>
      ) : log ? (
        <>
          <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
            <Icon icon={Icons.logs} size="lg" color="darkBlue" iconName="Logs" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>Log details</h1>
          </div>

          <div className={appStyles.row}>
            <div className={classNames(appStyles.col_md_6, appStyles.col_sm_12)}>
              <div className={classNames(appStyles.card, styles.card, appStyles.background_white, appStyles.card_boxShadow)}>
                <Legend text="Main details" type="medium" />
                <div className={appStyles.card__row}>
                  <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Log ID</div>
                  <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{log.logId}</div>
                </div>
                <div className={appStyles.card__row}>
                  <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Date</div>
                  <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{utils.dateTime.getFormattedDateAndTime(log.date)}</div>
                </div>
                <div className={appStyles.card__row}>
                  <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Message</div>
                  <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{log.message}</div>
                </div>
                <div className={appStyles.card__row}>
                  <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Log type</div>
                  <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{log.eventTypeName}</div>
                </div>

                {getOptionalLines(log)}
              </div>
            </div>
          </div>

          {log.data && (
            <div className={appStyles.row}>
              <div className={appStyles.col_12}>
                <div className={classNames(appStyles.card, styles.card, appStyles.background_white, appStyles.card_boxShadow)}>
                  <Legend text="Data" type="medium" />
                  <div className={appStyles.card__row} />
                  <div className={appStyles.card__row}>
                    <div className={classNames(appStyles.card__data, appStyles.text_oxfordBlue)}>
                      {log.legacy ? (
                        log.data
                      ) : (
                        <ReactJson
                          src={log.data}
                          theme="monokai"
                          iconStyle="square"
                          indentWidth={4}
                          collapsed={2}
                          enableClipboard={false}
                          displayObjectSize={true}
                          collapseStringsAfterLength={100}
                          displayDataTypes={false}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {log.exception && (
            <div className={appStyles.row}>
              <div className={appStyles.col_12}>
                <div className={classNames(appStyles.card, styles.card, appStyles.background_white, appStyles.card_boxShadow)}>
                  <Legend text="Exception" type="medium" />
                  <div className={appStyles.card__row} />
                  <div className={appStyles.card__row}>
                    <div className={classNames(appStyles.card__data, appStyles.text_oxfordBlue)}>
                      {
                        <ReactJson
                          src={log.exception}
                          theme="monokai"
                          iconStyle="square"
                          indentWidth={4}
                          collapsed={2}
                          enableClipboard={false}
                          displayObjectSize={true}
                          collapseStringsAfterLength={100}
                          displayDataTypes={false}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : null}

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button id="buttonBack" buttonStyle="secondary" size="lg" handleClick={back}>
          Back
        </Button>
      </BottomButtonContainer>
    </div>
  );
};
