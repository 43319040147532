import { EditCustomerForm } from 'models/forms/editCustomerForm.model';
import { Customer } from 'models/responses/customer.model';

export class EditCustomerAsAdminForm extends EditCustomerForm {
  sigmaId: number | null = null;
  samsAccountId: number | null = null;
  msdAccountId: number | null = null;
  isActive: boolean | null = null;

  constructor(user: Customer | null) {
    super(user);

    if (user) {
      this.sigmaId = user.sigmaId;
      this.samsAccountId = user.samsAccountId;
      this.msdAccountId = user.msdAccountId;
      this.isActive = user.isActive;
    }
  }
}
