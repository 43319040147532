import { Grid } from '@progress/kendo-react-grid';
import { adminContext } from 'App';
import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { ColumnMenu } from 'components/Generic/Grid/ColumnMenu';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import LoadingAlert from 'components/Generic/LoadingAlert/LoadingAlert';
import { useGrid } from 'hooks/useGrid';
import { useStores } from 'hooks/useStores';
import { Store } from 'models/responses/store.model';
import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const MaintenanceGrid: FC = () => {
  const grid = useGrid<Store>([{ field: 'name', dir: 'asc' }]);
  const stores = useStores();
  const adminAuth = useContext(adminContext);

  const [inProgress, setInProgress] = useState(true);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    grid.setData(stores.stores);
  }, [stores.stores]);

  const init = async () => {
    await loadData();
  };

  const loadData = async () => {
    if (stores.stores.length === 0) {
      setInProgress(true);
      await stores.loadStores();
      setInProgress(false);
    }
  };

  return (
    <div className={appStyles.container} id="AdminStores">
      <div className={appStyles.row}>
        <div className={appStyles.col_sm_12}>
          <div className={appStyles.pageHeading}>
            <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
              <Icon icon={Icons.maintenance} size="lg" color="darkBlue" iconName="people" customClass={appStyles.heading__icon} />
              <h1 className={appStyles.heading__text}>Maintenance</h1>
            </div>
          </div>
        </div>
      </div>

      {inProgress ? (
        <LoadingAlert />
      ) : (
        <div id="mainGrid" className="grid-with-no-filter">
          <Grid
            data={grid.getData()}
            filter={grid.filter}
            filterable={false}
            pageable={false}
            skip={grid.skip}
            take={grid.take}
            total={grid.getTotal()}
            onPageChange={grid.pageChange}
            sortable={false}
            sort={grid.getSort()}
            resizable={true}
            onFilterChange={grid.filterChange}
            onSortChange={grid.sortChange}>
            {ColumnHelper.getGridColumns(
              [
                {
                  field: '',
                  title: 'Edit',
                  dataType: 'icon',
                  size: 'xs',
                  columnMenu: false,
                  cell: (props) => (
                    <td className="k-table-td gridColumn_xs gridColumn_button">
                      <Link to={'/admin/maintenance/' + props.dataItem.storeId} className="grid__button">
                        <Icon icon={Icons.edit} iconName={'Edit message for ' + props.dataItem.name} color="darkBlue" size="xs" />
                      </Link>
                    </td>
                  )
                },
                { field: 'name', title: 'Name', dataType: 'text', size: 'sm' },
                { field: 'maintenanceMessage', title: 'Message', dataType: 'text', size: 'lg' },
                { field: 'maintenanceStart', title: 'Start', dataType: adminAuth.dateTimeFormat, size: 'md' },
                { field: 'maintenanceEnd', title: 'End', dataType: adminAuth.dateTimeFormat, size: 'md' }
              ],
              grid.filter,
              ColumnMenu
            )}
          </Grid>
        </div>
      )}
    </div>
  );
};
