import { IInsightsHook } from 'hooks/useInsights';
import { AppError } from 'models/generic/appError.model';
import { FieldMessages } from 'models/generic/fieldMessages.model';
import { Message } from 'models/generic/message.model';
import { useRef, useState } from 'react';
import utils from 'utils/utils';

export interface IMessagesHook {
  messages: Message[];
  initialiseInsights: (insightsHook: IInsightsHook) => void;
  addInfoAsString: (message: string) => void;
  addSuccessAsString: (message: string) => void;
  addWarningAsString: (message: string) => void;
  addErrorAsString: (message: string) => void;
  addError: (error: AppError) => void;
  addErrorsFromFieldMessages: (fieldMessages: FieldMessages) => void;
  addErrorsFromArray: (messages: string[]) => void;
  removeMessage: (id: string) => void;
}

export function useMessages(): IMessagesHook {
  const [messages, setMessages] = useState<Message[]>([]);
  const messagesRef = useRef(messages);
  messagesRef.current = messages;
  const removeMessageAfterSeconds = 10;
  let insights: IInsightsHook | null = null;

  const initialiseInsights = (insightsHook: IInsightsHook) => {
    insights = insightsHook;
  };

  const addMessage = (message: Message) => {
    const cloneMessages = [...messagesRef.current];
    cloneMessages.push(message);
    setMessages(cloneMessages);

    setTimeout(() => {
      removeMessage(message.id);
    }, removeMessageAfterSeconds * 1000);
  };

  const addInfoAsString = (message: string) => {
    addMessage(new Message(message, 'info'));
  };

  const addSuccessAsString = (message: string) => {
    addMessage(new Message(message, 'success'));
  };

  const addWarningAsString = (message: string) => {
    addMessage(new Message(message, 'warning'));
  };

  const addErrorAsString = (message: string) => {
    const error = new AppError();
    error.errorMessages.push(message);
    addError(error);
  };

  const addError = (error: AppError) => {
    // add an error to the array to be displayed in the header and remove it after pre-defined time
    error.errorMessages.forEach((errorMessage) => {
      addMessage(new Message(errorMessage, 'error'));
    });

    // also log the error to app insights
    if (insights) {
      insights.logErrorToAppInsights(error);
    }
  };

  const addErrorsFromFieldMessages = (fieldMessages: FieldMessages) => {
    const fieldNames = utils.object.getObjectFieldNames(fieldMessages);
    fieldNames.forEach((fieldName) => {
      addErrorsFromArray(fieldMessages[fieldName]);
    });
  };

  const addErrorsFromArray = (messages: string[]) => {
    if (messages) {
      messages.forEach((message) => {
        const error = new AppError();
        error.errorMessages.push(message);
        addError(error);
      });
    }
  };

  const removeMessage = (id: string) => {
    const filteredMessages = messagesRef.current.filter((i) => i.id !== id);
    setMessages(filteredMessages);
  };

  return {
    messages,
    initialiseInsights,
    addInfoAsString,
    addSuccessAsString,
    addWarningAsString,
    addErrorAsString,
    addError,
    addErrorsFromFieldMessages,
    addErrorsFromArray,
    removeMessage
  };
}
