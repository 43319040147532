import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ValidationRule } from 'models/validation/validationRule.model';
import constants from 'utils/constants';
import utils from 'utils/utils';
import { AppError } from 'models/generic/appError.model';
import { useRef, useState } from 'react';
import { IMessagesHook } from 'hooks/useMessages';
import { IAdminAuthHook } from 'hooks/useAdminAuth';
import { ICustomerAuthHook } from 'hooks/useCustomerAuth';

export interface IApiHook {
  requestsInProgress: number;
  initialise: (auth: IAdminAuthHook | ICustomerAuthHook) => void;
  get: <T>(url: string, friendlyErrorMessage: string | null) => Promise<T | AppError>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post: <T>(url: string, data: any, friendlyErrorMessage: string | null) => Promise<T | AppError>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put: <T>(url: string, data: any, friendlyErrorMessage: string | null) => Promise<T | AppError>;
  delete: <T>(url: string, friendlyErrorMessage: string | null) => Promise<T | AppError>;
  getFromExternal: <T>(url: string, friendlyErrorMessage: string | null) => Promise<T | AppError>;
  getValidationRules: (url: string) => Promise<ValidationRule[]>;
}

export interface IApiHookProps {
  messages: IMessagesHook;
}

export function useApi(props: IApiHookProps): IApiHook {
  const [requestsInProgress, setRequestsInProgress] = useState(0);
  const requestsInProgressRef = useRef(requestsInProgress);
  requestsInProgressRef.current = requestsInProgress;
  const timeoutInMinutes = 10;
  const axiosInstance = axios.create();
  const messages = props.messages;

  let auth: IAdminAuthHook | ICustomerAuthHook | null = null;

  const initialise = (authHook: IAdminAuthHook | ICustomerAuthHook) => {
    auth = authHook;

    // calculate requests on in progress on new request
    axiosInstance.interceptors.request.use((request) => {
      setRequestsInProgress(requestsInProgressRef.current + 1);

      return request;
    });

    // calculate requests on in progress on response/error
    axiosInstance.interceptors.response.use(
      (response) => {
        setRequestsInProgress(requestsInProgressRef.current - 1);

        return response;
      },
      (error) => {
        setRequestsInProgress(requestsInProgressRef.current - 1);

        return error;
      }
    );
  };

  const getConfig = (): AxiosRequestConfig => {
    // documentation here: https://github.com/axios/axios

    const config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_BASE_URL || '',
      timeout: timeoutInMinutes * 60 * 1000
    };

    config.headers = {
      Pragma: 'no-cache'
    };

    const token = auth?.getCurrentToken();
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }

    return config;
  };

  // #region GET/POST/PUT/DELETE
  const get = async <T>(url: string, friendlyErrorMessage: string | null): Promise<T | AppError> => {
    const config = getConfig();
    const response = await axiosInstance.get<T>(url, config);

    return getResult(response, friendlyErrorMessage) as T | AppError;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const post = async <T>(url: string, data: any, friendlyErrorMessage: string | null): Promise<T | AppError> => {
    const config = getConfig();
    const response = await axiosInstance.post<T>(url, data, config);

    return getResult(response, friendlyErrorMessage) as T | AppError;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const put = async <T>(url: string, data: any, friendlyErrorMessage: string | null): Promise<T | AppError> => {
    const config = getConfig();
    const response = await axiosInstance.put<T>(url, data, config);

    return getResult(response, friendlyErrorMessage) as T | AppError;
  };

  const del = async <T>(url: string, friendlyErrorMessage: string | null): Promise<T | AppError> => {
    const config = getConfig();
    const response = await axiosInstance.delete<T>(url, config);

    return getResult(response, friendlyErrorMessage) as T | AppError;
  };
  // #endregion

  // #region getResult functions
  const isError = (responseOrError: AxiosResponse | AxiosError): responseOrError is AxiosError => (responseOrError as AxiosError).isAxiosError === true;

  const isResponse = (responseOrError: AxiosResponse | AxiosError): responseOrError is AxiosResponse =>
    (responseOrError as AxiosResponse).headers !== undefined;

  const getResult = (responseOrError: AxiosResponse | AxiosError, friendlyErrorMessage: string | null) => {
    if (isResponse(responseOrError)) {
      // handle success response
      return getResultFromResponse(responseOrError as AxiosResponse);
    } else if (isError(responseOrError)) {
      // handle error and return result
      return getResultFromError(responseOrError as AxiosError, friendlyErrorMessage);
    }
  };

  const getResultFromExternal = (responseOrError: AxiosResponse | AxiosError, friendlyErrorMessage: string | null) => {
    if (isResponse(responseOrError)) {
      // handle success response
      return getResultFromResponse(responseOrError as AxiosResponse);
    } else if (isError(responseOrError)) {
      // show error message
      if (friendlyErrorMessage) {
        messages.addErrorAsString(friendlyErrorMessage);
      }
    }
  };

  const getResultFromResponse = (response: AxiosResponse) => {
    const result = response.data;

    // add total count if present in response
    const totalCount = response.headers['list-total-count'];
    if (totalCount) {
      result.totalCount = +totalCount;
    }

    return result;
  };

  // eslint-disable-next-line complexity
  const getResultFromError = (error: AxiosError, friendlyErrorMessage: string | null): AppError => {
    let apiError = new AppError();

    if (error.response) {
      apiError = new AppError(error.response);

      switch (error.response.status) {
        // Bad Request
        case 400:
          // no need to handle bad request errors, these should be handled by the caller component
          break;

        // Unauthorized
        case 401:
          // handle unathorized errors globally
          apiError.errorMessages.push(`Unauthorised user.`);
          messages.addError(apiError);

          if (auth) {
            auth.checkIfExpired(null, false);
          }
          break;

        // Forbidden
        case 403:
          // handle forbidden errors globally
          apiError.errorMessages.push(`Requested action is forbidden.`);
          messages.addError(apiError);
          break;

        // Not Found
        case 404:
          // handle not found errors globally
          // apiError.errorMessages.push(`We couldn't find what you requested.`);
          // messages.addError(apiError);
          break;

        // Internal Server Error
        case 500:
          // handle internal server errors globally
          if (friendlyErrorMessage) {
            apiError.errorMessages.push(friendlyErrorMessage);
          } else {
            apiError.errorMessages.push(`Something went wrong.`);
          }

          messages.addError(apiError);
          break;
      }
    } else {
      if (error.message === 'Network Error') {
        // return network error
        apiError.statusText = error.name;
        apiError.errorMessages.push(error.message);
        messages.addError(apiError);
      }
    }

    return apiError;
  };

  const getFromExternal = async <T>(url: string, friendlyErrorMessage: string | null) => {
    const response = await axiosInstance.get<T>(url);

    return getResultFromExternal(response, friendlyErrorMessage) as T | AppError;
  };
  // #endregion

  const getValidationRules = async (url: string): Promise<ValidationRule[]> => {
    // check if rules in cache
    const key = constants.storageKeys.validationRules + url;
    let rules: ValidationRule[] | null = utils.session.getFromSession(key);
    if (!rules) {
      // load rules from API
      const result = await get<ValidationRule[]>(url, 'There was an error loading validation rules.');
      if (result instanceof AppError) {
        rules = [];
      } else {
        rules = result;

        // save rules to cache
        utils.session.saveToSession(key, rules);
      }
    }

    // return rules or empty array
    return rules;
  };

  return {
    requestsInProgress,
    initialise,
    get,
    post,
    put,
    delete: del,
    getFromExternal,
    getValidationRules
  };
}
