import { customerContext } from 'App';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import utils from 'utils/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomerOnly = ({ Component }: any) => {
  const customerAuth = useContext(customerContext);
  const currentUser = customerAuth.getCurrentUser();

  // check if customer logged in
  if (currentUser) {
    return <Component />;
  } else {
    // save requested page URL before redirecting to login
    utils.routing.saveRequestedUrl(window.location.pathname + window.location.search);

    // customer not logged in, so go to login page
    return <Navigate to={{ pathname: '/login' }} />;
  }
};
