import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { GridFilterChangeEvent, GridPageChangeEvent, GridPagerSettings, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import utils from 'utils/utils';
import 'scss/kendoImports.scss';
import 'scss/kendoOverrides.scss';

interface IGridHook<DataItemType> {
  pageSizes: number[];
  skip: number;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  take: number;
  setTake: React.Dispatch<React.SetStateAction<number>>;
  filter: CompositeFilterDescriptor;
  setFilter: React.Dispatch<React.SetStateAction<CompositeFilterDescriptor>>;
  sort: SortDescriptor[];
  setSort: React.Dispatch<React.SetStateAction<SortDescriptor[]>>;
  allData: DataItemType[];
  setData: (data: DataItemType[]) => void;
  gridData: DataItemType[];
  searchKeyword: string;
  setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
  pdfExport: GridPDFExport | null;
  setPdfExport: React.Dispatch<React.SetStateAction<GridPDFExport | null>>;
  pdfExportInProgress: boolean;
  setPdfExportInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  pageChange: (event: GridPageChangeEvent) => void;
  sortChange: (event: GridSortChangeEvent) => void;
  filterChange: (event: GridFilterChangeEvent) => void;
  getData: (selectedItem?: DataItemType | null) => DataItemType[];
  getPageable: (selectedItem?: DataItemType | null) => GridPagerSettings | boolean;
  getSortable: (selectedItem?: DataItemType | null) => boolean;
  getSort: (selectedItem?: DataItemType | null) => SortDescriptor[] | undefined;
  getTotal: (selectedItem?: DataItemType | null) => number;
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  exportPdf: () => void;
  exportPdfCompleted: () => void;
  makeAccessible: (makeColumnHeadersFocusable: boolean) => void;
}

export function useGrid<DataItemType>(defaultSort?: SortDescriptor[]): IGridHook<DataItemType> {
  const pageSizes = [20, 50, 100];
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(20);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>({ logic: 'and', filters: [] });
  const [sort, setSort] = useState<SortDescriptor[]>(defaultSort || []);
  const [allData, setAllData] = useState<DataItemType[]>([]);
  const [gridData, setGridData] = useState<DataItemType[]>([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [pdfExport, setPdfExport] = useState<GridPDFExport | null>(null);
  const [pdfExportInProgress, setPdfExportInProgress] = useState(false);

  useEffect(() => {
    // make accessibility changes whenever data updates
    makeAccessible(true);
  }, [gridData]);

  const setData = (data: DataItemType[]) => {
    setAllData(data);
    setGridData(data);
  };

  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  const sortChange = (event: GridSortChangeEvent) => {
    if (event.sort) {
      setSort(event.sort);
    }
  };

  const filterChange = (event: GridFilterChangeEvent) => {
    setGridData(filterBy(allData, event.filter));
    setFilter(event.filter);
    setSkip(0);
  };

  const getData = (selectedItem?: DataItemType | null): DataItemType[] => {
    // if row is selected and breakdown area is visible then hide other rows
    if (selectedItem) {
      return [selectedItem];
    } else {
      return orderBy(filterBy(gridData, filter), sort).slice(skip, skip + take);
    }
  };

  const getPageable = (selectedItem?: DataItemType | null): GridPagerSettings | boolean => {
    // if row is selected then hide paging panel
    if (selectedItem) {
      return false;
    } else {
      return {
        buttonCount: 10,
        info: true,
        type: 'numeric',
        pageSizes: pageSizes,
        previousNext: true
      };
    }
  };

  const getSortable = (selectedItem?: DataItemType | null) => {
    // if row is selected then disable sorting
    if (selectedItem) {
      return false;
    } else {
      return true;
    }
  };

  const getSort = (selectedItem?: DataItemType | null) => {
    // if row is selected then remove current sort
    if (selectedItem) {
      return undefined;
    } else {
      return sort;
    }
  };

  const getTotal = (selectedItem?: DataItemType | null) => {
    // if row is selected then show 1 as total
    if (selectedItem) {
      return 1;
    } else {
      return filterBy(gridData, filter).length;
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);

    // use lower case keyword for searching
    const keyword = e.target.value.toLowerCase().trim();
    const filteredData = allData.filter((line) => utils.object.getAllFieldValuesAsString(line).toLowerCase().indexOf(keyword) > -1);
    setGridData(filteredData);

    // go to first page
    setSkip(0);
  };

  const exportPdf = () => {
    setPdfExportInProgress(true);

    if (pdfExport) {
      pdfExport.save(gridData, exportPdfCompleted);
    }
  };

  const exportPdfCompleted = () => {
    setPdfExportInProgress(false);
  };

  const makeAccessible = (makeColumnHeadersFocusable = true) => {
    // add tabindex to grid table to make it scrollable/focusable with tab
    const tables = document.getElementsByClassName('k-table-td');
    for (let i = 0; i < tables.length; i++) {
      tables[i].setAttribute('tabindex', '0');
    }

    // remove some attributes, which are on wrong elements or not globally supported yet
    const lists = document.getElementsByClassName('k-dropdownlist');
    for (let i = 0; i < lists.length; i++) {
      lists[i].removeAttribute('role');
      lists[i].removeAttribute('aria-expanded');
      lists[i].removeAttribute('aria-required');
      lists[i].removeAttribute('aria-owns');
    }

    // remove some attributes, which are on wrong elements or not globally supported yet
    const pagers = document.getElementsByClassName('k-grid-pager');
    for (let i = 0; i < pagers.length; i++) {
      pagers[i].removeAttribute('aria-roledescription');
    }

    // add tabindex to column headers to allow sorting with tab/enter
    if (makeColumnHeadersFocusable) {
      const header = document.getElementsByClassName('k-grid-header');
      if (header.length > 0) {
        const links = header[0].getElementsByClassName('k-link');
        for (let i = 0; i < links.length; i++) {
          links[i].setAttribute('tabindex', '0');
        }
      }
    }
  };

  return {
    pageSizes,
    skip,
    setSkip,
    take,
    setTake,
    filter,
    setFilter,
    sort,
    setSort,
    allData,
    setData,
    gridData,
    searchKeyword,
    setSearchKeyword,
    pdfExport,
    setPdfExport,
    pdfExportInProgress,
    setPdfExportInProgress,
    pageChange,
    sortChange,
    filterChange,
    getData,
    getPageable,
    getSortable,
    getSort,
    getTotal,
    handleSearch,
    exportPdf,
    exportPdfCompleted,
    makeAccessible
  };
}
