import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import { FC } from 'react';

interface IValidationAreaProps {
  /**
   * @property {ValidationError} validationError Optional. Any validation messages related to an associated field
   */
  validationErrors?: string[];
  /**
   * @property {sectionId} sectionId Optional. Set an ID on the validation errors element
   */
  sectionId?: string;
}

export const ValidationArea: FC<IValidationAreaProps> = (props: IValidationAreaProps) => (
  <>
    {props.validationErrors && props.validationErrors.length > 0 && (
      <div className={appStyles.validationAlerts} id={`error_${props.sectionId || ''}`}>
        {props.validationErrors?.map((error, index) => (
          <Alert customClass={appStyles.form__error} alertType="error" key={index}>
            {error}
          </Alert>
        ))}
      </div>
    )}
  </>
);
