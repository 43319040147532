import { apiContext } from 'App';
import { EditAdministratorForm } from 'models/forms/editAdministratorForm.model';
import { AppError } from 'models/generic/appError.model';
import { Administrator } from 'models/responses/administrator.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import { useContext } from 'react';

export interface IAdministratorsHook {
  getEditAdministratorValidationRules: () => Promise<ValidationRule[]>;
  getCreateAdministratorValidationRules: () => Promise<ValidationRule[]>;
  getAdministrators: () => Promise<Administrator[]>;
  getAdministratorById: (administratorId: number) => Promise<Administrator | null>;
  createAdministrator: (form: EditAdministratorForm) => Promise<Administrator | AppError>;
  updateAdministrator: (form: EditAdministratorForm, administratorId: number) => Promise<Administrator | AppError>;
}

export function useAdministrators(): IAdministratorsHook {
  const api = useContext(apiContext);

  const getEditAdministratorValidationRules = async (): Promise<ValidationRule[]> => api.getValidationRules('admin/editvalidation');

  const getCreateAdministratorValidationRules = async (): Promise<ValidationRule[]> => api.getValidationRules('admin/createvalidation');

  const getAdministrators = async (): Promise<Administrator[]> => {
    const response = await api.get<Administrator[]>('admin', 'Loading administrators failed.');
    if (response instanceof AppError) {
      return [];
    } else {
      return response;
    }
  };

  const getAdministratorById = async (administratorId: number): Promise<Administrator | null> => {
    const administrator = null;
    const response = await api.get<Administrator>(`admin/${administratorId}`, 'There was an error loading this administrator.');

    if (response instanceof AppError) {
    } else {
      return response;
    }

    return administrator;
  };

  const createAdministrator = async (form: EditAdministratorForm): Promise<Administrator | AppError> => {
    const response = await api.post<Administrator>('admin', form, 'Creating administrator failed.');

    return response;
  };

  const updateAdministrator = async (form: EditAdministratorForm, administratorId: number): Promise<Administrator | AppError> => {
    const response = await api.put<Administrator>(`admin/${administratorId}`, form, 'Updating administrator failed.');

    return response;
  };

  return {
    getEditAdministratorValidationRules,
    getCreateAdministratorValidationRules,
    getAdministrators,
    getAdministratorById,
    createAdministrator,
    updateAdministrator
  };
}
