import { customerContext } from 'App';
import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Login: FC = () => {
  const navigate = useNavigate();

  const customerAuth = useContext(customerContext);
  const currentUser = customerAuth.getCurrentUser();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (currentUser) {
      navigate('/');
    } else {
      const url = await customerAuth.getLoginUrl();
      if (url) {
        window.location.href = url;
      }
    }
  };

  return (
    <div className={appStyles.form__container} id="LoginForm">
      <Alert alertType="info">Please wait, we are taking you to the login page.</Alert>
    </div>
  );
};
