import { Grid } from '@progress/kendo-react-grid';
import appStyles from 'App.module.scss';
import { Input } from 'components/Generic/FormElements/Input/Input';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { ColumnMenu } from 'components/Generic/Grid/ColumnMenu';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { useGrid } from 'hooks/useGrid';
import { messageContext } from 'App';
import { AppError } from 'models/generic/appError.model';
import { Ebook } from 'models/responses/ebook.model';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useEbooks } from 'hooks/useEbooks';
import LoadingAlert from 'components/Generic/LoadingAlert/LoadingAlert';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import constants from 'utils/constants';
import { LoadEbooksForm } from 'models/forms/loadEbooksForm.model';
import { Select, SelectHelper } from 'components/Generic/FormElements/Select/Select';
import utils from 'utils/utils';
import { IntlProvider } from '@progress/kendo-react-intl';
import { useLookups } from 'hooks/useLookups';
import { ListItem } from 'models/generic/listItem.model';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';

export const EbooksGrid: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const storeName = params.store || 'uk';
  const storeId = constants.stores.getEbooksStoreIdFromName(storeName);

  const [form, setForm] = useState(new LoadEbooksForm(storeId));
  const [stores, setStores] = useState<ListItem[]>([]);
  const [locale, setLocale] = useState('en-GB');
  const [currencyType, setCurrencyType] = useState<'currencyGbp' | 'currencyUsd'>('currencyGbp');

  const grid = useGrid<Ebook>([{ field: 'title', dir: 'asc' }]);
  const messages = useContext(messageContext);
  const ebooks = useEbooks();
  const lookups = useLookups();

  const [inProgress, setInProgress] = useState(true);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    // load stores
    setStores(lookups.getStores('ebooks', false));

    // set currency and locale
    setLocale(utils.currency.getLocale(storeId));
    setCurrencyType(utils.currency.getCurrencyType(storeId));

    await loadData();
  };

  const handleSelectAsNumber = (e: ChangeEvent<HTMLSelectElement>) => {
    setForm(SelectHelper.baseHandleNumber(e, form));
  };

  const loadData = async () => {
    setInProgress(true);

    // change URL if store was changed
    if (form.storeId !== storeId) {
      if (form.storeId === constants.stores.ebooksUk) {
        navigate('./../uk');
      } else if (form.storeId === constants.stores.ebooksUs) {
        navigate('./../us');
      }
    }

    // set currency and locale
    setCurrencyType(utils.currency.getCurrencyType(form.storeId));
    setLocale(utils.currency.getLocale(form.storeId));

    // load data
    const result = await ebooks.getEbooks(form);
    setInProgress(false);

    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      grid.setData(result);
    }
  };

  const back = () => {
    navigate('/admin');
  };

  return (
    <div className={appStyles.container} id="AdminEbooks">
      <div className={appStyles.row}>
        <div className={appStyles.col_sm_12}>
          <div className={appStyles.filters}>
            <div className={classNames(appStyles.row, appStyles.filters_row)}>
              <div className={classNames(appStyles.col_md_6, appStyles.col_sm_12, appStyles.filters_cell)}>
                <div className={appStyles.pageHeading}>
                  <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
                    <Icon icon={Icons.ebooks} size="lg" color="darkBlue" iconName="E-books" customClass={appStyles.heading__icon} />
                    <h1 className={appStyles.heading__text}>{storeName.toUpperCase()}: E-books</h1>
                  </div>
                </div>
              </div>

              <div className={classNames(appStyles.col_md_3, appStyles.col_sm_6, appStyles.filters_cell)}>
                <Select
                  elementId="storeId"
                  labelText="Store"
                  name="storeId"
                  options={stores}
                  required={true}
                  placeholder="Select your store"
                  disablePlaceholder={true}
                  value={form.storeId}
                  handleChange={handleSelectAsNumber}
                  validationErrors={form.fieldMessages['storeId']}
                />
              </div>
              <div className={classNames(appStyles.col_md_3, appStyles.col_sm_6, appStyles.filters_cell__button)}>
                <Button id="loadButton" handleClick={loadData} buttonStyle="secondary" size="md" customClass={appStyles.filters_button} inProgress={inProgress}>
                  Load
                </Button>
              </div>
            </div>
            <div className={classNames(appStyles.row, appStyles.filters_row, appStyles.filters_row__search)}>
              <div className={classNames(appStyles.col_md_3, appStyles.col_sm_6)}>
                <Input
                  elementId="searchBar"
                  inputType="search"
                  name="searchBar"
                  labelText="Search report"
                  labelHidden={true}
                  placeholder="Search report"
                  value={grid.searchKeyword}
                  handleChange={grid.handleSearch}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {inProgress ? (
        <LoadingAlert />
      ) : (
        <div id="mainGrid" className="grid-with-sm-filter">
          <IntlProvider locale={locale}>
            <Grid
              data={grid.getData()}
              filter={grid.filter}
              filterable={false}
              pageable={grid.getPageable()}
              skip={grid.skip}
              take={grid.take}
              total={grid.getTotal()}
              onPageChange={grid.pageChange}
              sortable={grid.getSortable()}
              sort={grid.getSort()}
              resizable={true}
              onFilterChange={grid.filterChange}
              onSortChange={grid.sortChange}>
              {ColumnHelper.getGridColumns(
                [
                  { field: 'vbid', title: 'VBID', dataType: 'text', size: 'sm' },
                  { field: 'title', title: 'Title', dataType: 'text', size: 'lg' },
                  { field: 'eIsbn', title: 'E-ISBN', dataType: 'text', size: 'md' },
                  { field: 'printIsbn', title: 'Print ISBN', dataType: 'text', size: 'md' },
                  { field: 'price6M', title: 'Price (6M)', dataType: currencyType, size: 'sm' },
                  { field: 'price1Y', title: 'Price (1Y)', dataType: currencyType, size: 'sm' },
                  { field: 'pricePerpetual', title: 'Price (Per.)', dataType: currencyType, size: 'sm' }
                ],
                grid.filter,
                ColumnMenu
              )}
            </Grid>
          </IntlProvider>
        </div>
      )}

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button id="buttonBack" buttonStyle="secondary" size="lg" handleClick={back}>
          Back
        </Button>
      </BottomButtonContainer>
    </div>
  );
};
