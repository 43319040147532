import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { FC } from 'react';

interface ILegendProps {
  /**
   * @property {string} text Set the text to appear in the label
   */
  text: string;
  /**
   * @property {string} type Set the type of legend - large, small, checkbox (based on styles in _forms.scss)
   */
  type: 'large' | 'medium' | 'small';
  /**
   * @property {string} customClass Optional. Use a custom class on the legend - use styles from the current component or appStyles
   */
  customClass?: string;
}
/**
 * @description Add a legend element. Optional properties: customClass
 */
export const Legend: FC<ILegendProps> = (props: ILegendProps) => (
  <legend
    className={classNames(
      appStyles.form__legend,
      appStyles[`form__legend_${props.type}`],
      { [props.customClass as string]: props.customClass } // add custom class if defined
    )}>
    {props.text}
  </legend>
);

export default Legend;
