import appStyles from 'App.module.scss';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { FC, useEffect, useState } from 'react';
import FileUpload from 'components/Generic/FormElements/FileUpload/FileUpload';
import { useRinggold } from 'hooks/useRinggold';
import { UploadRinggoldFileForm } from 'models/forms/uploadRinggoldFileForm.model';
import { CsvFile } from 'models/generic/csvFile.model';
import Alert from 'components/Generic/Alert/Alert';
import { AppError } from 'models/generic/appError.model';
import { RinggoldImportResult } from 'models/responses/ringgoldImportResult.model';
import { RinggoldUniversity } from 'models/responses/ringgoldUniversity.model';
import classNames from 'classnames';

export const Ringgold: FC = () => {
  const [form, setForm] = useState(new UploadRinggoldFileForm());
  const [inProgress, setInProgress] = useState(false);
  const [result, setResult] = useState<RinggoldImportResult | null>(null);

  let intervalId: NodeJS.Timeout;

  const ringgold = useRinggold();

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    // no init required

    // clean-up on unload
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const handleFile = async (file: CsvFile | null) => {
    setInProgress(true);

    form.file = file;
    setForm(form);

    if (file) {
      const result = await ringgold.uploadRinggoldFile(form);
      if (result instanceof AppError) {
        form.fieldMessages = result.fieldMessages;
        setForm(form);
        setInProgress(false);
      } else {
        // form submitted OK, so check every 30s if completed
        intervalId = setInterval(checkProgress, 30000);
      }
    }
  };

  const checkProgress = async () => {
    const result = await ringgold.checkRinggoldImportStatus();
    if (result instanceof AppError) {
      form.fieldMessages = result.fieldMessages;
      setForm(form);
    } else {
      if (result) {
        setResult(result);
        clearInterval(intervalId);
        setInProgress(false);
      }
    }
  };

  return (
    <div className={appStyles.container} id="AdminRinggold">
      <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
        <Icon icon={Icons.university} size="lg" color="darkBlue" iconName="Ringgold university import" customClass={appStyles.heading__icon} />
        <h1 className={appStyles.heading__text}>Ringgold university import</h1>
      </div>

      <form className={appStyles.row}>
        <div className={appStyles.col_md_6}>
          {inProgress === false && result === null && (
            <div className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
              <fieldset>
                <Legend type="large" text="Upload Ringgold data file" />
                <div className={appStyles.form__row}>
                  <FileUpload
                    name="csvFile"
                    elementId="csvFile"
                    placeholder="Upload a file"
                    labelText="Upload a file"
                    value={form.file}
                    handleChange={handleFile}
                    validationErrors={form.fieldMessages['file']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <p>The upload file should be in CSV format and should contain the following columns:</p>
                  <ul>
                    <li>name + city + country</li>
                    <li>ringgold_id</li>
                    <li>name</li>
                    <li>country</li>
                  </ul>
                  <p>The column names should be included in the first row of the CSV file.</p>
                </div>
              </fieldset>
            </div>
          )}

          {inProgress && <Alert alertType="info">Please wait... We are processing the file. This may take a few minutes to complete.</Alert>}

          {result != null && (
            <div className={classNames(appStyles.card, appStyles.background_white, appStyles.card_boxShadow)}>
              <fieldset>
                <Legend type="large" text="Import completed" />
                <div className={appStyles.form__row}>
                  <div className={appStyles.card__row}>
                    <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Result</div>
                    <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>
                      {result.success ? 'Success' : 'Some rows failed to import'}
                    </div>
                  </div>
                  <div className={appStyles.card__row}>
                    <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Duration</div>
                    <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{result.duration}</div>
                  </div>
                  <div className={appStyles.card__row}>
                    <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Rows processed</div>
                    <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{result.processed}</div>
                  </div>
                  <div className={appStyles.card__row}>
                    <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Rows unchanged</div>
                    <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{result.unchanged}</div>
                  </div>
                  <div className={appStyles.card__row}>
                    <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Rows added</div>
                    <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{result.added}</div>
                  </div>
                  <div className={appStyles.card__row}>
                    <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Rows updated</div>
                    <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{result.updated}</div>
                  </div>
                  <div className={appStyles.card__row}>
                    <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Rows deleted</div>
                    <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{result.deleted}</div>
                  </div>
                  <div className={appStyles.card__row}>
                    <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>Rows failed</div>
                    <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{result.failed.length}</div>
                  </div>
                </div>
              </fieldset>

              {result.failed.length > 0 && (
                <fieldset>
                  <Legend type="large" text="Failed imports" />
                  <p>The below rows failed to import. Please check if all the columns have correct values and try again.</p>
                  <p>Potential issues can include: lack of numeric ringgold ID or country code not matching to any existing country.</p>

                  <div className={appStyles.form__row}>
                    {result.failed.map((item: RinggoldUniversity, index: number) => (
                      <div className={appStyles.card__row} key={index}>
                        <div className={appStyles.text_gabBlue}>{item.displayName}</div>
                      </div>
                    ))}
                  </div>
                </fieldset>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};
