import { DateOnly } from 'models/generic/dateOnly';
import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class DateRangeReportForm extends BaseValidatedObject {
  dateFrom: DateOnly = DateOnly.fromDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)); // default to 1st of current month
  dateTo: DateOnly = DateOnly.fromDate(new Date()); // default to today
  storeId: number;

  constructor(storeId: number) {
    super();

    this.storeId = storeId;
  }
}
