import { apiContext } from 'App';
import { EditCountryForm } from 'models/forms/editCountryForm.model';
import { AppError } from 'models/generic/appError.model';
import { ListItem } from 'models/generic/listItem.model';
import { NumericIdListItem } from 'models/generic/numericIdListItem.model';
import { Country } from 'models/responses/country.model';
import { LogType } from 'models/responses/logType.model';
import { OrderStatus } from 'models/responses/orderStatus.model';
import { Period } from 'models/responses/period.model';
import { Province } from 'models/responses/province.model';
import { University } from 'models/responses/university.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import { useContext } from 'react';
import constants from 'utils/constants';
import utils from 'utils/utils';

export interface ILookupsHook {
  getTitles: () => ListItem[];
  getCountries: (all: boolean) => Promise<Country[]>;
  getCountriesAsListItems: () => Promise<ListItem[]>;
  getCountryById: (countryId: number) => Promise<Country | null>;
  getEditCountryValidationRules: () => Promise<ValidationRule[]>;
  getCreateCountryValidationRules: () => Promise<ValidationRule[]>;
  createCountry: (form: EditCountryForm) => Promise<Country | AppError>;
  updateCountry: (form: EditCountryForm, countryId: number) => Promise<Country | AppError>;
  getProvincesAsListItems: (provinces: Province[]) => ListItem[];
  getUniversities: () => Promise<University[]>;
  getUniversitiesAsListItems: () => Promise<NumericIdListItem[]>;
  getPeriods: () => Promise<Period[]>;
  getPeriodsAsListItems: () => Promise<ListItem[]>;
  getPeriodName: (periodCode: string) => Promise<string>;
  getStores: (type: 'all' | 'subscriptions' | 'ebooks', includeNone: boolean) => ListItem[];
  getOrderStatuses: () => Promise<OrderStatus[]>;
  getOrderStatusesAsListItems: () => Promise<ListItem[]>;
  getLogTypes: () => Promise<LogType[]>;
  getLogTypesAsListItems: () => Promise<ListItem[]>;
}

export function useLookups(): ILookupsHook {
  const api = useContext(apiContext);

  const getTitles = () => [
    new ListItem('Mr', 'Mr'),
    new ListItem('Mrs', 'Mrs'),
    new ListItem('Dr', 'Dr'),
    new ListItem('Prof', 'Prof'),
    new ListItem('Ms', 'Ms')
  ];

  const getCountries = async (all: boolean): Promise<Country[]> => {
    let countries: Country[] = [];

    // check if data in cache (only when not loading all)
    const cachedData = all ? null : utils.session.getFromSession<Country[]>(constants.storageKeys.countries);
    if (cachedData) {
      countries = cachedData;
    } else {
      const response = await api.get<Country[]>('lookup/countries/' + all, 'Loading countries failed.');
      if (response instanceof AppError) {
        return [];
      } else {
        countries = response;

        // save data to cache (only when not loading all)
        if (all === false) {
          utils.session.saveToSession(constants.storageKeys.countries, countries);
        }
      }
    }

    return countries;
  };

  const getCountriesAsListItems = async (): Promise<ListItem[]> => {
    const countries = await getCountries(false);
    const items: ListItem[] = [];

    countries.forEach((country) => {
      if (country.countryId) {
        const item = new ListItem(country.countryId, country.name);
        items.push(item);
      }
    });

    return items;
  };

  const getCountryById = async (countryId: number): Promise<Country | null> => {
    const country = null;
    const response = await api.get<Country>(`lookup/country/${countryId}`, 'There was an error loading this country.');

    if (response instanceof AppError) {
    } else {
      return response;
    }

    return country;
  };

  const getEditCountryValidationRules = async (): Promise<ValidationRule[]> => api.getValidationRules('lookup/country/editvalidation');

  const getCreateCountryValidationRules = async (): Promise<ValidationRule[]> => api.getValidationRules('lookup/country/createvalidation');

  const createCountry = async (form: EditCountryForm): Promise<Country | AppError> => {
    const response = await api.post<Country>('lookup/country', form, 'Creating country failed.');

    return response;
  };

  const updateCountry = async (form: EditCountryForm, countryId: number): Promise<Country | AppError> => {
    const response = await api.put<Country>(`lookup/country/${countryId}`, form, 'Updating country failed.');

    return response;
  };

  const getProvincesAsListItems = (provinces: Province[]): ListItem[] => {
    const items: ListItem[] = [];

    provinces.forEach((province) => {
      if (province.provinceId) {
        const item = new ListItem(province.provinceId, province.name);
        items.push(item);
      }
    });

    return items;
  };

  const getUniversities = async (): Promise<University[]> => {
    let universities: University[] = [];

    // check if data in cache
    const cachedData = utils.session.getFromSession<University[]>(constants.storageKeys.universities, true);
    if (cachedData) {
      universities = cachedData;
    } else {
      const response = await api.get<University[]>('lookup/universities', 'Loading universities failed.');
      if (response instanceof AppError) {
        return [];
      } else {
        universities = response;

        // save data to cache
        utils.session.saveToSession(constants.storageKeys.universities, universities, true);
      }
    }

    return universities;
  };

  const getUniversitiesAsListItems = async (): Promise<NumericIdListItem[]> => {
    const universities = await getUniversities();
    const items: NumericIdListItem[] = [];

    universities.forEach((university) => {
      if (university.id) {
        const item = new NumericIdListItem(university.id, university.name);
        items.push(item);
      }
    });

    return items;
  };

  const getPeriods = async (): Promise<Period[]> => {
    let periods: Period[] = [];

    // check if data in cache
    const cachedData = utils.session.getFromSession<Period[]>(constants.storageKeys.periods);
    if (cachedData) {
      periods = cachedData;
    } else {
      const response = await api.get<Period[]>('lookup/periods', 'Loading periods failed.');
      if (response instanceof AppError) {
        return [];
      } else {
        periods = response;

        // save data to cache
        utils.session.saveToSession(constants.storageKeys.periods, periods);
      }
    }

    return periods;
  };

  const getPeriodsAsListItems = async (): Promise<ListItem[]> => {
    const periods = await getPeriods();
    const items: ListItem[] = [];

    periods.forEach((period) => {
      const item = new ListItem(period.code, period.name);
      items.push(item);
    });

    return items;
  };

  const getPeriodName = async (periodCode: string): Promise<string> => {
    const periods = await getPeriods();
    const period = periods.find((p) => p.code === periodCode);

    // return period name or code if not found
    return period ? period.name : periodCode;
  };

  const getStores = (type: 'all' | 'subscriptions' | 'ebooks', includeNone: boolean) => {
    const items = [];

    if (type === 'subscriptions') {
      items.push(new ListItem(constants.stores.subscriptionsUk, 'UK'));
      items.push(new ListItem(constants.stores.subscriptionsUs, 'US'));
    } else if (type === 'ebooks') {
      items.push(new ListItem(constants.stores.ebooksUk, 'UK'));
      items.push(new ListItem(constants.stores.ebooksUs, 'US'));
    } else if (type === 'all') {
      items.push(new ListItem(constants.stores.subscriptionsUk, 'Subscriptions UK'));
      items.push(new ListItem(constants.stores.subscriptionsUs, 'Subscriptions US'));
      items.push(new ListItem(constants.stores.ebooksUk, 'E-books UK'));
      items.push(new ListItem(constants.stores.ebooksUs, 'E-books US'));
    }

    if (includeNone) {
      items.push(new ListItem('', 'None'));
    }

    return items;
  };

  const getOrderStatuses = async (): Promise<OrderStatus[]> => {
    let orderStatuses: OrderStatus[] = [];

    // check if data in cache
    const cachedData = utils.session.getFromSession<OrderStatus[]>(constants.storageKeys.orderStatuses);
    if (cachedData) {
      orderStatuses = cachedData;
    } else {
      const response = await api.get<OrderStatus[]>('lookup/orderstatuses', 'Loading order statuses failed.');
      if (response instanceof AppError) {
        return [];
      } else {
        orderStatuses = response;

        // save data to cache
        utils.session.saveToSession(constants.storageKeys.orderStatuses, orderStatuses);
      }
    }

    return orderStatuses;
  };

  const getOrderStatusesAsListItems = async (): Promise<ListItem[]> => {
    const orderStatuses = await getOrderStatuses();
    const items: ListItem[] = [];

    orderStatuses.forEach((orderstatus) => {
      if (orderstatus.orderStatusId) {
        const item = new ListItem(orderstatus.orderStatusId, orderstatus.name);
        items.push(item);
      }
    });

    return items;
  };

  const getLogTypes = async (): Promise<LogType[]> => {
    let logTypes: LogType[] = [];

    // check if data in cache
    const cachedData = utils.session.getFromSession<LogType[]>(constants.storageKeys.logTypes);
    if (cachedData) {
      logTypes = cachedData;
    } else {
      const response = await api.get<LogType[]>('lookup/logtypes', 'Loading log types failed.');
      if (response instanceof AppError) {
        return [];
      } else {
        logTypes = response;

        // save data to cache
        utils.session.saveToSession(constants.storageKeys.logTypes, logTypes);
      }
    }

    return logTypes;
  };

  const getLogTypesAsListItems = async (): Promise<ListItem[]> => {
    const logTypes = await getLogTypes();
    const items: ListItem[] = [];

    logTypes.forEach((logType) => {
      if (logType.eventTypeId) {
        const item = new ListItem(logType.eventTypeId, logType.name);
        items.push(item);
      }
    });

    return items;
  };

  return {
    getTitles,
    getCountries,
    getCountriesAsListItems,
    getCountryById,
    getEditCountryValidationRules,
    getCreateCountryValidationRules,
    createCountry,
    updateCountry,
    getProvincesAsListItems,
    getUniversities,
    getUniversitiesAsListItems,
    getPeriods,
    getPeriodsAsListItems,
    getPeriodName,
    getStores,
    getOrderStatuses,
    getOrderStatusesAsListItems,
    getLogTypes,
    getLogTypesAsListItems
  };
}
