import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class CreatePaymentSessionForm extends BaseValidatedObject {
  basketId: number | null = null;
  universityId: number | null = null;

  onBehalfOf = false;
  onBehalfOfFirstName = '';
  onBehalfOfLastName = '';
  onBehalfOfEmail = '';
}
