import { Grid } from '@progress/kendo-react-grid';
import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { DateSelector, DateSelectorHelper } from 'components/Generic/FormElements/DateSelector/DateSelector';
import { Input, InputHelper } from 'components/Generic/FormElements/Input/Input';
import { Select, SelectHelper } from 'components/Generic/FormElements/Select/Select';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { ColumnMenu } from 'components/Generic/Grid/ColumnMenu';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { useGrid } from 'hooks/useGrid';
import { adminContext, messageContext } from 'App';
import { LoadLogsForm } from 'models/forms/loadLogsForm.model';
import { AppError } from 'models/generic/appError.model';
import { DateOnly } from 'models/generic/dateOnly';
import { ListItem } from 'models/generic/listItem.model';
import { Log } from 'models/responses/log.model';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLookups } from 'hooks/useLookups';
import constants from 'utils/constants';
import utils from 'utils/utils';
import { useLogs } from 'hooks/useLogs';
import LoadingAlert from 'components/Generic/LoadingAlert/LoadingAlert';

export const LogsGrid: FC = () => {
  const [form, setForm] = useState(new LoadLogsForm());
  const [logTypes, setLogTypes] = useState<ListItem[]>([]);
  const [inProgress, setInProgress] = useState(true);

  const grid = useGrid<Log>([{ field: 'date', dir: 'desc' }]);
  const messages = useContext(messageContext);
  const logs = useLogs();
  const lookups = useLookups();
  const adminAuth = useContext(adminContext);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLogTypes(await lookups.getLogTypesAsListItems());
    await loadData();
  };

  const handleSelectAsNumber = (e: ChangeEvent<HTMLSelectElement>) => {
    setForm(SelectHelper.baseHandleNumber(e, form));
  };

  const handleInputAsNumber = (e: ChangeEvent<HTMLInputElement>) => {
    setForm(InputHelper.baseHandleNumber(e, form));
  };

  const handleDateChange = (fieldName: string, date: Date | null | string, momentDate: moment.Moment | null, dateOnly: DateOnly | null) => {
    setForm(DateSelectorHelper.baseHandleDateOnly(fieldName, dateOnly, form));
  };

  const load = async () => {
    await loadData();

    // save form selection to cache
    utils.session.saveToSession(constants.storageKeys.logForm, form);
  };

  const loadData = async () => {
    setInProgress(true);
    const result = await logs.getLogs(form);
    setInProgress(false);

    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      grid.setData(result);
    }
  };

  return (
    <div className={appStyles.container} id="AdminLogs">
      <div className={appStyles.row}>
        <div className={appStyles.col_sm_12}>
          <div className={appStyles.pageHeading}>
            <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
              <Icon icon={Icons.logs} size="lg" color="darkBlue" iconName="Logs" customClass={appStyles.heading__icon} />
              <h1 className={appStyles.heading__text}>Logs</h1>
            </div>
          </div>
        </div>
      </div>

      <div className={appStyles.row}>
        <div className={appStyles.col_sm_12}>
          <div className={appStyles.filters}>
            <div className={classNames(appStyles.row, appStyles.filters_row)}>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell)}>
                <DateSelector
                  elementId="dateSelector"
                  name="startDate"
                  labelText="Start Date"
                  labelHidden={false}
                  placeholder="Select start date"
                  dateFormat={adminAuth.datePickerFormat}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  value={DateOnly.toDate(form.startDate)}
                  handleChange={handleDateChange}
                  validationErrors={form.fieldMessages['startDate']}
                />
              </div>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell)}>
                <DateSelector
                  elementId="dateSelector"
                  name="endDate"
                  labelText="End Date"
                  labelHidden={false}
                  placeholder="Select end date"
                  dateFormat={adminAuth.datePickerFormat}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  value={DateOnly.toDate(form.endDate)}
                  handleChange={handleDateChange}
                  validationErrors={form.fieldMessages['endDate']}
                />
              </div>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell)}>
                <Select
                  elementId="logTypeId"
                  labelText="Type"
                  name="logTypeId"
                  options={logTypes}
                  placeholder="Select log type"
                  disablePlaceholder={false}
                  value={form.logTypeId}
                  handleChange={handleSelectAsNumber}
                  validationErrors={form.fieldMessages['logTypeId']}
                />
              </div>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell)}>
                <Input
                  elementId="orderId"
                  labelText="Order ID"
                  inputType="number"
                  name="orderId"
                  placeholder="Enter order ID"
                  value={form.orderId}
                  handleChange={handleInputAsNumber}
                  validationErrors={form.fieldMessages['orderId']}
                />
              </div>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell)}>
                <Input
                  elementId="customerId"
                  labelText="Customer ID"
                  name="customerId"
                  inputType="number"
                  placeholder="Enter customer ID"
                  value={form.customerId}
                  handleChange={handleInputAsNumber}
                  validationErrors={form.fieldMessages['customerId']}
                />
              </div>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell__button)}>
                <Button id="loadButton" handleClick={load} buttonStyle="secondary" size="md" customClass={appStyles.filters_button} inProgress={inProgress}>
                  Load
                </Button>
              </div>
            </div>
            <div className={classNames(appStyles.row, appStyles.filters_row, appStyles.filters_row__search)}>
              <div className={appStyles.col_sm_4}>
                <Input
                  elementId="searchBar"
                  inputType="search"
                  name="searchBar"
                  labelText="Search logs"
                  labelHidden={true}
                  placeholder="Search logs"
                  value={grid.searchKeyword}
                  handleChange={grid.handleSearch}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {inProgress ? (
        <LoadingAlert />
      ) : (
        <div id="mainGrid" className="grid-with-lg-filter">
          <Grid
            data={grid.getData()}
            filter={grid.filter}
            filterable={false}
            pageable={grid.getPageable()}
            skip={grid.skip}
            take={grid.take}
            total={grid.getTotal()}
            onPageChange={grid.pageChange}
            sortable={grid.getSortable()}
            sort={grid.getSort()}
            resizable={true}
            onFilterChange={grid.filterChange}
            onSortChange={grid.sortChange}>
            {ColumnHelper.getGridColumns(
              [
                {
                  field: '',
                  title: 'View',
                  dataType: 'icon',
                  size: 'xs',
                  columnMenu: false,
                  cell: (props) => (
                    <td className="k-table-td gridColumn_xs gridColumn_button">
                      <Link to={'/admin/logs/' + props.dataItem.logId} className="grid__button">
                        <Icon icon={Icons.view} iconName={'View log message ' + props.dataItem.logId} color="darkBlue" size="xs" />
                      </Link>
                    </td>
                  )
                },
                { field: 'logId', title: 'Log ID', dataType: 'text', size: 'sm' },
                { field: 'date', title: 'Date', dataType: adminAuth.dateTimeFormat, size: 'md' },
                { field: 'message', title: 'Message', dataType: 'text', size: 'lg' },
                { field: 'eventTypeName', title: 'Type', dataType: 'text', size: 'md' }
              ],
              grid.filter,
              ColumnMenu
            )}
          </Grid>
        </div>
      )}
    </div>
  );
};
