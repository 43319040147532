import utils from 'utils/utils';
import { PaymentSession } from 'models/responses/paymentSession.model';
import { LogPaymentFailedForm } from 'models/forms/logPaymentFailedForm.model';
import { useNavigate } from 'react-router-dom';
import { PaymentSummary } from 'models/responses/paymentSummary.model';
import { LogPaymentInvokedForm } from 'models/forms/logPaymentInvokedForm.model';
import { useOrders } from 'hooks/useOrders';

export interface IApplePayHook {
  init: (details: PaymentSession, elementId: string, errorCallback: (logForm: LogPaymentFailedForm) => Promise<void>) => void;
}

export function useApplePay(): IApplePayHook {
  let handleErrorCallback: (logForm: LogPaymentFailedForm) => Promise<void>;
  let paymentSession: PaymentSession;
  let applePayConfig: unknown;

  const orders = useOrders();

  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccess = async (response: any) => {
    // success callback sometimes come back as Declined or Error, so check this
    if (response.result === 'Success') {
      // reset global judo object after successful payment
      utils.judopay.resetJudo();

      // extract useful info/message from response and pass to PaymentSummary object
      navigate('/confirmation', { state: new PaymentSummary(paymentSession.orderId, response.message, 'success', 'apple', response) });
    } else {
      // payment declined or error, so create a form to use to log payment failed/declined event and pass it back to UI to handle
      const logForm = new LogPaymentFailedForm(paymentSession, 'apple', 'success', applePayConfig, null, response);
      await handleErrorCallback(logForm);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = async (error: any) => {
    // create a form to use to log payment failed/declined event and pass it back to UI to handle
    const logForm = new LogPaymentFailedForm(paymentSession, 'apple', 'error', applePayConfig, error, null);
    await handleErrorCallback(logForm);
  };

  async function handleApplePayButtonClick(e: React.FormEvent) {
    e.preventDefault();

    const judo = utils.judopay.getJudo(paymentSession);

    // log payment invoked event
    const logForm = new LogPaymentInvokedForm(paymentSession.orderId, 'apple', paymentSession.session, applePayConfig);
    await orders.logPaymentInvoked(logForm);

    judo.digitalWallets.invokePaymentWithApplePay(paymentSession.session, applePayConfig).then(onSuccess).catch(onError);
  }

  const init = (session: PaymentSession, elementId: string, errorCallback: (logForm: LogPaymentFailedForm) => Promise<void>) => {
    handleErrorCallback = errorCallback;
    paymentSession = session;
    const judo = utils.judopay.getJudo(paymentSession);

    applePayConfig = {
      amount: '' + paymentSession.amount,
      currency: paymentSession.currencyCode,
      currencyCode: paymentSession.currencyCode,
      yourConsumerReference: paymentSession.consumerReference,
      yourPaymentReference: paymentSession.paymentReference,
      judoId: paymentSession.judoId,
      initiativeContext: paymentSession.domain.replace('https://', '')
    };

    const applePayButton = judo.digitalWallets.getApplePayButton({
      language: 'EN',
      style: 'black',
      type: 'pay'
    });

    if (applePayButton) {
      applePayButton.style = 'height:50px; width:280px; padding:2.5rem; border-radius:0.5rem; margin-top: 2rem;';
      applePayButton.onclick = handleApplePayButtonClick;

      const buttonContainer = document.getElementById(elementId);
      if (buttonContainer !== null) {
        buttonContainer.append(applePayButton);
      }
    }
  };

  return {
    init
  };
}
