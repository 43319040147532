import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class RemoveItemFromBasketForm extends BaseValidatedObject {
  basketId: number;
  basketItemId: number;

  constructor(basketId: number, basketItemId: number) {
    super();

    this.basketId = basketId;
    this.basketItemId = basketItemId;
  }
}
