import { adminContext } from 'App';
import appStyles from 'App.module.scss';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { Input, InputHelper } from 'components/Generic/FormElements/Input/Input';
import { FormWrapper } from 'components/Generic/FormWrapper/FormWrapper';
import { useRequestedUrl } from 'hooks/useRequestedUrl';
import { LoginForm } from 'models/forms/loginForm.model';
import { AppError } from 'models/generic/appError.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import validation from 'utils/validation';

export const AdminLogin: FC = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState(new LoginForm());
  const [inProgress, setInProgress] = useState(false);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const adminAuth = useContext(adminContext);
  const currentUser = adminAuth.getCurrentUser();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (currentUser) {
      navigate('/admin/');
    }

    setValidationRules(await adminAuth.getLoginValidationRules());
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = InputHelper.baseHandleString(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const validate = (form: LoginForm): boolean => validation.validateForm(form, validationRules, setForm);

  const logIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitAttempted(true);

    const isValid = validate(form);
    if (isValid) {
      setInProgress(true);
      const result = await adminAuth.logIn(form);
      if (result instanceof AppError) {
        form.fieldMessages = result.fieldMessages;
        setForm(form);
        setInProgress(false);
      } else {
        const url = useRequestedUrl();
        navigate(url);
      }
    }
  };

  return (
    <div className={appStyles.form__container} id="AdminLoginForm">
      <FormWrapper formTitle="Admin Area" onSubmit={logIn} className={appStyles.form__wrapper}>
        <div className={appStyles.form__row}>
          <Input
            elementId="login"
            inputType="text"
            labelText="Enter your email address"
            name="login"
            placeholder="Email"
            handleChange={handleInput}
            value={form.login}
            autocomplete="email"
            validationErrors={form.fieldMessages['login']}
          />
        </div>
        <div className={appStyles.form__row}>
          <Input
            elementId="password"
            inputType="password"
            labelText="Enter your password"
            name="password"
            placeholder="Password"
            handleChange={handleInput}
            value={form.password}
            autocomplete="current-password"
            validationErrors={form.fieldMessages['password']}
          />
        </div>
        <div className={appStyles.form__row}>
          <Button id="loginButton" buttonType="submit" buttonStyle="primary" size="lg" inProgress={inProgress}>
            Log in
          </Button>
        </div>
      </FormWrapper>
    </div>
  );
};
