import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class LogPaymentInvokedForm extends BaseValidatedObject {
  orderId: number | null = null;
  paymentMethod: 'card' | 'google' | 'apple' | null = null;
  paymentSession: string;
  paymentConfigJson: string;

  constructor(orderId: number | null, method: 'card' | 'google' | 'apple', session: string, config: unknown) {
    super();

    this.orderId = orderId;
    this.paymentMethod = method;
    this.paymentSession = session;
    this.paymentConfigJson = JSON.stringify(config);
  }
}
