import appStyles from 'App.module.scss';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { CheckBox, CheckBoxHelper } from 'components/Generic/FormElements/CheckBox/CheckBox';
import { DateSelector, DateSelectorHelper } from 'components/Generic/FormElements/DateSelector/DateSelector';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import { TextArea, TextAreaHelper } from 'components/Generic/FormElements/TextArea/TextArea';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { adminContext, messageContext } from 'App';
import { EditStoreForm } from 'models/forms/editStoreForm.model';
import { AppError } from 'models/generic/appError.model';
import { DateOnly } from 'models/generic/dateOnly';
import { ListItem } from 'models/generic/listItem.model';
import { Store } from 'models/responses/store.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import validation from 'utils/validation';
import { useStores } from 'hooks/useStores';
import classNames from 'classnames';
import LoadingAlert from 'components/Generic/LoadingAlert/LoadingAlert';

export const EditMaintenance: FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [form, setForm] = useState(new EditStoreForm(null));
  const [inProgress, setInProgress] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [store, setStore] = useState<Store | null>(null);
  const storeId = params.id ? +params.id : null;

  const messages = useContext(messageContext);
  const stores = useStores();
  const adminAuth = useContext(adminContext);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setForm(new EditStoreForm(store));
  }, [store]);

  const init = async () => {
    setValidationRules(await stores.getEditStoreValidationRules());
    if (storeId != null) {
      setStore(await stores.getStoreById(storeId));
    }
    setIsLoaded(true);
  };

  const handleTextAreaAsString = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedForm = TextAreaHelper.baseHandleString(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDateChange = (fieldName: string, date: Date | null | string, momentDate: moment.Moment | null, dateOnly: DateOnly | null) => {
    const updatedForm = DateSelectorHelper.baseHandleDate(fieldName, date as Date | null, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = CheckBoxHelper.baseHandleBool(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const validate = (form: EditStoreForm): boolean => validation.validateForm(form, validationRules, setForm);

  const save = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitAttempted(true);

    const isValid = validate(form);
    if (isValid) {
      if (store && store.storeId) {
        setInProgress(true);
        const result = await stores.updateStore(form, store.storeId);
        if (result instanceof AppError) {
          form.fieldMessages = result.fieldMessages;
          setForm(form);
          setInProgress(false);
        } else {
          messages.addSuccessAsString('Store message updated successfully.');
          navigate('/admin/maintenance');
        }
      }
    }
  };

  const back = () => {
    navigate('/admin/maintenance');
  };

  const notFound = isLoaded && store === null;

  return (
    <div className={appStyles.container} id="AdminEditMaintenance">
      {isLoaded === false ? (
        <LoadingAlert />
      ) : notFound ? (
        <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
          <Icon icon={Icons.warning} size="lg" color="darkBlue" iconName="Warning" customClass={appStyles.heading__icon} />
          <h1 id="NotFound" className={appStyles.heading__text}>
            Store not found
          </h1>
        </div>
      ) : store ? (
        <>
          <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
            <Icon icon={Icons.maintenance} size="lg" color="darkBlue" iconName="Maintenance" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>Edit maintenance message: {store.name}</h1>
          </div>

          <form className={appStyles.row}>
            <div className={appStyles.col_md_6}>
              <div id="messageCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
                <fieldset>
                  <Legend type="large" text="Message" />

                  <div className={appStyles.form__row}>
                    <TextArea
                      elementId="maintenanceMessage"
                      labelText="Message"
                      name="maintenanceMessage"
                      placeholder="Enter message"
                      value={form.maintenanceMessage}
                      handleChange={handleTextAreaAsString}
                      validationErrors={form.fieldMessages['maintenanceMessage']}
                    />
                  </div>
                  <label className={appStyles.form__label}>Disable Checkout during maintenance window</label>
                  <CheckBox
                    customLabelClass={appStyles.form__label}
                    name="disableCheckoutDuringMaintenance"
                    options={[new ListItem(true, 'If selected the checkout button will not be available during maintenance window.')]}
                    selectedOptions={[form.disableCheckoutDuringMaintenance]}
                    handleChange={handleCheckBox}
                  />
                </fieldset>
              </div>
            </div>

            <div className={appStyles.col_md_6}>
              <div id="datesCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
                <fieldset>
                  <Legend type="large" text="Dates" />

                  <div className={appStyles.form__row}>
                    <DateSelector
                      elementId="maintenanceStart"
                      name="maintenanceStart"
                      labelText="Start date and time"
                      labelHidden={false}
                      placeholder="Select start date"
                      dateFormat={adminAuth.datePickerFormat}
                      customLabelClass={appStyles.form__label}
                      customInputClass={appStyles.form__input}
                      value={form.maintenanceStart}
                      handleChange={handleDateChange}
                      includeTimePicker={true}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <DateSelector
                      elementId="maintenanceEnd"
                      name="maintenanceEnd"
                      labelText="End date and time"
                      labelHidden={false}
                      placeholder="Select end date"
                      dateFormat={adminAuth.datePickerFormat}
                      customLabelClass={appStyles.form__label}
                      customInputClass={appStyles.form__input}
                      value={form.maintenanceEnd}
                      handleChange={handleDateChange}
                      includeTimePicker={true}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </form>
        </>
      ) : null}

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button id="buttonBack" buttonStyle="secondary" size="lg" handleClick={back}>
          Back
        </Button>
        {isLoaded && notFound === false ? (
          <Button id="buttonSave" buttonStyle="primary" size="lg" handleClick={save} inProgress={inProgress}>
            Save
          </Button>
        ) : null}
      </BottomButtonContainer>
    </div>
  );
};
