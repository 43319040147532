import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class EditPreferencesForm extends BaseValidatedObject {
  optOutEmail = false;

  constructor(optInEmail: boolean) {
    super();

    this.optOutEmail = !optInEmail;
  }
}
