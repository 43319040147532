import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { FC } from 'react';

export const PageNotFound: FC = () => (
  <div className={appStyles.container} id="NotFoundPage">
    <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
      <Icon icon={Icons.warning} size="heading" color="darkBlue" iconName="Warning" customClass={appStyles.heading__icon} />
      <h1 id="NotFound" className={appStyles.heading__text}>
        Page not found
      </h1>
    </div>
  </div>
);
