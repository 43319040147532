import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { ValidationArea } from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { ChangeEvent, FC } from 'react';

interface IInputProps {
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   * @property {string} title Set the title attribute
   */
  title?: string;
  /**
   * @property {boolean} required Optional. Set the field to required
   */
  required?: boolean;
  /**
   * @property {string} labelText Set the text to appear in the label
   */
  labelText: string;
  /**
   * @property {string} elementId Set the ID attribute
   */
  elementId: string;
  /**
   * @property {string} value Set the value attribute
   */
  value: string | number | null;
  /**
   * @property {string} inputType Choose the type of input
   */
  inputType: 'text' | 'password' | 'email' | 'number' | 'tel' | 'url' | 'search';
  /**
   * @property {string} inputMode Optional. Choose the input mode, default is 'text'.
   * Check https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode for details
   */
  inputMode?: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
  /**
   * @property {string} pattern Choose the type of input
   */
  pattern?: string;
  /**
   * @property {string} placeholder Set the placeholder
   */
  placeholder: string;
  /**
   * @property {boolean} labelHidden Optional. Hide the label. labelText will be used for screenreaders. Default is false.
   */
  labelHidden?: boolean;
  /**
   * @property {number} maxLength Optional. Set the maxLength of the input
   */
  maxLength?: number;
  /**
   * @property {boolean} readonly Optional. Set the readonly of the input
   */
  readonly?: boolean;
  /**
   * @property {(event: ChangeEvent<HTMLInputElement>) => void} handleChange Set the action onChange
   */
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * @property {(event: ChangeEvent<HTMLInputElement>) => void} handleBlur Set the action on blue (lost focus)
   */
  handleBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * @property {(value: string) => void} handleFinishedTyping Set the action handleFinishedTyping
   */
  handleFinishedTyping?: (value: string) => void;
  /**
   * @property {string} minValue Optional. Set min value for a number input
   */
  minValue?: string;
  /**
   * @property {string} customInputClass Optional. Use a custom class on the input - use styles from the current component or appStyles
   */
  customInputClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {string[]} validationErrors Optional. Any validation messages related to this field
   */
  validationErrors?: string[];
  /**
   * @property {string} autocomplete Optional. Set the autocomplete attribute, default is off. Check https://www.w3.org/TR/WCAG21/#input-purposes for details
   */
  autocomplete?:
    | 'off'
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'organization-title'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'organization'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-level4'
    | 'address-level3'
    | 'address-level2'
    | 'address-level1'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'cc-name'
    | 'cc-given-name'
    | 'cc-additional-name'
    | 'cc-family-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-csc'
    | 'cc-type'
    | 'transaction-currency'
    | 'transaction-amount'
    | 'language'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'sex'
    | 'url'
    | 'photo'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-area-code'
    | 'tel-local'
    | 'tel-local-prefix'
    | 'tel-local-suffix'
    | 'tel-extension'
    | 'email'
    | 'impp';
}

/**
 * @description Add an input element with a label. Optional properties: readonly, customInputClass, customLabelClass,
 * autocomplete, inputMode, pattern, title, required, maxLength, validationError
 */
// eslint-disable-next-line complexity
export const Input: FC<IInputProps> = (props: IInputProps) => {
  let value: string | null = null;
  const finishTypingWaitTimeInSeconds = 1;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    // raise change event
    if (props.handleChange) {
      props.handleChange(event);
    }

    // check if user is still typing (if value after a short delay is the same as current)
    const newValue = event.target.value;
    value = newValue;
    if (props.handleFinishedTyping) {
      setTimeout(() => {
        checkIfFinishedTyping(newValue);
      }, finishTypingWaitTimeInSeconds * 1000);
    }
  };

  const checkIfFinishedTyping = (newValue: string) => {
    if (props.handleFinishedTyping && newValue === value) {
      props.handleFinishedTyping(newValue);
    }
  };

  return (
    <>
      <label
        htmlFor={props.name}
        className={classNames(
          appStyles.form__label,
          { [appStyles.form__label_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
          { [props.customLabelClass as string]: props.customLabelClass }, // add custom class if defined
          { [appStyles.srOnly]: props.labelHidden } // add srOnly class if label hidden
        )}>
        {props.labelText}
      </label>

      <input
        className={classNames(
          appStyles.form__input,
          { [appStyles.form__input_search]: props.inputType === 'search' }, // add search class if type is search
          { [appStyles.form__input_text]: props.inputType !== 'search' }, // add text class if type is not search
          { [appStyles.form__input_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
          { [appStyles.form__input_readonly]: props.readonly === true }, // add readonly class
          { [props.customInputClass as string]: props.customInputClass } // add custom class if defined
        )}
        id={props.name}
        name={props.name}
        type={props.inputType === 'search' ? 'text' : props.inputType} // don't use 'search', as it doesn't render ok on safari for some reason
        value={props.value === null || props.value === undefined ? '' : props.value}
        onChange={onChange}
        onBlur={props.handleBlur}
        placeholder={props.placeholder}
        autoComplete={props.autocomplete || 'off'}
        inputMode={props.inputMode || 'text'}
        pattern={props.pattern || undefined}
        title={props.title || ''}
        min={props.minValue || undefined}
        maxLength={props.maxLength || undefined}
        required={props.required || undefined}
        readOnly={props.readonly || undefined}
        aria-required={props.required || undefined}
      />
      {props.inputType === 'search' ? <Icon icon={Icons.search} iconName="Search" size="md" color="darkBlue" customClass={appStyles.search__icon} /> : ''}

      <ValidationArea sectionId={props.elementId} validationErrors={props.validationErrors} />
    </>
  );
};

export class InputHelper {
  static baseHandleString<T>(e: ChangeEvent<HTMLInputElement>, obj: T): T {
    const value = e.target.value;
    const fieldName = e.target.name;

    obj = {
      ...obj,
      [fieldName]: value
    };

    return obj;
  }

  static baseHandleNumber<T>(e: ChangeEvent<HTMLInputElement>, obj: T): T {
    let value = null;
    if (e.target.value) {
      value = +e.target.value;
    }
    const fieldName = e.target.name;

    obj = {
      ...obj,
      [fieldName]: value
    };

    return obj;
  }
}
