import { Address } from 'models/responses/address.model';
import { ITokenResponse } from 'models/responses/tokenResponse.model';
import moment, { Moment } from 'moment';

export class Customer {
  customerId: number | null = null;
  sigmaId: number | null = null;
  samsAccountId: number | null = null;
  msdAccountId: number | null = null;
  expectedMsdAccountId: number | null = null;
  firstName = '';
  lastName = '';
  fullName = '';
  title = '';
  email = '';
  optInEmail = false;
  isActive = false;
  dateLastLogin: Date | null = null;
  dateRegistered: Date | null = null;
  dateImported: Date | null = null;

  address: Address | null = null;

  rawToken = '';
  tokenExpiry: Moment | null = null;

  static fromTokenResponse(tokenResponse: ITokenResponse): Customer {
    if (tokenResponse.customer && tokenResponse.token) {
      tokenResponse.customer.rawToken = tokenResponse.token;
      tokenResponse.customer.tokenExpiry = moment(tokenResponse.expiry);

      return tokenResponse.customer;
    } else {
      throw new Error('Customer response is null');
    }
  }
}
