import appStyles from 'App.module.scss';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { Input, InputHelper } from 'components/Generic/FormElements/Input/Input';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { customerContext, messageContext } from 'App';
import { ChangePasswordForm } from 'models/forms/changePasswordForm.model';
import { AppError } from 'models/generic/appError.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import validation from 'utils/validation';
import classNames from 'classnames';

export const ChangePassword: FC = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState(new ChangePasswordForm());
  const [inProgress, setInProgress] = useState(false);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const messages = useContext(messageContext);
  const customerAuth = useContext(customerContext);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setValidationRules(await customerAuth.getChangePasswordValidationRules());
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = InputHelper.baseHandleString(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const validate = (form: ChangePasswordForm): boolean => validation.validateForm(form, validationRules, setForm);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitAttempted(true);

    const isValid = validate(form);
    if (isValid) {
      setInProgress(true);
      const result = await customerAuth.changePassword(form);
      if (result instanceof AppError) {
        form.fieldMessages = result.fieldMessages;
        setForm(form);
        setInProgress(false);
      } else {
        messages.addSuccessAsString('Your password has been changed.');
        navigate('/');
      }
    }
  };

  const back = () => {
    navigate('/');
  };

  return (
    <>
      <div className={appStyles.container} id="ChangePasswordForm">
        <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
          <Icon icon={Icons.user} size="lg" color="darkBlue" iconName="User" customClass={appStyles.heading__icon} />
          <h1 className={appStyles.heading__text}>Change password</h1>
        </div>

        <form className={appStyles.row}>
          <div className={appStyles.col_md_6}>
            <div id="passwordDetailsCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
              <fieldset>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="newPassword"
                    inputType="password"
                    labelText="Enter your new password"
                    name="newPassword"
                    placeholder="New password"
                    handleChange={handleInput}
                    value={form.newPassword}
                    autocomplete="new-password"
                    validationErrors={form.fieldMessages['newPassword']}
                  />
                </div>

                <div className={appStyles.form__row}>
                  <Input
                    elementId="confirmPassword"
                    inputType="password"
                    labelText="Confirm your new password"
                    name="confirmPassword"
                    placeholder="Confirm new password"
                    handleChange={handleInput}
                    value={form.confirmPassword}
                    autocomplete="new-password"
                    validationErrors={form.fieldMessages['confirmPassword']}
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </form>
      </div>

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button buttonStyle="secondary" size="lg" handleClick={back}>
          Back to dashboard
        </Button>

        <Button id="submitButton" buttonStyle="primary" size="lg" handleClick={submit} inProgress={inProgress} title="Click to update your password">
          Update password
        </Button>
      </BottomButtonContainer>
    </>
  );
};
