export class ListItem {
  id: number | string | boolean;
  text: string;
  visible = true; // this is currently supported by CheckBox only

  constructor(id: number | string | boolean, text: string, visible = true) {
    this.id = id;
    this.text = text;
    this.visible = visible;
  }
}
