import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { ValidationArea } from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { ListItem } from 'models/generic/listItem.model';
import React, { ChangeEvent, FC } from 'react';

interface IRadioProps {
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   * @property {ListItem[]} options Set the available options
   */
  options: ListItem[];
  /**
   * @property {(event: ChangeEvent<HTMLInputElement>) => void} handleChange Set the action onChange
   */
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * @property {string} selectedOption Set the selected option
   */
  selectedOption: number | string | boolean | null | undefined;
  /**
   * @property {string} customInputClass Optional. Use a custom class on the input - use styles from the current component or appStyles
   */
  customInputClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {string[]} validationErrors Optional. Any validation messages related to this field
   */
  validationErrors?: string[];
}

/**
 * @description Add an radio button group. Optional properties: customInputClass, customLabelClass, validationError
 */
export const Radio: FC<IRadioProps> = (props: IRadioProps) => (
  <>
    {props.options.map((option) => (
      <React.Fragment key={option.id as string}>
        <input
          className={classNames(
            appStyles.checkbox,
            { [appStyles.form__input_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
            { [props.customInputClass as string]: props.customInputClass } // add custom class if defined
          )}
          onChange={props.handleChange}
          type="radio"
          checked={props.selectedOption === (option.id as string)}
          aria-checked={props.selectedOption === (option.id as string) ? 'true' : 'false'}
          name={props.name}
          value={option.id as string}
          id={props.name + option.id}
        />
        <label
          htmlFor={props.name + option.id}
          className={classNames(
            appStyles.checkbox__label,
            appStyles.checkbox__label_radio,
            { [appStyles.checkbox__label_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
            { [props.customLabelClass as string]: props.customLabelClass } // add custom class if defined
          )}>
          {option.text}
        </label>
      </React.Fragment>
    ))}

    <ValidationArea validationErrors={props.validationErrors} />
  </>
);

export class RadioHelper {
  static baseHandleBool<T>(e: ChangeEvent<HTMLInputElement>, obj: T): T {
    const value = e.target.value === 'true' ? true : e.target.value === 'false' ? false : null;
    const fieldName = e.target.name;

    obj = {
      ...obj,
      [fieldName]: value
    };

    return obj;
  }

  static baseHandleNumber<T>(e: ChangeEvent<HTMLInputElement>, obj: T): T {
    let value = null;
    if (e.target.value) {
      value = +e.target.value;
    }
    const fieldName = e.target.name;

    obj = {
      ...obj,
      [fieldName]: value
    };

    return obj;
  }
}
