import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import { customerContext } from 'App';
import { useRequestedUrl } from 'hooks/useRequestedUrl';
import { CodeLoginForm } from 'models/forms/codeLoginForm.model';
import { AppError } from 'models/generic/appError.model';
import { FC, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import { Button } from 'components/Generic/FormElements/Button/Button';
import constants from 'utils/constants';
import classNames from 'classnames';

export const LoginCallback: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState(new CodeLoginForm());
  const [inProgress, setInProgress] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [contactEmailAddress, setContactEmailAddress] = useState('onlinepersonalsubs@oup.com');

  const customerAuth = useContext(customerContext);
  const currentUser = customerAuth.getCurrentUser();

  useEffect(() => {
    // load user details from code
    init();
  }, []);

  const init = async () => {
    if (currentUser) {
      navigate('/');
    } else {
      const code = new URLSearchParams(location.search).get('code');
      if (code) {
        form.code = code;
        setForm(form);
        await logIn();
      } else {
        // redirect to login page after a short delay
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      }
    }
  };

  const logIn = async () => {
    setInProgress(true);
    const result = await customerAuth.logIn(form);
    setInProgress(false);

    if (result instanceof AppError) {
      if (result.fieldMessages && result.fieldMessages['code'] && result.fieldMessages['code'].length > 0) {
        const errorMessage = result.fieldMessages['code'][0];
        setLoginErrorMessage(errorMessage);
      } else {
        setLoginErrorMessage('Login failed');
      }

      if (result.fieldMessages && result.fieldMessages['defaultStoreId'] && result.fieldMessages['defaultStoreId'].length > 0) {
        const customerStoreId = +result.fieldMessages['defaultStoreId'][0];
        switch (customerStoreId) {
          case constants.stores.subscriptionsUs:
            setContactEmailAddress('online@oup.com');
            break;
          default:
            setContactEmailAddress('onlinepersonalsubs@oup.com');
            break;
        }
      }
      setLoginFailed(true);
    } else {
      // check if user has all required details populated
      const needDetails = customerAuth.needsDetailsPopulated(result);
      if (needDetails) {
        // if no then redirect to edit details page
        navigate('/edit-details');
      } else {
        const url = useRequestedUrl();
        navigate(url);
      }
    }
  };

  const goToLogin = () => {
    navigate('/login');
  };

  return (
    <div className={appStyles.container} id="TokenLoginForm">
      <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
        <Icon icon={Icons.user} size="lg" color="darkBlue" iconName="Log in" customClass={appStyles.heading__icon} />
        <h1 className={appStyles.heading__text}>Login</h1>
      </div>

      <form className={appStyles.row}>
        <div className={appStyles.col_md_12}>
          <div id="loginfForm" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
            {inProgress && <Alert alertType="info">Please wait, we are logging you in.</Alert>}
            {loginFailed && (
              <div id="loginFailed">
                <Legend type="large" text={loginErrorMessage} />
                <p>It looks like we were not able to authenticate you.</p>
                <p>You may try again by going to the login page.</p>
                <p>
                  If the problem persists, feel free to contact us on <a href={`mailto:${contactEmailAddress}`}>{contactEmailAddress}</a>.
                </p>
                <br />
                <p>
                  <Button buttonStyle="primary" size="lg" handleClick={goToLogin} title="Go to login">
                    Go to login page
                  </Button>
                </p>
                <br />
              </div>
            )}

            {!form.code && <Alert alertType="error">Login code not found, we are redirecting you to the login page.</Alert>}
          </div>
        </div>
      </form>
    </div>
  );
};
