import { IApiHook } from 'hooks/useApi';
import { IBaseAuthHook, useBaseAuth } from 'hooks/useBaseAuth';
import { LoginForm } from 'models/forms/loginForm.model';
import { AppError } from 'models/generic/appError.model';
import { Administrator } from 'models/responses/administrator.model';
import { ITokenResponse } from 'models/responses/tokenResponse.model';
import { useEffect, useState } from 'react';
import constants from 'utils/constants';

export interface IAdminAuthHook extends IBaseAuthHook<Administrator> {
  dateFormat: 'dateUk' | 'dateUs';
  dateTimeFormat: 'dateAndTimeUk' | 'dateAndTimeUs';
  datePickerFormat: 'dd/MM/yyyy' | 'MM/dd/yyyy';
  logIn: (loginForm: LoginForm) => Promise<boolean | AppError>;
}

export interface IAdminAuthHookProps {
  api: IApiHook;
}

export function useAdminAuth(props: IAdminAuthHookProps): IAdminAuthHook {
  const [dateFormat, setDateFormat] = useState<'dateUk' | 'dateUs'>('dateUk');
  const [dateTimeFormat, setDateTimeFormat] = useState<'dateAndTimeUk' | 'dateAndTimeUs'>('dateAndTimeUk');
  const [datePickerFormat, setDatePickerFormat] = useState<'dd/MM/yyyy' | 'MM/dd/yyyy'>('dd/MM/yyyy');

  const api = props.api;
  const baseAuth = useBaseAuth<Administrator>({ controllerName: 'admin', userStorageKey: constants.storageKeys.currentAdmin, api: api });
  const currentUser = baseAuth.getCurrentUser();

  useEffect(() => {
    // load date format for current user on change
    setDateFormats();
  }, [currentUser]);

  const setDateFormats = () => {
    if (currentUser) {
      if (currentUser.dateFormat === 'us') {
        setDateFormat('dateUs');
        setDateTimeFormat('dateAndTimeUs');
        setDatePickerFormat('MM/dd/yyyy');
      } else {
        setDateFormat('dateUk');
        setDateTimeFormat('dateAndTimeUk');
        setDatePickerFormat('dd/MM/yyyy');
      }
    }
  };

  const logIn = async (loginForm: LoginForm): Promise<boolean | AppError> => {
    const response = await api.post<ITokenResponse>(baseAuth.controllerName + '/login', loginForm, 'Login failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      baseAuth.setCurrentUser(Administrator.fromTokenResponse(response));

      return true;
    }
  };

  return {
    dateFormat,
    dateTimeFormat,
    datePickerFormat,
    logIn,
    ...baseAuth
  };
}
