import { Grid } from '@progress/kendo-react-grid';
import { CheckBox } from 'components/Generic/FormElements/CheckBox/CheckBox';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { useGrid } from 'hooks/useGrid';
import { customerContext, messageContext } from 'App';
import { UpdateAutoRenewalForm } from 'models/forms/updateAutoRenewalForm.model';
import { AppError } from 'models/generic/appError.model';
import appStyles from 'App.module.scss';
import { ListItem } from 'models/generic/listItem.model';
import { ListView, ListViewItemProps } from '@progress/kendo-react-listview';
import { Subscription } from 'models/responses/subscription.model';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import constants from 'utils/constants';
import { useSubscriptions } from 'hooks/useSubscriptions';
import Alert from 'components/Generic/Alert/Alert';
import classNames from 'classnames';
import styles from './Subscriptions.module.scss';

export const Subscriptions: FC = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dateFormat, setDateFormat] = useState<'dateUk' | 'dateUs'>('dateUk');
  // TODO: refactor to use @uidotdev/usehooks and useWindowSize, once project is upgraded and not relying on buggy version of babel
  const [width, setWidth] = useState<number>(window.innerWidth);

  const grid = useGrid<Subscription>();
  const messages = useContext(messageContext);
  const subscriptions = useSubscriptions();
  const customerAuth = useContext(customerContext);
  const currentUser = customerAuth.getCurrentUser();

  useEffect(() => {
    init();

    // clean-up on unload
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const init = async () => {
    window.addEventListener('resize', handleWindowSizeChange);

    // show all subscriptions on a single page
    grid.setTake(Number.MAX_VALUE);

    await loadData();
    grid.makeAccessible(false);

    // set date format based on current user's country
    if (currentUser && currentUser.address) {
      const format = currentUser.address.countryId === constants.usCountryId ? 'dateUs' : 'dateUk';
      setDateFormat(format);
    }
  };

  const handleCheckBox = async (e: ChangeEvent<HTMLInputElement>, msdOrderId: number, productName: string) => {
    const updatedData = grid.allData.map((subscription: Subscription) =>
      subscription.msdOrderId === msdOrderId ? { ...subscription, willAutoRenew: e.target.checked } : subscription
    );

    grid.setData(updatedData);

    await saveAutoRenewal(msdOrderId, e.target.checked, productName);
  };

  const loadData = async () => {
    const result = await subscriptions.getSubscriptions();
    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      grid.setData(result);
      setDataLoaded(true);
    }
  };

  const saveAutoRenewal = async (msdOrderId: number, autorenew: boolean, productName: string) => {
    const form = new UpdateAutoRenewalForm(msdOrderId, autorenew);
    const response = await subscriptions.saveAutoRenewal(form);
    if (response instanceof AppError) {
    } else {
      messages.addSuccessAsString(`Your preferences have been updated for ${productName}.`);
    }
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = width <= 768;

  // eslint-disable-next-line complexity
  const MobileSubscriptionsRender = (props: ListViewItemProps) => (
    <>
      {props.index === 0 ? <hr /> : null}

      <div className="k-listview-item row p-2 border-bottom align-middle">
        <div>
          {props.dataItem.productUrl ? (
            <span className={styles.listViewItemTitle}>
              <a href={props.dataItem.productUrl} target="_blank" rel="noopener noreferrer" className={styles.titleLink} title="Click to open in a new tab">
                {props.dataItem.productName}
              </a>
            </span>
          ) : (
            <span className={styles.listViewItemTitle}>{props.dataItem.productName}</span>
          )}

          <span className={styles.listViewItemDates}>
            (from {props.dataItem.startDate.toLocaleDateString()} to {props.dataItem.endDate.toLocaleDateString()})
          </span>
        </div>
        <div className={appStyles.mobile__twoColumn}>
          <div className={classNames(appStyles.mobile__halfWidth, styles.listViewRowRenew)}>
            {props.dataItem.isProductAutoRenewable ? (
              props.dataItem.isLatest ? (
                <>
                  <span className={styles.listViewItemRenew}>Renew now: </span>
                  <a href={props.dataItem.renewalUrl} target="_blank" rel="noopener noreferrer">
                    <Icon icon={Icons.renew} iconName="Renew your subscription now" color="darkBlue" size="md" />
                  </a>
                </>
              ) : null
            ) : null}
          </div>
          <div className={appStyles.mobile__halfWidth}>
            {props.dataItem.isProductAutoRenewable &&
            props.dataItem.hasRecurringDetails &&
            props.dataItem.isLatest &&
            (props.dataItem.paymentMethod === 'card' || !props.dataItem.paymentMethod) ? (
              <CheckBox
                name={`renew_${props.dataItem.msdOrderId}`}
                options={[new ListItem(true, 'Auto renew')]}
                selectedOptions={[props.dataItem.willAutoRenew]}
                customWrapperClass={styles.centeredCheckBox}
                customLabelClass={styles.autoRenewLabel}
                handleChange={(event) => {
                  handleCheckBox(event, props.dataItem.msdOrderId, props.dataItem.productName);
                }}
              />
            ) : null}
          </div>
        </div>
      </div>

      <hr />
    </>
  );

  return dataLoaded === false ? (
    // loading
    <Alert alertType="info">Loading subscriptions...</Alert>
  ) : grid.allData.length === 0 ? (
    // no data found
    <>
      <p>You have no subscriptions.</p>
      <p>Please also note that subscriptions that have a future start date are not shown below.</p>
    </>
  ) : (
    // data loaded
    <>
      <p>
        Below are your active subscriptions, if any of them are renewable you should be able to click the <b>Renew now</b>
        button to renew them or select the <b>Auto renew</b> checkbox to enable or disable automated renewal on expiry.
      </p>
      <p>Please also note that subscriptions that have a future start date are not shown below.</p>

      {isMobile ? (
        <ListView data={grid.getData()} item={MobileSubscriptionsRender} className={styles.listView} />
      ) : (
        <div id="mainSubscriptionsGrid" className={styles.subscriptionsGrid}>
          <Grid data={grid.getData()} filterable={false} resizable={true}>
            {ColumnHelper.getGridColumns([
              {
                field: '',
                title: 'Title',
                dataType: 'text',
                size: 'md',
                cell: (props) => (
                  <td className="k-table-td gridColumn_md">
                    {props.dataItem.productUrl ? (
                      <a
                        href={props.dataItem.productUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.titleLink}
                        title="Click to open in a new tab">
                        {props.dataItem.productName}
                      </a>
                    ) : (
                      props.dataItem.productName
                    )}
                  </td>
                )
              },
              { field: 'startDate', title: 'Start date', dataType: dateFormat, size: 'sm' },
              { field: 'endDate', title: 'Expiry date', dataType: dateFormat, size: 'sm' },
              {
                field: '',
                title: 'Renew now',
                dataType: 'icon',
                size: 'md',
                columnMenu: false,
                cell: (props) => (
                  <td className="k-table-td gridColumn_md gridColumn_button">
                    {props.dataItem.isProductAutoRenewable ? (
                      props.dataItem.isLatest ? (
                        <a href={props.dataItem.renewalUrl} target="_blank" rel="noopener noreferrer">
                          <Icon icon={Icons.renew} iconName="Renew your subscription now" color="darkBlue" size="xs" />
                        </a>
                      ) : (
                        <p>Subscription is already renewed</p>
                      )
                    ) : (
                      <p>Subscription is not renewable</p>
                    )}
                  </td>
                )
              },
              {
                field: '',
                title: 'Auto renew',
                dataType: 'text',
                size: 'sm',
                columnMenu: false,
                cell: (props) => (
                  <td className="k-table-td gridColumn_sm gridColumn_button">
                    {props.dataItem.isProductAutoRenewable &&
                    props.dataItem.hasRecurringDetails &&
                    props.dataItem.isLatest &&
                    (props.dataItem.paymentMethod === 'card' || !props.dataItem.paymentMethod) ? (
                      <CheckBox
                        name={`renew_${props.dataItem.msdOrderId}`}
                        options={[new ListItem(true, 'Auto renew')]}
                        selectedOptions={[props.dataItem.willAutoRenew]}
                        customWrapperClass={styles.centeredCheckBox}
                        customLabelClass={styles.autoRenewLabel}
                        handleChange={(event) => {
                          handleCheckBox(event, props.dataItem.msdOrderId, props.dataItem.productName);
                        }}
                      />
                    ) : (props.dataItem.paymentMethod !== 'card' && props.dataItem.paymentMethod) ||
                      (props.dataItem.isLatest && props.dataItem.isProductAutoRenewable) ? (
                      <p>Subscription cannot be auto renewed</p>
                    ) : null}
                  </td>
                )
              }
            ])}
          </Grid>
        </div>
      )}
    </>
  );
};
