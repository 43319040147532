import { IApiHook } from 'hooks/useApi';
import { AppError } from 'models/generic/appError.model';
import { Administrator } from 'models/responses/administrator.model';
import { Customer } from 'models/responses/customer.model';
import { ITokenResponse } from 'models/responses/tokenResponse.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import constants from 'utils/constants';
import utils from 'utils/utils';

export interface IBaseAuthHook<TUserType extends Customer | Administrator> {
  controllerName: 'customer' | 'admin';
  getCurrentUser: () => TUserType | null;
  setCurrentUser: (user: TUserType | null) => void;
  getLoginValidationRules: () => Promise<ValidationRule[]>;
  logOut: () => void;
  checkIfExpired: (userToCheck: TUserType | null, goToLoginOnExpiry: boolean) => void;
  getCurrentToken: () => string;
  isValid: boolean;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IAuthHookProps {
  userStorageKey: string;
  controllerName: 'customer' | 'admin';
  api: IApiHook;
}

export function useBaseAuth<TUserType extends Customer | Administrator>(props: IAuthHookProps): IBaseAuthHook<TUserType> {
  const api = props.api;

  const [user, setUser] = useState<TUserType | null>(null);
  const [isValid, setIsValid] = useState<boolean>(true);

  const userStorageKey = props.userStorageKey;
  const controllerName = props.controllerName;
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const intervalIdRef = useRef(intervalId);
  intervalIdRef.current = intervalId;

  useEffect(() => {
    if (user === null) {
      // when user gets reset to null stop scanning for token expiry
      stopScanningToken();
    } else {
      // when user gets set start scanning for token expiry
      startScanningToken();
    }
  }, [user]);

  const getCurrentUser = (): TUserType | null => {
    if (user === null) {
      // load user from cache if present
      const cachedUser = utils.localStorage.getFromLocalStorage<TUserType>(userStorageKey);
      if (cachedUser) {
        cachedUser.tokenExpiry = moment(cachedUser.tokenExpiry);
        setCurrentUser(cachedUser);

        // when loading user from cache check if token is not expired
        checkIfExpired(cachedUser, true);

        const cachedIsValid = utils.localStorage.getFromLocalStorage<boolean>(constants.storageKeys.isValid) as boolean;
        if (isValid !== cachedIsValid) {
          setIsValid(cachedIsValid);
        }
      }
    }

    return user;
  };

  const setCurrentUser = (user: TUserType | null) => {
    utils.localStorage.saveToLocalStorage(userStorageKey, user);
    setUser(user);
  };

  const getLoginValidationRules = async (): Promise<ValidationRule[]> => api.getValidationRules(controllerName + '/login/validation');

  const logOut = () => {
    utils.localStorage.removeFromLocalStorage(userStorageKey);
    setCurrentUser(null);
    sessionStorage.clear();
  };

  const startScanningToken = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    // every 10s check if token is not expired or expiring
    const id = setInterval(() => checkIfExpired(), 10000);
    setIntervalId(id);
  };

  const stopScanningToken = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      setIntervalId(null);
    }
  };

  const getCurrentToken = (): string => {
    const cachedUser = utils.localStorage.getFromLocalStorage<TUserType>(userStorageKey);

    return cachedUser?.rawToken || '';
  };

  // private
  const getNewToken = async () => {
    const response = await api.get<ITokenResponse>(controllerName + '/renew', 'Token renewal failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      if (response.isActive) {
        switch (controllerName) {
          case 'customer':
            setCurrentUser(Customer.fromTokenResponse(response) as TUserType);
            break;
          case 'admin':
            setCurrentUser(Administrator.fromTokenResponse(response) as TUserType);
            break;
        }
      } else {
        // user was deactivated, so force log out
        logOut();
      }

      return true;
    }
  };

  const checkIfExpired = (userToCheck: TUserType | null = null, goToLoginOnExpiry = false) => {
    if (userToCheck === null) {
      userToCheck = user;
    }

    if (userToCheck && userToCheck.tokenExpiry) {
      const now = moment();
      const expiryTime = userToCheck.tokenExpiry.clone();
      const minutes = 1;
      const renewTime = expiryTime.clone().add(-minutes, 'minutes');

      if (now > expiryTime) {
        stopScanningToken();

        // check if user is on logout page
        const isLogout = window.location.href.toLowerCase().includes('/logout');
        if (!isLogout) {
          // log user out
          if (goToLoginOnExpiry) {
            logOut();

            // take user directly to login page - this only happens on first load of stale session
            if (controllerName === 'customer') {
              window.location.href = '/login';
            } else {
              window.location.href = '/admin/login';
            }
          } else {
            // take user to logout page and show a warning message - this happens when current session expires
            if (controllerName === 'customer') {
              window.location.href = '/logout?expired';
            } else {
              window.location.href = '/admin/logout?expired';
            }
          }
        }
      } else if (now > renewTime) {
        // current token will expire soon, so get a new token
        getNewToken();
      }
    }
  };

  return {
    controllerName,
    getCurrentUser,
    setCurrentUser,
    getLoginValidationRules,
    logOut,
    checkIfExpired,
    getCurrentToken,
    isValid,
    setIsValid
  };
}
