import { App } from 'App';
import appStyles from 'App.module.scss';
import { AdminDashboard } from 'components/AdminArea/AdminDashboard/AdminDashboard';
import { AdministratorsGrid } from 'components/AdminArea/Administrators/AdministratorsGrid';
import { EditAdministrator } from 'components/AdminArea/Administrators/EditAdministrator';
import { AdminLogin } from 'components/AdminArea/AdminLogin/AdminLogin';
import { CountriesGrid } from 'components/AdminArea/Countries/CountriesGrid';
import { EditCountry } from 'components/AdminArea/Countries/EditCountry';
import { LogDetails } from 'components/AdminArea/Logs/LogDetails';
import { LogsGrid } from 'components/AdminArea/Logs/LogsGrid';
import { EditMaintenance } from 'components/AdminArea/Maintenance/EditMaintenance';
import { MaintenanceGrid } from 'components/AdminArea/Maintenance/MaintenanceGrid';
import { OrderDetails } from 'components/AdminArea/Orders/OrderDetails';
import { OrdersGrid } from 'components/AdminArea/Orders/OrdersGrid';
import { EditProduct } from 'components/AdminArea/Products/EditProduct';
import { ProductsGrid } from 'components/AdminArea/Products/ProductsGrid';
import { EbooksGrid } from 'components/AdminArea/Ebooks/EbooksGrid';
import { ReportAccounts } from 'components/AdminArea/Reports/ReportAccounts';
import { ReportGdpr } from 'components/AdminArea/Reports/ReportGdpr';
import { ReportTrove } from 'components/AdminArea/Reports/ReportTrove';
import { Reports } from 'components/AdminArea/Reports/Reports';
import { ReportSubscriptions } from 'components/AdminArea/Reports/ReportSubscriptions';
import { ChangePassword } from 'components/CustomerArea/Authentication/ChangePassword/ChangePassword';
import { ForgottenPassword } from 'components/CustomerArea/Authentication/ForgottenPassword/ForgottenPassword';
import { Login } from 'components/CustomerArea/Authentication/Login/Login';
import { LoginCallback } from 'components/CustomerArea/Authentication/LoginCallback/LoginCallback';
import { Logout } from 'components/CustomerArea/Authentication/Logout/Logout';
import { Register } from 'components/CustomerArea/Authentication/Register/Register';
import { ResetPassword } from 'components/CustomerArea/Authentication/ResetPassword/ResetPassword';
import { BasketPage } from 'components/CustomerArea/Basket/BasketPage';
import { Checkout } from 'components/CustomerArea/Basket/Checkout';
import { Payment } from 'components/CustomerArea/Basket/Payment';
import { Dashboard } from 'components/CustomerArea/Dashboard/Dashboard';
import { EditCustomer } from 'components/CustomerArea/EditCustomer/EditCustomer';
import { EditPreferences } from 'components/CustomerArea/EditPreferences/EditPreferences';
import { PurchaseConfirmation } from 'components/CustomerArea/PurchaseConfirmation/PurchaseConfirmation';
import { AccessDenied } from 'components/Generic/AccessDenied/AccessDenied';
import CookiePopup from 'components/Generic/CookiePopup/CookiePopup';
import { Footer } from 'components/Generic/Footer/Footer';
import { Header } from 'components/Generic/Header/Header';
import { PageNotFound } from 'components/Generic/PageNotFound/PageNotFound';
import { AddItemToBasketTest } from 'components/Test/AddItemToBasketTest';
import { FC } from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { AdminOnly } from 'routing/adminOnly';
import { CustomerOnly } from 'routing/customerOnly';
import { ReportEbooksOrders } from 'components/AdminArea/Reports/ReportEbooksOrders';
import { ReportEbooksTax } from 'components/AdminArea/Reports/ReportEbooksTax';
import { ReportEbooksSales } from 'components/AdminArea/Reports/ReportEbooksSales';
import { ReportEbooksMarketing } from 'components/AdminArea/Reports/ReportEbooksMarketing';
import { Ringgold } from 'components/AdminArea/Ringgold/Ringgold';
import { CustomersGrid } from 'components/AdminArea/Customers/CustomersGrid';
import { EditCustomerAsAdmin } from 'components/AdminArea/Customers/EditCustomerAsAdmin';
import { useEnvironment } from 'hooks/useEnvironment';

export const AppRouter: FC = () => {
  const environment = useEnvironment();

  return (
    <App>
      <BrowserRouter>
        <Header />
        <div role="main" className={appStyles.main}>
          <Routes>
            <Route path="/" element={<CustomerOnly Component={Dashboard} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/callback" element={<LoginCallback />} />
            <Route path="/register" element={<Register />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/forgotten-password" element={<ForgottenPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/password" element={<CustomerOnly Component={ChangePassword} />} />
            <Route path="/edit-details" element={<CustomerOnly Component={EditCustomer} />} />
            <Route path="/edit-preferences" element={<CustomerOnly Component={EditPreferences} />} />

            {/* Test routes for basket test page and payment form, excluded from production build */}
            {environment.isProduction ? '' : <Route path="/basket/test" element={<AddItemToBasketTest />} />}

            <Route path="/basket/:query" element={<BasketPage />} />
            <Route path="/basket/" element={<BasketPage />} />
            <Route path="/checkout" element={<CustomerOnly Component={Checkout} />} />
            <Route path="/payment" element={<CustomerOnly Component={Payment} />} />
            <Route path="/confirmation/" element={<PurchaseConfirmation />} />

            <Route path="/no-access" element={<AccessDenied />} />

            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/logout" element={<Logout />} />
            <Route path="/admin" element={<AdminOnly Component={AdminDashboard} />} />
            <Route path="/admin/administrators" element={<AdminOnly Component={AdministratorsGrid} />} />
            <Route path="/admin/administrators/new" element={<AdminOnly Component={EditAdministrator} />} />
            <Route path="/admin/administrators/:id" element={<AdminOnly Component={EditAdministrator} />} />
            <Route path="/admin/customers" element={<AdminOnly Component={CustomersGrid} />} />
            <Route path="/admin/customers/:id" element={<AdminOnly Component={EditCustomerAsAdmin} />} />
            <Route path="/admin/ebooks/:store" element={<AdminOnly Component={EbooksGrid} />} />
            <Route path="/admin/logs" element={<AdminOnly Component={LogsGrid} />} />
            <Route path="/admin/logs/:id" element={<AdminOnly Component={LogDetails} />} />
            <Route path="/admin/orders" element={<AdminOnly Component={OrdersGrid} />} />
            <Route path="/admin/orders/:id" element={<AdminOnly Component={OrderDetails} />} />
            <Route path="/admin/products" element={<AdminOnly Component={ProductsGrid} />} />
            <Route path="/admin/products/new" element={<AdminOnly Component={EditProduct} />} />
            <Route path="/admin/products/:id" element={<AdminOnly Component={EditProduct} />} />
            <Route path="/admin/countries" element={<AdminOnly Component={CountriesGrid} />} />
            <Route path="/admin/countries/new" element={<AdminOnly Component={EditCountry} />} />
            <Route path="/admin/countries/:id" element={<AdminOnly Component={EditCountry} />} />
            <Route path="/admin/maintenance" element={<AdminOnly Component={MaintenanceGrid} />} />
            <Route path="/admin/maintenance/:id" element={<AdminOnly Component={EditMaintenance} />} />
            <Route path="/admin/reports" element={<AdminOnly Component={Reports} />} />
            <Route path="/admin/reports/accounts/:store" element={<AdminOnly Component={ReportAccounts} />} />
            <Route path="/admin/reports/gdpr/:store" element={<AdminOnly Component={ReportGdpr} />} />
            <Route path="/admin/reports/trove/:store" element={<AdminOnly Component={ReportTrove} />} />
            <Route path="/admin/reports/subscriptions/:store" element={<AdminOnly Component={ReportSubscriptions} />} />
            <Route path="/admin/reports/ebooks/orders/:store" element={<AdminOnly Component={ReportEbooksOrders} />} />
            <Route path="/admin/reports/ebooks/tax/:store" element={<AdminOnly Component={ReportEbooksTax} />} />
            <Route path="/admin/reports/ebooks/sales/:store" element={<AdminOnly Component={ReportEbooksSales} />} />
            <Route path="/admin/reports/ebooks/marketing/:store" element={<AdminOnly Component={ReportEbooksMarketing} />} />
            <Route path="/admin/ringgold" element={<AdminOnly Component={Ringgold} />} />

            <Route path="*" element={<PageNotFound />} />
          </Routes>

          <CookiePopup />
        </div>
        <Footer />
      </BrowserRouter>
    </App>
  );
};
