import { apiContext } from 'App';
import { LoadLogsForm } from 'models/forms/loadLogsForm.model';
import { AppError } from 'models/generic/appError.model';
import { Log } from 'models/responses/log.model';
import { useContext } from 'react';

export interface ILogsHook {
  getLogs: (form: LoadLogsForm) => Promise<Log[] | AppError>;
  getLogById: (logId: number) => Promise<Log | null>;
  getLogsByOrderId: (orderId: number) => Promise<Log[] | AppError>;
}

export function useLogs(): ILogsHook {
  const api = useContext(apiContext);

  const getLogs = async (form: LoadLogsForm): Promise<Log[] | AppError> => {
    const response = await api.post<Log[]>('log', form, 'Loading logs failed.');
    if (response instanceof AppError) {
    } else {
      response.forEach((item) => {
        item.date = new Date(item.date);
      });
    }

    return response;
  };

  const getLogById = async (logId: number): Promise<Log | null> => {
    let log = null;
    const response = await api.get<Log>(`log/${logId}`, 'There was an error loading log details.');

    if (response instanceof AppError) {
    } else {
      if (response.date) {
        response.date = new Date(response.date);
      }
      if (response.legacy === false) {
        response.data = response.data ? JSON.parse(response.data as string) : null;
        response.exception = response.exception ? JSON.parse(response.exception as string) : null;
      }
      log = response;
    }

    return log;
  };

  const getLogsByOrderId = async (orderId: number): Promise<Log[] | AppError> => {
    const response = await api.get<Log[]>(`log/order/${orderId}`, 'Loading logs for order failed.');
    if (response instanceof AppError) {
    } else {
      response.forEach((item) => {
        item.date = new Date(item.date);
      });
    }

    return response;
  };

  return {
    getLogs,
    getLogById,
    getLogsByOrderId
  };
}
