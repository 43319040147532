import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { ValidationArea } from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { ListItem } from 'models/generic/listItem.model';
import { ChangeEvent, FC } from 'react';
import ReactHtmlParser from 'react-html-parser';

interface ICheckBoxProps {
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   * @property {ListItem[]} options Set the available options
   */
  options: ListItem[];
  /**
   * @property {(event: ChangeEvent<HTMLInputElement>) => void} handleChange Set the action onChange
   */
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * @property {string} selectedOptions Set the selected options
   */
  selectedOptions: number[] | string[] | boolean[];
  /**
   * @property {string} customWrapperClass Optional. Use a custom class on the wrapper div - use styles from the current component or appStyles
   */
  customWrapperClass?: string;
  /**
   * @property {string} customInputClass Optional. Use a custom class on the input - use styles from the current component or appStyles
   */
  customInputClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {string[]} validationErrors Optional. Any validation messages related to this field
   */
  validationErrors?: string[];
  /**
   * @property {boolean} disabled Optional. Set the checkbox to disabled
   */
  disabled?: boolean;
}

/**
 * @description Add an checkbox group. Optional properties: customInputClass, customLabelClass, validationError
 */
export const CheckBox: FC<ICheckBoxProps> = (props: ICheckBoxProps) => (
  <>
    {props.options.map((option) => (
      <div
        className={classNames(
          props.options.length === 1 ? appStyles.checkbox__wrapper_single : appStyles.checkbox__wrapper,
          { [props.customWrapperClass as string]: props.customWrapperClass } // add custom class if defined
        )}
        key={option.id as string}>
        <input
          className={classNames(
            appStyles.checkbox,
            { [appStyles.form__input_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
            { [props.customInputClass as string]: props.customInputClass }, // add custom class if defined
            { [appStyles.hidden]: option.visible === false }
          )}
          onChange={props.handleChange}
          type="checkbox"
          checked={props.selectedOptions.some((id: number | string | boolean) => (id as string) === (option.id as string))}
          aria-checked={props.selectedOptions.some((id: number | string | boolean) => (id as string) === (option.id as string)) ? 'true' : 'false'}
          name={props.name}
          value={option.id as string}
          id={props.name + '_CheckBox_' + option.id}
          disabled={props.disabled}
        />
        <label
          id={props.name + '_Label_' + option.id}
          htmlFor={props.name + '_CheckBox_' + option.id}
          className={classNames(
            appStyles.checkbox__label,
            { [appStyles.checkbox__label_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
            { [props.customLabelClass as string]: props.customLabelClass }, // add custom class if defined
            { [appStyles.hidden]: option.visible === false }
          )}>
          <span className={classNames({ [appStyles.hidden]: option.visible === false })}>{ReactHtmlParser(option.text)}</span>
        </label>
      </div>
    ))}

    <ValidationArea sectionId={props.name} validationErrors={props.validationErrors} />
  </>
);

export class CheckBoxHelper {
  static baseHandleBool<T>(e: ChangeEvent<HTMLInputElement>, obj: T): T {
    const value = e.target.checked;
    const fieldName = e.target.name;

    obj = {
      ...obj,
      [fieldName]: value
    };

    return obj;
  }
}
