import { apiContext } from 'App';
import { UploadRinggoldFileForm } from 'models/forms/uploadRinggoldFileForm.model';
import { AppError } from 'models/generic/appError.model';
import { RinggoldImportResult } from 'models/responses/ringgoldImportResult.model';
import { useContext } from 'react';

export interface IRinggoldHook {
  uploadRinggoldFile: (form: UploadRinggoldFileForm) => Promise<boolean | AppError>;
  checkRinggoldImportStatus: () => Promise<RinggoldImportResult | null | AppError>;
}

export function useRinggold(): IRinggoldHook {
  const api = useContext(apiContext);

  const uploadRinggoldFile = async (form: UploadRinggoldFileForm): Promise<boolean | AppError> =>
    await api.post<boolean>('admin/ringgold', form, 'Importing ringgold data failed.');

  const checkRinggoldImportStatus = async (): Promise<RinggoldImportResult | null | AppError> =>
    await api.get<RinggoldImportResult>('admin/ringgold', 'Checking ringgold import status failed.');

  return {
    uploadRinggoldFile,
    checkRinggoldImportStatus
  };
}
