import appStyles from 'App.module.scss';
import classNames from 'classnames';
import Alert from 'components/Generic/Alert/Alert';
import { FC } from 'react';
import styles from './LoadingAlert.module.scss';

export const LoadingAlert: FC = () => (
  <div className={classNames(appStyles.row, styles.loading)}>
    <div className={appStyles.col_md_12}>
      <Alert alertType="info">Please wait. We're loading the data...</Alert>
    </div>
  </div>
);

export default LoadingAlert;
