/* eslint-disable max-len */
import { FC } from 'react';
import classNames from 'classnames';
import styles from './Icon.module.scss';

interface IIconProps {
  /**
   * @property {string} icon Choose an icon, start typing Icon. to get available icons
   */
  icon: string;
  /**
   * @property {string} size Applies a class for the size of the icon (these styles are in icon.module.scss)
   */
  size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'heading' | 'shortcut';
  /**
   * @property {string} color Applies a class for the colour of the icon (these styles are in icon.module.scss)
   */
  color: 'oxfordBlue' | 'gabBlue' | 'darkBlue' | 'paleGrey' | 'white' | 'grey' | 'error' | 'warning' | 'info' | 'currentColor' | 'gabDarkGrey';
  /**
   * @property {string} iconName Sets the <title> value of the icon - for accessibility purposes
   */
  iconName: string;
  /**
   * @property {string} customClass Optional. Use a custom class on the icon - use styles from the current component or appStyles
   */
  customClass?: string;
}

/**
 * @description Add an icon element. Optional properties: customInputClass
 */
export const Icon: FC<IIconProps> = (props: IIconProps) => (
  <svg
    viewBox="0 0 32 32"
    className={classNames(
      styles[`fill_${props.color}`],
      styles[`icon_${props.size}`],
      { [props.customClass as string]: props.customClass } // add custom class if defined
    )}>
    <title>{props.iconName}</title>
    <path d={props.icon} />
  </svg>
);

export class Icons {
  static menu =
    'M24 21v2c0 0.547-0.453 1-1 1h-22c-0.547 0-1-0.453-1-1v-2c0-0.547 0.453-1 1-1h22c0.547 0 1 0.453 1 1zM24 13v2c0 0.547-0.453 1-1 1h-22c-0.547 0-1-0.453-1-1v-2c0-0.547 0.453-1 1-1h22c0.547 0 1 0.453 1 1zM24 5v2c0 0.547-0.453 1-1 1h-22c-0.547 0-1-0.453-1-1v-2c0-0.547 0.453-1 1-1h22c0.547 0 1 0.453 1 1z';
  static search =
    'M18 13c0-3.859-3.141-7-7-7s-7 3.141-7 7 3.141 7 7 7 7-3.141 7-7zM26 26c0 1.094-0.906 2-2 2-0.531 0-1.047-0.219-1.406-0.594l-5.359-5.344c-1.828 1.266-4.016 1.937-6.234 1.937-6.078 0-11-4.922-11-11s4.922-11 11-11 11 4.922 11 11c0 2.219-0.672 4.406-1.937 6.234l5.359 5.359c0.359 0.359 0.578 0.875 0.578 1.406z';
  static warning =
    'M16 21.484v-2.969c0-0.281-0.219-0.516-0.5-0.516h-3c-0.281 0-0.5 0.234-0.5 0.516v2.969c0 0.281 0.219 0.516 0.5 0.516h3c0.281 0 0.5-0.234 0.5-0.516zM15.969 15.641l0.281-7.172c0-0.094-0.047-0.219-0.156-0.297-0.094-0.078-0.234-0.172-0.375-0.172h-3.437c-0.141 0-0.281 0.094-0.375 0.172-0.109 0.078-0.156 0.234-0.156 0.328l0.266 7.141c0 0.203 0.234 0.359 0.531 0.359h2.891c0.281 0 0.516-0.156 0.531-0.359zM15.75 1.047l12 22c0.344 0.609 0.328 1.359-0.031 1.969s-1.016 0.984-1.719 0.984h-24c-0.703 0-1.359-0.375-1.719-0.984s-0.375-1.359-0.031-1.969l12-22c0.344-0.641 1.016-1.047 1.75-1.047s1.406 0.406 1.75 1.047z';
  static error =
    'M20.5 13.953c0-1.703-0.5-3.281-1.359-4.609l-11.781 11.766c1.344 0.875 2.938 1.391 4.641 1.391 4.688 0 8.5-3.828 8.5-8.547zM4.891 18.625l11.797-11.781c-1.344-0.906-2.953-1.422-4.688-1.422-4.688 0-8.5 3.828-8.5 8.531 0 1.734 0.516 3.328 1.391 4.672zM24 13.953c0 6.656-5.375 12.047-12 12.047s-12-5.391-12-12.047c0-6.641 5.375-12.031 12-12.031s12 5.391 12 12.031z';
  static basket =
    'M10 24c0 1.094-0.906 2-2 2s-2-0.906-2-2 0.906-2 2-2 2 0.906 2 2zM24 24c0 1.094-0.906 2-2 2s-2-0.906-2-2 0.906-2 2-2 2 0.906 2 2zM26 7v8c0 0.5-0.391 0.938-0.891 1l-16.312 1.906c0.078 0.359 0.203 0.719 0.203 1.094 0 0.359-0.219 0.688-0.375 1h14.375c0.547 0 1 0.453 1 1s-0.453 1-1 1h-16c-0.547 0-1-0.453-1-1 0-0.484 0.703-1.656 0.953-2.141l-2.766-12.859h-3.187c-0.547 0-1-0.453-1-1s0.453-1 1-1h4c1.047 0 1.078 1.25 1.234 2h18.766c0.547 0 1 0.453 1 1z';
  static logOut =
    'M10 22.5c0 0.438 0.203 1.5-0.5 1.5h-5c-2.484 0-4.5-2.016-4.5-4.5v-11c0-2.484 2.016-4.5 4.5-4.5h5c0.266 0 0.5 0.234 0.5 0.5 0 0.438 0.203 1.5-0.5 1.5h-5c-1.375 0-2.5 1.125-2.5 2.5v11c0 1.375 1.125 2.5 2.5 2.5h4.5c0.391 0 1-0.078 1 0.5zM24.5 14c0 0.266-0.109 0.516-0.297 0.703l-8.5 8.5c-0.187 0.187-0.438 0.297-0.703 0.297-0.547 0-1-0.453-1-1v-4.5h-7c-0.547 0-1-0.453-1-1v-6c0-0.547 0.453-1 1-1h7v-4.5c0-0.547 0.453-1 1-1 0.266 0 0.516 0.109 0.703 0.297l8.5 8.5c0.187 0.187 0.297 0.438 0.297 0.703z';
  static user =
    'M20 21.859c0 2.281-1.5 4.141-3.328 4.141h-13.344c-1.828 0-3.328-1.859-3.328-4.141 0-4.109 1.016-8.859 5.109-8.859 1.266 1.234 2.984 2 4.891 2s3.625-0.766 4.891-2c4.094 0 5.109 4.75 5.109 8.859zM16 8c0 3.313-2.688 6-6 6s-6-2.688-6-6 2.688-6 6-6 6 2.688 6 6z';
  static cross =
    'M19.2 16l12.114-12.114c0.914-0.914 0.914-2.286 0-3.2s-2.286-0.914-3.2 0l-12.114 12.114-12.114-12.114c-0.914-0.914-2.286-0.914-3.2 0s-0.914 2.286 0 3.2l12.114 12.114-12.114 12.114c-0.914 0.914-0.914 2.286 0 3.2 0.457 0.457 0.914 0.686 1.6 0.686s1.143-0.229 1.6-0.686l12.114-12.114 12.114 12.114c0.457 0.457 1.143 0.686 1.6 0.686s1.143-0.229 1.6-0.686c0.914-0.914 0.914-2.286 0-3.2l-12.114-12.114z';
  static dashboard =
    'M6 18c0-1.109-0.891-2-2-2s-2 0.891-2 2 0.891 2 2 2 2-0.891 2-2zM9 11c0-1.109-0.891-2-2-2s-2 0.891-2 2 0.891 2 2 2 2-0.891 2-2zM15.687 18.516l1.578-5.969c0.125-0.531-0.187-1.078-0.719-1.219v0c-0.531-0.141-1.078 0.187-1.219 0.719l-1.578 5.969c-1.234 0.094-2.312 0.953-2.656 2.219-0.422 1.609 0.547 3.25 2.141 3.672 1.609 0.422 3.25-0.547 3.672-2.141 0.328-1.266-0.203-2.547-1.219-3.25zM26 18c0-1.109-0.891-2-2-2s-2 0.891-2 2 0.891 2 2 2 2-0.891 2-2zM16 8c0-1.109-0.891-2-2-2s-2 0.891-2 2 0.891 2 2 2 2-0.891 2-2zM23 11c0-1.109-0.891-2-2-2s-2 0.891-2 2 0.891 2 2 2 2-0.891 2-2zM28 18c0 2.688-0.766 5.281-2.203 7.547-0.187 0.281-0.5 0.453-0.844 0.453h-21.906c-0.344 0-0.656-0.172-0.844-0.453-1.437-2.25-2.203-4.859-2.203-7.547 0-7.719 6.281-14 14-14s14 6.281 14 14z';
  static reports =
    'M22.937 7.438c0.156 0.156 0.297 0.344 0.438 0.562h-7.375v-7.375c0.219 0.141 0.406 0.281 0.562 0.438zM15.5 10h8.5v16.5c0 0.828-0.672 1.5-1.5 1.5h-21c-0.828 0-1.5-0.672-1.5-1.5v-25c0-0.828 0.672-1.5 1.5-1.5h12.5v8.5c0 0.828 0.672 1.5 1.5 1.5zM18 21.5v-1c0-0.281-0.219-0.5-0.5-0.5h-11c-0.281 0-0.5 0.219-0.5 0.5v1c0 0.281 0.219 0.5 0.5 0.5h11c0.281 0 0.5-0.219 0.5-0.5zM18 17.5v-1c0-0.281-0.219-0.5-0.5-0.5h-11c-0.281 0-0.5 0.219-0.5 0.5v1c0 0.281 0.219 0.5 0.5 0.5h11c0.281 0 0.5-0.219 0.5-0.5zM18 13.5v-1c0-0.281-0.219-0.5-0.5-0.5h-11c-0.281 0-0.5 0.219-0.5 0.5v1c0 0.281 0.219 0.5 0.5 0.5h11c0.281 0 0.5-0.219 0.5-0.5z';
  static people =
    'M9.266 14c-1.625 0.047-3.094 0.75-4.141 2h-2.094c-1.563 0-3.031-0.75-3.031-2.484 0-1.266-0.047-5.516 1.937-5.516 0.328 0 1.953 1.328 4.062 1.328 0.719 0 1.406-0.125 2.078-0.359-0.047 0.344-0.078 0.688-0.078 1.031 0 1.422 0.453 2.828 1.266 4zM26 23.953c0 2.531-1.672 4.047-4.172 4.047h-13.656c-2.5 0-4.172-1.516-4.172-4.047 0-3.531 0.828-8.953 5.406-8.953 0.531 0 2.469 2.172 5.594 2.172s5.063-2.172 5.594-2.172c4.578 0 5.406 5.422 5.406 8.953zM10 4c0 2.203-1.797 4-4 4s-4-1.797-4-4 1.797-4 4-4 4 1.797 4 4zM21 10c0 3.313-2.688 6-6 6s-6-2.688-6-6 2.688-6 6-6 6 2.688 6 6zM30 13.516c0 1.734-1.469 2.484-3.031 2.484h-2.094c-1.047-1.25-2.516-1.953-4.141-2 0.812-1.172 1.266-2.578 1.266-4 0-0.344-0.031-0.688-0.078-1.031 0.672 0.234 1.359 0.359 2.078 0.359 2.109 0 3.734-1.328 4.062-1.328 1.984 0 1.937 4.25 1.937 5.516zM28 4c0 2.203-1.797 4-4 4s-4-1.797-4-4 1.797-4 4-4 4 1.797 4 4z';
  static orders =
    'M17 13c0-0.547-0.453-1-1-1h-4c-0.547 0-1 0.453-1 1s0.453 1 1 1h4c0.547 0 1-0.453 1-1zM26 10v15c0 0.547-0.453 1-1 1h-22c-0.547 0-1-0.453-1-1v-15c0-0.547 0.453-1 1-1h22c0.547 0 1 0.453 1 1zM27 3v4c0 0.547-0.453 1-1 1h-24c-0.547 0-1-0.453-1-1v-4c0-0.547 0.453-1 1-1h24c0.547 0 1 0.453 1 1z';
  static products =
    'M10 4h8v-2h-8v2zM28 14v7.5c0 1.375-1.125 2.5-2.5 2.5h-23c-1.375 0-2.5-1.125-2.5-2.5v-7.5h10.5v2.5c0 0.547 0.453 1 1 1h5c0.547 0 1-0.453 1-1v-2.5h10.5zM16 14v2h-4v-2h4zM28 6.5v6h-28v-6c0-1.375 1.125-2.5 2.5-2.5h5.5v-2.5c0-0.828 0.672-1.5 1.5-1.5h9c0.828 0 1.5 0.672 1.5 1.5v2.5h5.5c1.375 0 2.5 1.125 2.5 2.5z';
  static ebooks =
    'M24 0h-18c-1.1 0-2 0.9-2 2v28c0 1.1 0.9 2 2 2h18c1.1 0 2-0.9 2-2v-28c0-1.1-0.9-2-2-2zM15 30.556c-0.859 0-1.556-0.697-1.556-1.556s0.697-1.556 1.556-1.556 1.556 0.697 1.556 1.556-0.697 1.556-1.556 1.556zM24 26h-18v-22h18v22z';
  static logs =
    'M25.609 7.469c0.391 0.562 0.5 1.297 0.281 2.016l-4.297 14.156c-0.391 1.328-1.766 2.359-3.109 2.359h-14.422c-1.594 0-3.297-1.266-3.875-2.891-0.25-0.703-0.25-1.391-0.031-1.984 0.031-0.313 0.094-0.625 0.109-1 0.016-0.25-0.125-0.453-0.094-0.641 0.063-0.375 0.391-0.641 0.641-1.062 0.469-0.781 1-2.047 1.172-2.859 0.078-0.297-0.078-0.641 0-0.906 0.078-0.297 0.375-0.516 0.531-0.797 0.422-0.719 0.969-2.109 1.047-2.844 0.031-0.328-0.125-0.688-0.031-0.938 0.109-0.359 0.453-0.516 0.688-0.828 0.375-0.516 1-2 1.094-2.828 0.031-0.266-0.125-0.531-0.078-0.812 0.063-0.297 0.438-0.609 0.688-0.969 0.656-0.969 0.781-3.109 2.766-2.547l-0.016 0.047c0.266-0.063 0.531-0.141 0.797-0.141h11.891c0.734 0 1.391 0.328 1.781 0.875 0.406 0.562 0.5 1.297 0.281 2.031l-4.281 14.156c-0.734 2.406-1.141 2.938-3.125 2.938h-13.578c-0.203 0-0.453 0.047-0.594 0.234-0.125 0.187-0.141 0.328-0.016 0.672 0.313 0.906 1.391 1.094 2.25 1.094h14.422c0.578 0 1.25-0.328 1.422-0.891l4.688-15.422c0.094-0.297 0.094-0.609 0.078-0.891 0.359 0.141 0.688 0.359 0.922 0.672zM8.984 7.5c-0.094 0.281 0.063 0.5 0.344 0.5h9.5c0.266 0 0.562-0.219 0.656-0.5l0.328-1c0.094-0.281-0.063-0.5-0.344-0.5h-9.5c-0.266 0-0.562 0.219-0.656 0.5zM7.688 11.5c-0.094 0.281 0.063 0.5 0.344 0.5h9.5c0.266 0 0.562-0.219 0.656-0.5l0.328-1c0.094-0.281-0.063-0.5-0.344-0.5h-9.5c-0.266 0-0.562 0.219-0.656 0.5z';
  static calendar =
    'M2 26h22v-16h-22v16zM8 7v-4.5c0-0.281-0.219-0.5-0.5-0.5h-1c-0.281 0-0.5 0.219-0.5 0.5v4.5c0 0.281 0.219 0.5 0.5 0.5h1c0.281 0 0.5-0.219 0.5-0.5zM20 7v-4.5c0-0.281-0.219-0.5-0.5-0.5h-1c-0.281 0-0.5 0.219-0.5 0.5v4.5c0 0.281 0.219 0.5 0.5 0.5h1c0.281 0 0.5-0.219 0.5-0.5zM26 6v20c0 1.094-0.906 2-2 2h-22c-1.094 0-2-0.906-2-2v-20c0-1.094 0.906-2 2-2h2v-1.5c0-1.375 1.125-2.5 2.5-2.5h1c1.375 0 2.5 1.125 2.5 2.5v1.5h6v-1.5c0-1.375 1.125-2.5 2.5-2.5h1c1.375 0 2.5 1.125 2.5 2.5v1.5h2c1.094 0 2 0.906 2 2z';
  static view =
    'M26 15c-1.484-2.297-3.516-4.266-5.953-5.516 0.625 1.062 0.953 2.281 0.953 3.516 0 3.859-3.141 7-7 7s-7-3.141-7-7c0-1.234 0.328-2.453 0.953-3.516-2.438 1.25-4.469 3.219-5.953 5.516 2.672 4.125 6.984 7 12 7s9.328-2.875 12-7zM14.75 9c0-0.406-0.344-0.75-0.75-0.75-2.609 0-4.75 2.141-4.75 4.75 0 0.406 0.344 0.75 0.75 0.75s0.75-0.344 0.75-0.75c0-1.781 1.469-3.25 3.25-3.25 0.406 0 0.75-0.344 0.75-0.75zM28 15c0 0.391-0.125 0.75-0.313 1.078-2.875 4.734-8.141 7.922-13.687 7.922s-10.813-3.203-13.687-7.922c-0.187-0.328-0.313-0.688-0.313-1.078s0.125-0.75 0.313-1.078c2.875-4.719 8.141-7.922 13.687-7.922s10.813 3.203 13.687 7.922c0.187 0.328 0.313 0.688 0.313 1.078z';
  static edit =
    'M5.672 24l1.422-1.422-3.672-3.672-1.422 1.422v1.672h2v2h1.672zM13.844 9.5c0-0.203-0.141-0.344-0.344-0.344-0.094 0-0.187 0.031-0.266 0.109l-8.469 8.469c-0.078 0.078-0.109 0.172-0.109 0.266 0 0.203 0.141 0.344 0.344 0.344 0.094 0 0.187-0.031 0.266-0.109l8.469-8.469c0.078-0.078 0.109-0.172 0.109-0.266zM13 6.5l6.5 6.5-13 13h-6.5v-6.5zM23.672 8c0 0.531-0.219 1.047-0.578 1.406l-2.594 2.594-6.5-6.5 2.594-2.578c0.359-0.375 0.875-0.594 1.406-0.594s1.047 0.219 1.422 0.594l3.672 3.656c0.359 0.375 0.578 0.891 0.578 1.422z';
  static renew =
    'M22 15.453v4.047c0 2.484-2.016 4.5-4.5 4.5h-13c-2.484 0-4.5-2.016-4.5-4.5v-13c0-2.484 2.016-4.5 4.5-4.5h3.984c0.266 0 0.5 0.219 0.5 0.5 0 0.25-0.172 0.453-0.406 0.5-0.781 0.266-1.484 0.578-2.078 0.938-0.078 0.031-0.156 0.063-0.25 0.063h-1.75c-1.375 0-2.5 1.125-2.5 2.5v13c0 1.375 1.125 2.5 2.5 2.5h13c1.375 0 2.5-1.125 2.5-2.5v-3.344c0-0.187 0.109-0.359 0.281-0.453 0.313-0.141 0.594-0.344 0.844-0.578 0.141-0.141 0.359-0.203 0.547-0.125s0.328 0.25 0.328 0.453zM25.703 7.703l-6 6c-0.187 0.203-0.438 0.297-0.703 0.297-0.125 0-0.266-0.031-0.391-0.078-0.359-0.156-0.609-0.516-0.609-0.922v-3h-2.5c-3.437 0-5.625 0.656-6.844 2.047-1.266 1.453-1.641 3.797-1.156 7.391 0.031 0.219-0.109 0.438-0.313 0.531-0.063 0.016-0.125 0.031-0.187 0.031-0.156 0-0.313-0.078-0.406-0.203-0.109-0.156-2.594-3.672-2.594-6.797 0-4.188 1.313-9 11.5-9h2.5v-3c0-0.406 0.25-0.766 0.609-0.922 0.125-0.047 0.266-0.078 0.391-0.078 0.266 0 0.516 0.109 0.703 0.297l6 6c0.391 0.391 0.391 1.016 0 1.406z';
  static card =
    'M27.5 2c1.375 0 2.5 1.125 2.5 2.5v19c0 1.375-1.125 2.5-2.5 2.5h-25c-1.375 0-2.5-1.125-2.5-2.5v-19c0-1.375 1.125-2.5 2.5-2.5h25zM2.5 4c-0.266 0-0.5 0.234-0.5 0.5v3.5h26v-3.5c0-0.266-0.234-0.5-0.5-0.5h-25zM27.5 24c0.266 0 0.5-0.234 0.5-0.5v-9.5h-26v9.5c0 0.266 0.234 0.5 0.5 0.5h25zM4 22v-2h4v2h-4zM10 22v-2h6v2h-6z';
  static country =
    'M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 30c-1.967 0-3.84-0.407-5.538-1.139l7.286-8.197c0.163-0.183 0.253-0.419 0.253-0.664v-3c0-0.552-0.448-1-1-1-3.531 0-7.256-3.671-7.293-3.707-0.188-0.188-0.442-0.293-0.707-0.293h-4c-0.552 0-1 0.448-1 1v6c0 0.379 0.214 0.725 0.553 0.894l3.447 1.724v5.871c-3.627-2.53-6-6.732-6-11.489 0-2.147 0.484-4.181 1.348-6h3.652c0.265 0 0.52-0.105 0.707-0.293l4-4c0.188-0.188 0.293-0.442 0.293-0.707v-2.419c1.268-0.377 2.61-0.581 4-0.581 2.2 0 4.281 0.508 6.134 1.412-0.13 0.109-0.256 0.224-0.376 0.345-1.133 1.133-1.757 2.64-1.757 4.243s0.624 3.109 1.757 4.243c1.139 1.139 2.663 1.758 4.239 1.758 0.099 0 0.198-0.002 0.297-0.007 0.432 1.619 1.211 5.833-0.263 11.635-0.014 0.055-0.022 0.109-0.026 0.163-2.541 2.596-6.084 4.208-10.004 4.208z';
  static maintenance =
    'M31.342 25.559l-14.392-12.336c0.67-1.259 1.051-2.696 1.051-4.222 0-4.971-4.029-9-9-9-0.909 0-1.787 0.135-2.614 0.386l5.2 5.2c0.778 0.778 0.778 2.051 0 2.828l-3.172 3.172c-0.778 0.778-2.051 0.778-2.828 0l-5.2-5.2c-0.251 0.827-0.386 1.705-0.386 2.614 0 4.971 4.029 9 9 9 1.526 0 2.963-0.38 4.222-1.051l12.336 14.392c0.716 0.835 1.938 0.882 2.716 0.104l3.172-3.172c0.778-0.778 0.731-2-0.104-2.716z';
  static help =
    'M13.75 18.75v2.5c0 0.281-0.219 0.5-0.5 0.5h-2.5c-0.281 0-0.5-0.219-0.5-0.5v-2.5c0-0.281 0.219-0.5 0.5-0.5h2.5c0.281 0 0.5 0.219 0.5 0.5zM17.75 11c0 2.219-1.547 3.094-2.688 3.734-0.812 0.469-1.313 0.766-1.313 1.266v0.5c0 0.281-0.219 0.5-0.5 0.5h-2.5c-0.281 0-0.5-0.219-0.5-0.5v-1.062c0-1.922 1.375-2.531 2.484-3.031 0.938-0.438 1.516-0.734 1.516-1.437 0-0.906-1.141-1.578-2.172-1.578-0.547 0-1.125 0.172-1.484 0.422-0.344 0.234-0.672 0.578-1.25 1.297-0.094 0.125-0.234 0.187-0.391 0.187-0.109 0-0.219-0.031-0.297-0.094l-1.687-1.281c-0.203-0.156-0.25-0.453-0.109-0.672 1.281-2.016 3.078-3 5.453-3v0c2.562 0 5.437 2.031 5.437 4.75zM12 4c-5.516 0-10 4.484-10 10s4.484 10 10 10 10-4.484 10-10-4.484-10-10-10zM24 14c0 6.625-5.375 12-12 12s-12-5.375-12-12 5.375-12 12-12v0c6.625 0 12 5.375 12 12z';
  static plus =
    'M30 14h-12v-12c0-1.2-0.8-2-2-2s-2 0.8-2 2v12h-12c-1.2 0-2 0.8-2 2s0.8 2 2 2h12v12c0 1.2 0.8 2 2 2s2-0.8 2-2v-12h12c1.2 0 2-0.8 2-2s-0.8-2-2-2z';
  static university =
    'M15 0l15 6v2h-2c0 0.547-0.484 1-1.078 1h-23.844c-0.594 0-1.078-0.453-1.078-1h-2v-2zM4 10h4v12h2v-12h4v12h2v-12h4v12h2v-12h4v12h0.922c0.594 0 1.078 0.453 1.078 1v1h-26v-1c0-0.547 0.484-1 1.078-1h0.922v-12zM28.922 25c0.594 0 1.078 0.453 1.078 1v2h-30v-2c0-0.547 0.484-1 1.078-1h27.844z';
}
