import appStyles from 'App.module.scss';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { CheckBox, CheckBoxHelper } from 'components/Generic/FormElements/CheckBox/CheckBox';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { customerContext, messageContext } from 'App';
import { EditPreferencesForm } from 'models/forms/editPreferencesForm.model';
import { AppError } from 'models/generic/appError.model';
import { ListItem } from 'models/generic/listItem.model';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

export const EditPreferences: FC = () => {
  const navigate = useNavigate();

  const messages = useContext(messageContext);
  const customerAuth = useContext(customerContext);
  const currentUser = customerAuth.getCurrentUser();

  const [form, setForm] = useState(new EditPreferencesForm(currentUser?.optInEmail || false));
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    if (!currentUser) {
      throw new Error('Current user is null');
    }
  };

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    setForm(CheckBoxHelper.baseHandleBool(e, form));
  };

  const save = async () => {
    setInProgress(true);
    const result = await customerAuth.updatePreferences(form);
    if (result instanceof AppError) {
      form.fieldMessages = result.fieldMessages;
      setForm(form);
      setInProgress(false);
    } else {
      if (currentUser) {
        const updatedCurrentUser = currentUser;

        // update the preference
        updatedCurrentUser.optInEmail = result.optInEmail;
        customerAuth.setCurrentUser(updatedCurrentUser);
      }

      messages.addSuccessAsString('Your preferences have been updated.');

      // go back to dashboard
      navigate('/');
    }
  };

  const back = () => {
    navigate('/');
  };

  return (
    currentUser && (
      <>
        <div className={appStyles.container} id="EditPreferencesForm">
          <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
            <Icon icon={Icons.user} size="lg" color="darkBlue" iconName="User" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>Marketing Preferences</h1>
          </div>

          {currentUser.optInEmail ? (
            <p>You have opted in to receive emails. You can change your preference using the check box below.</p>
          ) : (
            <p>You have opted out of receiving emails. You can change your preference using the check box below.</p>
          )}

          <form className={appStyles.row}>
            <div className={appStyles.col_md_12}>
              <div id="preferenceUpdates" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
                <fieldset>
                  <div className={appStyles.form__row}>
                    <CheckBox
                      name="optOutEmail"
                      options={[
                        new ListItem(true, 'Please tick this box if you do not want to receive email or phone marketing from Oxford University Press.')
                      ]}
                      selectedOptions={[form.optOutEmail]}
                      handleChange={handleCheckBox}
                      validationErrors={form.fieldMessages['optOutEmail']}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </form>
        </div>

        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Button buttonStyle="secondary" size="lg" handleClick={back}>
            Back to dashboard
          </Button>

          <Button id="submitButton" buttonStyle="primary" size="lg" handleClick={save} inProgress={inProgress} title="Click to save your preferences">
            Save changes
          </Button>
        </BottomButtonContainer>
      </>
    )
  );
};
