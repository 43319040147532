import appStyles from 'App.module.scss';
import { Subscriptions } from 'components/CustomerArea/Subscriptions/Subscriptions';
import { Button } from 'components/Generic/FormElements/Button/Button';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLookups } from 'hooks/useLookups';
import { customerContext } from 'App';
import { Ebooks } from 'components/CustomerArea/Ebooks/Ebooks';
import classNames from 'classnames';

export const Dashboard: FC = () => {
  const [countryName, setCountryName] = useState('');

  const lookups = useLookups();
  const customerAuth = useContext(customerContext);
  const currentUser = customerAuth.getCurrentUser();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    window.scrollTo(0, 0);

    // load country
    if (currentUser && currentUser.address && currentUser.address.countryId) {
      const country = await lookups.getCountryById(currentUser.address.countryId);
      if (country) {
        setCountryName(country.name);
      }
    }
  };

  return (
    currentUser &&
    currentUser.address && (
      <div className={classNames(appStyles.container, appStyles.dashboard)} id="Dashboard">
        <div className={appStyles.row}>
          <div className={appStyles.col_lg_8} id="DashboardSubscriptionsAndEbooks">
            <div id="Subscriptions" className={classNames(appStyles.background_cardBlue, appStyles.card__dashboard_large)}>
              <Legend type="large" text="Subscriptions" />
              <Subscriptions />
            </div>

            <div id="Ebooks" className={classNames(appStyles.background_cardBlue, appStyles.card__dashboard_large)}>
              <Legend type="large" text="E-books" />
              <Ebooks />
            </div>
          </div>

          <div className={appStyles.col_lg_4} id="DashboardInformation">
            <div id="Information" className={classNames(appStyles.background_cardBlue, appStyles.card__dashboard_large)}>
              <Legend type="large" text="Account details" />
              <p>
                {currentUser.title} {currentUser.fullName}
                <br />
                {currentUser.address.address1}
                {currentUser.address.address2 && (
                  <>
                    <br />
                    {currentUser.address.address2}
                  </>
                )}
                <br />
                {currentUser.address.city}
                <br />
                {currentUser.address.postCode}
                <br />
                {countryName}
                <br />
                {currentUser.email}
                <br />
                {currentUser.address.telephone}
              </p>

              <div className={appStyles.card_button}>
                <Link to="/edit-details/">
                  <Button id="editDetailsButton" buttonType="submit" buttonStyle="secondary" size="lg">
                    Edit details
                  </Button>
                </Link>
              </div>
            </div>

            <div id="PasswordChange" className={classNames(appStyles.background_cardBlue, appStyles.card__dashboard_large)}>
              <Legend type="large" text="Password details" />
              <p>You can change your password by clicking the button below.</p>
              <div className={appStyles.card_button}>
                <Link to="/password/">
                  <Button id="editPasswordButton" buttonType="submit" buttonStyle="secondary" size="lg">
                    Change password
                  </Button>
                </Link>
              </div>
            </div>

            <div id="Marketing" className={classNames(appStyles.background_cardBlue, appStyles.card__dashboard_large)}>
              <Legend type="large" text="Marketing preferences" />
              {currentUser.optInEmail ? (
                <p>You have opted in to receive emails. Click the button below to change your preferences.</p>
              ) : (
                <p>You have opted out of receiving emails. Click the button below to change your preferences.</p>
              )}
              <div className={appStyles.card_button}>
                <Link to="/edit-preferences/">
                  <Button id="optInButton" buttonType="button" buttonStyle="secondary" size="lg">
                    Change preferences
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
