import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { Select } from 'components/Generic/FormElements/Select/Select';
import { ValidationArea } from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { DateOnly } from 'models/generic/dateOnly';
import { ListItem } from 'models/generic/listItem.model';
import moment from 'moment';
import React, { ChangeEvent, FC } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DateSelector.module.scss';

interface IDateSelectorProps {
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   * @property {string} title Set the title attribute
   */
  title?: string;
  /**
   * @property {boolean} required Optional. Set the field to required
   */
  required?: boolean;
  /**
   * @property {string} labelText Set the text to appear in the label
   */
  labelText: string;
  /**
   * @property {string} elementId Set the ID attribute
   */
  elementId: string;
  /**
   * @property {Date | null} value Set the value attribute
   */
  value: Date | null;
  /**
   * @property {string} placeholder Set the placeholder
   */
  placeholder: string;
  /**
   * @property {boolean} labelHidden Optional. Hide the label. labelText will be used for screenreaders. Default is false.
   */
  labelHidden?: boolean;
  /**
   * @property {string} dateFormat Set the date format
   */
  dateFormat: string;
  /**
   * @property {(fieldName: string, date: Date | null | string, momentDate: moment.Moment | null, dateOnly: DateOnly | null) => void} handleChange change event
   */
  handleChange: (fieldName: string, date: Date | null | string, momentDate: moment.Moment | null, dateOnly: DateOnly | null) => void;
  /**
   * @property {string} customInputClass Optional. Use a custom class on the input - use styles from the current component or appStyles
   */
  customInputClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {boolean} includeTimePicker Optional. Enables time picker if set to true
   */
  includeTimePicker?: boolean;
  /**
   * @property {string[]} validationErrors Optional. Any validation messages related to this field
   */
  validationErrors?: string[];
}
export const DateSelector: FC<IDateSelectorProps> = (props: IDateSelectorProps) => {
  const hoursOptions = [
    new ListItem(0, '0'),
    new ListItem(1, '1'),
    new ListItem(2, '2'),
    new ListItem(3, '3'),
    new ListItem(4, '4'),
    new ListItem(5, '5'),
    new ListItem(6, '6'),
    new ListItem(7, '7'),
    new ListItem(8, '8'),
    new ListItem(9, '9'),
    new ListItem(10, '10'),
    new ListItem(11, '11'),
    new ListItem(12, '12'),
    new ListItem(13, '13'),
    new ListItem(14, '14'),
    new ListItem(15, '15'),
    new ListItem(16, '16'),
    new ListItem(17, '17'),
    new ListItem(18, '18'),
    new ListItem(19, '19'),
    new ListItem(20, '20'),
    new ListItem(21, '21'),
    new ListItem(22, '22'),
    new ListItem(23, '23')
  ];

  const minutesOptions = [
    new ListItem(0, '00'),
    new ListItem(5, '05'),
    new ListItem(10, '10'),
    new ListItem(15, '15'),
    new ListItem(20, '20'),
    new ListItem(25, '25'),
    new ListItem(30, '30'),
    new ListItem(35, '35'),
    new ListItem(40, '40'),
    new ListItem(45, '45'),
    new ListItem(50, '50'),
    new ListItem(55, '55')
  ];

  let datePickerInstance: DatePicker | null = null;

  return (
    <>
      <label
        htmlFor={props.name}
        className={classNames(
          appStyles.form__label,
          { [appStyles.form__label_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
          { [props.customLabelClass as string]: props.customLabelClass }, // add custom class if defined
          { [appStyles.srOnly]: props.labelHidden } // add srOnly class if label hidden
        )}>
        {props.labelText}
      </label>

      <DatePicker
        ref={(ref) => {
          datePickerInstance = ref;
        }}
        className={classNames(
          styles.dropdown,
          { [styles.dropdown_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
          { [props.customInputClass as string]: props.customInputClass } // add custom class if defined
        )}
        id={props.name}
        name={props.name}
        selected={props.value ? props.value : null}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(value: Date, event: React.SyntheticEvent<any>) => {
          const date = value ? value : null;
          const momentDate = value ? moment(value) : null;
          const dateOnly = value ? DateOnly.fromDate(value) : null;

          // raise date changed event only when value changes to valid date matching defined format
          if (event.target) {
            const inputValue = (event.target as HTMLInputElement).value;
            if (inputValue === undefined) {
              // date was selected from calendar, so raise an event
              props.handleChange(props.name, date, momentDate, dateOnly);
            } else {
              // date was entered with keyboard, so check if matches format first
              if (inputValue.length === props.dateFormat.length) {
                props.handleChange(props.name, date, momentDate, dateOnly);
              }
            }
          }
        }}
        dateFormat={props.dateFormat}
        title={props.title || ''}
        aria-required={props.required || undefined}
      />

      {props.includeTimePicker && (
        <>
          <Select
            elementId={props.name + '_hours'}
            labelText="Hour"
            name="hours"
            options={hoursOptions}
            placeholder=""
            disablePlaceholder={true}
            customSelectClass={styles.hours}
            customLabelClass={appStyles.srOnly}
            value={props.value ? props.value.getHours() : 0}
            handleChange={(event: ChangeEvent<HTMLSelectElement>) => {
              const date = datePickerInstance ? datePickerInstance.props.selected : null;
              if (date) {
                const hours = +event.target.value;
                date.setHours(hours);

                const momentDate = moment(date);
                const dateOnly = DateOnly.fromDate(date);

                // raise date changed event
                props.handleChange(props.name, date, momentDate, dateOnly);
              }
            }}
          />
          :
          <Select
            elementId={props.name + 'minutes'}
            labelText="Minutes"
            name="minutes"
            options={minutesOptions}
            placeholder=""
            disablePlaceholder={true}
            customSelectClass={styles.minutes}
            customLabelClass={appStyles.srOnly}
            value={props.value ? props.value.getMinutes() : 0}
            handleChange={(event: ChangeEvent<HTMLSelectElement>) => {
              const date = datePickerInstance ? datePickerInstance.props.selected : null;
              if (date) {
                const minutes = +event.target.value;
                date.setMinutes(minutes);

                const momentDate = moment(date);
                const dateOnly = DateOnly.fromDate(date);

                // raise date changed event
                props.handleChange(props.name, date, momentDate, dateOnly);
              }
            }}
          />
        </>
      )}

      {/* include icon only when there's no time picker, as this breaks styles */}
      {!props.includeTimePicker && (
        <Icon
          icon={Icons.calendar}
          iconName="Calendar"
          size="md"
          color="darkBlue"
          customClass={classNames(appStyles.datePicker__icon, {
            [appStyles.datePicker__icon_withLabel]: !props.labelHidden
          })}
        />
      )}

      <ValidationArea sectionId={props.elementId} validationErrors={props.validationErrors} />
    </>
  );
};

export class DateSelectorHelper {
  static baseHandleMoment<T>(fieldName: string, value: moment.Moment | null, obj: T): T {
    if (value) {
      value = value.clone();
    }

    obj = {
      ...obj,
      [fieldName]: value || null
    };

    return obj;
  }

  static baseHandleDate<T>(fieldName: string, value: Date | null, obj: T): T {
    obj = {
      ...obj,
      [fieldName]: value || null
    };

    return obj;
  }

  static baseHandleDateOnly<T>(fieldName: string, value: DateOnly | null, obj: T): T {
    obj = {
      ...obj,
      [fieldName]: value || null
    };

    return obj;
  }
}
