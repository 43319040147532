import cookie from 'react-cookies';
import ReactGA from 'react-ga4';

export interface IAnalyticsHook {
  initialise: () => void;
  enableAnalytics: () => void;
  disableAnalytics: () => void;
}

export function useAnalytics(): IAnalyticsHook {
  const initialise = () => {
    const allowAnalyticsCookies = cookie.load('allowAnalyticsCookies') === 'true';
    if (allowAnalyticsCookies) {
      enableAnalytics();
    }
  };

  const enableAnalytics = () => {
    // set preference
    const expiry = new Date();
    expiry.setFullYear(new Date().getFullYear() + 1);
    cookie.save('allowAnalyticsCookies', 'true', { expires: expiry });

    const gaTrackingId = process.env.REACT_APP_GA_KEY;
    const gaCookieDomain = process.env.REACT_APP_GA_COOKIE_DOMAIN;

    if (gaTrackingId) {
      ReactGA.initialize([
        {
          trackingId: gaTrackingId,
          gaOptions: { cookieDomain: gaCookieDomain }
        }
      ]);
    }
  };

  const disableAnalytics = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)[`ga-disable-${process.env.REACT_APP_GA_KEY}`] = true;

    // set preference
    const expiry = new Date();
    expiry.setFullYear(new Date().getFullYear() + 1);
    cookie.save('allowAnalyticsCookies', 'false', { expires: expiry });

    // manually remove any GA cookies
    const cookies = cookie.loadAll();
    const names = Object.keys(cookies);
    const options = {
      path: '/',
      domain: process.env.REACT_APP_GA_COOKIE_DOMAIN
    };
    names.forEach((cookieName) => {
      if (cookieName.startsWith('_ga') || cookieName.startsWith('_gat') || cookieName.startsWith('_gid')) {
        cookie.remove(cookieName, options);
      }
    });
  };

  return {
    initialise,
    enableAnalytics,
    disableAnalytics
  };
}
