import { AxiosResponse } from 'axios';
import { FieldMessages } from 'models/generic/fieldMessages.model';

export class AppError {
  code: number | undefined;
  statusText: string | undefined;
  errorMessages: string[];
  fieldMessages: FieldMessages = new FieldMessages();

  constructor(error?: AxiosResponse) {
    this.errorMessages = [];

    if (error) {
      this.code = error.status;
      this.statusText = error.statusText;
      if (this.code === 400 && error.data) {
        // for BadRequest check fieldMessages property
        if (error.data.fieldMessages) {
          this.fieldMessages = error.data.fieldMessages;
        } else {
          // it can also be a string being an error message
          if (error.data) {
            this.errorMessages.push(error.data);
          }
        }
      }
    }
  }
}
