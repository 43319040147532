import FileDownload from 'js-file-download';

export function useDownload(fileContent: string, fileName: string, encoding = 'UTF8') {
  let mimeType: string | undefined;
  let bom: Uint8Array | undefined;

  if (fileName.endsWith('.csv')) {
    mimeType = 'text/csv';
  }

  if (encoding === 'UTF8') {
    // TODO: uncomment to add a preamble
    //   bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  }

  FileDownload(fileContent, fileName, mimeType, bom as unknown as string);
}
