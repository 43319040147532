import appStyles from 'App.module.scss';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { adminContext } from 'App';

export const AdminDashboard: FC = () => {
  const adminAuth = useContext(adminContext);
  const user = adminAuth.getCurrentUser();
  const defaultStore = user?.dateFormat ?? 'uk'; // set default store based on current admin user's date format ('uk' or 'us') or use default 'uk' value

  return (
    <div className={classNames(appStyles.container, appStyles.dashboard)} id="AdminDashboard">
      <div className={appStyles.row}>
        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to="/admin/orders/">
            <div id="ordersCard" className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.orders} size="xl" color="gabBlue" iconName="Orders" />
              <p className={appStyles.dashboard__heading}>Orders</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to="/admin/reports/">
            <div id="reportsCard" className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.reports} size="xl" color="gabBlue" iconName="Reports" />
              <p className={appStyles.dashboard__heading}>Reports</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to="/admin/products/">
            <div id="productsCard" className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.products} size="xl" color="gabBlue" iconName="Products" />
              <p className={appStyles.dashboard__heading}>Products</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to={`/admin/ebooks/${defaultStore}`}>
            <div id="ebooksCard" className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.ebooks} size="xl" color="gabBlue" iconName="E-books" />
              <p className={appStyles.dashboard__heading}>E-books</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to="/admin/customers/">
            <div id="customersCard" className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.people} size="xl" color="gabBlue" iconName="Customers" />
              <p className={appStyles.dashboard__heading}>Customers</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to="/admin/administrators/">
            <div id="adminCard" className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.people} size="xl" color="gabBlue" iconName="Administrators" />
              <p className={appStyles.dashboard__heading}>Administrators</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to="/admin/countries/">
            <div id="countriesCard" className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.country} size="xl" color="gabBlue" iconName="Countries" />
              <p className={appStyles.dashboard__heading}>Countries</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to="/admin/maintenance/">
            <div id="maintenanceCard" className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.maintenance} size="xl" color="gabBlue" iconName="Maintenance" />
              <p className={appStyles.dashboard__heading}>Maintenance</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to="/admin/logs/">
            <div id="logsCard" className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.logs} size="xl" color="gabBlue" iconName="Logs" />
              <p className={appStyles.dashboard__heading}>Logs</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to="/admin/ringgold/">
            <div id="ringgoldCard" className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.university} size="xl" color="gabBlue" iconName="Ringgold" />
              <p className={appStyles.dashboard__heading}>Ringgold</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to="/admin/logout/">
            <div id="logoutCard" className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.logOut} size="xl" color="gabBlue" iconName="Logout" />
              <p className={appStyles.dashboard__heading}>Logout</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
