import appStyles from 'App.module.scss';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { Input, InputHelper } from 'components/Generic/FormElements/Input/Input';
import { Select, SelectHelper } from 'components/Generic/FormElements/Select/Select';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { ListItem } from 'models/generic/listItem.model';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLookups } from 'hooks/useLookups';
import hmacSHA1 from 'crypto-js/hmac-sha1';
import classNames from 'classnames';

export const AddItemToBasketTest: FC = () => {
  const navigate = useNavigate();
  const hashingKey = process.env.REACT_APP_HASHING_KEY as string;

  const [form, setForm] = useState({
    productCode: '9781789624748',
    store: 6,
    price: 1.01,
    promo: 'ABC',
    period: '01',
    returnUrl: '/basket/test'
  });
  const [periods, setPeriods] = useState<ListItem[]>([]);

  const lookups = useLookups();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setPeriods(await lookups.getPeriodsAsListItems());
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setForm(InputHelper.baseHandleString(e, form));
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setForm(SelectHelper.baseHandleString(e, form));
  };

  const addToBasket = () => {
    const price = (+form.price).toFixed(2);

    const query = `product=${form.productCode}&store=${form.store}&price=${price}&promo=${form.promo}&period=${form.period}`;

    const hash = hmacSHA1(query, hashingKey).toString().toUpperCase();

    const fullQuery = query + `&hash=${hash}&returnUrl=${encodeURIComponent(form.returnUrl)}`;

    navigate('/basket/' + fullQuery);
  };

  return (
    <>
      <div className={appStyles.container} id="AddItemToBasketTest">
        <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
          <Icon icon={Icons.products} size="lg" color="darkBlue" iconName="Product" customClass={appStyles.heading__icon} />
          <h1 className={appStyles.heading__text}>Test page: Add product to basket</h1>
        </div>

        <form className={appStyles.row}>
          <div className={appStyles.col_md_6}>
            <div className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
              <fieldset>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="productCode"
                    inputType="text"
                    labelText="Product code / ISBN"
                    name="productCode"
                    placeholder="Enter product code"
                    handleChange={handleInput}
                    value={form.productCode}
                  />
                </div>

                <div className={appStyles.form__row}>
                  <Input
                    elementId="store"
                    inputType="text"
                    labelText="Store ID"
                    name="store"
                    placeholder="Enter store ID"
                    handleChange={handleInput}
                    value={form.store}
                  />
                </div>

                <div className={appStyles.form__row}>
                  <Input
                    elementId="price"
                    inputType="number"
                    labelText="Price"
                    name="price"
                    placeholder="Enter product price"
                    handleChange={handleInput}
                    value={form.price}
                  />
                </div>
              </fieldset>
            </div>
          </div>

          <div className={appStyles.col_md_6}>
            <div className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
              <fieldset>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="promo"
                    inputType="text"
                    labelText="Promo code"
                    name="promo"
                    placeholder="Enter product code"
                    handleChange={handleInput}
                    value={form.promo}
                  />
                </div>

                <div className={appStyles.form__row}>
                  <Select
                    elementId="period"
                    labelText="Period"
                    name="period"
                    options={periods}
                    placeholder="Select period"
                    disablePlaceholder={true}
                    value={form.period}
                    handleChange={handleSelect}
                  />
                </div>

                {/* <div className={appStyles.form__row}>
                <Select
                  elementId="renew"
                  labelText="Auto renew"
                  name="renew"
                  options={renewOptions}
                  placeholder="Select renew"
                  disablePlaceholder={true}
                  value={form.renew}
                  handleChange={handleSelect}
                />
              </div> */}

                <div className={appStyles.form__row}>
                  <Input
                    elementId="returnUrl"
                    inputType="text"
                    labelText="Return URL"
                    name="returnUrl"
                    placeholder="Enter product code"
                    handleChange={handleInput}
                    value={form.returnUrl}
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </form>
      </div>

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button id="addButton" buttonStyle="primary" size="lg" handleClick={addToBasket} title="Add to basket">
          Add to basket
        </Button>
      </BottomButtonContainer>
    </>
  );
};
