import appStyles from 'App.module.scss';
import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Alert.module.scss';

interface IAlertProps {
  /**
   * @property {string} alertType Applies a class for the type of alert (these styles are in _validation.scss)
   */
  alertType: 'error' | 'warning' | 'info' | 'success' | 'blank';
  /**
   * @property {ReactNode} children Add elements inside this container
   */
  children: ReactNode;
  /**
   * @property {string} customClass Optional. Use a custom class on the alert - use styles from the current component or appStyles
   */
  customClass?: string;
  /**
   * @property {string} id Optional.
   */
  id?: string;
}

/**
 * @description Add an alert. Optional properties: customClass
 */
export const Alert: FC<IAlertProps> = (props: IAlertProps) => (
  <div
    id={props.id || undefined}
    className={classNames(
      styles.alert,
      styles[`alert_${props.alertType}`],
      { [props.customClass as string]: props.customClass } // add custom class if defined
    )}
    role="alert"
    data-type={'alert-' + props.alertType}>
    {props.alertType !== 'blank' ? <span className={appStyles.srOnly}>{props.alertType}:</span> : ''}
    <div>
      {React.Children.map(props.children, (child) => (
        <React.Fragment>{child}</React.Fragment>
      ))}
    </div>
  </div>
);

export default Alert;
