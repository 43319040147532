import { Store } from 'models/responses/store.model';
import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class EditStoreForm extends BaseValidatedObject {
  maintenanceMessage = '';
  maintenanceStart: Date | null = null;
  maintenanceEnd: Date | null = null;
  disableCheckoutDuringMaintenance = false;

  constructor(store: Store | null) {
    super();

    if (store) {
      this.maintenanceMessage = store.maintenanceMessage;
      this.maintenanceStart = store.maintenanceStart;
      this.maintenanceEnd = store.maintenanceEnd;
      this.disableCheckoutDuringMaintenance = store.disableCheckoutDuringMaintenance || false;
    }
  }
}
