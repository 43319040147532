/* eslint-disable complexity */
import { Grid } from '@progress/kendo-react-grid/dist/npm/Grid';
import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { Input, InputHelper } from 'components/Generic/FormElements/Input/Input';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { useGrid } from 'hooks/useGrid';
import { adminContext, messageContext } from 'App';
import { RefundForm } from 'models/forms/refundForm.model';
import { AppError } from 'models/generic/appError.model';
import { ListItem } from 'models/generic/listItem.model';
import { Log } from 'models/responses/log.model';
import { Order } from 'models/responses/order.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import utils from 'utils/utils';
import validation from 'utils/validation';
import { useLogs } from 'hooks/useLogs';
import { useOrders } from 'hooks/useOrders';
import constants from 'utils/constants';
import { CheckBox } from 'components/Generic/FormElements/CheckBox/CheckBox';
import { useSubscriptions } from 'hooks/useSubscriptions';
import { UpdateAutoRenewalForm } from 'models/forms/updateAutoRenewalForm.model';
import classNames from 'classnames';
import LoadingAlert from 'components/Generic/LoadingAlert/LoadingAlert';
import styles from './Orders.module.scss';

// eslint-disable-next-line complexity
export const OrderDetails: FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [order, setOrder] = useState<Order | null>(null);
  const [form, setForm] = useState(new RefundForm());
  const [sendEmailInProgress, setSendEmailInProgress] = useState(false);
  const [refundInProgress, setRefundInProgress] = useState(false);
  const [loadingLogsInProgress, setLoadingLogsInProgress] = useState(true);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [maxRefundAmount, setMaxRefundAmount] = useState(0);
  const [refundAttempted, setRefundAttempted] = useState(false);
  const [orderId, setOrderId] = useState(params.id ? +params.id : null);
  const [isLoaded, setIsLoaded] = useState(false);

  const grid = useGrid<Log>([{ field: 'logId', dir: 'asc' }]);
  const messages = useContext(messageContext);
  const logs = useLogs();
  const orders = useOrders();
  const subscriptions = useSubscriptions();
  const adminAuth = useContext(adminContext);

  useEffect(() => {
    init();
  }, [orderId]);

  useEffect(() => {
    // reload refund amount when order loads
    if (order) {
      if (order.subTotal !== null && order.refundedSubTotal !== null) {
        setMaxRefundAmount(order.subTotal - order.refundedSubTotal);
      }
    }
  }, [order]);

  const init = async () => {
    window.scrollTo(0, 0);

    if (orderId) {
      setOrder(await orders.getOrderById(orderId));

      setValidationRules(await orders.getRefundValidationRules());
      loadData(orderId);
    } else {
      navigate('/admin/orders');
    }
    setIsLoaded(true);
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = InputHelper.baseHandleNumber(e, form);

    if (refundAttempted) {
      validateRefundForm(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleCheckBox = async (e: ChangeEvent<HTMLInputElement>) => {
    const renewSelected = e.target.checked;
    const msdOrderId = +(order?.msdOrderIds || 0);
    const form = new UpdateAutoRenewalForm(msdOrderId, renewSelected);
    const response = await subscriptions.saveAutoRenewalAsAdmin(form);
    if (response) {
      messages.addSuccessAsString(`Auto-renew preferences have been updated.`);
      setOrder(response);
    }
  };

  const sendOrderConfirmation = async () => {
    if (order && order.orderId) {
      setSendEmailInProgress(true);
      const result = await orders.sendOrderConfirmaton(order.orderId);
      setSendEmailInProgress(false);
      if (result instanceof AppError) {
      } else {
        messages.addSuccessAsString('Order confimation email has been sent successfully.');
      }
    }
  };

  const requestRefund = async () => {
    const isValid = validateRefundForm(form);
    setRefundAttempted(true);
    if (isValid) {
      if (window.confirm('Are you sure you cancel this order?')) {
        setRefundInProgress(true);

        if (order && order.orderId) {
          form.orderId = order.orderId;
          const response = await orders.refundOrder(form);
          if (response instanceof AppError) {
            form.fieldMessages = response.fieldMessages;
            setForm(form);
          } else {
            order.refundedTax = response.refundedTax;
            order.refundedSubTotal = response.refundedSubTotal;
            order.refundedTotal = response.refundedTotal;
            order.cancelledTotal = response.cancelledTotal;
            order.orderStatus = response.orderStatus;
            if (order.subTotal !== null && order.refundedSubTotal !== null) {
              setMaxRefundAmount(order.subTotal - order.refundedSubTotal);
            }

            form.refundAmount = null;
            messages.addSuccessAsString('Order refunded successfully.');
          }
        }

        setRefundInProgress(false);
      }
    }
  };

  const validateRefundForm = (form: RefundForm): boolean => validation.validateForm(form, validationRules, setForm);

  const loadData = async (orderId: number) => {
    setLoadingLogsInProgress(true);

    const result = await logs.getLogsByOrderId(orderId);
    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      // show all logs on a single page
      grid.setTake(result.length);
      grid.setData(result);
    }

    setLoadingLogsInProgress(false);
  };

  const getCardLines = (lines: ListItem[]): JSX.Element[] =>
    lines.map((item: ListItem, index: number) => (
      <div className={appStyles.card__row} key={index}>
        <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>{item.id}</div>
        <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{item.text}</div>
      </div>
    ));

  const getMainDetails = (): JSX.Element[] => {
    let lines: JSX.Element[] = [];

    if (order) {
      lines = getCardLines([
        new ListItem('Order ID', '' + order.orderId),
        new ListItem('MSD Order ID', '' + order.msdOrderIds),
        new ListItem('Payment method', order.paymentMethod),
        new ListItem('Auth code or message', order.authCode || order.transactionMessage),
        new ListItem('Judopay payment reference', order.paymentReference || 'N/A'),
        new ListItem('Judopay consumer reference', order.consumerReference || 'N/A'),
        new ListItem('Status', order.orderStatus),
        new ListItem('Order date', utils.dateTime.getFormattedDateAndTime(order.orderDate)),
        new ListItem('Store', order.storeName),
        new ListItem('Customer', order.fullName + ', ' + order.customerId)
      ]);
    }

    return lines;
  };

  const getInvoiceDetails = () => {
    let lines: JSX.Element[] = [];

    if (order) {
      lines = getCardLines([
        new ListItem('Currency code', order.currencyCode),
        new ListItem('Subtotal', utils.currency.formatNumberAsCurrency(order.storeId, order.subTotal)),
        new ListItem('Tax', utils.currency.formatNumberAsCurrency(order.storeId, order.tax)),
        new ListItem('Total', utils.currency.formatNumberAsCurrency(order.storeId, order.total)),
        new ListItem('Cancelled', utils.currency.formatNumberAsCurrency(order.storeId, order.cancelledTotal)),
        new ListItem('Refunded subtotal', utils.currency.formatNumberAsCurrency(order.storeId, order.refundedSubTotal)),
        new ListItem('Refunded tax', utils.currency.formatNumberAsCurrency(order.storeId, order.refundedTax)),
        new ListItem('Refunded total', utils.currency.formatNumberAsCurrency(order.storeId, order.refundedTotal))
      ]);
    }

    return lines;
  };

  const back = () => {
    navigate('/admin/orders');
  };

  const notFound = isLoaded && order === null;

  return (
    <div className={appStyles.container} id="AdminOrderDetails">
      {isLoaded === false ? (
        <LoadingAlert />
      ) : notFound ? (
        <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
          <Icon icon={Icons.warning} size="lg" color="darkBlue" iconName="Order" customClass={appStyles.heading__icon} />
          <h1 id="NotFound" className={appStyles.heading__text}>
            Order not found
          </h1>
        </div>
      ) : order ? (
        <>
          <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
            <Icon icon={Icons.orders} size="lg" color="darkBlue" iconName="Logs" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>Order {order.orderId}</h1>
          </div>

          {/* Main details section */}
          <div className={appStyles.row}>
            <div className={appStyles.col_md_6}>
              <div className={classNames(appStyles.card, styles.card, appStyles.background_white, appStyles.card_boxShadow)}>
                <Legend text="Main details" type="medium" />
                {getMainDetails()}
              </div>
            </div>

            {/* Invoice details section */}
            <div className={appStyles.col_md_6}>
              <div className={classNames(appStyles.card, styles.card, appStyles.background_white, appStyles.card_boxShadow)}>
                <Legend text="Invoice" type="medium" />

                {getInvoiceDetails()}
              </div>
            </div>

            {/* Billing address section */}
            <div className={appStyles.col_md_6}>
              <div className={classNames(appStyles.card, styles.card, appStyles.background_white, appStyles.card_boxShadow)}>
                <Legend text="Billing address" type="medium" />
                <div className={appStyles.card__row}>
                  <div className={classNames(appStyles.card__label, appStyles.text_oxfordBlue)}>
                    {order.title} {order.fullName}
                    <br />
                    {order.address1}
                    {order.address2 && (
                      <>
                        <br />
                        {order.address2}
                      </>
                    )}
                    <br />
                    {order.city}
                    <br />
                    {order.postCode}
                    <br />
                    {order.countryName}
                    <br />
                    <br />
                    <a href={`mailto:${order.email}`}>{order.email}</a>
                    <br />
                    {order.telephone}
                    <br />
                    {order.mobileCountryCode} {order.mobileNumber}
                  </div>
                </div>

                {/* Optional details when the order is on behalf of someone else */}
                {order.onBehalfOf && (
                  <>
                    <Legend text="Order on behalf of" type="medium" />
                    <div className={appStyles.card__row} id="onBehalfOfDetails">
                      <div className={classNames(appStyles.card__label, appStyles.text_oxfordBlue)}>
                        {order.onBehalfOfFullName}
                        <br />
                        <a href={`mailto:${order.onBehalfOfEmail}`}>{order.onBehalfOfEmail}</a>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* E-books/Subscriptions section - if there is at least one */}
              {order.items.length > 0 && (
                <div className={classNames(appStyles.card, styles.card, appStyles.background_white, appStyles.card_boxShadow)}>
                  {order.storeId === constants.stores.ebooksUs || order.storeId === constants.stores.ebooksUk ? (
                    <Legend text="E-books" type="medium" />
                  ) : (
                    <Legend text="Subscriptions" type="medium" />
                  )}

                  {order.items.map((item, index) => (
                    <div key={index}>
                      <div className={appStyles.card__row}>
                        <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}>{item.productCode}</div>
                        <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>{item.description}</div>
                      </div>

                      {/* show auto-renew details only for subscriptions */}
                      {[constants.stores.subscriptionsUk, constants.stores.subscriptionsUs].includes(order.storeId as number) && (
                        <>
                          <div className={appStyles.card__row}>
                            <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}></div>
                            <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>
                              Is auto-renewal:{' '}
                              {order.isAutoRenew ? (
                                order.previousAutoRenewOrderId ? (
                                  <>
                                    Yes, renewed from order{' '}
                                    <Link to={'/admin/orders/' + order.previousAutoRenewOrderId} onClick={() => setOrderId(order.previousAutoRenewOrderId)}>
                                      {order.previousAutoRenewOrderId}
                                    </Link>
                                  </>
                                ) : (
                                  'Yes'
                                )
                              ) : (
                                'No'
                              )}
                            </div>
                          </div>

                          {/* show auto-renew checkbox only for products that are enabled for auto-renew */}
                          <div className={appStyles.card__row}>
                            <div className={classNames(appStyles.card__label, appStyles.text_gabBlue)}></div>
                            <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue)}>
                              Will be auto-renewed:{' '}
                              {order.nextAutoRenewOrderId ? (
                                <>
                                  Already renewed, order{' '}
                                  <Link to={'/admin/orders/' + order.nextAutoRenewOrderId} onClick={() => setOrderId(order.nextAutoRenewOrderId)}>
                                    {order.nextAutoRenewOrderId}
                                  </Link>
                                </>
                              ) : order.expired ? (
                                'No, subscription is already expired'
                              ) : order.orderStatusId !== constants.orderStatuses.completed &&
                                order.orderStatusId !== constants.orderStatuses.partiallyRefunded &&
                                order.orderStatusId !== constants.orderStatuses.failedAtRefund ? (
                                'No, order was not completed'
                              ) : order.isAutoRenewAllowed === false ? (
                                'No, order contains no auto-renewable products'
                              ) : (
                                <CheckBox
                                  customWrapperClass={styles.autoRenewCheckBox}
                                  name="willAutoRenew"
                                  options={[new ListItem(true, '')]}
                                  selectedOptions={[order.willAutoRenew]}
                                  handleChange={handleCheckBox}
                                />
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Status updates section - if there is at least one (won't be displayed for legacy orders) */}
            {order.statusUpdates.length > 0 && (
              <div className={appStyles.col_md_6}>
                <div className={classNames(appStyles.card, styles.card, appStyles.background_white, appStyles.card_boxShadow)}>
                  <Legend text="Status updates" type="medium" />
                  <br />

                  <div id="statusGrid">
                    <Grid data={order.statusUpdates} filterable={false} pageable={false} total={order.statusUpdates.length} sortable={false} resizable={true}>
                      {ColumnHelper.getGridColumns(
                        [
                          {
                            field: '',
                            title: 'Status',
                            dataType: 'text',
                            size: 'lg',
                            columnMenu: false,
                            cell: (props) => (
                              <td className="k-table-td gridColumn_lg">
                                <span className={props.dataItem.statusName.startsWith('Failed') ? styles.errorStatus : ''}>{props.dataItem.statusName}</span>

                                {props.dataItem.message && (
                                  <>
                                    : <span className={styles.statusMessage}>{props.dataItem.message}</span>
                                  </>
                                )}
                                {props.dataItem.relatedEventLogId && (
                                  <>
                                    <br />
                                    <Link to={'/admin/logs/' + props.dataItem.relatedEventLogId} title="View related log message" target="_blank">
                                      (view details)
                                    </Link>
                                  </>
                                )}
                              </td>
                            )
                          },
                          { field: 'dateUpdated', title: 'Date', dataType: adminAuth.dateTimeFormat, size: 'sm' }
                        ],
                        grid.filter
                      )}
                    </Grid>
                  </div>
                </div>
              </div>
            )}

            {/* Re-send confirmation email section - only for near-completed orders */}
            {(order.orderStatusId === constants.orderStatuses.completed ||
              order.orderStatusId === constants.orderStatuses.partiallyRefunded ||
              order.orderStatusId === constants.orderStatuses.failedAtCompletion) && (
              <div className={appStyles.col_md_6}>
                <div className={classNames(appStyles.card, styles.card, appStyles.background_white, appStyles.card_boxShadow)}>
                  <Legend text="Re-send order confirmation email" type="medium" />
                  <div className={appStyles.card__row}>
                    <Button
                      id="buttonResendOrderConfimation"
                      buttonStyle="secondary"
                      size="lg"
                      handleClick={sendOrderConfirmation}
                      inProgress={sendEmailInProgress}>
                      Send
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {/* Refund section - only for near-completed orders */}
            {(order.orderStatusId === constants.orderStatuses.completed ||
              order.orderStatusId === constants.orderStatuses.partiallyRefunded ||
              order.orderStatusId === constants.orderStatuses.failedAtCompletion ||
              order.orderStatusId === constants.orderStatuses.failedAtRefund) && (
              <div className={appStyles.col_md_6}>
                <div className={classNames(appStyles.card, styles.card, appStyles.background_white, appStyles.card_boxShadow)}>
                  <Legend text="Refund" type="medium" />
                  <div className={appStyles.card__row}>
                    <div className={classNames(appStyles.card__label, appStyles.text_gabBlue, styles.refundLabel)}>Maximum net amount to be refunded</div>
                    <div className={classNames(appStyles.card__value, appStyles.text_oxfordBlue, styles.refundValue)}>
                      {utils.currency.formatNumberAsCurrency(order.storeId, maxRefundAmount)}
                    </div>
                  </div>
                  <div className={classNames(appStyles.form__row, styles.refund)}>
                    <Input
                      elementId="refundAmount"
                      inputType="number"
                      labelText=""
                      labelHidden={true}
                      name="refundAmount"
                      placeholder="Enter refund amount excluding tax"
                      handleChange={handleInput}
                      value={form.refundAmount}
                      validationErrors={form.fieldMessages['refundAmount']}
                    />
                  </div>
                  <div className={appStyles.card__row}>
                    <div>
                      <Button id="buttonRefund" buttonStyle="primary" size="lg" handleClick={requestRefund} inProgress={refundInProgress}>
                        Refund
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Logs section */}
            <div className={appStyles.col_md_12}>
              <div className={classNames(appStyles.card, styles.card, appStyles.background_white, appStyles.card_boxShadow)}>
                <Legend text="Logs" type="medium" />
                <br />

                <div id="logsGrid">
                  {loadingLogsInProgress ? (
                    <Alert alertType="info">Loading details...</Alert>
                  ) : (
                    <Grid data={grid.getData()} filterable={false} pageable={false} total={grid.getTotal()} sortable={false} resizable={true}>
                      {ColumnHelper.getGridColumns(
                        [
                          {
                            field: '',
                            title: 'View',
                            dataType: 'icon',
                            size: 'xs',
                            columnMenu: false,
                            cell: (props) => (
                              <td className="k-table-td gridColumn_xs gridColumn_button">
                                <Link to={'/admin/logs/' + props.dataItem.logId} className="grid__button">
                                  <Icon icon={Icons.view} iconName={'View log message ' + props.dataItem.logId} color="darkBlue" size="xs" />
                                </Link>
                              </td>
                            )
                          },
                          { field: 'logId', title: 'Log ID', dataType: 'text', size: 'xs' },
                          { field: 'date', title: 'Date', dataType: adminAuth.dateTimeFormat, size: 'md' },
                          { field: 'message', title: 'Message', dataType: 'text', size: 'lg' },
                          { field: 'eventTypeName', title: 'Type', dataType: 'text', size: 'lg' }
                        ],
                        grid.filter
                      )}
                    </Grid>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button id="buttonBack" buttonStyle="secondary" size="lg" handleClick={back}>
          Back
        </Button>
      </BottomButtonContainer>
    </div>
  );
};
