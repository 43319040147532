import { adminContext, apiContext, customerContext, messageContext } from 'App';
import appStyles from 'App.module.scss';
import oupLogo from 'assets/images/oup-logo-blue.svg';
import preloader from 'assets/images/preloader.gif';
import classNames from 'classnames';
import Alert from 'components/Generic/Alert/Alert';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { useStores } from 'hooks/useStores';
import { Administrator } from 'models/responses/administrator.model';
import { FC, useContext, useEffect, useState } from 'react';
import { Link, NavLink as BaseNavLink, NavLinkProps } from 'react-router-dom';
import utils from 'utils/utils';
import { useSwipeable } from 'react-swipeable';
import styles from './Header.module.scss';

const NavLink: FC<NavLinkProps> = ({ ...props }) => (
  <BaseNavLink {...props} className={({ isActive }) => classNames(styles.nav__link, { [styles.active]: isActive })} />
);

// eslint-disable-next-line complexity
export const Header: FC = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [scroll, setScroll] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const isAdminSite = utils.routing.isAdminSite;

  const messages = useContext(messageContext);
  const api = useContext(apiContext);
  const stores = useStores();
  const auth = isAdminSite ? useContext(adminContext) : useContext(customerContext);
  const currentUser = auth.getCurrentUser();

  const swipeToUse = useSwipeable({
    preventScrollOnSwipe: true,
    onSwipedLeft: () => closeMobileNav()
  });

  useEffect(() => {
    init();

    // clean-up on unload
    return () => {
      utils.routing.unsubscribe(subscriptionId);

      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const init = () => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 1);
    });

    window.addEventListener('resize', handleWindowSizeChange);

    // when user navigates to a page, close mobile nav
    const id = utils.routing.subscribe(() => {
      closeMobileNav();
    });
    setSubscriptionId(id);
  };

  const showMobileNav = () => {
    setMobileNavOpen(true);
  };

  const closeMobileNav = () => {
    setMobileNavOpen(false);
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = width <= 768;

  const getCustomerNavigation = (): JSX.Element => {
    if (currentUser) {
      return (
        // authenticated customer nav
        <>
          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} to="/" title="Dashboard" onClick={closeMobileNav}>
              Dashboard
            </NavLink>
          </li>

          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} to="/edit-details" title="Edit details" onClick={closeMobileNav}>
              Details
            </NavLink>
          </li>

          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} to="/password" title="Change password" onClick={closeMobileNav}>
              Password
            </NavLink>
          </li>
        </>
      );
    } else {
      return (
        // non-authenticated customer nav
        <>
          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} to="/login" title="Login" onClick={closeMobileNav}>
              Login
            </NavLink>
          </li>

          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} to="/register" title="Register new account" onClick={closeMobileNav}>
              Register
            </NavLink>
          </li>
        </>
      );
    }
  };

  const getAdminNavigation = (): JSX.Element => {
    if (currentUser) {
      return (
        // authenticated admin nav
        <>
          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} to="/admin" title="Dashboard" onClick={closeMobileNav}>
              Dashboard
            </NavLink>
          </li>

          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} to="/admin/orders" title="Orders" onClick={closeMobileNav}>
              Orders
            </NavLink>
          </li>

          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} to="/admin/reports" title="Reports" onClick={closeMobileNav}>
              Reports
            </NavLink>
          </li>

          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} to="/admin/logs" title="Logs" onClick={closeMobileNav}>
              Logs
            </NavLink>
          </li>

          <li className={styles.nav__item}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              title="Help"
              className={styles.nav__link}
              onClick={closeMobileNav}
              href="https://global.oup.com/academic/help/personal-subscriptions/">
              Help
            </a>
          </li>
        </>
      );
    } else {
      return (
        // non-authenticated admin nav
        <>
          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} to="/admin/login" title="Login" onClick={closeMobileNav}>
              Login
            </NavLink>
          </li>
        </>
      );
    }
  };

  const getCustomerSideNavigation = (): JSX.Element => {
    if (currentUser) {
      return (
        // authenticated customer side nav
        <>
          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} to="/basket" title="Basket" onClick={closeMobileNav}>
              Basket
            </NavLink>
          </li>

          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} id="logoutButton" to="/logout" title="Logout" onClick={closeMobileNav}>
              Logout
            </NavLink>
          </li>

          {/* removed help, as this is in footer */}
          {/* <li className={styles.nav__item}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              title="Help"
              className={styles.nav__link}
              onClick={closeMobileNav}
              href="https://global.oup.com/academic/help/personal-subscriptions/">
              Help
            </a>
          </li> */}

          {/* <li>
            <p className={styles.nav__helloMessage} id="helloMessage">
              Hello, {(currentUser as Customer).firstName}
            </p>
          </li> */}
        </>
      );
    } else {
      return (
        // non-authenticated customer side nav
        <>
          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} to="/basket" title="Basket" onClick={closeMobileNav}>
              Basket
            </NavLink>
          </li>

          <li className={styles.nav__item}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              title="Help"
              className={styles.nav__link}
              onClick={closeMobileNav}
              href="https://global.oup.com/academic/help/personal-subscriptions/">
              Help
            </a>
          </li>
        </>
      );
    }
  };

  const getAdminSideNavigation = (): JSX.Element => {
    if (currentUser) {
      return (
        // authenticated admin side nav
        <>
          <li className={styles.nav__item}>
            <NavLink className={styles.nav__link} id="logoutButton" to="/admin/logout" title="Logout" onClick={closeMobileNav}>
              Logout
            </NavLink>
          </li>
          <li>
            <p className={styles.nav__helloMessage} id="helloMessage">
              Hello, {(currentUser as Administrator).displayName}
            </p>
          </li>
        </>
      );
    } else {
      return (
        // non-authenticated admin side nav
        <></>
      );
    }
  };

  const getMobileNumberMissingWarning = (): JSX.Element => {
    if (auth.isValid === false) {
      return (
        <div className={styles.messageBar}>
          <div className={classNames(styles.messageBar__item, appStyles.container)}>
            <Link to="/edit-details">
              <Alert alertType="error">Please click here to update your details before placing an order.</Alert>
            </Link>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <header className={scroll ? styles.header__scrolled : styles.header}>
      {api.requestsInProgress ? (
        <div id="headerLoaderInContent" className={classNames(styles.header__loader, styles.header__loader_content)}>
          <img alt="Loading..." className={styles.preloader} src={preloader} />
        </div>
      ) : null}

      <div className={styles.mobileLogo}>
        {isAdminSite ? (
          <Link to="/admin/">
            <img className={styles.nav__logo} src={oupLogo} alt="OUP logo" />
          </Link>
        ) : (
          <Link to="/">
            <img className={styles.nav__logo} src={oupLogo} alt="OUP logo" />
          </Link>
        )}
      </div>

      <nav {...swipeToUse} className={styles.nav} role="navigation" aria-label="Main">
        {stores.currentMaintenanceMessage && (
          <Alert id="maintenanceAlert" alertType="error" customClass={styles.maintenance}>
            {stores.currentMaintenanceMessage}
          </Alert>
        )}

        <div
          className={classNames(
            styles.mobileNav,
            { [styles.mobileNav_hide]: mobileNavOpen } // set hidden conditionally
          )}>
          <button
            className={styles.mobileNav__button}
            type="button"
            data-toggle="collapse"
            data-target="#navbarContent"
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={showMobileNav}>
            <Icon icon={Icons.menu} color="gabDarkGrey" size="lg" iconName="Navigation" />
            <span className={classNames(appStyles.text_gabDarkGrey, styles.mobileNav__label)}>Menu</span>
          </button>
        </div>

        <div
          id="navbarContent"
          className={classNames(
            appStyles.container,
            styles.nav__container,
            { [styles.nav_show]: mobileNavOpen } // set visible conditionally
          )}>
          <div className={styles.header_left}>
            {api.requestsInProgress ? (
              <div id="headerLoaderInNav" className={classNames(styles.header__loader, styles.header__loader_nav)}>
                <img alt="Loading..." className={styles.preloader} src={preloader} />
              </div>
            ) : null}

            <div className={styles.nav__logoContainer}>
              {isAdminSite ? (
                <Link to="/admin/">
                  <img className={styles.nav__logo} src={oupLogo} alt="OUP logo" />
                </Link>
              ) : (
                <Link to="/">
                  <img className={styles.nav__logo} src={oupLogo} alt="OUP logo" />
                </Link>
              )}
            </div>
          </div>

          <div className={styles.header_left}>
            <ul className={styles.nav__list}>
              {isMobile ? (
                <li className={styles.nav__cross}>
                  <button onClick={closeMobileNav} className={styles.mobileNav__close}>
                    <Icon icon={Icons.cross} color="gabDarkGrey" size="xs" iconName="Close" />
                  </button>
                </li>
              ) : undefined}
              {isAdminSite ? getAdminNavigation() : getCustomerNavigation()}
              {isAdminSite ? getAdminSideNavigation() : getCustomerSideNavigation()}
            </ul>
          </div>
        </div>

        <div
          onClick={closeMobileNav}
          role="navigation"
          className={classNames(
            styles.mobileNav__backdrop,
            { [styles.mobileNav__backdrop_show]: mobileNavOpen } // add mobileNav__backdrop_show class if nav open
          )}
        />
      </nav>

      <div className={styles.header__spacer} />
      {messages.messages.length > 0 && (
        <div className={styles.messageBar}>
          {messages.messages.map((message, index) => (
            <div className={classNames(styles.messageBar__item, appStyles.container)} key={index}>
              <Alert alertType={message.type}>{message.text}</Alert>
              <button className={styles.messageBar__itemClose} onClick={() => messages.removeMessage(message.id)}>
                <Icon icon={Icons.cross} iconName="Close" color="gabDarkGrey" size="xs" />
              </button>
            </div>
          ))}
        </div>
      )}

      {getMobileNumberMissingWarning()}
    </header>
  );
};
