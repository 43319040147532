import { Grid } from '@progress/kendo-react-grid';
import { IntlProvider } from '@progress/kendo-react-intl';
import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { CheckBox, CheckBoxHelper } from 'components/Generic/FormElements/CheckBox/CheckBox';
import { Combo, ComboBoxHelper } from 'components/Generic/FormElements/ComboBox/ComboBox';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { customerContext, messageContext } from 'App';
import { ClearBasketForm } from 'models/forms/clearBasketForm.model';
import { CreateOrderForm } from 'models/forms/createOrderForm.model';
import { CreatePaymentSessionForm } from 'models/forms/createPaymentSessionForm.model';
import { AppError } from 'models/generic/appError.model';
import { ListItem } from 'models/generic/listItem.model';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLookups } from 'hooks/useLookups';
import constants from 'utils/constants';
import utils from 'utils/utils';
import { useOrders } from 'hooks/useOrders';
import { useBasket } from 'hooks/useBasket';
import { useStores } from 'hooks/useStores';
import { useGrid } from 'hooks/useGrid';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import { ListView, ListViewItemProps } from '@progress/kendo-react-listview';
import { Input, InputHelper } from 'components/Generic/FormElements/Input/Input';
import classNames from 'classnames';
import styles from './Basket.module.scss';

// eslint-disable-next-line complexity
export const Checkout: FC = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState(new CreatePaymentSessionForm());
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [taxLoadingInProgress, setTaxLoadingInProgress] = useState(false);
  const [goToPaymentInProgress, setGoToPaymentInProgress] = useState(false);
  const [orderCreated, setOrderCreated] = useState(false);
  const [showLocalSiteLink, setShowLocalSiteLink] = useState(false);
  const [showBasketAdjustedMessage, setShowBasketAdjustedMessage] = useState(false);
  const [createOrderErrorMessage, setCreateOrderErrorMessage] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [locale, setLocale] = useState('en-GB');
  const [universities, setUniversities] = useState<ListItem[]>([]);
  const [otherUniversity, setOtherUniversity] = useState<ListItem | null>(null);
  const [dateFormat, setDateFormat] = useState<'dateUk' | 'dateUs'>('dateUk');
  const [currencyType, setCurrencyType] = useState<'currencyGbp' | 'currencyUsd'>('currencyGbp');
  const [width, setWidth] = useState<number>(window.innerWidth);

  const grid = useGrid();
  const messages = useContext(messageContext);
  const orders = useOrders();
  const lookups = useLookups();
  const basket = useBasket();
  const stores = useStores();
  const customerAuth = useContext(customerContext);
  const currentUser = customerAuth.getCurrentUser();
  let currentBasket = basket.currentBasket;

  useEffect(() => {
    init();

    // clean-up on unload
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const init = async () => {
    window.scrollTo(0, 0);

    window.addEventListener('resize', handleWindowSizeChange);

    setLocale(utils.currency.getLocale(currentBasket.storeId));

    // create order from basket and apply tax
    createOrder();

    form.basketId = currentBasket.basketId;

    // set date format based on current user's country
    if (currentUser && currentUser.address) {
      const dateFormat = currentUser.address.countryId === constants.usCountryId ? 'dateUs' : 'dateUk';
      setDateFormat(dateFormat);
    }

    // set currency
    setCurrencyType(utils.currency.getCurrencyType(currentBasket.storeId));

    let allUniversities = await lookups.getUniversitiesAsListItems();
    const otherUniversity = allUniversities.find((u) => u.id === 1) as ListItem;
    allUniversities = allUniversities.filter((u) => u.id > 1);

    setUniversities(allUniversities);
    setOtherUniversity(otherUniversity);

    grid.makeAccessible(false);
  };

  const handleComboBox = (fieldName: string, value: number | string | boolean | null) => {
    setForm(ComboBoxHelper.baseHandleNumber(fieldName, value, form));
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setForm(InputHelper.baseHandleString(e, form));
  };

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    setForm(CheckBoxHelper.baseHandleBool(e, form));
  };

  const createOrder = async () => {
    if (currentBasket.basketId) {
      const form = new CreateOrderForm(currentBasket.basketId);

      setTaxLoadingInProgress(true);
      const result = await basket.createOrder(form);
      setTaxLoadingInProgress(false);
      if (result instanceof AppError) {
        // if error mentions local site then show a link
        if (result.fieldMessages['basketId'] && result.fieldMessages['basketId'][0].includes('local site')) {
          setShowLocalSiteLink(true);
        } else {
          if (result.fieldMessages['basketId'] && result.fieldMessages['basketId'][0]) {
            setCreateOrderErrorMessage(result.fieldMessages['basketId'][0]);
          } else {
            messages.addErrorsFromFieldMessages(result.fieldMessages);
          }
        }
      } else {
        if (currentBasket.storeId !== result.storeId) {
          setShowBasketAdjustedMessage(true);
        }
        currentBasket = result;

        setLocale(utils.currency.getLocale(currentBasket.storeId));
        setCurrencyType(utils.currency.getCurrencyType(currentBasket.storeId));
        setOrderCreated(true);
      }
    } else {
      navigate('/basket');
    }
  };

  const goToPayment = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitAttempted(true);

    if (termsAccepted) {
      if (currentBasket.orderId) {
        setGoToPaymentInProgress(true);
        const result = await orders.createPaymentSession(form);
        if (result instanceof AppError) {
          form.fieldMessages = result.fieldMessages;
          setForm(form);
          setGoToPaymentInProgress(false);
        } else {
          // reset global judo object before going to payment page
          utils.judopay.resetJudo();

          navigate('/payment', { state: result });
        }
      } else {
        messages.addErrorAsString('Order ID is missing');
      }
    }
  };

  const goToLocalSite = async () => {
    // get ID of the other store that the current user is using
    const storeId = +currentBasket.storeId === constants.stores.subscriptionsUk ? constants.stores.subscriptionsUs : constants.stores.subscriptionsUk;

    // clear basket and then redirect user
    const request = new ClearBasketForm(currentBasket.basketId as number);
    const result = await basket.clearBasket(request);
    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      // redirect user to local site
      const store = await stores.getStoreById(storeId);
      if (store) {
        window.location.href = store.storeUrl;
      }
    }
  };

  const acceptTerms = (event: ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  const backToBasket = () => {
    navigate('/basket');
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = width <= 768;

  const MobileCheckoutRender = (props: ListViewItemProps) => (
    <>
      <div>
        <div>
          <p>
            <b>Title</b>
          </p>
          <p>{props.dataItem.productName}</p>
        </div>
        <div className={appStyles.mobile__twoColumn}>
          <div className={appStyles.mobile__halfWidth}>
            <p>
              <b>Price</b>
            </p>
            <p>
              {currentBasket.currencySymbol}
              {props.dataItem.price}
            </p>
          </div>
          <div className={appStyles.mobile__halfWidth}>
            <p>
              <b>Tax</b>
            </p>
            <p>
              {currentBasket.currencySymbol}
              {props.dataItem.tax}
            </p>
          </div>
        </div>
        <div className={appStyles.mobile__twoColumn}>
          <div className={appStyles.mobile__halfWidth}>
            <p>
              <b>Start date</b>
            </p>
            <p>{props.dataItem.startDate ? props.dataItem.startDate.toLocaleDateString() : null}</p>
          </div>
          <div className={appStyles.mobile__halfWidth}>
            <p>
              <b>Expiry date</b>
            </p>
            <p>{props.dataItem.endDate ? props.dataItem.endDate.toLocaleDateString() : null}</p>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className={appStyles.container} id="Checkout">
      <div className={appStyles.pageHeading}>
        <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
          <Icon icon={Icons.basket} size="heading" color="darkBlue" iconName="Basket" customClass={appStyles.heading__icon} />
          <h1 className={appStyles.heading__text}>Checkout</h1>
        </div>
      </div>

      {taxLoadingInProgress ? <Alert alertType="info">Please wait while tax is being calculated...</Alert> : null}

      {showBasketAdjustedMessage ? <Alert alertType="warning">Please note the basket has been adjusted for your local store price and currency</Alert> : null}

      {currentBasket.items.length === 0 && (
        <div className={appStyles.row}>
          <div className={appStyles.col_md_12}>
            <h2>Your basket is empty.</h2>
          </div>
        </div>
      )}

      {createOrderErrorMessage ? <Alert alertType="error">{createOrderErrorMessage}</Alert> : null}

      {isMobile ? (
        <ListView data={currentBasket.items} item={MobileCheckoutRender} className={styles.listView} />
      ) : (
        <div className={appStyles.row}>
          <div className={appStyles.col_md_12}>
            <div id="mainGrid">
              {currentBasket.items.length > 0 && (
                <IntlProvider locale={locale}>
                  <Grid data={currentBasket.items} filterable={false} resizable={true}>
                    {ColumnHelper.getGridColumns([
                      { field: 'productName', title: 'Title', dataType: 'text', size: 'lg' },
                      { field: 'price', title: 'Price', dataType: currencyType, size: 'xs' },
                      { field: 'tax', title: 'Tax', dataType: currencyType, size: 'xs' },
                      { field: 'startDate', title: 'Start date', dataType: dateFormat, size: 'sm' },
                      { field: 'endDate', title: 'Expiry date', dataType: dateFormat, size: 'sm' }
                      // { field: 'autoRenew', title: 'Auto renew', dataType: 'boolean', size: 'sm' }
                    ])}
                  </Grid>
                </IntlProvider>
              )}
            </div>
          </div>
        </div>
      )}

      {currentBasket.hasSubscriptionsStartingIn3Months && (
        <Alert alertType="error">
          Subscriptions starting more than 3 months in the future can't be renewed at this point. You may attempt to renew closer to expiry date.
        </Alert>
      )}

      {currentBasket.subTotal > 0 && (
        <h2 id="subtotal" className={styles.basket__subHeader}>
          Subtotal: {currentBasket.currencySymbol}
          {currentBasket.subTotal.toFixed(2)}
        </h2>
      )}

      {orderCreated && (
        <>
          <h2 id="tax" className={styles.basket__subHeader}>
            Tax: {currentBasket.currencySymbol}
            {currentBasket.tax.toFixed(2)}
          </h2>

          <h2 id="total" className={styles.basket__subHeader}>
            Total: {currentBasket.currencySymbol}
            {currentBasket.totalWithTax.toFixed(2)}
          </h2>
        </>
      )}

      <form>
        {currentBasket.requireUniversity && (
          <div className={appStyles.form__row}>
            <div className={appStyles.col_md_12}>
              <Legend type="large" text="Please select your university" />
              <Combo
                elementId="universityId"
                labelText="Please enter 3 or more characters and select from the list"
                name="universityId"
                data={universities}
                additionalItem={otherUniversity}
                placeholder="Search for university..."
                selectPlaceholder="Select university..."
                value={form.universityId}
                handleChange={handleComboBox}
                validationErrors={form.fieldMessages['universityId']}
                disablePlaceholder={true}
              />
            </div>
          </div>
        )}
        {currentBasket.storeId === constants.stores.ebooksUs || currentBasket.storeId === constants.stores.ebooksUk ? (
          <div className={appStyles.form__row}>
            <div className={appStyles.col_md_12}>
              <Legend type="large" text="Is this order for someone else?" />
              <CheckBox
                customLabelClass={appStyles.form__label}
                name="onBehalfOf"
                options={[new ListItem(true, 'Select if this order is for someone else.')]}
                selectedOptions={[form.onBehalfOf]}
                handleChange={handleCheckBox}
              />

              {form.onBehalfOf && (
                <fieldset>
                  <Legend type="small" text="Please enter details of the person this purchase is for" />
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="onBehalfOfFirstName"
                      inputType="text"
                      labelText="First name"
                      name="onBehalfOfFirstName"
                      placeholder="First name"
                      handleChange={handleInput}
                      value={form.onBehalfOfFirstName}
                      autocomplete="given-name"
                      validationErrors={form.fieldMessages['onBehalfOfFirstName']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="onBehalfOfLastName"
                      inputType="text"
                      labelText="Last name"
                      name="onBehalfOfLastName"
                      placeholder="Last name"
                      handleChange={handleInput}
                      value={form.onBehalfOfLastName}
                      autocomplete="family-name"
                      validationErrors={form.fieldMessages['onBehalfOfLastName']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="onBehalfOfEmail"
                      inputType="text"
                      labelText="Email address"
                      name="onBehalfOfEmail"
                      placeholder="Email address"
                      handleChange={handleInput}
                      value={form.onBehalfOfEmail}
                      autocomplete="email"
                      validationErrors={form.fieldMessages['onBehalfOfEmail']}
                    />
                  </div>
                </fieldset>
              )}
            </div>
          </div>
        ) : null}
        <div className={appStyles.form__row}>
          <div className={appStyles.col_md_12}>
            <Legend type="large" text="Terms & conditions" />
            <CheckBox
              name="termsAccepted"
              options={[
                new ListItem(
                  true,
                  'By ticking this box you acknowledge that you have agreed to the <a target="_blank" class="' +
                    appStyles.inline +
                    // eslint-disable-next-line max-len
                    '" rel="noopener noreferrer" title="Terms and Conditions" href="http://www.oup.com/online/subscribe/catalogue/terms/">Terms & conditions</a>.'
                )
              ]}
              selectedOptions={[termsAccepted]}
              handleChange={acceptTerms}
              validationErrors={submitAttempted && termsAccepted === false ? ['You must agree to our terms and conditions before you place your order.'] : []}
            />
          </div>
        </div>

        {showLocalSiteLink ? (
          <div className={appStyles.form__row}>
            <div className={appStyles.col_md_12}>
              <Alert alertType="error">
                We're sorry, but customers from your country should place orders from your local site.
                <br />
                <br />
                <Link to="/checkout" title="Go to local OUP site" onClick={goToLocalSite}>
                  Click here to go to your local OUP site.
                </Link>
              </Alert>
            </div>
          </div>
        ) : null}
      </form>

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button id="buttonBack" buttonStyle="secondary" size="md" handleClick={backToBasket}>
          Back to basket
        </Button>

        {
          /* enable payment button once tax and total are calculated and order is created */
          orderCreated && currentBasket.hasSubscriptionsStartingIn3Months === false && (
            <Button id="buttonPayment" buttonStyle="primary" size="md" handleClick={goToPayment} inProgress={goToPaymentInProgress}>
              Go to payment
            </Button>
          )
        }
      </BottomButtonContainer>
    </div>
  );
};
