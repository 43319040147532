import { Grid } from '@progress/kendo-react-grid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { useGrid } from 'hooks/useGrid';
import { customerContext, messageContext } from 'App';
import { AppError } from 'models/generic/appError.model';
import { FC, useContext, useEffect, useState } from 'react';
import constants from 'utils/constants';
import { useEbooks } from 'hooks/useEbooks';
import { CustomerEbook } from 'models/responses/customerEbook.model';
import Alert from 'components/Generic/Alert/Alert';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { ListView, ListViewItemProps } from '@progress/kendo-react-listview';
import styles from './Ebooks.module.scss';

export const Ebooks: FC = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dateFormat, setDateFormat] = useState<'dateUk' | 'dateUs'>('dateUk');
  const [redirectInProgress, setRedirectInProgress] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const grid = useGrid<CustomerEbook>([{ field: 'startDate', dir: 'asc' }]);
  const messages = useContext(messageContext);
  const ebooks = useEbooks();
  const customerAuth = useContext(customerContext);
  const currentUser = customerAuth.getCurrentUser();

  useEffect(() => {
    init();

    // clean-up on unload
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const init = async () => {
    window.addEventListener('resize', handleWindowSizeChange);

    // show all e-books on a single page
    grid.setTake(Number.MAX_VALUE);

    await loadData();
    grid.makeAccessible(false);

    // set date format based on current user's country
    if (currentUser && currentUser.address) {
      const format = currentUser.address.countryId === constants.usCountryId ? 'dateUs' : 'dateUk';
      setDateFormat(format);
    }
  };

  const loadData = async () => {
    const result = await ebooks.getCustomerEbooks();
    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      grid.setData(result);
      setDataLoaded(true);
    }
  };

  const goToVitalSource = async () => {
    setRedirectInProgress(true);

    const url = await ebooks.getVitalSourceRedirectUrl();
    if (url) {
      window.location.href = url;
    }
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = width <= 768;

  const MobileEbooksRender = (props: ListViewItemProps) => (
    <>
      {props.index === 0 ? <hr /> : null}

      <div className="k-listview-item row p-2 border-bottom align-middle">
        <span className={styles.listViewItemTitle}>{props.dataItem.title}</span>
        <span className={styles.listViewItemDates}>
          (from {props.dataItem.startDate.toLocaleDateString()} to {props.dataItem.endDate.toLocaleDateString()})
        </span>
      </div>

      <hr />
    </>
  );

  return dataLoaded === false ? (
    // loading
    <Alert alertType="info">Loading e-books...</Alert>
  ) : grid.allData.length === 0 ? (
    // no data found
    <p>You have no e-books.</p>
  ) : (
    // data loaded
    <>
      <p>Below are your active e-books, which you can access from the VitalSource bookshelf.</p>

      {isMobile ? (
        <>
          <p>Below are your active e-books, which you can access from the VitalSource bookshelf.</p>
          <ListView data={grid.getData()} item={MobileEbooksRender} className={styles.listView} />
        </>
      ) : (
        <div id="mainEbooksGrid" className={styles.ebooksGrid}>
          <Grid data={grid.getData()} filterable={false} resizable={true}>
            {ColumnHelper.getGridColumns([
              { field: 'title', title: 'Title', dataType: 'text', size: 'lg' },
              { field: 'startDate', title: 'Start date', dataType: dateFormat, size: 'sm' },
              { field: 'endDate', title: 'Expiry date', dataType: dateFormat, size: 'sm' }
            ])}
          </Grid>
        </div>
      )}

      <Button
        id="goToVitalSourceButton"
        buttonType="button"
        buttonStyle="secondary"
        size="lg"
        title="Go to your boohshelf"
        handleClick={goToVitalSource}
        inProgress={redirectInProgress}>
        Go to your bookshelf
      </Button>
    </>
  );
};
