import appStyles from 'App.module.scss';
import classNames from 'classnames';
import Alert from 'components/Generic/Alert/Alert';
import { ValidationArea } from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { ListItem } from 'models/generic/listItem.model';
import { ChangeEvent, FC, useState } from 'react';

interface IComboBoxProps {
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   * @property {string} labelText Set the text to appear in the label
   */
  labelText: string;
  /**
   * @property {string} elementId Set the ID attribute
   */
  elementId: string;
  /**
   * @property {string | number | boolean | null} value Set the value attribute
   */
  value: string | number | boolean | null;
  /**
   * @property {string} placeholder Set the placeholder
   */
  placeholder: string;
  /**
   * @property {string} selectPlaceholder Set the select placeholder option
   */
  selectPlaceholder: string;
  /**
   * @property {boolean} disablePlaceholder Set the placeholder option as disabled
   */
  disablePlaceholder?: boolean;
  /**
   * @property {ListItem[]} data Set the list of available options
   */
  data: ListItem[];
  /**
   * @property {ListItem | null} additionalItem Set the additional item to appear at the bottom of the list
   */
  additionalItem: ListItem | null;
  /**
   * @property {(fieldName: string, value: number | string | boolean | null) => void} handleChange Set the action onChange
   */
  handleChange: (fieldName: string, value: number | string | boolean | null) => void;
  /**
   * @property {string} customSelectClass Optional. Use a custom class on the select element - use styles from the current component or appStyles
   */
  customSelectClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {string[]} validationErrors Optional. Any validation messages related to this field
   */
  validationErrors?: string[];
  /**
   * @property {boolean} Optional. required Set the field to required
   */
  required?: boolean;
}

/**
 * @description Add a select element with a label. Optional properties: customSelectClass, customLabelClass,
 * disablePlaceholder, required, validationError
 */
// eslint-disable-next-line complexity
export const Combo: FC<IComboBoxProps> = (props: IComboBoxProps) => {
  const isValueValid = () => props.data.find((i) => i.id === props.value);

  const [search, setSearch]: [string, (search: string) => void] = useState('');
  const [hideSelect, setHideSelect]: [boolean, (hideSelect: boolean) => void] = useState(Boolean);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setHideSelect(false);
    props.handleChange(props.name, null);
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value.toString();
    let item: ListItem | undefined;

    if (props.additionalItem?.id?.toString() === id) {
      // select 'other'
      item = props.additionalItem;
    } else {
      item = props.data.find((i) => i.id.toString() === id);
    }

    if (item) {
      setSearch(item.text);
      setHideSelect(true);
      props.handleChange(props.name, item.id);
    }
  };

  let selectCount = 0;
  {
    props.data.forEach((element) => {
      if (element?.text?.toLowerCase()?.includes(search.toLowerCase())) {
        selectCount = selectCount + 1;
      }
    });
  }

  let noResultsFound = false;
  if (search.length > 0 && selectCount <= 0 && search !== props.additionalItem?.text) {
    noResultsFound = true;
  }

  let displayCount = 0;

  return (
    <>
      <label
        htmlFor={props.name + '_input'}
        className={classNames(
          appStyles.form__label,
          { [appStyles.form__label_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
          { [props.customLabelClass as string]: props.customLabelClass } // add custom class if defined
        )}>
        {props.labelText}
      </label>
      <input
        id={props.elementId + '_input'}
        name={props.name + '_input'}
        value={search === '' ? '' : search}
        placeholder={props.placeholder}
        onChange={handleInput}
        className={classNames(
          appStyles.form__input,
          appStyles.comboBox__input,
          appStyles.form__input_select,
          { [appStyles.select_placeholder]: props.value === null || props.value === undefined }, // add placeholder class if no value
          { [appStyles.form__input_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
          { [props.customSelectClass as string]: props.customSelectClass } // add custom class if defined
        )}
        aria-label={props.labelText}></input>

      <select
        id={props.elementId}
        name={props.name}
        size={selectCount <= 11 ? selectCount + 2 : 12}
        className={
          search.length <= 2 || hideSelect === true
            ? classNames(appStyles.hidden)
            : classNames(
                appStyles.comboBox_form__select,
                { [appStyles.select_placeholder]: props.value === null || props.value === undefined }, // add placeholder class if no value
                { [appStyles.form__input_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
                { [props.customSelectClass as string]: props.customSelectClass } // add custom class if defined
              )
        }
        aria-label={props.labelText}
        onBlur={undefined}
        onChange={handleSelect}
        value={props.value !== null && isValueValid() ? props.value.toString() : ''}
        required={props.required || undefined}
        aria-required={props.required || undefined}>
        <option className={appStyles.comboBox__placeholder}>{props.selectPlaceholder}</option>

        {props.data.map((option) => {
          if (option?.text?.toLowerCase()?.includes(search.toLowerCase()) && search.length >= 3) {
            displayCount++;

            // display first 10 items only
            if (displayCount <= 10) {
              return (
                <option
                  key={option.id as string}
                  id={option.id as string}
                  value={option.id as string}
                  label={option.text as string}
                  aria-selected={option.id === props.value ? 'true' : 'false'}
                  className={appStyles.comboBox__option}>
                  {option.text}
                </option>
              );
            }
          }
        })}

        {props.additionalItem && search.length >= 3 && (
          // display additional 'Other' item (if defined)
          <option
            key={props.additionalItem.id as string}
            id={props.additionalItem.id as string}
            value={props.additionalItem.id as string}
            label={props.additionalItem.text as string}
            aria-selected={props.additionalItem.id === props.value ? 'true' : 'false'}
            className={appStyles.comboBox__option}>
            {props.additionalItem.text}
          </option>
        )}
      </select>

      {noResultsFound && (
        <Alert customClass={appStyles.combo__noResults} alertType="error">
          No results found...
        </Alert>
      )}

      <ValidationArea sectionId={props.elementId} validationErrors={props.validationErrors} />
    </>
  );
};

export class ComboBoxHelper {
  static baseHandleNumber<T>(fieldName: string, value: number | string | boolean | null, obj: T): T {
    obj = {
      ...obj,
      [fieldName]: value
    };

    return obj;
  }
}
