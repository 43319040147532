import appStyles from 'App.module.scss';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { Input, InputHelper } from 'components/Generic/FormElements/Input/Input';
import { FormWrapper } from 'components/Generic/FormWrapper/FormWrapper';
import { customerContext, messageContext } from 'App';
import { ResetPasswordForm } from 'models/forms/resetPasswordForm.model';
import { AppError } from 'models/generic/appError.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import validation from 'utils/validation';

export const ResetPassword: FC = () => {
  const params = useParams();

  const [form, setForm] = useState(new ResetPasswordForm());
  const [inProgress, setInProgress] = useState(false);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [formVisible, setFormVisible] = useState(true);

  const messages = useContext(messageContext);
  const customerAuth = useContext(customerContext);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setValidationRules(await customerAuth.getResetPasswordValidationRules());
    form.token = params.token || '';
    setForm(form);
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = InputHelper.baseHandleString(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const validate = (form: ResetPasswordForm): boolean => validation.validateForm(form, validationRules, setForm);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitAttempted(true);

    const isValid = validate(form);
    if (isValid) {
      setInProgress(true);
      const result = await customerAuth.resetPassword(form);
      if (result instanceof AppError) {
        form.fieldMessages = result.fieldMessages;
        setForm(form);

        // token validation messages are not displayed on the form, so push them to message store
        messages.addErrorsFromArray(result.fieldMessages['token']);
      } else {
        messages.addSuccessAsString('Your password has been changed.');
        setFormVisible(false);
      }

      setInProgress(false);
    }
  };

  return (
    <div className={appStyles.form__container} id="ResetPasswordForm">
      <FormWrapper formTitle="OUP Online Personal Subscriptions" onSubmit={submit} className={appStyles.form__wrapper}>
        {formVisible && (
          <>
            <div className={appStyles.form__row}>
              <Input
                elementId="password"
                inputType="password"
                labelText="Enter your password"
                name="password"
                placeholder="Password"
                handleChange={handleInput}
                value={form.password}
                autocomplete="new-password"
                validationErrors={form.fieldMessages['password']}
              />
            </div>

            <div className={appStyles.form__row}>
              <Input
                elementId="confirmPassword"
                inputType="password"
                labelText="Confirm your password"
                name="confirmPassword"
                placeholder="Confirm password"
                handleChange={handleInput}
                value={form.confirmPassword}
                autocomplete="new-password"
                validationErrors={form.fieldMessages['confirmPassword']}
              />
            </div>
            <div className={appStyles.form__row}>
              <Button id="submitButton" buttonType="submit" buttonStyle="primary" size="lg" inProgress={inProgress}>
                Click here to proceed
              </Button>
            </div>
          </>
        )}

        <div className={appStyles.form__row}>
          <p className={appStyles.form__wrapperText}>
            <Link to="/register" title="Register">
              Click here to register a new account.
            </Link>
          </p>
          <p className={appStyles.form__wrapperText}>
            <Link to="/login" title="Login">
              Back to login
            </Link>
          </p>
        </div>
      </FormWrapper>
    </div>
  );
};
