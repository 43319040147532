import { Grid } from '@progress/kendo-react-grid';
import { IntlProvider } from '@progress/kendo-react-intl';
import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { Radio } from 'components/Generic/FormElements/Radio/Radio';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { customerContext, messageContext } from 'App';
import { AddItemToBasketForm } from 'models/forms/addItemToBasketForm.model';
import { ClearBasketForm } from 'models/forms/clearBasketForm.model';
import { RemoveItemFromBasketForm } from 'models/forms/removeItemFromBasketForm.model';
import { UpdateBasketItemForm } from 'models/forms/updateBasketItemForm.model';
import { AppError } from 'models/generic/appError.model';
import { ListItem } from 'models/generic/listItem.model';
import { BasketItem } from 'models/responses/basketItem.model';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import utils from 'utils/utils';
import { useBasket } from 'hooks/useBasket';
import { useStores } from 'hooks/useStores';
import { useGrid } from 'hooks/useGrid';
import { ListView, ListViewItemProps } from '@progress/kendo-react-listview';
import classNames from 'classnames';
import styles from './Basket.module.scss';

// eslint-disable-next-line complexity
export const BasketPage: FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [locale, setLocale] = useState('en-GB');
  const [autoRenewSelected, setAutoRenewSelected] = useState<boolean | null>(null);
  const [currencyType, setCurrencyType] = useState<'currencyGbp' | 'currencyUsd'>('currencyGbp');
  const [width, setWidth] = useState<number>(window.innerWidth);

  const grid = useGrid();
  const messages = useContext(messageContext);
  const stores = useStores();
  const basket = useBasket();
  const customerAuth = useContext(customerContext);
  const currentUser = customerAuth.getCurrentUser();
  const currentBasket = basket.currentBasket;

  useEffect(() => {
    init();

    // clean-up on unload
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const init = async () => {
    window.scrollTo(0, 0);

    window.addEventListener('resize', handleWindowSizeChange);

    setLocale(utils.currency.getLocale(currentBasket.storeId));

    // set currency
    setCurrencyType(utils.currency.getCurrencyType(currentBasket.storeId));

    // if user is logged in then check if has all required details populated
    const needDetails = currentUser && customerAuth.needsDetailsPopulated(currentUser);
    if (needDetails) {
      // if no then redirect to edit details page
      messages.addWarningAsString('Please complete your profile before continuing to your basket.');
      navigate('/edit-details');
    } else {
      const query = params.query || '';
      if (query) {
        // clear query from URL
        navigate('/basket');

        // add item to basket
        const addRequest = new AddItemToBasketForm(query, currentBasket.basketId);
        addItemToBasket(addRequest);
      }

      grid.makeAccessible(false);
    }
  };

  const handleRadioButton = async (e: ChangeEvent<HTMLInputElement>, basketItem: BasketItem) => {
    const value = e.target.value === 'true' ? true : e.target.value === 'false' ? false : null;

    const request = new UpdateBasketItemForm();
    request.basketId = currentBasket.basketId;
    request.basketItemId = basketItem.basketItemId;
    request.autoRenew = value;

    const result = await basket.updateBasketItem(request);
    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      if (autoRenewSelected !== null) {
        checkAutoRenewSelected();
      }
    }
  };

  const addItemToBasket = async (request: AddItemToBasketForm) => {
    // validate request and add item to basket if valid
    const result = await basket.addItemToBasket(request);
    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      setLocale(utils.currency.getLocale(currentBasket.storeId));
      setCurrencyType(utils.currency.getCurrencyType(currentBasket.storeId));
    }
  };

  const removeItemFromBasket = async (basketItemId: number) => {
    const request = new RemoveItemFromBasketForm(currentBasket.basketId as number, basketItemId);
    const result = await basket.removeItemFromBasket(request);
    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      if (autoRenewSelected !== null) {
        checkAutoRenewSelected();
      }
    }
  };

  const clear = async () => {
    const request = new ClearBasketForm(currentBasket.basketId as number);
    const result = await basket.clearBasket(request);
    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    }
  };

  const checkAutoRenewSelected = (): boolean => {
    let selected = true;

    currentBasket.items.forEach((item) => {
      if (item.autoRenew === null) {
        selected = false;
      }
    });

    setAutoRenewSelected(selected);

    return selected;
  };

  const checkout = () => {
    const selected = checkAutoRenewSelected();
    if (selected) {
      navigate('/checkout');
    }
  };

  const backToShop = () => {
    window.location.href = currentBasket.returnUrl;
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = width < 768;

  // eslint-disable-next-line complexity
  const MobileBasketRender = (props: ListViewItemProps) => (
    <>
      <div>
        <p>
          <b>Title</b>
        </p>
        <p>{props.dataItem.productName}</p>
      </div>
      <div className={appStyles.mobile__twoColumn}>
        <div className={appStyles.mobile__halfWidth}>
          <p>
            <b>Period</b>
          </p>
          <p>{props.dataItem.periodName}</p>
        </div>
        <div className={appStyles.mobile__halfWidth}>
          <p>
            <b>Price</b>
          </p>
          <p>
            {currentBasket.currencySymbol}
            {props.dataItem.price}
          </p>
        </div>
      </div>

      {currentBasket.hasAutoRenewableItems ? (
        <div>
          <p>
            <b>Auto renew</b>
          </p>
          {props.dataItem.autoRenewable ? (
            <Radio
              name={`renew_${props.dataItem.basketItemId}`}
              options={[new ListItem(true, 'Yes'), new ListItem(false, 'No')]}
              selectedOption={props.dataItem.autoRenew}
              handleChange={(event) => {
                handleRadioButton(event, props.dataItem);
              }}
            />
          ) : (
            <p>This subscription cannot be auto renewed</p>
          )}
        </div>
      ) : null}

      <hr />

      {autoRenewSelected === false && (
        <Alert alertType="error">Please select if you'd like any of the subscriptions in your basket to automatically renew on expiry.</Alert>
      )}

      {currentBasket.subTotal > 0 && (
        <h2 id="subtotal" className={styles.basket__subHeader}>
          Subtotal: {currentBasket.currencySymbol}
          {currentBasket.subTotal.toFixed(2)}
        </h2>
      )}

      <div className={appStyles.row}>
        <div className={appStyles.col_md_12}>
          <p>Any discount code should be entered on the main product page before adding an item to the basket.</p>
        </div>
      </div>

      {currentBasket.hasAutoRenewableItems && (
        <div className={appStyles.row}>
          <div className={appStyles.col_md_12}>
            <p>
              These are the subscriptions in your basket. Before purchasing, please specify whether you'd like the subscriptions to renew automatically on the
              expiry date. You can change this option at any time after placing the order on the Dashboard page.
            </p>
            <p>
              Please note auto renew is not supported when paying with Google Pay or Apple Pay, so with those payment options the purchase will be for the
              initial term only.
            </p>
          </div>
        </div>
      )}

      {currentBasket.items.length > 0 ? (
        <>
          <div className={appStyles.row}>
            <div className={appStyles.col_md_12}>
              <p>Please note tax may be applied at checkout based on your location.</p>
            </div>
          </div>

          <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
            {
              /* show Checkout button only if there is no maintenance and if customer details are valid */
              stores.checkoutEnabled && customerAuth.isValid && (
                <Button id="buttonCheckout" buttonStyle="primary" size="lg" handleClick={checkout}>
                  {currentUser ? 'Checkout' : 'Login and checkout'}
                </Button>
              )
            }
            <Button id="buttonClear" buttonStyle="secondary" size="lg" handleClick={clear}>
              Clear
            </Button>
            <Button id="buttonBack" buttonStyle="secondary" size="lg" handleClick={backToShop}>
              Back to shop
            </Button>
          </BottomButtonContainer>
        </>
      ) : (
        currentBasket.returnUrl && (
          <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
            <Button id="buttonBack" buttonStyle="secondary" size="lg" handleClick={backToShop}>
              Back to shop
            </Button>
          </BottomButtonContainer>
        )
      )}
    </>
  );

  // TODO: consider creating component(s) for sections shared between views
  // return mobile-friendly component for mobile
  if (isMobile) {
    return (
      <>
        <div className={appStyles.container} id="Basket">
          <div className={appStyles.pageHeading}>
            <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
              <Icon icon={Icons.basket} size="heading" color="darkBlue" iconName="Basket" customClass={appStyles.heading__icon} />
              <h1 className={appStyles.heading__text}>Basket</h1>
            </div>
          </div>

          {currentBasket.items.length === 0 ? (
            <div className={appStyles.row}>
              <div className={appStyles.col_md_12}>
                <h2>Your basket is empty.</h2>
              </div>
            </div>
          ) : (
            <ListView data={currentBasket.items} item={MobileBasketRender} className={styles.listView} />
          )}
        </div>
      </>
    );
  } else {
    // dektop grid view
    return (
      <div className={appStyles.container} id="Basket">
        <div className={appStyles.pageHeading}>
          <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
            <Icon icon={Icons.basket} size="heading" color="darkBlue" iconName="Basket" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>Basket</h1>
          </div>
        </div>

        {currentBasket.items.length === 0 && (
          <div className={appStyles.row}>
            <div className={appStyles.col_md_12}>
              <h2>Your basket is empty.</h2>
            </div>
          </div>
        )}
        <div className={appStyles.row}>
          <div className={appStyles.col_md_12}>
            <div id="mainGrid">
              {currentBasket.items.length > 0 && (
                <IntlProvider locale={locale}>
                  <Grid data={currentBasket.items} filterable={false} resizable={true}>
                    {ColumnHelper.getGridColumns([
                      { field: 'productName', title: 'Title', dataType: 'text', size: 'lg' },
                      { field: 'periodName', title: 'Period', dataType: 'text', size: 'md' },
                      { field: 'price', title: 'Price', dataType: currencyType, size: 'xs' },
                      currentBasket.hasAutoRenewableItems
                        ? {
                            field: '',
                            title: 'Auto renew',
                            dataType: 'text',
                            size: 'md',
                            columnMenu: false,
                            cell: (props) => (
                              <td className="k-table-td gridColumn_md gridColumn_button">
                                {props.dataItem.autoRenewable ? (
                                  <Radio
                                    name={`renew_${props.dataItem.basketItemId}`}
                                    options={[new ListItem(true, 'Yes'), new ListItem(false, 'No')]}
                                    selectedOption={props.dataItem.autoRenew}
                                    handleChange={(event) => {
                                      handleRadioButton(event, props.dataItem);
                                    }}
                                  />
                                ) : (
                                  <p>This subscription cannot be auto renewed</p>
                                )}
                              </td>
                            )
                          }
                        : undefined,
                      {
                        field: '',
                        title: 'Remove',
                        dataType: 'icon',
                        size: 'xs',
                        columnMenu: false,
                        cell: (props) => (
                          <td className="k-table-td gridColumn_xs gridColumn_button">
                            <button
                              className="grid__button"
                              onClick={() => {
                                removeItemFromBasket(props.dataItem.basketItemId);
                              }}>
                              <Icon icon={Icons.cross} iconName="Remove this item from basket" color="darkBlue" size="xs" />
                            </button>
                          </td>
                        )
                      }
                    ])}
                  </Grid>
                </IntlProvider>
              )}
            </div>
          </div>
        </div>

        {autoRenewSelected === false && (
          <Alert alertType="error">Please select if you'd like any of the subscriptions in your basket to automatically renew on expiry.</Alert>
        )}

        {currentBasket.subTotal > 0 && (
          <h2 id="subtotal" className={styles.basket__subHeader}>
            Subtotal: {currentBasket.currencySymbol}
            {currentBasket.subTotal.toFixed(2)}
          </h2>
        )}

        <div className={appStyles.row}>
          <div className={appStyles.col_md_12}>
            <p>Any discount code should be entered on the main product page before adding an item to the basket.</p>
          </div>
        </div>

        {currentBasket.hasAutoRenewableItems && (
          <div className={appStyles.row}>
            <div className={appStyles.col_md_12}>
              <p>
                These are the subscriptions in your basket. Before purchasing, please specify whether you'd like the subscriptions to renew automatically on the
                expiry date. You can change this option at any time after placing the order on the Dashboard page.
              </p>
              <p>
                Please note auto renew is not supported when paying with Google Pay or Apple Pay, so with those payment options the purchase will be for the
                initial term only.
              </p>
            </div>
          </div>
        )}

        {currentBasket.items.length > 0 ? (
          <>
            <div className={appStyles.row}>
              <div className={appStyles.col_md_12}>
                <p>Please note tax may be applied at checkout based on your location.</p>
              </div>
            </div>

            <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
              <Button id="buttonBack" buttonStyle="secondary" size="md" customClass={styles.basketMobileButtons} handleClick={backToShop}>
                Back to shop
              </Button>

              <Button id="buttonClear" buttonStyle="secondary" size="md" customClass={styles.basketMobileButtons} handleClick={clear}>
                Clear
              </Button>

              {
                /* show Checkout button only if there is no maintenance and if customer details are valid */
                stores.checkoutEnabled && customerAuth.isValid && (
                  <Button id="buttonCheckout" buttonStyle="primary" size="md" customClass={styles.basketMobileButtons} handleClick={checkout}>
                    {currentUser ? 'Checkout' : 'Login and checkout'}
                  </Button>
                )
              }
            </BottomButtonContainer>
          </>
        ) : (
          currentBasket.returnUrl && (
            <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
              <Button id="buttonBack" buttonStyle="secondary" size="md" customClass={styles.basketMobileButtons} handleClick={backToShop}>
                Back to shop
              </Button>
            </BottomButtonContainer>
          )
        )}
      </div>
    );
  }
};
