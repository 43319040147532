import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridColumn, GridColumnMenuFilter, GridColumnMenuProps } from '@progress/kendo-react-grid';
import classNames from 'classnames';
import { ComponentType } from 'react';
import ReactHtmlParser from 'react-html-parser';

interface IColumn {
  field: string;
  title: string;
  dataType: 'text' | 'dateUk' | 'dateUs' | 'dateAndTimeUk' | 'dateAndTimeUs' | 'decimal' | 'integer' | 'currencyGbp' | 'currencyUsd' | 'boolean' | 'icon';
  size: 'lg' | 'md' | 'sm' | 'xs';
  sortable?: boolean; // defaults to true
  isHtml?: boolean; // defaults to false
  minResizableWidth?: number; // defaults to 40
  columnMenu?: false | ComponentType<GridColumnMenuProps>; // optional
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell?: (props: any) => JSX.Element; // optional
}

export class ColumnHelper {
  private static isColumnActive(field: string, filter: CompositeFilterDescriptor | undefined) {
    return GridColumnMenuFilter.active(field, filter);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static getCellContentsAsHtml(column: IColumn, dataItem: any) {
    return <td className={`gridColumn_${column.size}`}>{ReactHtmlParser(dataItem[column.field])}</td>;
  }

  // eslint-disable-next-line complexity
  private static getFormat(dataType: string): string | undefined {
    let format: string | undefined;

    switch (dataType) {
      case 'dateUk':
        format = '{0:dd/MM/yyyy}';
        break;
      case 'dateUs':
        format = '{0:MM/dd/yyyy}';
        break;
      case 'dateAndTimeUk':
        format = '{0:dd/MM/yyyy HH:mm:ss}';
        break;
      case 'dateAndTimeUs':
        format = '{0:MM/dd/yyyy HH:mm:ss}';
        break;
      case 'integer':
        format = '{0}';
        break;
      case 'decimal':
        format = '{0:n2}';
        break;
      case 'currencyGbp':
        format = '£{0:n2}';
        break;
      case 'currencyUsd':
        // eslint-disable-next-line
        format = '${0:n2}';
        break;
      case 'boolean':
        format = '{0}';
        break;
    }

    return format;
  }

  // eslint-disable-next-line complexity
  private static getFilter(dataType: string): 'text' | 'date' | 'numeric' | 'boolean' | undefined {
    let filter: 'text' | 'date' | 'numeric' | 'boolean' | undefined;

    switch (dataType) {
      case 'text':
        filter = 'text';
        break;
      case 'dateUk':
      case 'dateUs':
      case 'dateAndTimeUk':
      case 'dateAndTimeUs':
        filter = 'date';
        break;
      case 'decimal':
      case 'integer':
      case 'currencyGbp':
      case 'currencyUsd':
        filter = 'numeric';
        break;
      case 'boolean':
        filter = 'boolean';
        break;
      case 'icon':
        filter = undefined;
        break;
    }

    return filter;
  }

  private static getMinResizableWidth(size: string): number {
    let width = 40;

    switch (size) {
      case 'xs':
        width = 40;
        break;
      case 'sm':
        break;
      case 'md':
        width = 80;
        break;
      case 'lg':
        width = 80;
        break;
    }

    return width;
  }

  public static getGridColumns(
    columns: Array<IColumn | undefined>,
    filter?: CompositeFilterDescriptor | undefined,
    columnMenu?: ComponentType<GridColumnMenuProps> // default column menu for all columns (can be overridded by individual columns)
  ): JSX.Element[] {
    const array: JSX.Element[] = [];

    columns.forEach((column) => {
      if (column) {
        array.push(
          <GridColumn
            key={column.field}
            field={column.field}
            className={`gridColumn_${column.size}`}
            headerClassName={classNames(
              `gridColumn_${column.size}`,
              { active: this.isColumnActive(column.field, filter) }, // add active conditionally
              { text_center: column.dataType === 'icon' } // add text_center for icon columns
            )}
            filter={ColumnHelper.getFilter(column.dataType)}
            title={column.title}
            minResizableWidth={ColumnHelper.getMinResizableWidth(column.size)}
            width={column.dataType === 'icon' ? 80 : undefined}
            columnMenu={column.columnMenu === false ? undefined : column.columnMenu ? column.columnMenu : columnMenu}
            format={ColumnHelper.getFormat(column.dataType)}
            sortable={column.sortable === false || column.dataType === 'icon' ? false : true}
            cell={column.cell ? column.cell : column.isHtml ? (props) => ColumnHelper.getCellContentsAsHtml(column, props.dataItem) : undefined}
          />
        );
      }
    });

    return array;
  }
}
