import { Country } from 'models/responses/country.model';
import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class EditCountryForm extends BaseValidatedObject {
  name = '';
  countryCode = '';
  unCode = '';
  enabledForRegistration = true;
  subscriptionsStoreId: number | null = null;
  ebooksStoreId: number | null = null;
  postCodeFormat = '';
  postCodeSample = '';

  constructor(country: Country | null) {
    super();

    if (country) {
      this.name = country.name;
      this.countryCode = country.countryCode;
      this.unCode = country.unCode;
      this.enabledForRegistration = country.enabledForRegistration;
      this.subscriptionsStoreId = country.subscriptionsStoreId;
      this.ebooksStoreId = country.ebooksStoreId;
      this.postCodeFormat = country.postCodeFormat;
      this.postCodeSample = country.postCodeSample;
    }
  }
}
