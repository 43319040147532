import { apiContext } from 'App';
import { UpdateAutoRenewalForm } from 'models/forms/updateAutoRenewalForm.model';
import { AppError } from 'models/generic/appError.model';
import { Order } from 'models/responses/order.model';
import { Subscription } from 'models/responses/subscription.model';
import { useContext } from 'react';

export interface ISubscriptionsHook {
  getSubscriptions: () => Promise<Subscription[]>;
  saveAutoRenewal: (form: UpdateAutoRenewalForm) => Promise<boolean | AppError>;
  saveAutoRenewalAsAdmin: (form: UpdateAutoRenewalForm) => Promise<Order | null>;
}

export function useSubscriptions(): ISubscriptionsHook {
  const api = useContext(apiContext);

  const getSubscriptions = async (): Promise<Subscription[]> => {
    const response = await api.get<Subscription[]>('subscription', 'Loading subscriptions failed.');
    if (response instanceof AppError) {
      return [];
    } else {
      response.forEach((subscription) => {
        subscription.startDate = subscription.startDate ? new Date(subscription.startDate) : '';
        subscription.endDate = subscription.endDate ? new Date(subscription.endDate) : '';
      });
    }

    return response;
  };

  const saveAutoRenewal = async (form: UpdateAutoRenewalForm): Promise<boolean | AppError> => {
    const response = await api.put<boolean>('subscription/autorenewal', form, 'Changing auto renewal preference failed');

    return response;
  };

  const saveAutoRenewalAsAdmin = async (form: UpdateAutoRenewalForm): Promise<Order | null> => {
    let order = null;
    const response = await api.put<Order>('admin/autorenewal', form, 'Changing auto renewal preference failed');

    if (response instanceof AppError) {
    } else {
      response.orderDate = new Date(response.orderDate);
      order = response;

      if (order.statusUpdates) {
        order.statusUpdates.forEach((statusUpdate) => {
          statusUpdate.dateUpdated = new Date(statusUpdate.dateUpdated);
        });
      }
    }

    return order;
  };

  return {
    getSubscriptions,
    saveAutoRenewal,
    saveAutoRenewalAsAdmin
  };
}
