import { BasketItem } from 'models/responses/basketItem.model';
import constants from 'utils/constants';
import utils from 'utils/utils';

export class Basket {
  basketId: number | null = null;
  storeId = 4;
  subTotal = 0;
  tax = 0;
  totalWithTax = 0;
  currencySymbol = '£';
  returnUrl = '';
  orderId: number | null = null;
  requireUniversity = false;
  hasAutoRenewableItems = false;
  hasSubscriptionsStartingIn3Months = false;
  items: BasketItem[] = [];

  constructor() {
    // check if basket saved in cache, if yes, then populate with cached details
    const cachedBasket = utils.session.getFromSession<Basket>(constants.storageKeys.basket);

    if (cachedBasket) {
      // for some reason deserialised object doesn't work with kendo grid (array), so copying value to a new objects
      this.basketId = cachedBasket.basketId;
      this.storeId = cachedBasket.storeId;
      this.orderId = cachedBasket.orderId;
      this.subTotal = cachedBasket.subTotal;
      this.tax = cachedBasket.tax;
      this.totalWithTax = cachedBasket.totalWithTax;
      this.currencySymbol = cachedBasket.currencySymbol;
      this.returnUrl = cachedBasket.returnUrl;
      this.requireUniversity = cachedBasket.requireUniversity;
      this.hasAutoRenewableItems = cachedBasket.hasAutoRenewableItems;
      this.hasSubscriptionsStartingIn3Months = cachedBasket.hasSubscriptionsStartingIn3Months;

      cachedBasket.items.forEach((element) => {
        if (element.startDate !== null) {
          element.startDate = new Date(element.startDate);
        }
        if (element.endDate !== null) {
          element.endDate = new Date(element.endDate);
        }
        this.items.push(element);
      });
    }
  }
}
