import { adminContext } from 'App';
import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';

export const Reports: FC = () => {
  const adminAuth = useContext(adminContext);
  const user = adminAuth.getCurrentUser();
  const defaultStore = user?.dateFormat ?? 'uk'; // set default store based on current admin user's date format ('uk' or 'us') or use default 'uk' value

  return (
    <div className={classNames(appStyles.container, appStyles.dashboard)} id="AdminReports">
      <div className={appStyles.row}>
        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to={`/admin/reports/accounts/${defaultStore}`}>
            <div className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.people} size="xl" color="gabBlue" iconName="Accounts" />
              <p className={appStyles.dashboard__heading}>Accounts</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to={`/admin/reports/subscriptions/${defaultStore}`}>
            <div className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.products} size="xl" color="gabBlue" iconName="Subscriptions" />
              <p className={appStyles.dashboard__heading}>Subscriptions</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to="/admin/reports/gdpr/uk">
            <div className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.people} size="xl" color="gabBlue" iconName="GDPR" />
              <p className={appStyles.dashboard__heading}>GDPR</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to="/admin/reports/trove/uk">
            <div className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.products} size="xl" color="gabBlue" iconName="Trove" />
              <p className={appStyles.dashboard__heading}>Trove</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to={`/admin/reports/ebooks/orders/${defaultStore}`}>
            <div className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.ebooks} size="xl" color="gabBlue" iconName="E-books Orders" />
              <p className={appStyles.dashboard__heading}>E-books Orders</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to={`/admin/reports/ebooks/tax/${defaultStore}`}>
            <div className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.ebooks} size="xl" color="gabBlue" iconName="E-books Tax" />
              <p className={appStyles.dashboard__heading}>E-books Tax</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to={`/admin/reports/ebooks/sales/${defaultStore}`}>
            <div className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.ebooks} size="xl" color="gabBlue" iconName="E-books Sales" />
              <p className={appStyles.dashboard__heading}>E-books Sales</p>
            </div>
          </Link>
        </div>

        <div className={classNames(appStyles.col_lg_3, appStyles.col_md_4, appStyles.col_6)}>
          <Link to={`/admin/reports/ebooks/marketing/${defaultStore}`}>
            <div className={classNames(appStyles.card__dashboard, appStyles.background_cardBlue, appStyles.card__column)}>
              <Icon icon={Icons.ebooks} size="xl" color="gabBlue" iconName="E-books Marketing" />
              <p className={appStyles.dashboard__heading}>E-books Marketing</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
