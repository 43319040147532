import { FieldMessages } from 'models/generic/fieldMessages.model';
import { ValidationRule } from 'models/validation/validationRule.model';

export class BaseValidatedObject {
  validationRules: ValidationRule[] = [];
  fieldMessages: FieldMessages = new FieldMessages();

  addError(fieldName: string, description: string | null = null) {
    this.fieldMessages[0] = [];

    if (!this.fieldMessages[fieldName]) {
      this.fieldMessages[fieldName] = [];
    }

    if (description) {
      this.fieldMessages[fieldName].push(description);
    }
  }
}
