export class PaymentSummary {
  orderId: number | null = null;
  message: string | null = null;
  callback: 'success' | 'error' | null = null;
  method: 'card' | 'google' | 'apple' | null = null;
  callbackJson: string | null = null;

  constructor(orderId: number | null, message: string | null, callback: 'success' | 'error', method: 'card' | 'google' | 'apple', callbackObject: unknown) {
    this.orderId = orderId;
    this.message = message;
    this.callback = callback;
    this.method = method;

    if (callbackObject) {
      this.callbackJson = JSON.stringify(callbackObject);
    }
  }
}
