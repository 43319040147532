import appStyles from 'App.module.scss';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { CheckBox, CheckBoxHelper } from 'components/Generic/FormElements/CheckBox/CheckBox';
import { Input, InputHelper } from 'components/Generic/FormElements/Input/Input';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import { Select, SelectHelper } from 'components/Generic/FormElements/Select/Select';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { customerContext, messageContext } from 'App';
import { RegisterForm } from 'models/forms/registerForm.model';
import { AppError } from 'models/generic/appError.model';
import { ListItem } from 'models/generic/listItem.model';
import { Country } from 'models/responses/country.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLookups } from 'hooks/useLookups';
import validation from 'utils/validation';
import classNames from 'classnames';
import styles from './Register.module.scss';

export const Register: FC = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState(new RegisterForm());
  const [inProgress, setInProgress] = useState(false);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [titles, setTitles] = useState<ListItem[]>([]);
  const [countries, setCountries] = useState<ListItem[]>([]);
  const [country, setCountry] = useState<Country | null>(null);
  const [provinceItems, setProvinceItems] = useState<ListItem[]>([]);

  const messages = useContext(messageContext);
  const lookups = useLookups();
  const customerAuth = useContext(customerContext);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    // reload provinces if country changed
    if (country) {
      setProvinceItems(lookups.getProvincesAsListItems(country.provinces));
    }
  }, [country]);

  useEffect(() => {
    // update form if provinces changed (and country already loaded)
    if (country) {
      if (provinceItems.length) {
        // reset textbox value to blank if list is used
        form.county = '';
        setForm(form);
      } else {
        // reset list value to null if textbox is used
        form.provinceId = null;
        setForm(form);
      }
    }
  }, [provinceItems]);

  const init = async () => {
    setTitles(lookups.getTitles());
    setCountries(await lookups.getCountriesAsListItems());

    if (form.countryId) {
      await loadCountry(form.countryId);
    }

    setValidationRules(await customerAuth.getRegistrationValidationRules());
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = InputHelper.baseHandleString(e, form);

    // convert post code to upper case
    if (e.target.name === 'postCode') {
      updatedForm.postCode = updatedForm.postCode.toUpperCase();
    }

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const updatedForm = SelectHelper.baseHandleString(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = CheckBoxHelper.baseHandleBool(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleSelectAsNumber = async (e: ChangeEvent<HTMLSelectElement>) => {
    const updatedForm = SelectHelper.baseHandleNumber(e, form);

    // reload country and its provinces if selected countryId changed
    if (e.target.name === 'countryId') {
      const countryId = +e.target.value;
      if (countryId !== country?.countryId) {
        await loadCountry(+e.target.value);
      }
    }

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const loadCountry = async (countryId: number | null) => {
    if (countryId) {
      setCountry(await lookups.getCountryById(countryId));
    }
  };

  const validate = (form: RegisterForm): boolean => validation.validateForm(form, validationRules, setForm);

  const back = () => {
    navigate('/login');
  };

  const register = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitAttempted(true);

    const isValid = validate(form);
    if (isValid) {
      setInProgress(true);
      const result = await customerAuth.register(form);
      if (result instanceof AppError) {
        form.fieldMessages = result.fieldMessages;
        setForm(form);
        setInProgress(false);
      } else {
        messages.addSuccessAsString('You have registered successfully.');
        navigate('/login');
      }
    }
  };

  return (
    <>
      <div className={appStyles.container} id="RegisterForm">
        <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
          <Icon icon={Icons.user} size="lg" color="darkBlue" iconName="Register" customClass={appStyles.heading__icon} />
          <h1 className={appStyles.heading__text}>Registration</h1>
        </div>

        <form className={appStyles.row}>
          <div className={appStyles.col_md_6}>
            <div id="accountDetailsCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
              <fieldset>
                <Legend type="large" text="Account details" />
                <div className={appStyles.form__row}>
                  <Input
                    elementId="email"
                    inputType="text"
                    labelText="Enter your email"
                    name="email"
                    placeholder="Email"
                    handleChange={handleInput}
                    value={form.email}
                    autocomplete="email"
                    validationErrors={form.fieldMessages['email']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="password"
                    inputType="password"
                    labelText="Enter your password"
                    name="password"
                    placeholder="Password"
                    handleChange={handleInput}
                    value={form.password}
                    autocomplete="new-password"
                    validationErrors={form.fieldMessages['password']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="confirmPassword"
                    inputType="password"
                    labelText="Confirm your password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    handleChange={handleInput}
                    value={form.confirmPassword}
                    autocomplete="new-password"
                    validationErrors={form.fieldMessages['confirmPassword']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Select
                    elementId="title"
                    labelText="Title"
                    name="title"
                    options={titles}
                    placeholder="Select your title"
                    disablePlaceholder={true}
                    value={form.title}
                    handleChange={handleSelect}
                    validationErrors={form.fieldMessages['title']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="firstName"
                    inputType="text"
                    labelText="First name"
                    name="firstName"
                    placeholder="Enter your first name"
                    handleChange={handleInput}
                    value={form.firstName}
                    autocomplete="given-name"
                    validationErrors={form.fieldMessages['firstName']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="lastName"
                    inputType="text"
                    labelText="Last name"
                    name="lastName"
                    placeholder="Enter your last name"
                    handleChange={handleInput}
                    value={form.lastName}
                    autocomplete="family-name"
                    validationErrors={form.fieldMessages['lastName']}
                  />
                </div>
              </fieldset>
            </div>

            <div id="phoneDetailsCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
              <fieldset>
                <Legend type="large" text="Phone details" />

                <div className={appStyles.form__row}>
                  <Input
                    elementId="telephone"
                    inputType="text"
                    labelText="Telephone number"
                    name="telephone"
                    placeholder="Telephone number"
                    handleChange={handleInput}
                    value={form.telephone}
                    autocomplete="tel"
                    validationErrors={form.fieldMessages['telephone']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="mobileCountryCode"
                    inputType="text"
                    labelText="Mobile number with country code (e.g. 44 1234567890)"
                    name="mobileCountryCode"
                    placeholder="Code"
                    handleChange={handleInput}
                    value={form.mobileCountryCode}
                    customInputClass={styles.code}
                    validationErrors={form.fieldMessages['mobileCountryCode']}
                  />
                  <Input
                    elementId="mobileNumber"
                    inputType="text"
                    labelText=""
                    name="mobileNumber"
                    placeholder="Mobile number"
                    handleChange={handleInput}
                    value={form.mobileNumber}
                    autocomplete="tel"
                    customInputClass={styles.number}
                    customLabelClass={styles.hidden}
                    validationErrors={form.fieldMessages['mobileNumber']}
                  />
                </div>
                {/* <div className={appStyles.form__row}>
                <Input
                  elementId="fax"
                  inputType="text"
                  labelText="Fax number"
                  name="fax"
                  placeholder="Enter your fax number"
                  handleChange={this.handleInput}
                  value={this.form.fax}
                  validationErrors={this.form.fieldMessages['fax']}
                />
              </div> */}
              </fieldset>
            </div>
          </div>

          <div className={appStyles.col_md_6}>
            <div id="addressDetailsCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
              <fieldset>
                <Legend type="large" text="Address details" />
                <div className={appStyles.form__row}>
                  <Select
                    elementId="countryId"
                    labelText="Country"
                    name="countryId"
                    options={countries}
                    placeholder="Select your country"
                    disablePlaceholder={true}
                    value={form.countryId}
                    handleChange={handleSelectAsNumber}
                    validationErrors={form.fieldMessages['countryId']}
                  />
                </div>
                {form.countryId &&
                  (provinceItems.length > 0 ? (
                    <div className={appStyles.form__row}>
                      <Select
                        elementId="provinceId"
                        labelText="State/Province"
                        name="provinceId"
                        options={provinceItems}
                        placeholder="Select your state/province"
                        disablePlaceholder={true}
                        value={form.provinceId}
                        handleChange={handleSelectAsNumber}
                        validationErrors={form.fieldMessages['provinceId']}
                      />
                    </div>
                  ) : (
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="county"
                        inputType="text"
                        labelText="County/State/Province"
                        name="county"
                        placeholder="Enter your county"
                        handleChange={handleInput}
                        value={form.county}
                        validationErrors={form.fieldMessages['county']}
                      />
                    </div>
                  ))}
                <div className={appStyles.form__row}>
                  <Input
                    elementId="address1"
                    inputType="text"
                    labelText="Address line 1"
                    name="address1"
                    placeholder="Enter your address line 1"
                    handleChange={handleInput}
                    value={form.address1}
                    autocomplete="address-line1"
                    validationErrors={form.fieldMessages['address1']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="address2"
                    inputType="text"
                    labelText="Address line 2"
                    name="address2"
                    placeholder="Enter your address line 2"
                    handleChange={handleInput}
                    value={form.address2}
                    autocomplete="address-line2"
                    validationErrors={form.fieldMessages['address2']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="city"
                    inputType="text"
                    labelText="Town/City"
                    name="city"
                    placeholder="Enter your city"
                    handleChange={handleInput}
                    value={form.city}
                    autocomplete="address-line3"
                    validationErrors={form.fieldMessages['city']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Input
                    elementId="postCode"
                    inputType="text"
                    labelText={country?.postCodeSample ? 'Post/Zip code (please use format: ' + country.postCodeSample + ')' : 'Post/Zip code'}
                    name="postCode"
                    placeholder="Enter your post code"
                    handleChange={handleInput}
                    value={form.postCode}
                    autocomplete="postal-code"
                    validationErrors={form.fieldMessages['postCode']}
                  />
                </div>
              </fieldset>
            </div>
          </div>

          <div className={appStyles.col_md_12}>
            <div id="policyDetailsCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
              <fieldset>
                <Legend type="large" text="Policy and marketing" />
                <div className={appStyles.form__row}>
                  <span>
                    Our{' '}
                    <a
                      target="_blank"
                      className={appStyles.inline}
                      rel="noopener noreferrer"
                      href="https://global.oup.com/privacy?cc=gb"
                      title="Privacy Policy">
                      Privacy policy
                    </a>{' '}
                    sets out how Oxford University Press handles your personal information, and your rights to object to your personal information being used
                    for marketing to you or being processed as part of our business activities.
                  </span>
                </div>
                <div className={appStyles.form__row}>
                  <CheckBox
                    name="optOutEmail"
                    options={[new ListItem(true, 'Please tick this box if you do not want to receive email or phone marketing from Oxford University Press.')]}
                    selectedOptions={[form.optOutEmail]}
                    handleChange={handleCheckBox}
                    validationErrors={form.fieldMessages['optOutEmail']}
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </form>
      </div>
      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button buttonStyle="secondary" size="lg" handleClick={back}>
          Back to login
        </Button>
        <Button buttonStyle="primary" size="lg" handleClick={register} inProgress={inProgress} title="Click to proceed with registration">
          Register
        </Button>
      </BottomButtonContainer>
    </>
  );
};
