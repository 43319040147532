import { FieldMessages } from 'models/generic/fieldMessages.model';
import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import constants from 'utils/constants';

class Validation {
  regexPatterns = {
    email:
      // eslint-disable-next-line max-len
      "^((([a-z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+(\\.([a-z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+)*)|((\\x22)((((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(([\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x7f]|\\x21|[\\x23-\\x5b]|[\\x5d-\\x7e]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(\\\\([\\x01-\\x09\\x0b\\x0c\\x0d-\\x7f]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF]))))*(((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(\\x22)))@((([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.)+(([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.?$",
    fileName: '^[a-zA-Z0-9](?:[a-zA-Z0-9_]*[a-zA-Z0-9])?\\.[a-zA-Z0-9]+$',
    // eslint-disable-next-line max-len
    url: "^(https?|ftp):\\/\\/(((([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:)*@)?(((\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])\\.(\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])\\.(\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])\\.(\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5]))|((([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.)+(([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.?)(:\\d*)?)(\\/((([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)+(\\/(([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)*)*)?)?(\\?((([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)|[\\uE000-\\uF8FF]|\\/|\\?)*)?(\\#((([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)|\\/|\\?)*)?$",
    numeric: '^[0-9]+$',
    amount: '^([0-9]+(\\.[0-9]([0-9])?)?)$',
    password: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#£$%^&*()_=+`;:\'@~,<.>|/?{}\\[\\]\\-\\\\])(?!.*[<>&]).{10,}$',
    postCode: '^[0-9a-zA-Z -]*$'
  };

  // #region individual rule implementation
  validateEmail(value: string, fieldName: string, errors: FieldMessages, messages: string[]): boolean {
    return this.validateRegex(value, this.regexPatterns.email, true, fieldName, errors, messages);
  }

  validateNumeric(value: number, fieldName: string, errors: FieldMessages, messages: string[]): boolean {
    let isValid = true;

    if (value) {
      isValid = this.validateRegex(value.toString(), this.regexPatterns.numeric, true, fieldName, errors, messages);
    }

    return isValid;
  }

  validateAmount(value: number, fieldName: string, errors: FieldMessages, messages: string[]): boolean {
    let isValid = true;

    if (value) {
      isValid = this.validateRegex(value.toString(), this.regexPatterns.amount, true, fieldName, errors, messages);
    }

    return isValid;
  }

  validatePassword(value: string, fieldName: string, errors: FieldMessages, messages: string[]): boolean {
    let isValid = true;
    if (value) {
      isValid = this.validateRegex(value, this.regexPatterns.password, false, fieldName, errors, messages);
    }

    return isValid;
  }

  validateNumericRange(value: number, min: number, max: number, fieldName: string, errors: FieldMessages, messages: string[]): boolean {
    let isValid = true;

    if (value < min || value > max) {
      this.addError(errors, fieldName, messages);
      isValid = false;
    }

    return isValid;
  }

  validateRequired(value: unknown, fieldName: string, errors: FieldMessages, messages: string[]): boolean {
    let isValid = true;

    if (value === '' || value === null || value === undefined || (Array.isArray(value) && (value as []).length === 0)) {
      this.addError(errors, fieldName, messages);
      isValid = false;
    }

    return isValid;
  }

  validateStringLength(value: string, min: number, max: number, fieldName: string, errors: FieldMessages, messages: string[]): boolean {
    let isValid = true;

    if ((value && value.length < min) || (value && value.length > max)) {
      this.addError(errors, fieldName, messages);
      isValid = false;
    }

    return isValid;
  }

  validateTrue(value: unknown, fieldName: string, errors: FieldMessages, messages: string[]): boolean {
    let isValid = true;

    if (value !== true) {
      this.addError(errors, fieldName, messages);
      isValid = false;
    }

    return isValid;
  }

  validateUrl(value: string, fieldName: string, errors: FieldMessages, messages: string[]): boolean {
    // validate with regex, but override error message
    return this.validateRegex(value, this.regexPatterns.url, true, fieldName, errors, messages);
  }

  validateFieldCompare(value: string, valueToCompare: string, fieldName: string, errors: FieldMessages, messages: string[]): boolean {
    let isValid = true;
    if (valueToCompare && value !== valueToCompare) {
      this.addError(errors, fieldName, messages);
      isValid = false;
    }

    return isValid;
  }

  validatePostCode(value: string, fieldName: string, errors: FieldMessages, messages: string[]): boolean {
    return this.validateRegex(value, this.regexPatterns.postCode, true, fieldName, errors, messages);
  }

  private validateRegex(value: string, pattern: string, ignoreCase: boolean, fieldName: string, errors: FieldMessages, messages: string[]): boolean {
    let isValid = true;
    let regex: RegExp;
    if (ignoreCase) {
      regex = RegExp(pattern, 'i'); // ignore case
    } else {
      regex = RegExp(pattern);
    }

    if (regex.test(value) === false) {
      this.addError(errors, fieldName, messages || 'This field is in incorrect format');
      isValid = false;
    }

    return isValid;
  }
  // #endregion

  public validateForm<T extends BaseValidatedObject>(form: T, validationRules: ValidationRule[], setForm: React.Dispatch<React.SetStateAction<T>>): boolean {
    // create fresh instance of form and reset validation messages
    const updatedForm = {
      ...form,
      fieldMessages: new FieldMessages()
    } as T;

    // validate
    const isValid = validation.validate(updatedForm, validationRules);

    // refresh the form
    setForm(updatedForm);

    return isValid;
  }

  public validate(item: BaseValidatedObject, rules: ValidationRule[]): boolean {
    let isValid = true;

    // group rules by properties
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const groupedRules: any = [];
    const properties: string[] = [];
    rules.forEach((rule) => {
      if (rule.propertyName) {
        if (!groupedRules[rule.propertyName]) {
          groupedRules[rule.propertyName] = [];
          properties.push(rule.propertyName);
        }
        groupedRules[rule.propertyName].push(rule);
      }
    });

    // for each field check if required and if has value first
    // continue with other validation rules only if required check passes
    properties.forEach((property) => {
      const propertyRules = groupedRules[property];

      let isRequired = false;
      let isPopulated = false;

      propertyRules.forEach((propertyRule: ValidationRule) => {
        if (propertyRule.ruleName === constants.validation.rules.required) {
          isRequired = true;

          const fieldName = propertyRule.propertyName[0].toLowerCase() + propertyRule.propertyName.substring(1);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const value = (item as any)[fieldName];

          isPopulated = this.validateRequired(value, fieldName, item.fieldMessages, propertyRule.messages);
          if (!isPopulated) {
            isValid = false;
          }
        }
      });

      // check other rules only if not required or if required and populated
      if ((isRequired && isPopulated) || !isRequired) {
        // eslint-disable-next-line complexity
        rules.forEach((rule) => {
          if (rule.propertyName === property) {
            const fieldName = rule.propertyName[0].toLowerCase() + rule.propertyName.substring(1);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const value = (item as any)[fieldName];

            // map rules by name and execute them on an object
            if (rule.ruleName === constants.validation.rules.email) {
              isValid = this.validateEmail(value, fieldName, item.fieldMessages, rule.messages) && isValid;
            }

            if (rule.ruleName === constants.validation.rules.stringLength) {
              if (rule.parameters && rule.parameters.min !== null && rule.parameters.max !== null) {
                isValid = this.validateStringLength(value, rule.parameters.min, rule.parameters.max, fieldName, item.fieldMessages, rule.messages) && isValid;
              }
            }

            if (rule.ruleName === constants.validation.rules.url) {
              isValid = this.validateUrl(value, fieldName, item.fieldMessages, rule.messages) && isValid;
            }

            if (rule.ruleName === constants.validation.rules.password) {
              isValid = this.validatePassword(value, fieldName, item.fieldMessages, rule.messages) && isValid;
            }

            if (rule.ruleName === constants.validation.rules.amount) {
              isValid = this.validateAmount(value, fieldName, item.fieldMessages, rule.messages) && isValid;
            }

            if (rule.ruleName === constants.validation.rules.numeric) {
              isValid = this.validateNumeric(value, fieldName, item.fieldMessages, rule.messages) && isValid;
            }

            if (rule.ruleName === constants.validation.rules.postCode) {
              isValid = this.validatePostCode(value, fieldName, item.fieldMessages, rule.messages) && isValid;
            }

            if (rule.ruleName.includes(constants.validation.rules.fieldCompare)) {
              if (rule.parameters && rule.parameters.propertyToCompareName) {
                const propertyName = rule.parameters.propertyToCompareName[0].toLowerCase() + rule.parameters.propertyToCompareName.substring(1);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const compareValue = (item as any)[propertyName];

                isValid = this.validateFieldCompare(value, compareValue, fieldName, item.fieldMessages, rule.messages) && isValid;
              }
            }
          }
        });
      }
    });

    return isValid;
  }

  private addError(errors: FieldMessages, fieldName: string, messages: string[]) {
    if (errors) {
      if (!errors[fieldName]) {
        errors[fieldName] = [];
      }
      messages.forEach((message) => {
        errors[fieldName].push(message);
      });
    }
  }
}

const validation = new Validation();
export default validation;
