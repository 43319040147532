import { apiContext } from 'App';
import { AddItemToBasketForm } from 'models/forms/addItemToBasketForm.model';
import { ClearBasketForm } from 'models/forms/clearBasketForm.model';
import { CreateOrderForm } from 'models/forms/createOrderForm.model';
import { RemoveItemFromBasketForm } from 'models/forms/removeItemFromBasketForm.model';
import { UpdateBasketItemForm } from 'models/forms/updateBasketItemForm.model';
import { AppError } from 'models/generic/appError.model';
import { Basket } from 'models/responses/basket.model';
import { useContext, useState } from 'react';
import constants from 'utils/constants';
import utils from 'utils/utils';

export interface IBasketHook {
  currentBasket: Basket;
  addItemToBasket: (request: AddItemToBasketForm) => Promise<AppError | null>;
  updateBasketItem: (request: UpdateBasketItemForm) => Promise<AppError | null>;
  removeItemFromBasket: (request: RemoveItemFromBasketForm) => Promise<AppError | null>;
  clearBasket: (request: ClearBasketForm) => Promise<AppError | null>;
  resetBasket: () => void;
  createOrder: (form: CreateOrderForm) => Promise<AppError | Basket>;
}

export function useBasket(): IBasketHook {
  const api = useContext(apiContext);

  const [basket, setBasket] = useState<Basket>(new Basket());

  const save = (b: Basket) => {
    if (b.items.length > 0) {
      // convert date strings to dates after deserialisation
      b.items.forEach((item) => {
        if (item.startDate) {
          item.startDate = new Date(item.startDate);
        }
        if (item.endDate) {
          item.endDate = new Date(item.endDate);
        }
      });

      // save in session if has items
      utils.session.saveToSession(constants.storageKeys.basket, b);
    } else {
      // remove from session if has no items
      utils.session.removeFromSession(constants.storageKeys.basket);
    }

    setBasket(b);
  };

  const addItemToBasket = async (request: AddItemToBasketForm): Promise<AppError | null> => {
    const b = basket;
    b.storeId = request.storeId;
    b.returnUrl = request.returnUrl;

    if (b.items.length > 0) {
      const error = new AppError();
      error.fieldMessages['productCode'] = [];
      error.fieldMessages['productCode'].push('Only a single item can be processed within a single order.');

      return error;
    } else {
      const response = await api.post<Basket>('basket/add', request, 'Adding item to basket failed.');
      if (response instanceof AppError) {
        return response;
      } else {
        save(response);

        return null;
      }
    }
  };

  const updateBasketItem = async (request: UpdateBasketItemForm): Promise<AppError | null> => {
    const response = await api.post<Basket>('basket/update', request, 'Updating item in basket failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      save(response);

      return null;
    }
  };

  const removeItemFromBasket = async (request: RemoveItemFromBasketForm): Promise<AppError | null> => {
    const response = await api.post<Basket>('basket/remove', request, 'Removing item from basket failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      // set returnUrl after reset of state
      if (basket.returnUrl) {
        response.returnUrl = basket.returnUrl;
      }

      save(response);

      return null;
    }
  };

  const clearBasket = async (request: ClearBasketForm): Promise<AppError | null> => {
    const response = await api.post<Basket>('basket/clear', request, 'Clearing basket failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      // set returnUrl after reset of state
      if (basket.returnUrl) {
        response.returnUrl = basket.returnUrl;
      }

      save(response);

      return null;
    }
  };

  const resetBasket = () => {
    // remove current basket from cache and replace with blank
    utils.session.removeFromSession(constants.storageKeys.basket);
    setBasket(new Basket());
  };

  const createOrder = async (form: CreateOrderForm): Promise<AppError | Basket> => {
    const response = await api.post<Basket>(`order/create`, form, 'There was an error creating order or calculating tax.');
    if (response instanceof AppError) {
      return response;
    } else {
      save(response);

      return response;
    }
  };

  return {
    currentBasket: basket,
    addItemToBasket,
    updateBasketItem,
    removeItemFromBasket,
    clearBasket,
    resetBasket,
    createOrder
  };
}
