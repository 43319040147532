import { apiContext } from 'App';
import { DateRangeReportForm } from 'models/forms/dateRangeReportForm.model';
import { AppError } from 'models/generic/appError.model';
import { CsvFile } from 'models/generic/csvFile.model';
import { DateOnly } from 'models/generic/dateOnly';
import { ReportAccountLine } from 'models/responses/reportAccountLine.model';
import { ReportEbooksMarketingLine } from 'models/responses/reportEbooksMarketingLine.model';
import { ReportEbooksOrderLine } from 'models/responses/reportEbooksOrderLine.model';
import { ReportEbooksSalesLine } from 'models/responses/reportEbooksSalesLine.model';
import { ReportEbooksTaxLine } from 'models/responses/reportEbooksTaxLine.model';
import { ReportGdprLine } from 'models/responses/reportGdprLine.model';
import { ReportTroveLine } from 'models/responses/reportTroveLine.model';
import { ReportSubscriptionLine } from 'models/responses/reportSubscriptionLine.model';
import { useContext } from 'react';
import constants from 'utils/constants';

export interface IReportsHook {
  getAccountsReport: (form: DateRangeReportForm) => Promise<ReportAccountLine[] | AppError>;
  getAccountsReportAsCsv: (form: DateRangeReportForm) => Promise<CsvFile | AppError>;
  getSubscriptionsReport: (form: DateRangeReportForm) => Promise<ReportSubscriptionLine[] | AppError>;
  getSubscriptionsReportAsCsv: (form: DateRangeReportForm) => Promise<CsvFile | AppError>;
  getTroveReport: (form: DateRangeReportForm) => Promise<ReportTroveLine[] | AppError>;
  getTroveReportAsCsv: (form: DateRangeReportForm) => Promise<CsvFile | AppError>;
  getGdprReport: (form: DateRangeReportForm) => Promise<ReportGdprLine[] | AppError>;
  getGdprReportAsCsv: (form: DateRangeReportForm) => Promise<CsvFile | AppError>;
  getEbooksOrdersReport: (form: DateRangeReportForm) => Promise<ReportEbooksOrderLine[] | AppError>;
  getEbooksOrdersReportAsCsv: (form: DateRangeReportForm) => Promise<CsvFile | AppError>;
  getEbooksTaxReport: (form: DateRangeReportForm) => Promise<ReportEbooksTaxLine[] | AppError>;
  getEbooksTaxReportAsCsv: (form: DateRangeReportForm) => Promise<CsvFile | AppError>;
  getEbooksSalesReport: (form: DateRangeReportForm) => Promise<ReportEbooksSalesLine[] | AppError>;
  getEbooksSalesReportAsCsv: (form: DateRangeReportForm) => Promise<CsvFile | AppError>;
  getEbooksMarketingReport: (form: DateRangeReportForm) => Promise<ReportEbooksMarketingLine[] | AppError>;
  getEbooksMarketingReportAsCsv: (form: DateRangeReportForm) => Promise<CsvFile | AppError>;
}

export function useReports(): IReportsHook {
  const api = useContext(apiContext);

  const getAccountsReport = async (form: DateRangeReportForm): Promise<ReportAccountLine[] | AppError> => {
    const response = await api.post<ReportAccountLine[]>('report/accounts', form, 'Loading accounts report failed.');
    if (response instanceof AppError) {
    } else {
      response.forEach((item) => {
        item.dateModified = new Date(item.dateModified);
      });
    }

    return response;
  };

  const getAccountsReportAsCsv = async (form: DateRangeReportForm): Promise<CsvFile | AppError> => {
    const response = await api.post<string>('report/accounts/csv', form, 'Loading accounts report CSV failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      const fileName = getDateRangeReportFileName('Accounts', form);

      return new CsvFile(fileName, response);
    }
  };

  const getSubscriptionsReport = async (form: DateRangeReportForm): Promise<ReportSubscriptionLine[] | AppError> => {
    const response = await api.post<ReportSubscriptionLine[]>('report/subscriptions/', form, 'Loading subscriptions report failed.');
    if (response instanceof AppError) {
    } else {
      response.forEach((item) => {
        item.creationDate = new Date(item.creationDate);
        item.startDate = new Date(item.startDate);
        item.endDate = new Date(item.endDate);
      });
    }

    return response;
  };

  const getSubscriptionsReportAsCsv = async (form: DateRangeReportForm): Promise<CsvFile | AppError> => {
    const response = await api.post<string>('report/subscriptions/csv', form, 'Loading subscriptions report CSV failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      const fileName = getDateRangeReportFileName('Subscriptions', form);

      return new CsvFile(fileName, response);
    }
  };

  const getTroveReport = async (form: DateRangeReportForm): Promise<ReportTroveLine[] | AppError> => {
    const response = await api.post<ReportTroveLine[]>('report/trove/', form, 'Loading trove report failed.');
    if (response instanceof AppError) {
    } else {
      response.forEach((item) => {
        item.orderDate = new Date(item.orderDate);
      });
    }

    return response;
  };

  const getTroveReportAsCsv = async (form: DateRangeReportForm): Promise<CsvFile | AppError> => {
    const response = await api.post<string>('report/trove/csv', form, 'Loading trove report CSV failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      const fileName = getDateRangeReportFileName('Trove', form);

      return new CsvFile(fileName, response);
    }
  };

  const getGdprReport = async (form: DateRangeReportForm): Promise<ReportGdprLine[] | AppError> => {
    const response = await api.post<ReportGdprLine[]>('report/gdpr/', form, 'Loading GDPR report failed');
    if (response instanceof AppError) {
    }

    return response;
  };

  const getGdprReportAsCsv = async (form: DateRangeReportForm): Promise<CsvFile | AppError> => {
    const response = await api.post<string>('report/gdpr/csv', form, 'Loading GDPR report CSV failed');
    if (response instanceof AppError) {
      return response;
    } else {
      const fileName = getDateRangeReportFileName('GDPR', form);

      return new CsvFile(fileName, response);
    }
  };

  const getEbooksOrdersReport = async (form: DateRangeReportForm): Promise<ReportEbooksOrderLine[] | AppError> => {
    const response = await api.post<ReportEbooksOrderLine[]>('report/ebooks/orders', form, 'Loading e-books orders report failed');
    if (response instanceof AppError) {
    }

    return response;
  };

  const getEbooksOrdersReportAsCsv = async (form: DateRangeReportForm): Promise<CsvFile | AppError> => {
    const response = await api.post<string>('report/ebooks/orders/csv', form, 'Loading e-books orders report CSV failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      const fileName = getDateRangeReportFileName('EbooksOrders', form);

      return new CsvFile(fileName, response);
    }
  };

  const getEbooksTaxReport = async (form: DateRangeReportForm): Promise<ReportEbooksTaxLine[] | AppError> => {
    const response = await api.post<ReportEbooksTaxLine[]>('report/ebooks/tax', form, 'Loading e-books tax report failed');
    if (response instanceof AppError) {
    } else {
      response.forEach((item) => {
        item.transactionDate = new Date(item.transactionDate);
      });
    }

    return response;
  };

  const getEbooksTaxReportAsCsv = async (form: DateRangeReportForm): Promise<CsvFile | AppError> => {
    const response = await api.post<string>('report/ebooks/tax/csv', form, 'Loading e-books tax report CSV failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      const fileName = getDateRangeReportFileName('EbooksTax', form);

      return new CsvFile(fileName, response);
    }
  };

  const getEbooksSalesReport = async (form: DateRangeReportForm): Promise<ReportEbooksSalesLine[] | AppError> => {
    const response = await api.post<ReportEbooksSalesLine[]>('report/ebooks/sales', form, 'Loading e-books sales report failed');
    if (response instanceof AppError) {
    } else {
      response.forEach((item) => {
        item.date = new Date(item.date);
      });
    }

    return response;
  };

  const getEbooksSalesReportAsCsv = async (form: DateRangeReportForm): Promise<CsvFile | AppError> => {
    const response = await api.post<string>('report/ebooks/sales/csv', form, 'Loading e-books sales report CSV failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      const fileName = getDateRangeReportFileName('EbooksSales', form);

      return new CsvFile(fileName, response);
    }
  };

  const getEbooksMarketingReport = async (form: DateRangeReportForm): Promise<ReportEbooksMarketingLine[] | AppError> => {
    const response = await api.post<ReportEbooksMarketingLine[]>('report/ebooks/marketing', form, 'Loading e-books marketing report failed');
    if (response instanceof AppError) {
    } else {
      response.forEach((item) => {
        item.orderDate = new Date(item.orderDate);
      });
    }

    return response;
  };

  const getEbooksMarketingReportAsCsv = async (form: DateRangeReportForm): Promise<CsvFile | AppError> => {
    const response = await api.post<string>('report/ebooks/marketing/csv', form, 'Loading e-books marketing report CSV failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      const fileName = getDateRangeReportFileName('EbooksMarketing', form);

      return new CsvFile(fileName, response);
    }
  };

  const getDateRangeReportFileName = (prefix: string, form: DateRangeReportForm) =>
    prefix +
    '_' +
    constants.stores.getStoreRegionName(form.storeId) +
    '_' +
    DateOnly.format(form.dateFrom, 'YYYY-MM-DD') +
    '_to_' +
    DateOnly.format(form.dateTo, 'YYYY-MM-DD') +
    '.csv';

  return {
    getAccountsReport,
    getAccountsReportAsCsv,
    getSubscriptionsReport,
    getSubscriptionsReportAsCsv,
    getTroveReport,
    getTroveReportAsCsv,
    getGdprReport,
    getGdprReportAsCsv,
    getEbooksOrdersReport,
    getEbooksOrdersReportAsCsv,
    getEbooksTaxReport,
    getEbooksTaxReportAsCsv,
    getEbooksSalesReport,
    getEbooksSalesReportAsCsv,
    getEbooksMarketingReport,
    getEbooksMarketingReportAsCsv
  };
}
