import { PaymentSession } from 'models/responses/paymentSession.model';
import moment from 'moment';
import constants from 'utils/constants';
import { Routing } from 'utils/routing';
import LZString from 'lz-string';
import { useEnvironment } from 'hooks/useEnvironment';

class Utils {
  environment = useEnvironment();

  session = {
    getFromSession<T>(key: string, decompress = false): T | null {
      let result: T | null = null;
      const stringValue = sessionStorage.getItem(key);
      if (stringValue) {
        const json = decompress ? LZString.decompress(stringValue) : stringValue;
        result = JSON.parse(json) as T;
      }

      return result;
    },

    saveToSession(key: string, obj: unknown, compress = false) {
      if (obj !== null && obj !== undefined) {
        const json = JSON.stringify(obj);
        const stringValue = compress ? LZString.compress(json) : json;
        sessionStorage.setItem(key, stringValue);
      } else {
        this.removeFromSession(key);
      }
    },

    removeFromSession(key: string) {
      sessionStorage.removeItem(key);
    }
  };

  localStorage = {
    getFromLocalStorage<T>(key: string, decompress = false): T | null {
      let result: T | null = null;
      const stringValue = localStorage.getItem(key);
      if (stringValue) {
        const json = decompress ? LZString.decompress(stringValue) : stringValue;
        result = JSON.parse(json) as T;
      }

      return result;
    },

    saveToLocalStorage(key: string, obj: unknown, compress = false) {
      if (obj !== null && obj !== undefined) {
        const json = JSON.stringify(obj);
        const stringValue = compress ? LZString.compress(json) : json;
        localStorage.setItem(key, stringValue);
      } else {
        this.removeFromLocalStorage(key);
      }
    },

    removeFromLocalStorage(key: string) {
      localStorage.removeItem(key);
    }
  };

  dateTime = {
    getFormattedMomentDate(m: moment.Moment): string {
      return m ? m.format('Do MMMM YYYY') : '';
    },

    getFormattedMomentTime(m: moment.Moment): string {
      return m ? m.format('HH:mm') : '';
    },

    getFormattedMomentDateAndTime(m: moment.Moment): string {
      return m ? m.format('Do MMMM YYYY, HH:mm') : '';
    },

    getFormattedDate(d: string | Date) {
      let date: Date;

      if (d instanceof Date) {
        date = d;
      } else {
        date = new Date(d);
      }

      return moment(date).format('Do MMMM YYYY');
    },

    getFormattedDateAndTime(d: string | Date) {
      let date: Date;

      if (d instanceof Date) {
        date = d;
      } else {
        date = new Date(d);
      }

      return moment(date).format('Do MMMM YYYY, HH:mm');
    },

    toMomentFromUtc(dateTime: Date | moment.Moment, toLocal: boolean): moment.Moment {
      if (dateTime) {
        // convert to moment
        if (dateTime instanceof Date) {
          dateTime = moment(dateTime);
        }

        if (dateTime) {
          dateTime = moment.utc(dateTime);

          if (dateTime && toLocal) {
            dateTime = dateTime.local();
          }
        }

        return dateTime;
      } else {
        return moment.invalid();
      }
    }
  };

  currency = {
    formatNumberAsCurrency(storeId: number | null, value: number | null) {
      const currencySymbol = this.getCurrencySymbol(storeId);

      return value || value === 0 ? currencySymbol + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '';
    },

    getLocale(storeId: number | null): string {
      let locale = 'en-GB';

      switch (storeId) {
        case constants.stores.subscriptionsUk:
        case constants.stores.ebooksUk:
          locale = 'en-GB';
          break;

        case constants.stores.subscriptionsUs:
        case constants.stores.ebooksUs:
          locale = 'en-US';
          break;
      }

      return locale;
    },

    getCurrencySymbol(storeId: number | null): string {
      let symbol = '£';

      switch (storeId) {
        case constants.stores.subscriptionsUk:
        case constants.stores.ebooksUk:
          symbol = '£';
          break;

        case constants.stores.subscriptionsUs:
        case constants.stores.ebooksUs:
          symbol = '$';
          break;
      }

      return symbol;
    },

    getCurrencyType(storeId: number | null): 'currencyGbp' | 'currencyUsd' {
      let type: 'currencyGbp' | 'currencyUsd' = 'currencyGbp';

      switch (storeId) {
        case constants.stores.subscriptionsUk:
        case constants.stores.ebooksUk:
          type = 'currencyGbp';
          break;

        case constants.stores.subscriptionsUs:
        case constants.stores.ebooksUs:
          type = 'currencyUsd';
          break;
      }

      return type;
    }
  };

  logging = {
    // logging only enabled for non-production
    enabled: !this.environment.isProduction,

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    log(message?: string, ...optionalParams: any[]) {
      if (this.enabled) {
        // eslint-disable-next-line no-console
        console.log(message, ...optionalParams);
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error(message?: string, ...optionalParams: any[]) {
      if (this.enabled) {
        // eslint-disable-next-line no-console
        console.error(message, ...optionalParams);
      }
    }
  };

  object = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getObjectFieldNames(o: any): string[] {
      const fieldNames: string[] = [];

      for (const fieldName in o) {
        if (Object.prototype.hasOwnProperty.call(o, fieldName)) {
          fieldNames.push(fieldName);
        }
      }

      return fieldNames;
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAllFieldValuesAsString(o: any) {
      let result = '';

      const fieldNames = this.getObjectFieldNames(o);
      fieldNames.forEach((fieldName) => {
        if (o[fieldName]) {
          result += o[fieldName] + ',';
        }
      });

      return result;
    }
  };

  judopay = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getJudo(session: PaymentSession): any {
      // get global judo
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let judo = (global as any).judo;

      // if no judo then create and set globally
      if (!judo) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        judo = new (global as any).JudoPay(session.token, session.isSandbox);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (global as any).judo = judo;
      }

      return judo;
    },

    resetJudo() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (global as any).judo = null;
    }
  };

  routing = new Routing();
}

const utils = new Utils();
export default utils;
