import { useAnalytics } from 'hooks/useAnalytics';
import { FC, useEffect, useState } from 'react';
import cookie from 'react-cookies';
import classNames from 'classnames';
import { Button } from '../FormElements/Button/Button';
import styles from './CookiePopup.module.scss';

export const CookiePopup: FC = () => {
  const [showCookieDialog, setShowCookieDialog] = useState(false);

  const analytics = useAnalytics();

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    const allowAnalyticsCookies = cookie.load('allowAnalyticsCookies');

    // ff allowAnalyticsCookies is undefined or if allowAnalyticsCookies.expiry is before today show the dialog
    if (allowAnalyticsCookies === undefined) {
      setShowCookieDialog(true);
    }
  };

  const setCookiePreference = (acceptCookies: boolean) => {
    if (acceptCookies) {
      analytics.enableAnalytics();
    } else {
      analytics.disableAnalytics();
    }

    setShowCookieDialog(false);
  };

  return (
    <div
      className={classNames(
        styles.cookie,
        { [styles.cookie_hidden]: showCookieDialog === false } // add hidden class conditionally
      )}
      id="cookieDialog">
      <div className={styles.cookie__heading}>Cookies</div>
      <div className={styles.cookie__content}>
        <p className={styles.cookie__text}>
          We use cookies to improve your experience and collect statistics to optimise site functionality. By using the OUP Online Personal Subscriptions
          website, you agree to our{' '}
          <a href="http://global.oup.com/cookiepolicy/" className={styles.cookie__link} title="Cookie Policy" target="_blank" rel="noopener noreferrer">
            Cookie Policy
          </a>
          .
        </p>
        <p className={styles.cookie__text}>
          You can choose to accept or opt out of analytical cookies below or view our{' '}
          <a href="http://global.oup.com/cookiepolicy/" className={styles.cookie__link} title="Cookie Policy" target="_blank" rel="noopener noreferrer">
            Cookie Policy
          </a>{' '}
          for more information.
        </p>
      </div>
      <div className={styles.cookie__section}>
        <Button
          customClass={styles.cookie__button}
          buttonStyle="outline_primary"
          id="cookieOptOut"
          size="md"
          handleClick={() => {
            setCookiePreference(false);
          }}>
          Opt out
        </Button>
        <Button
          customClass={styles.cookie__button}
          buttonStyle="primary"
          id="cookieOptIn"
          size="md"
          handleClick={() => {
            setCookiePreference(true);
          }}>
          Accept
        </Button>
      </div>
    </div>
  );
};

export default CookiePopup;
