import { adminContext } from 'App';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import utils from 'utils/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AdminOnly = ({ Component }: any) => {
  const adminAuth = useContext(adminContext);
  const currentUser = adminAuth.getCurrentUser();

  // check if admin logged in
  if (currentUser) {
    return <Component />;
  } else {
    // save requested page URL before redirecting to login
    utils.routing.saveRequestedUrl(window.location.pathname + window.location.search);

    // admin not logged in, so go to login page
    return <Navigate to={{ pathname: '/admin/login' }} />;
  }
};
