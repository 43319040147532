import { IApiHook } from 'hooks/useApi';
import { IBaseAuthHook, useBaseAuth } from 'hooks/useBaseAuth';
import { ChangePasswordForm } from 'models/forms/changePasswordForm.model';
import { CodeLoginForm } from 'models/forms/codeLoginForm.model';
import { EditCustomerForm } from 'models/forms/editCustomerForm.model';
import { EditPreferencesForm } from 'models/forms/editPreferencesForm.model';
import { RegisterForm } from 'models/forms/registerForm.model';
import { RequestPasswordResetForm } from 'models/forms/requestPasswordResetForm.model';
import { ResetPasswordForm } from 'models/forms/resetPasswordForm.model';
import { AppError } from 'models/generic/appError.model';
import { Customer } from 'models/responses/customer.model';
import { Preferences } from 'models/responses/preferences.model';
import { ITokenResponse } from 'models/responses/tokenResponse.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import constants from 'utils/constants';
import utils from 'utils/utils';

export interface ICustomerAuthHook extends IBaseAuthHook<Customer> {
  getRegistrationValidationRules: () => Promise<ValidationRule[]>;
  getEditCustomerValidationRules: () => Promise<ValidationRule[]>;
  getRequestPasswordResetValidationRules: () => Promise<ValidationRule[]>;
  getResetPasswordValidationRules: () => Promise<ValidationRule[]>;
  getChangePasswordValidationRules: () => Promise<ValidationRule[]>;
  getLoginUrl: () => Promise<string>;
  logIn: (loginForm: CodeLoginForm) => Promise<Customer | AppError>;
  register: (registerForm: RegisterForm) => Promise<Customer | AppError>;
  requestPasswordReset: (form: RequestPasswordResetForm) => Promise<boolean | AppError>;
  resetPassword: (form: ResetPasswordForm) => Promise<boolean | AppError>;
  changePassword: (form: ChangePasswordForm) => Promise<boolean | AppError>;
  updateCustomer: (form: EditCustomerForm) => Promise<Customer | AppError>;
  updatePreferences: (form: EditPreferencesForm) => Promise<Preferences | AppError>;
  needsDetailsPopulated: (customer: Customer) => boolean;
}

export interface ICustomerAuthHookProps {
  api: IApiHook;
}

export function useCustomerAuth(props: ICustomerAuthHookProps): ICustomerAuthHook {
  const api = props.api;
  const baseAuth = useBaseAuth<Customer>({ controllerName: 'customer', userStorageKey: constants.storageKeys.currentCustomer, api: api });

  const getRegistrationValidationRules = async (): Promise<ValidationRule[]> => api.getValidationRules('customer/register/validation');
  const getEditCustomerValidationRules = async (): Promise<ValidationRule[]> => api.getValidationRules('customer/validation');
  const getRequestPasswordResetValidationRules = async (): Promise<ValidationRule[]> => api.getValidationRules('customer/requestReset/validation');
  const getResetPasswordValidationRules = async (): Promise<ValidationRule[]> => api.getValidationRules('customer/resetPassword/validation');
  const getChangePasswordValidationRules = (): Promise<ValidationRule[]> => api.getValidationRules('customer/password/validation');

  const getLoginUrl = async (): Promise<string> => {
    const response = await api.get<string>(baseAuth.controllerName + '/login/url', 'Loading login URL failed.');
    if (response instanceof AppError) {
      return '';
    } else {
      return response;
    }
  };

  const logIn = async (loginForm: CodeLoginForm): Promise<Customer | AppError> => {
    const response = await api.post<ITokenResponse>(baseAuth.controllerName + '/tokenlogin', loginForm, 'Login failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      // set current user
      const user = Customer.fromTokenResponse(response);
      baseAuth.setCurrentUser(user);

      // also save valid flag to cache
      baseAuth.setIsValid(response.isValid);
      utils.localStorage.saveToLocalStorage(constants.storageKeys.isValid, response.isValid);

      return user;
    }
  };

  const register = async (registerForm: RegisterForm): Promise<Customer | AppError> => {
    const response = await api.post<Customer>('customer/register', registerForm, 'Registration failed.');

    return response;
  };

  const requestPasswordReset = async (form: RequestPasswordResetForm): Promise<boolean | AppError> => {
    const response = await api.post<boolean>('customer/requestReset', form, 'Request password reset failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      return true;
    }
  };

  const resetPassword = async (form: ResetPasswordForm): Promise<boolean | AppError> => {
    const response = await api.post<boolean>('customer/resetPassword', form, 'Password reset failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      return true;
    }
  };

  const changePassword = async (form: ChangePasswordForm): Promise<boolean | AppError> => {
    const response = await api.post<boolean>('customer/password', form, 'Password change failed.');
    if (response instanceof AppError) {
      return response;
    } else {
      return true;
    }
  };

  const updateCustomer = async (form: EditCustomerForm): Promise<Customer | AppError> => {
    const response = await api.put<Customer>('customer', form, 'There was an error saving your details.');

    return response;
  };

  const updatePreferences = async (form: EditPreferencesForm): Promise<Preferences | AppError> => {
    const response = await api.put<Preferences>('customer/preferences', form, 'There was an error saving your marketing preferences.');

    return response;
  };

  const needsDetailsPopulated = (customer: Customer): boolean => {
    const needDetails =
      !customer ||
      !customer.address ||
      !customer.firstName ||
      !customer.lastName ||
      !customer.address.address1 ||
      !customer.address.postCode ||
      !customer.address.city ||
      !customer.address.countryId ||
      !customer.address.mobileNumber ||
      !customer.address.mobileCountryCode;

    return needDetails;
  };

  return {
    getRegistrationValidationRules,
    getEditCustomerValidationRules,
    getRequestPasswordResetValidationRules,
    getResetPasswordValidationRules,
    getChangePasswordValidationRules,
    getLoginUrl,
    logIn,
    register,
    requestPasswordReset,
    resetPassword,
    changePassword,
    updateCustomer,
    updatePreferences,
    needsDetailsPopulated,
    ...baseAuth
  };
}
