import appStyles from 'App.module.scss';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { Input, InputHelper } from 'components/Generic/FormElements/Input/Input';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import { Select, SelectHelper } from 'components/Generic/FormElements/Select/Select';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { messageContext } from 'App';
import { AppError } from 'models/generic/appError.model';
import { ListItem } from 'models/generic/listItem.model';
import { Country } from 'models/responses/country.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLookups } from 'hooks/useLookups';
import validation from 'utils/validation';
import { EditCustomerAsAdminForm } from 'models/forms/editCustomerAsAdminForm.model';
import { useCustomers } from 'hooks/useCustomers';
import { Customer } from 'models/responses/customer.model';
import Alert from 'components/Generic/Alert/Alert';
import { CheckBox, CheckBoxHelper } from 'components/Generic/FormElements/CheckBox/CheckBox';
import utils from 'utils/utils';
import LoadingAlert from 'components/Generic/LoadingAlert/LoadingAlert';
import classNames from 'classnames';
import styles from './EditCustomerAsAdmin.module.scss';

// eslint-disable-next-line complexity
export const EditCustomerAsAdmin: FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const messages = useContext(messageContext);
  const lookups = useLookups();
  const customers = useCustomers();

  const [form, setForm] = useState(new EditCustomerAsAdminForm(null));
  const [inProgress, setInProgress] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [titles, setTitles] = useState<ListItem[]>([]);
  const [countries, setCountries] = useState<ListItem[]>([]);
  const [country, setCountry] = useState<Country | null>(null);
  const [provinceItems, setProvinceItems] = useState<ListItem[]>([]);
  const customerId = params.id ? +params.id : null;

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    // reload provinces if country changed
    if (country) {
      setProvinceItems(lookups.getProvincesAsListItems(country.provinces));
    }
  }, [country]);

  useEffect(() => {
    // update form if provinces changed (and country already loaded)
    if (country) {
      if (provinceItems.length) {
        // reset textbox value to blank if list is used
        form.county = '';
        setForm(form);
      } else {
        // reset list value to null if textbox is used
        form.provinceId = null;
        setForm(form);
      }
    }
  }, [provinceItems]);

  const init = async () => {
    if (customerId != null) {
      const customer = await customers.getCustomerById(customerId);
      if (customer?.customerId) {
        setCustomer(customer);
      }
      const updatedForm = new EditCustomerAsAdminForm(customer);
      setForm(updatedForm);
      await loadCountry(updatedForm.countryId);
      setIsLoaded(true);
    }

    setTitles(lookups.getTitles());
    setCountries(await lookups.getCountriesAsListItems());
    await loadCountry(form.countryId);
    setValidationRules(await customers.getEditCustomerValidationRules());
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = InputHelper.baseHandleString(e, form);

    // convert post code to upper case
    if (e.target.name === 'postCode') {
      updatedForm.postCode = updatedForm.postCode.toUpperCase();
    }

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleInputAsNumber = (e: ChangeEvent<HTMLInputElement>) => {
    setForm(InputHelper.baseHandleNumber(e, form));
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const updatedForm = SelectHelper.baseHandleString(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleSelectAsNumber = async (e: ChangeEvent<HTMLSelectElement>) => {
    const updatedForm = SelectHelper.baseHandleNumber(e, form);

    // reload country and its provinces if selected countryId changed
    if (e.target.name === 'countryId') {
      const countryId = +e.target.value;
      if (countryId !== country?.countryId) {
        await loadCountry(+e.target.value);
      }
    }

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = CheckBoxHelper.baseHandleBool(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const loadCountry = async (countryId: number | null) => {
    if (countryId) {
      setCountry(await lookups.getCountryById(countryId));
    }
  };

  const validate = (form: EditCustomerAsAdminForm): boolean => validation.validateForm(form, validationRules, setForm);

  const back = () => {
    navigate('/admin/customers');
  };

  const save = async () => {
    setSubmitAttempted(true);

    const isValid = validate(form);
    if (isValid) {
      setInProgress(true);

      if (customerId) {
        const result = await customers.updateCustomer(form, customerId);
        // if result is error than check for validation messages
        if (result instanceof AppError) {
          form.fieldMessages = result.fieldMessages;
          setForm(form);
          setInProgress(false);
        } else {
          messages.addSuccessAsString('Customer details have been saved.');

          // go back to dashboard
          navigate('/admin/customers');
        }
      }
    }
  };

  const notFound = isLoaded && !customer;

  return (
    <div className={appStyles.container} id="AdminEditCustomer">
      {isLoaded === false ? (
        <LoadingAlert />
      ) : notFound ? (
        <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
          <Icon icon={Icons.warning} size="lg" color="darkBlue" iconName="Warning" customClass={appStyles.heading__icon} />
          <h1 id="NotFound" className={appStyles.heading__text}>
            Customer not found
          </h1>
        </div>
      ) : customer ? (
        <>
          <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
            <Icon icon={Icons.user} size="lg" color="darkBlue" iconName="User" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>Edit customer</h1>
          </div>
          <form className={appStyles.row}>
            <div className={appStyles.col_md_6}>
              <div id="personalDetailsCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
                <fieldset>
                  <Legend type="large" text="Personal details" />
                  <div className={appStyles.form__row}>
                    <Select
                      elementId="title"
                      labelText="Title"
                      name="title"
                      options={titles}
                      placeholder="Select customer's title"
                      disablePlaceholder={true}
                      value={form.title}
                      handleChange={handleSelect}
                      validationErrors={form.fieldMessages['title']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="firstName"
                      inputType="text"
                      labelText="First name"
                      name="firstName"
                      placeholder="Enter customer's first name"
                      handleChange={handleInput}
                      value={form.firstName}
                      autocomplete="given-name"
                      validationErrors={form.fieldMessages['firstName']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="lastName"
                      inputType="text"
                      labelText="Last name"
                      name="lastName"
                      placeholder="Enter customer's last name"
                      handleChange={handleInput}
                      value={form.lastName}
                      autocomplete="family-name"
                      validationErrors={form.fieldMessages['lastName']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input elementId="email" inputType="text" labelText="Email" name="email" placeholder="" readonly={true} value={customer.email || ''} />
                  </div>
                </fieldset>
              </div>

              <div id="identifiersCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
                <fieldset>
                  <Legend type="large" text="Identifiers" />
                  <p>Please note the following:</p>
                  <ul>
                    <li>Customer ID is generated by Chess database, is unique and not editable.</li>
                    <li>
                      Sigma ID is used to identify the Sigma account that needs to be updated on save.
                      <br />
                      This field is non-editable and is automatically imported during first log in.
                    </li>
                    <li>SAMS Account ID is displayed mainly for reference and is not editable.</li>
                    <li>
                      MSD Account ID is saved both locally and in Sigma.
                      <br />
                      It should be in a 7-digit format and should also be unique.
                    </li>
                  </ul>

                  <div className={appStyles.form__row}>
                    <Input
                      elementId="customerId"
                      inputType="number"
                      labelText="Customer ID"
                      name="customerId"
                      placeholder=""
                      readonly={true}
                      value={customerId}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="sigmaId"
                      inputType="number"
                      labelText="Sigma ID"
                      name="sigmaId"
                      placeholder=""
                      readonly={true}
                      handleChange={handleInputAsNumber}
                      value={customer.sigmaId}
                      validationErrors={form.fieldMessages['sigmaId']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="samsAccountId"
                      inputType="number"
                      labelText="SAMS Account ID"
                      name="samsAccountId"
                      placeholder=""
                      readonly={true}
                      handleChange={handleInputAsNumber}
                      value={customer.samsAccountId}
                      validationErrors={form.fieldMessages['samsAccountId']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="msdAccountId"
                      inputType="number"
                      labelText="MSD Account ID"
                      name="msdAccountId"
                      placeholder="Enter customer's MSD Account ID"
                      handleChange={handleInputAsNumber}
                      value={form.msdAccountId}
                      validationErrors={form.fieldMessages['msdAccountId']}
                    />

                    {customer && customer.expectedMsdAccountId && customer.expectedMsdAccountId !== form.msdAccountId && (
                      <>
                        <br />
                        <Alert alertType="warning" customClass={appStyles.form__error}>
                          Expected MSD Account ID for this customer is {customer.expectedMsdAccountId}. Please check if this needs to be corrected before
                          saving.
                        </Alert>
                      </>
                    )}
                  </div>
                </fieldset>
              </div>
            </div>

            <div className={appStyles.col_md_6}>
              <div id="accessCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
                <fieldset>
                  <Legend type="large" text="Access" />
                  <div className={appStyles.form__row}>
                    <label className={appStyles.form__label}>Active</label>
                    <CheckBox
                      customLabelClass={appStyles.form__label}
                      name="isActive"
                      options={[new ListItem(true, 'If unselected, this customer will be disabled and unable to log in or place orders.')]}
                      selectedOptions={[form.isActive as boolean]}
                      handleChange={handleCheckBox}
                    />
                  </div>

                  <div className={appStyles.form__row}>
                    <Input
                      elementId="dateLastLogin"
                      inputType="text"
                      labelText="Last login date"
                      name="dateLastLogin"
                      placeholder="Last login date"
                      readonly={true}
                      value={customer.dateLastLogin ? utils.dateTime.getFormattedDateAndTime(customer.dateLastLogin) : 'Unknown'}
                    />
                  </div>

                  <div className={appStyles.form__row}>
                    <Input
                      elementId="dateRegistered"
                      inputType="text"
                      labelText={customer.dateImported ? 'Imported from Sigma on' : 'Registration date'}
                      name="dateRegistered"
                      placeholder="Registration date"
                      readonly={true}
                      value={
                        customer.dateImported
                          ? utils.dateTime.getFormattedDateAndTime(customer.dateImported)
                          : customer.dateRegistered
                          ? utils.dateTime.getFormattedDateAndTime(customer.dateRegistered)
                          : 'Unknown'
                      }
                    />
                  </div>
                </fieldset>
              </div>

              <div id="addressDetailsCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
                <fieldset>
                  <Legend type="large" text="Address details" />
                  <div className={appStyles.form__row}>
                    <Select
                      elementId="countryId"
                      labelText="Country"
                      name="countryId"
                      options={countries}
                      placeholder="Select customer's country"
                      disablePlaceholder={true}
                      value={form.countryId}
                      handleChange={handleSelectAsNumber}
                      validationErrors={form.fieldMessages['countryId']}
                    />
                  </div>
                  {form.countryId &&
                    (provinceItems.length > 0 ? (
                      <div className={appStyles.form__row}>
                        <Select
                          elementId="provinceId"
                          labelText="State/Province"
                          name="provinceId"
                          options={provinceItems}
                          placeholder="Select customer's state/province"
                          disablePlaceholder={true}
                          value={form.provinceId}
                          handleChange={handleSelectAsNumber}
                          validationErrors={form.fieldMessages['provinceId']}
                        />
                      </div>
                    ) : (
                      <div className={appStyles.form__row}>
                        <Input
                          elementId="county"
                          inputType="text"
                          labelText="County/State/Province"
                          name="county"
                          placeholder="Enter customer's county"
                          handleChange={handleInput}
                          value={form.county}
                          validationErrors={form.fieldMessages['county']}
                        />
                      </div>
                    ))}
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="address1"
                      inputType="text"
                      labelText="Address line 1"
                      name="address1"
                      placeholder="Enter customer's address line 1"
                      handleChange={handleInput}
                      value={form.address1}
                      autocomplete="address-line1"
                      validationErrors={form.fieldMessages['address1']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="address2"
                      inputType="text"
                      labelText="Address line 2"
                      name="address2"
                      placeholder="Enter customer's address line 2"
                      handleChange={handleInput}
                      value={form.address2}
                      autocomplete="address-line2"
                      validationErrors={form.fieldMessages['address2']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="city"
                      inputType="text"
                      labelText="Town/City"
                      name="city"
                      placeholder="Enter customer's city"
                      handleChange={handleInput}
                      value={form.city}
                      autocomplete="address-line3"
                      validationErrors={form.fieldMessages['city']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="postCode"
                      inputType="text"
                      labelText={country?.postCodeSample ? 'Post/Zip code (please use format: ' + country.postCodeSample + ')' : 'Post/Zip code'}
                      name="postCode"
                      placeholder="Enter customer's post code"
                      handleChange={handleInput}
                      value={form.postCode}
                      autocomplete="postal-code"
                      validationErrors={form.fieldMessages['postCode']}
                    />
                  </div>
                </fieldset>
              </div>

              <div id="phoneDetailsCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
                <fieldset>
                  <Legend type="large" text="Phone details" />

                  <div className={appStyles.form__row}>
                    <Input
                      elementId="telephone"
                      inputType="text"
                      labelText="Telephone number"
                      name="telephone"
                      placeholder="Customer's telephone number"
                      handleChange={handleInput}
                      value={form.telephone}
                      autocomplete="tel"
                      validationErrors={form.fieldMessages['telephone']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="mobileCountryCode"
                      inputType="text"
                      labelText="Mobile number with country code (e.g. 44 1234567890)"
                      name="mobileCountryCode"
                      placeholder="Code"
                      handleChange={handleInput}
                      value={form.mobileCountryCode}
                      customInputClass={styles.code}
                      validationErrors={form.fieldMessages['mobileCountryCode']}
                    />
                    <Input
                      elementId="mobileNumber"
                      inputType="text"
                      labelText=""
                      name="mobileNumber"
                      placeholder="Customer's mobile number"
                      handleChange={handleInput}
                      value={form.mobileNumber}
                      autocomplete="tel"
                      customInputClass={styles.number}
                      customLabelClass={styles.hidden}
                      validationErrors={form.fieldMessages['mobileNumber']}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </form>
        </>
      ) : null}

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button id="buttonBack" buttonStyle="secondary" size="lg" handleClick={back}>
          Back
        </Button>
        {isLoaded && notFound === false ? (
          <Button id="buttonSave" buttonStyle="primary" size="lg" handleClick={save} inProgress={inProgress}>
            Save
          </Button>
        ) : null}
      </BottomButtonContainer>
    </div>
  );
};
