import utils from 'utils/utils';
import { PaymentSession } from 'models/responses/paymentSession.model';
import { LogPaymentFailedForm } from 'models/forms/logPaymentFailedForm.model';
import { useNavigate } from 'react-router-dom';
import { PaymentSummary } from 'models/responses/paymentSummary.model';
import { LogPaymentInvokedForm } from 'models/forms/logPaymentInvokedForm.model';
import { useOrders } from 'hooks/useOrders';

export interface IGooglePayHook {
  init: (session: PaymentSession, elementId: string, errorCallback: (logForm: LogPaymentFailedForm) => Promise<void>) => void;
}

export function useGooglePay(): IGooglePayHook {
  let handleErrorCallback: (logForm: LogPaymentFailedForm) => Promise<void>;
  let paymentSession: PaymentSession;
  let googlePayConfig: unknown;

  const orders = useOrders();

  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccess = async (response: any) => {
    // success callback sometimes come back as Declined or Error, so check this
    if (response.result === 'Success') {
      // reset global judo object after successful payment
      utils.judopay.resetJudo();

      // extract useful info/message from response and pass to PaymentSummary object
      navigate('/confirmation', { state: new PaymentSummary(paymentSession.orderId, response.message, 'success', 'google', response) });
    } else {
      // payment declined or error, so create a form to use to log payment failed/declined event and pass it back to UI to handle
      const logForm = new LogPaymentFailedForm(paymentSession, 'google', 'success', googlePayConfig, null, response);
      await handleErrorCallback(logForm);
    }
  };

  const onError = async (error: unknown) => {
    // create a form to use to log payment failed/declined event and pass it back to UI to handle
    const logForm = new LogPaymentFailedForm(paymentSession, 'google', 'error', googlePayConfig, error, null);
    await handleErrorCallback(logForm);
  };

  const init = (session: PaymentSession, elementId: string, errorCallback: (logForm: LogPaymentFailedForm) => Promise<void>) => {
    handleErrorCallback = errorCallback;
    paymentSession = session;

    googlePayConfig = {
      judoId: paymentSession.judoId,
      amount: '' + paymentSession.amount,
      currency: paymentSession.currencyCode,
      domainName: paymentSession.domain.replace('https://', ''),
      yourPaymentReference: paymentSession.paymentReference,
      yourConsumerReference: paymentSession.consumerReference,
      paymentSession: paymentSession.session,
      environment: paymentSession.isSandbox ? 'TEST' : 'PRODUCTION',
      transactionMode: 'payment',
      scaExemption: null,
      challengeRequestIndicator: 'challengeAsMandate',
      paymentRequest: {
        apiVersion: 2,
        apiVersionMinor: 0,
        merchantInfo: {
          merchantId: paymentSession.googlePayMerchantId,
          merchantName: 'Oxford University Press'
        },
        transactionInfo: {
          currencyCode: paymentSession.currencyCode,
          countryCode: paymentSession.currencyCode === 'GBP' ? 'GB' : 'US',
          totalPriceStatus: 'FINAL',
          totalPrice: '' + paymentSession.amount
        },
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['VISA', 'MASTERCARD', 'AMEX', 'DISCOVER', 'JCB'],
              billingAddressRequired: false,
              billingAddressParameters: {
                format: 'FULL',
                phoneNumberRequired: true
              }
            }
          }
        ]
      },
      buttonStyle: {
        type: 'pay',
        color: 'default',
        sizeMode: 'fill',
        locale: 'en',
        width: 280,
        height: 50
      },
      onSuccess: onSuccess,
      onError: onError
    };

    const judo = utils.judopay.getJudo(paymentSession);
    const googlePayButton = judo.digitalWallets.getGooglePayButton(googlePayConfig);
    if (googlePayButton) {
      // Google Pay is enabled on this device/browser
      const googleContainer = document.getElementById(elementId);
      if (googleContainer !== null) {
        googleContainer.append(googlePayButton);

        // log payment invoked event, by attaching an (additional) onclick event to the button
        googlePayButton.onclick = async () => {
          const logForm = new LogPaymentInvokedForm(paymentSession.orderId, 'google', paymentSession.session, googlePayConfig);
          await orders.logPaymentInvoked(logForm);
        };
      }
    }
  };

  return {
    init
  };
}
