import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class AddItemToBasketForm extends BaseValidatedObject {
  basketId: number | null = null;
  productCode: string;
  storeId: number;
  price: number;
  promoCode: string;
  period: string;
  returnUrl: string;
  hash: string;

  constructor(query: string, basketId: number | null) {
    super();

    const urlParams = new URLSearchParams(query);

    this.productCode = urlParams.get('product') || '';
    this.storeId = parseInt(urlParams.get('store') || '', 10);
    this.price = parseFloat(urlParams.get('price') || '') || 0;
    this.promoCode = urlParams.get('promo') || '';
    this.period = urlParams.get('period') || '';
    this.returnUrl = urlParams.get('returnUrl') || '';
    this.hash = urlParams.get('hash') || '';

    this.basketId = basketId;
  }
}
