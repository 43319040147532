import moment from 'moment';

export class DateOnly {
  year: number;
  month: number; // 1-12
  day: number;

  constructor(y: number, m: number, d: number) {
    this.year = y;
    this.month = m;
    this.day = d;
  }

  static fromDate(value: Date): DateOnly {
    return new DateOnly(value.getFullYear(), value.getMonth() + 1, value.getDate());
  }

  static toDate(value: DateOnly) {
    return new Date(value.year, value.month - 1, value.day);
  }

  static format(value: DateOnly, format: string): string {
    const date = DateOnly.toDate(value);
    const m = moment(date);

    return m.format(format);
  }
}
