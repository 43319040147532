import { apiContext } from 'App';
import { EditStoreForm } from 'models/forms/editStoreForm.model';
import { AppError } from 'models/generic/appError.model';
import { Basket } from 'models/responses/basket.model';
import { Store } from 'models/responses/store.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import { useContext, useEffect, useState } from 'react';
import constants from 'utils/constants';
import utils from 'utils/utils';

export interface IStoresHook {
  stores: Store[];
  currentMaintenanceMessage: string;
  checkoutEnabled: boolean;
  loadStores: () => void;
  getStoreById: (storeId: number) => Promise<Store | null>;
  updateStore: (form: EditStoreForm, id: number) => Promise<Store | AppError>;
  getEditStoreValidationRules: () => Promise<ValidationRule[]>;
  checkMaintenanceMessage: () => Promise<boolean>;
}

export function useStores(): IStoresHook {
  const [stores, setStores] = useState<Store[]>([]);
  const [currentMaintenanceMessage, setCurrentMaintenanceMessage] = useState('');
  const [checkoutEnabled, setCheckoutEnabled] = useState(false);

  let intervalId: NodeJS.Timeout | null = null;
  let lastStoreId = 4;
  let lastMessageRequired = false;
  let checkCount = 0;

  const api = useContext(apiContext);

  useEffect(() => {
    initialiseMonitoring();

    // clean-up on unload
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const initialiseMonitoring = async () => {
    // check for updates to maintenance messages for stores - initially and then once every 60 seconds
    checkMaintenanceMessage();

    if (intervalId === null) {
      intervalId = setInterval(() => checkMaintenanceMessage(), 6000);
    }
  };

  const loadStores = async (): Promise<Store[]> => {
    const response = await api.get<Store[]>('store', 'Loading stores failed.');
    if (response instanceof AppError) {
      return [];
    } else {
      response.forEach((item) => {
        if (item.maintenanceStart) {
          item.maintenanceStart = new Date(item.maintenanceStart);
        }
        if (item.maintenanceEnd) {
          item.maintenanceEnd = new Date(item.maintenanceEnd);
        }
      });

      // save to state and also return to call
      setStores(response);

      return response;
    }
  };

  const getStoreById = async (storeId: number): Promise<Store | null> => {
    let store: Store | null = null;

    let items = stores;
    if (items.length === 0) {
      items = await loadStores();
    }

    store = items.find((s) => s.storeId === storeId) || null;

    return store;
  };

  const updateStore = async (form: EditStoreForm, id: number): Promise<Store | AppError> => {
    const response = await api.put<Store>(`store/${id}`, form, 'Updating store failed.');

    // reload stores and check messages
    await checkMaintenanceMessage();

    return response;
  };

  const getEditStoreValidationRules = async (): Promise<ValidationRule[]> => api.getValidationRules('store/validation');

  // eslint-disable-next-line complexity
  const checkMaintenanceMessage = async (): Promise<boolean> => {
    // check is made every 6s
    // every 10th check is full (including reload of stores)
    let fullCheck = false;
    if (checkCount === 0) {
      fullCheck = true;
    }
    checkCount++;
    if (checkCount === 10) {
      checkCount = 0;
    }

    // check current store ID
    const basket = utils.session.getFromSession<Basket>(constants.storageKeys.basket);
    const storeId = basket?.storeId || 4;
    if (storeId !== lastStoreId) {
      // full check is also initiated every time the current store ID changes
      lastStoreId = storeId;
      fullCheck = true;
    }

    if (fullCheck) {
      let messageRequired = false;

      // reload stores
      await loadStores();

      // check current user's store and if there's a message to display
      if (storeId) {
        const store = await getStoreById(storeId);
        const now = new Date();
        let message = '';

        setCheckoutEnabled(true);

        // check if message is set and if now is between start and end date
        if (store) {
          if (store.maintenanceMessage && store.maintenanceStart && store.maintenanceStart < now && store.maintenanceEnd && store.maintenanceEnd > now) {
            message = store.maintenanceMessage;
            messageRequired = true;

            setCheckoutEnabled(!store.disableCheckoutDuringMaintenance);
          }

          setCurrentMaintenanceMessage(message);
        }
      }
      lastMessageRequired = messageRequired;

      return messageRequired;
    } else {
      // return last used value
      return lastMessageRequired;
    }
  };

  return {
    stores,
    currentMaintenanceMessage,
    checkoutEnabled,
    loadStores,
    getStoreById,
    updateStore,
    getEditStoreValidationRules,
    checkMaintenanceMessage
  };
}
