import { Grid } from '@progress/kendo-react-grid';
import appStyles from 'App.module.scss';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { Input } from 'components/Generic/FormElements/Input/Input';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { ColumnMenu } from 'components/Generic/Grid/ColumnMenu';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { useGrid } from 'hooks/useGrid';
import { messageContext } from 'App';
import { AppError } from 'models/generic/appError.model';
import { Country } from 'models/responses/country.model';
import { FC, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLookups } from 'hooks/useLookups';
import LoadingAlert from 'components/Generic/LoadingAlert/LoadingAlert';
import classNames from 'classnames';

export const CountriesGrid: FC = () => {
  const navigate = useNavigate();

  const grid = useGrid<Country>([{ field: 'name', dir: 'asc' }]);
  const messages = useContext(messageContext);
  const lookups = useLookups();

  const [inProgress, setInProgress] = useState(true);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await loadData();
  };

  const loadData = async () => {
    setInProgress(true);
    const result = await lookups.getCountries(true);
    setInProgress(false);

    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      grid.setData(result);
    }
  };

  const goToNewForm = () => {
    navigate('/admin/countries/new');
  };

  return (
    <div className={appStyles.container} id="AdminCountries">
      <div className={appStyles.row}>
        <div className={appStyles.col_sm_8}>
          <div className={appStyles.pageHeading}>
            <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
              <Icon icon={Icons.country} size="lg" color="darkBlue" iconName="people" customClass={appStyles.heading__icon} />
              <h1 className={appStyles.heading__text}>Countries</h1>
            </div>
          </div>
        </div>

        <div className={classNames(appStyles.col_sm_4, appStyles.filters_search__inline)}>
          <Input
            elementId="searchBar"
            inputType="search"
            name="searchBar"
            labelText="Search countries"
            labelHidden={true}
            placeholder="Search countries"
            value={grid.searchKeyword}
            handleChange={grid.handleSearch}
            customLabelClass={appStyles.form__label}
            customInputClass={appStyles.form__input}
            autocomplete="off"
          />
        </div>
      </div>

      {inProgress ? (
        <LoadingAlert />
      ) : (
        <div id="mainGrid" className="grid-with-no-filter">
          <Grid
            data={grid.getData()}
            filter={grid.filter}
            filterable={false}
            pageable={grid.getPageable()}
            skip={grid.skip}
            take={grid.take}
            total={grid.getTotal()}
            onPageChange={grid.pageChange}
            sortable={grid.getSortable()}
            sort={grid.getSort()}
            resizable={true}
            onFilterChange={grid.filterChange}
            onSortChange={grid.sortChange}>
            {ColumnHelper.getGridColumns(
              [
                {
                  field: '',
                  title: 'Edit',
                  dataType: 'icon',
                  size: 'xs',
                  columnMenu: false,
                  cell: (props) => (
                    <td className="k-table-td gridColumn_xs gridColumn_button">
                      <Link to={'/admin/countries/' + props.dataItem.countryId} className="grid__button">
                        <Icon icon={Icons.edit} iconName={'Edit country: ' + props.dataItem.name} color="darkBlue" size="xs" />
                      </Link>
                    </td>
                  )
                },
                { field: 'name', title: 'Name', dataType: 'text', size: 'md' },
                { field: 'countryCode', title: 'ISO Code', dataType: 'text', size: 'sm' },
                { field: 'unCode', title: 'UN Code', dataType: 'text', size: 'sm' },
                { field: 'enabledForRegistration', title: 'Enabled', dataType: 'boolean', size: 'sm' },
                { field: 'subscriptionsStoreName', title: 'Subscriptions Store', dataType: 'text', size: 'sm' },
                { field: 'ebooksStoreName', title: 'E-books Store', dataType: 'text', size: 'sm' }
              ],
              grid.filter,
              ColumnMenu
            )}
          </Grid>
        </div>
      )}

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button id="buttonCreate" buttonStyle="primary" size="lg" handleClick={goToNewForm}>
          Add new country
        </Button>
      </BottomButtonContainer>
    </div>
  );
};
