import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { useBasket } from 'hooks/useBasket';
import { useOrders } from 'hooks/useOrders';
import { CompleteOrderForm } from 'models/forms/completeOrderForm.model';
import { AppError } from 'models/generic/appError.model';
import { OrderConfirmation } from 'models/responses/orderConfirmation.model';
import { PaymentSummary } from 'models/responses/paymentSummary.model';
import { FC, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import styles from './PurchaseConfirmation.module.scss';

// eslint-disable-next-line complexity
export const PurchaseConfirmation: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [inProgress, setInProgress] = useState(false);
  const [orderConfirmation, setOrderConfirmation] = useState<OrderConfirmation | null>(null);
  const [judopayMessage, setJudopayMessage] = useState('');
  const [orderConfirmationMessage, setOrderConfirmationMessage] = useState('');
  const paymentSummary = location.state as PaymentSummary;

  const orders = useOrders();
  const basket = useBasket();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (paymentSummary.message) {
      setJudopayMessage(paymentSummary.message);
    }

    completeOrder();
  }, []);

  const completeOrder = async () => {
    if (paymentSummary && paymentSummary.orderId) {
      const orderForm = new CompleteOrderForm(paymentSummary);

      setInProgress(true);
      const result = await orders.completeOrder(orderForm);
      setInProgress(false);

      if (result instanceof AppError) {
        // nothing here, error message will be shown inside render function
      } else {
        setOrderConfirmation(result);
      }
    } else {
      // redirect to home page if there is no order ID
      navigate('/');
    }
  };

  useEffect(() => {
    // refresh when complete order result is returned
    if (orderConfirmation) {
      if (orderConfirmation.message) {
        setOrderConfirmationMessage(orderConfirmation.message);
      }

      if (orderConfirmation.success) {
        // order completed, so reset and remove from cache
        basket.resetBasket();

        // redirect to VitalSource if redirect URL specified and if it's not an order on behalf of someone else
        if (orderConfirmation.redirectUrl && orderConfirmation.autoRedirect) {
          setTimeout(() => {
            window.location.href = orderConfirmation.redirectUrl;
          }, 5000);
        }
      } else {
        // nothing here, error message will be shown inside render function
      }
    }
  }, [orderConfirmation]);

  return (
    <div className={appStyles.container} id="PurchaseConfirmation">
      <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
        <Icon icon={Icons.orders} size="lg" color="darkBlue" iconName="Confirmation" customClass={appStyles.heading__icon} />
        <h1 className={appStyles.heading__text}>Order confirmation</h1>
      </div>

      {inProgress && (
        // processing in progress
        <Alert alertType="info">Please wait. We are finalising your order...</Alert>
      )}

      {orderConfirmation && orderConfirmation.success && orderConfirmation.orderType === 'subscription' && (
        // success - subscription
        <>
          <p id="successMessage">
            Thank you for ordering from OUP. Your order ID is {paymentSummary?.orderId}. An email will be sent to you shortly confirming your subscription
            details and access instructions.
          </p>

          {orderConfirmation.redirectUrl && (
            <div className={appStyles.form__row}>
              <a
                id="GoToProduct"
                href={orderConfirmation.redirectUrl}
                target="_blank"
                rel="noopener noreferrer"
                title="Click to go to this product's site"
                className={styles.confirmationPageLink}>
                Go to product
              </a>
            </div>
          )}

          <div className={appStyles.form__row}>
            <Link to="/" className={styles.confirmationPageLink}>
              Click here to view all your current subscriptions and e-books.
            </Link>
          </div>
        </>
      )}

      {orderConfirmation && orderConfirmation.success && orderConfirmation.orderType === 'ebook' && !orderConfirmation.onBehalf && (
        // success - e-book
        <>
          <p id="successMessage">
            Thank you for ordering from OUP. Your order ID is {paymentSummary?.orderId}. You will be redirected to VitalSource shortly, where you will be able
            to access all your e-books.
          </p>
          <Alert alertType="info">Please wait, you are being redirected to your bookshelf...</Alert>
        </>
      )}

      {orderConfirmation && orderConfirmation.success && orderConfirmation.orderType === 'ebook' && orderConfirmation.onBehalf && (
        // success - e-book on behalf
        <>
          <p id="successMessage">
            Thank you for ordering from OUP. Your order ID is {paymentSummary?.orderId}. An email confirming your e-book purchase will shortly be sent to you
            and the person you made the purchase for.
          </p>

          <div className={appStyles.form__row}>
            <Link to="/" className={styles.confirmationPageLink}>
              Click here to view all your current subscriptions and e-books.
            </Link>
          </div>
        </>
      )}

      {!inProgress && ((orderConfirmation && !orderConfirmation.success) || !orderConfirmation) && (
        // error
        <>
          <Alert id="errorMessage" alertType="error">
            There was an error processing your order ({paymentSummary?.orderId}).
          </Alert>
          {judopayMessage && (
            <Alert id="judoMessage" alertType="error">
              {judopayMessage}
            </Alert>
          )}
          {orderConfirmationMessage && (
            <Alert id="orderMessage" alertType="error">
              {orderConfirmationMessage}
            </Alert>
          )}
        </>
      )}
    </div>
  );
};
