import constants from 'utils/constants';
import utils from 'utils/utils';

export function useRequestedUrl(): string {
  // return originally requested page or dashboard URL
  let path = utils.session.getFromSession(constants.storageKeys.requestedUrl) as string;
  if (path) {
    utils.session.removeFromSession(constants.storageKeys.requestedUrl);
  } else {
    if (utils.routing.isAdminSite) {
      path = '/admin/';
    } else {
      path = '/';
    }
  }

  return path;
}
