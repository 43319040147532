import appStyles from 'App.module.scss';
import classNames from 'classnames';
import Alert from 'components/Generic/Alert/Alert';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { FC } from 'react';

export const AccessDenied: FC = () => (
  <div className={appStyles.container}>
    <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
      <Icon icon={Icons.warning} size="heading" color="darkBlue" iconName="Warning" customClass={appStyles.heading__icon} />
      <h1 className={appStyles.heading__text}>No Access</h1>
    </div>
    <div id="accessDeniedMessage">
      <Alert alertType="blank" customClass="accessDeniedText">
        <p className={classNames(appStyles.text_gabBlue, appStyles.info__main)}>This page requires admin permissions.</p>
      </Alert>
    </div>
  </div>
);
