import appStyles from 'App.module.scss';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { CheckBox, CheckBoxHelper } from 'components/Generic/FormElements/CheckBox/CheckBox';
import { Input, InputHelper } from 'components/Generic/FormElements/Input/Input';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import { Radio, RadioHelper } from 'components/Generic/FormElements/Radio/Radio';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { messageContext } from 'App';
import { EditCountryForm } from 'models/forms/editCountryForm.model';
import { AppError } from 'models/generic/appError.model';
import { ListItem } from 'models/generic/listItem.model';
import { Country } from 'models/responses/country.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import validation from 'utils/validation';
import { useLookups } from 'hooks/useLookups';
import classNames from 'classnames';
import LoadingAlert from 'components/Generic/LoadingAlert/LoadingAlert';

// eslint-disable-next-line complexity
export const EditCountry: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const lookups = useLookups();

  const [form, setForm] = useState(new EditCountryForm(null));
  const [inProgress, setInProgress] = useState(false);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [isNew, setIsNew] = useState<boolean | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [country, setCountry] = useState<Country | null>(null);
  const subscriptionStoreItems = lookups.getStores('subscriptions', true);
  const ebooksStoreItems = lookups.getStores('ebooks', true);
  const countryId = params.id ? +params.id : null;

  const messages = useContext(messageContext);

  useEffect(() => {
    // load country on init
    init();
  }, []);

  useEffect(() => {
    // reload form and validation rules on country change
    reloadForm();
  }, [country]);

  const init = async () => {
    if (countryId != null) {
      setCountry(await lookups.getCountryById(countryId));
    }
    setIsLoaded(true);
  };

  const reloadForm = async () => {
    if (country) {
      setForm(new EditCountryForm(country));
      setIsNew(false);
      setValidationRules(await lookups.getEditCountryValidationRules());
    } else {
      setForm(new EditCountryForm(null));
      if (window.location.pathname === '/admin/countries/new') {
        setIsNew(true);
      }
      setValidationRules(await lookups.getCreateCountryValidationRules());
    }
  };

  const handleInputAsString = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = InputHelper.baseHandleString(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleRadioButton = async (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = RadioHelper.baseHandleNumber(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = CheckBoxHelper.baseHandleBool(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const validate = (form: EditCountryForm): boolean => validation.validateForm(form, validationRules, setForm);

  const save = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitAttempted(true);

    const isValid = validate(form);

    if (isValid) {
      setInProgress(true);
      if (country && countryId) {
        const result = await lookups.updateCountry(form, countryId);
        if (result instanceof AppError) {
          form.fieldMessages = result.fieldMessages;
          setForm(form);
          setInProgress(false);
        } else {
          messages.addSuccessAsString('Country updated successfully.');
          navigate('/admin/countries');
        }
      } else {
        const result = await lookups.createCountry(form);
        if (result instanceof AppError) {
          form.fieldMessages = result.fieldMessages;
          setForm(form);
          setInProgress(false);
        } else {
          messages.addSuccessAsString('Country created successfully.');
          navigate('/admin/countries');
        }
      }
    }
  };

  const back = () => {
    navigate('/admin/countries');
  };

  const notFound = isLoaded && country === null && isNew !== true;

  return (
    <div className={appStyles.container} id="AdminEditCountry">
      {isLoaded === false ? (
        <LoadingAlert />
      ) : notFound ? (
        <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
          <Icon icon={Icons.warning} size="lg" color="darkBlue" iconName="Country" customClass={appStyles.heading__icon} />
          <h1 id="NotFound" className={appStyles.heading__text}>
            Country not found
          </h1>
        </div>
      ) : (
        <>
          <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
            <Icon icon={Icons.country} size="lg" color="darkBlue" iconName="Country" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>{isNew === false ? 'Edit country' : 'Add country'}</h1>
          </div>

          <form className={appStyles.row}>
            <div className={appStyles.col_md_6}>
              <div id="countryDetailsCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
                <fieldset>
                  <Legend type="large" text="Country details" />
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="name"
                      inputType="text"
                      labelText="Name"
                      name="name"
                      placeholder="Enter name"
                      value={form.name}
                      handleChange={handleInputAsString}
                      validationErrors={form.fieldMessages['name']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="countryCode"
                      inputType="text"
                      labelText="Country code"
                      name="countryCode"
                      placeholder="Enter country code"
                      value={form.countryCode}
                      handleChange={handleInputAsString}
                      validationErrors={form.fieldMessages['countryCode']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="unCode"
                      inputType="text"
                      labelText="UN code"
                      name="unCode"
                      placeholder="Enter UN code"
                      value={form.unCode}
                      handleChange={handleInputAsString}
                      validationErrors={form.fieldMessages['unCode']}
                    />
                  </div>
                </fieldset>
              </div>
            </div>

            <div className={appStyles.col_md_6}>
              <div id="countryAvailabilityCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
                <fieldset>
                  <Legend type="large" text="Availability" />

                  <div className={appStyles.form__row}>
                    <label className={appStyles.form__label}>Enabled for registration</label>
                    <CheckBox
                      customLabelClass={appStyles.form__label}
                      name="enabledForRegistration"
                      options={[new ListItem(true, 'Enabled')]}
                      selectedOptions={[form.enabledForRegistration]}
                      handleChange={handleCheckBox}
                    />
                    <p>If unselected, this country will not appear on the list for registration.</p>
                  </div>

                  <div className={appStyles.form__row}>
                    <label className={appStyles.form__label}>Enabled for subscription orders from store</label>
                    <Radio
                      name="subscriptionsStoreId"
                      options={subscriptionStoreItems}
                      selectedOption={form.subscriptionsStoreId || ''}
                      handleChange={(event) => {
                        handleRadioButton(event);
                      }}
                    />
                    <p>If None is selected, customers from this country will be unable to purchase subscriptions.</p>
                  </div>

                  <div className={appStyles.form__row}>
                    <label className={appStyles.form__label}>Enabled for e-book orders from store</label>
                    <Radio
                      name="ebooksStoreId"
                      options={ebooksStoreItems}
                      selectedOption={form.ebooksStoreId || ''}
                      handleChange={(event) => {
                        handleRadioButton(event);
                      }}
                    />
                    <p>If None is selected, customers from this country will be unable to purchase e-books.</p>
                  </div>
                </fieldset>
              </div>
            </div>

            <div className={appStyles.col_md_6}>
              <div id="postCodeCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
                <fieldset>
                  <Legend type="large" text="Post code format" />

                  <div className={appStyles.form__row}>
                    <label className={appStyles.form__label}>
                      A regular expression (Regex) format can be specified for post codes for this country, if they need to be in a specific format for SAP.
                      <br />
                      This section is optional.
                    </label>
                  </div>

                  <div className={appStyles.form__row}>
                    <Input
                      elementId="postCodeFormat"
                      inputType="text"
                      labelText="Regex format"
                      name="postCodeFormat"
                      placeholder="Enter format"
                      value={form.postCodeFormat}
                      handleChange={handleInputAsString}
                      validationErrors={form.fieldMessages['postCodeFormat']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="postCodeSample"
                      inputType="text"
                      labelText="Sample to appear on registration page"
                      name="postCodeSample"
                      placeholder="Enter sample"
                      value={form.postCodeSample}
                      handleChange={handleInputAsString}
                      validationErrors={form.fieldMessages['postCodeSample']}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </form>
        </>
      )}

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button id="buttonBack" buttonStyle="secondary" size="lg" handleClick={back}>
          Back
        </Button>
        {isLoaded && notFound === false ? (
          <Button id="buttonSave" buttonStyle="primary" size="lg" handleClick={save} inProgress={inProgress}>
            Save
          </Button>
        ) : null}
      </BottomButtonContainer>
    </div>
  );
};
