import appStyles from 'App.module.scss';
import { IBaseProps } from 'models/generic/iBaseProps';
import { FC, FormEvent } from 'react';

interface IFormWrapperProps extends IBaseProps {
  formTitle: string;
  className: string;
  onSubmit?: ((event: FormEvent<Element>) => void) | undefined;
}

export const FormWrapper: FC<IFormWrapperProps> = (props: IFormWrapperProps) => (
  <form onSubmit={props.onSubmit} className={props.className}>
    <div className={appStyles.form_default}>
      <h1 className={appStyles.form__header}>{props.formTitle}</h1>

      <div className={appStyles.background_gabBlue}>
        <div className={appStyles.form__content}>{props.children}</div>
      </div>
    </div>
  </form>
);
