import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { ValidationArea } from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { ListItem } from 'models/generic/listItem.model';
import { ChangeEvent, FC } from 'react';

interface ISelectProps {
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   * @property {string} labelText Set the text to appear in the label
   */
  labelText: string;
  /**
   * @property {string} elementId Set the ID attribute
   */
  elementId: string;
  /**
   * @property {string | number | boolean | null} value Set the value attribute
   */
  value: string | number | boolean | null;
  /**
   * @property {string} placeholder Set the placeholder option
   */
  placeholder: string;
  /**
   * @property {boolean} disablePlaceholder Set the placeholder option as disabled
   */
  disablePlaceholder?: boolean;
  /**
   * @property {ListItem[]} options Set the list of available options
   */
  options: ListItem[];
  /**
   * @property {(event: ChangeEvent<HTMLSelectElement>) => void} handleChange Set the action onChange
   */
  handleChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  /**
   * @property {string} customSelectClass Optional. Use a custom class on the select element - use styles from the current component or appStyles
   */
  customSelectClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {string[]} validationErrors Optional. Any validation messages related to this field
   */
  validationErrors?: string[];
  /**
   * @property {boolean} Optional. required Set the field to required
   */
  required?: boolean;
}

/**
 * @description Add a select element with a label. Optional properties: customSelectClass, customLabelClass,
 * disablePlaceholder, required, validationError
 */
// eslint-disable-next-line complexity
export const Select: FC<ISelectProps> = (props: ISelectProps) => {
  const isValueValid = () => props.options.find((i) => i.id === props.value);

  return (
    <>
      <label
        htmlFor={props.name}
        className={classNames(
          appStyles.form__label,
          { [appStyles.form__label_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
          { [props.customLabelClass as string]: props.customLabelClass } // add custom class if defined
        )}>
        {props.labelText}
      </label>
      <select
        id={props.elementId}
        name={props.name}
        className={classNames(
          appStyles.form__input,
          appStyles.form__input_select,
          { [appStyles.select_placeholder]: props.value === null || props.value === undefined }, // add placeholder class if no value
          { [appStyles.form__input_invalid]: props.validationErrors && props.validationErrors.length > 0 }, // add invalid class if errors
          { [props.customSelectClass as string]: props.customSelectClass } // add custom class if defined
        )}
        aria-label={props.labelText}
        onBlur={props.handleChange}
        onChange={props.handleChange}
        value={props.value !== null && isValueValid() ? props.value.toString() : ''}
        required={props.required || undefined}
        aria-required={props.required || undefined}>
        <option value="" disabled={props.disablePlaceholder} aria-selected={props.value === null || props.value === '' || !isValueValid() ? 'true' : 'false'}>
          {props.placeholder}
        </option>
        {props.options.map((option) => (
          <option
            key={option.id as string}
            value={option.id as string}
            label={option.text as string}
            aria-selected={option.id === props.value ? 'true' : 'false'}>
            {option.text}
          </option>
        ))}
      </select>

      <ValidationArea sectionId={props.elementId} validationErrors={props.validationErrors} />
    </>
  );
};

export class SelectHelper {
  static baseHandleString<T>(e: ChangeEvent<HTMLSelectElement>, obj: T): T {
    const value = e.target.value;
    const fieldName = e.target.name;

    obj = {
      ...obj,
      [fieldName]: value
    };

    return obj;
  }

  static baseHandleNumber<T>(e: ChangeEvent<HTMLSelectElement>, obj: T): T {
    let value = null;
    if (e.target.value) {
      value = +e.target.value;
    }
    const fieldName = e.target.name;

    obj = {
      ...obj,
      [fieldName]: value
    };

    return obj;
  }

  static baseHandleBool<T>(e: ChangeEvent<HTMLSelectElement>, obj: T): T {
    let value = null;
    if (e.target.value === 'true') {
      value = true;
    } else if (e.target.value === 'false') {
      value = false;
    }
    const fieldName = e.target.name;

    obj = {
      ...obj,
      [fieldName]: value
    };

    return obj;
  }
}
