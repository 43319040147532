import appStyles from 'App.module.scss';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useEnvironment } from 'hooks/useEnvironment';
import { useNodeVersionChecker } from 'hooks/useNodeVersionChecker';
import classNames from 'classnames';
import styles from './Footer.module.scss';

export const Footer: FC = () => {
  const year = new Date().getFullYear();
  const environment = useEnvironment();
  const checker = useNodeVersionChecker();
  const [nodeVersion, setNodeVersion] = useState('');
  const nodeString = nodeVersion ? ', Node ' + nodeVersion : '';

  useEffect(() => {
    if (environment.isDev) {
      loadNodeVersion();
    }
  }, []);

  const loadNodeVersion = async () => {
    const version = await checker.load();
    setNodeVersion(version.trim());
  };

  return (
    <footer className={classNames(appStyles.container, styles.footer)}>
      <div className={appStyles.row}>
        <div className={classNames(appStyles.col_lg_8, styles.footer__links)}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="Terms and Conditions"
            className={styles.footer__link}
            href="http://www.oup.com/online/subscribe/catalogue/terms/">
            Terms &amp; conditions
          </a>
          <a target="_blank" rel="noopener noreferrer" title="Cookie policy" className={styles.footer__link} href="http://global.oup.com/cookiepolicy/">
            Cookie policy
          </a>
          <a target="_blank" rel="noopener noreferrer" title="Privacy policy" className={styles.footer__link} href="http://global.oup.com/privacy">
            Privacy policy
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="Refunds policy"
            className={styles.footer__link}
            href="https://global.oup.com/academic/help/refunds/">
            Refunds policy
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="Help"
            className={styles.footer__link}
            href="https://global.oup.com/academic/help/personal-subscriptions/">
            Help
          </a>

          {environment.isProduction ? null : (
            <Link to="/basket/test" className={styles.footer__link}>
              Test
            </Link>
          )}
        </div>

        <div className={classNames(appStyles.col_lg_4, styles.footer__text)}>
          &copy; OUP {year}
          {environment.isProduction === false ? (
            <span className={styles.nodeString}>
              ({environment.name}
              {nodeString})
            </span>
          ) : null}
          <br />
          Registered VAT number: GB 195 27 53 34
        </div>
      </div>
    </footer>
  );
};
