import { Grid } from '@progress/kendo-react-grid';
import appStyles from 'App.module.scss';
import { Input } from 'components/Generic/FormElements/Input/Input';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { ColumnMenu } from 'components/Generic/Grid/ColumnMenu';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { useGrid } from 'hooks/useGrid';
import { messageContext } from 'App';
import { AppError } from 'models/generic/appError.model';
import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Customer } from 'models/responses/customer.model';
import { useCustomers } from 'hooks/useCustomers';
import classNames from 'classnames';
import LoadingAlert from 'components/Generic/LoadingAlert/LoadingAlert';

export const CustomersGrid: FC = () => {
  const grid = useGrid<Customer>([{ field: 'lastName', dir: 'asc' }]);
  const messages = useContext(messageContext);
  const customers = useCustomers();

  const [inProgress, setInProgress] = useState(true);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await loadData();
  };

  const loadData = async () => {
    setInProgress(true);
    const result = await customers.getCustomers();
    setInProgress(false);

    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      grid.setData(result);
    }
  };

  return (
    <div className={appStyles.container} id="AdminCustomers">
      <div className={appStyles.row}>
        <div className={appStyles.col_sm_8}>
          <div className={appStyles.pageHeading}>
            <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
              <Icon icon={Icons.people} size="lg" color="darkBlue" iconName="people" customClass={appStyles.heading__icon} />
              <h1 className={appStyles.heading__text}>Customers</h1>
            </div>
          </div>
        </div>

        <div className={classNames(appStyles.col_sm_4, appStyles.filters_search__inline)}>
          <Input
            elementId="searchBar"
            inputType="search"
            name="searchBar"
            labelText="Search customers"
            labelHidden={true}
            placeholder="Search customers"
            value={grid.searchKeyword}
            handleChange={grid.handleSearch}
            customLabelClass={appStyles.form__label}
            customInputClass={appStyles.form__input}
            autocomplete="off"
          />
        </div>
      </div>

      {inProgress ? (
        <LoadingAlert />
      ) : (
        <div id="mainGrid" className="grid-with-no-filter">
          <Grid
            data={grid.getData()}
            filter={grid.filter}
            filterable={false}
            pageable={grid.getPageable()}
            skip={grid.skip}
            take={grid.take}
            total={grid.getTotal()}
            onPageChange={grid.pageChange}
            sortable={grid.getSortable()}
            sort={grid.getSort()}
            resizable={true}
            onFilterChange={grid.filterChange}
            onSortChange={grid.sortChange}>
            {ColumnHelper.getGridColumns(
              [
                {
                  field: '',
                  title: 'Edit',
                  dataType: 'icon',
                  size: 'xs',
                  columnMenu: false,
                  cell: (props) => (
                    <td className="k-table-td gridColumn_xs gridColumn_button">
                      <Link to={'/admin/customers/' + props.dataItem.customerId} className="grid__button">
                        <Icon
                          icon={Icons.edit}
                          iconName={'Edit customer: ' + props.dataItem.firstName + ' ' + props.dataItem.lastName}
                          color="darkBlue"
                          size="xs"
                        />
                      </Link>
                    </td>
                  )
                },
                { field: 'customerId', title: 'Customer ID', dataType: 'integer', size: 'sm' },
                { field: 'sigmaId', title: 'Sigma ID', dataType: 'integer', size: 'sm' },
                { field: 'msdAccountId', title: 'MSD Account ID', dataType: 'integer', size: 'sm' },
                { field: 'samsAccountId', title: 'SAMS ID', dataType: 'integer', size: 'sm' },
                { field: 'firstName', title: 'First Name', dataType: 'text', size: 'md' },
                { field: 'lastName', title: 'Last Name', dataType: 'text', size: 'md' },
                { field: 'email', title: 'Email', dataType: 'text', size: 'md' }
              ],
              grid.filter,
              ColumnMenu
            )}
          </Grid>
        </div>
      )}
    </div>
  );
};
