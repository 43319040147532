import { browserHistory } from 'App';
import ReactGA from 'react-ga4';
import { Params, PathMatch, useMatch } from 'react-router-dom';
import constants from 'utils/constants';
import utils from 'utils/utils';
import { v4 as uuidv4 } from 'uuid';

export class Routing {
  // eslint-disable-next-line
  subscriptions: { [key: string]: (location: string) => void } = {};
  currentLocation = '';

  get isAdminSite() {
    return this.currentLocation.indexOf('/admin') > -1;
  }

  saveRequestedUrl(url: string) {
    utils.session.saveToSession(constants.storageKeys.requestedUrl, url);
  }

  initialiseMonitoring() {
    this.currentLocation = browserHistory.location.pathname;

    // capture initial page view
    const initialPath = window.location.pathname + window.location.search;
    ReactGA.send({ hitType: 'pageview', page: initialPath });

    // capture redirects (route changes)
    browserHistory.listen(() => {
      const redirectPath = window.location.pathname + window.location.search;
      ReactGA.send({ hitType: 'pageview', page: redirectPath });

      if (location.pathname !== this.currentLocation) {
        this.currentLocation = location.pathname;

        const ids = utils.object.getObjectFieldNames(this.subscriptions);
        ids.forEach((id) => {
          // call callback
          this.subscriptions[id](this.currentLocation);
        });
      }
    });
  }

  subscribe(callback: (location: string) => void): string {
    const id = uuidv4();
    this.subscriptions[id] = callback;

    return id;
  }

  unsubscribe(id: string) {
    if (this.subscriptions[id]) {
      delete this.subscriptions[id];
    }
  }

  matchRoutes(location: string, routes: string[]): Readonly<Params<string>> | null {
    let match: PathMatch<string> | null = null;

    if (routes) {
      routes.forEach((route) => {
        match = match || useMatch(route);
      });
    }

    return match;
  }
}
