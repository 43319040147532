import { Grid } from '@progress/kendo-react-grid';
import appStyles from 'App.module.scss';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { Input } from 'components/Generic/FormElements/Input/Input';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { ColumnMenu } from 'components/Generic/Grid/ColumnMenu';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { useGrid } from 'hooks/useGrid';
import { adminContext, messageContext } from 'App';
import { AppError } from 'models/generic/appError.model';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReports } from 'hooks/useReports';
import { ReportEbooksOrderLine } from 'models/responses/reportEbooksOrderLine.model';
import { DateOnly } from 'models/generic/dateOnly';
import Alert from 'components/Generic/Alert/Alert';
import { DateRangeReportForm } from 'models/forms/dateRangeReportForm.model';
import { useDownload } from 'hooks/useDownload';
import constants from 'utils/constants';
import { DateSelector, DateSelectorHelper } from 'components/Generic/FormElements/DateSelector/DateSelector';
import classNames from 'classnames';
import { Select, SelectHelper } from 'components/Generic/FormElements/Select/Select';
import { useLookups } from 'hooks/useLookups';
import { ListItem } from 'models/generic/listItem.model';
import { IntlProvider } from '@progress/kendo-react-intl';
import utils from 'utils/utils';

export const ReportEbooksOrders: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const storeName = params.store || 'uk';
  const storeId = constants.stores.getEbooksStoreIdFromName(storeName);

  const [form, setForm] = useState(new DateRangeReportForm(storeId));
  const [stores, setStores] = useState<ListItem[]>([]);
  const [inProgress, setInProgress] = useState(true);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [locale, setLocale] = useState('en-GB');
  const [currencyType, setCurrencyType] = useState<'currencyGbp' | 'currencyUsd'>('currencyGbp');

  const grid = useGrid<ReportEbooksOrderLine>([{ field: 'isbn', dir: 'asc' }]);
  const messages = useContext(messageContext);
  const reports = useReports();
  const lookups = useLookups();
  const adminAuth = useContext(adminContext);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    // set currency and locale
    setCurrencyType(utils.currency.getCurrencyType(form.storeId));
    setLocale(utils.currency.getLocale(form.storeId));

    // load stores
    setStores(lookups.getStores('ebooks', false));

    await loadData();
  };

  const handleDateChange = (fieldName: string, date: Date | null | string, momentDate: moment.Moment | null, dateOnly: DateOnly | null) => {
    if (dateOnly) {
      setForm(DateSelectorHelper.baseHandleDateOnly(fieldName, dateOnly, form));
    }
  };

  const handleSelectAsNumber = (e: ChangeEvent<HTMLSelectElement>) => {
    setForm(SelectHelper.baseHandleNumber(e, form));
  };

  const loadData = async () => {
    setInProgress(true);

    // change URL if store was changed
    if (form.storeId !== storeId) {
      if (form.storeId === constants.stores.ebooksUk) {
        navigate('./../uk');
      } else if (form.storeId === constants.stores.ebooksUs) {
        navigate('./../us');
      }
    }

    // set currency and locale
    setCurrencyType(utils.currency.getCurrencyType(form.storeId));
    setLocale(utils.currency.getLocale(form.storeId));

    // load report data
    const result = await reports.getEbooksOrdersReport(form);
    setInProgress(false);

    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      grid.setData(result);
    }

    setInProgress(false);
  };

  const download = async () => {
    setDownloadInProgress(true);

    const result = await reports.getEbooksOrdersReportAsCsv(form);
    if (result instanceof AppError) {
    } else {
      useDownload(result.fileContent, result.fileName);
    }

    setDownloadInProgress(false);
  };

  const back = () => {
    navigate('/admin/reports');
  };

  return (
    <div className={appStyles.container} id="EbooksOrdersReport">
      <div className={appStyles.row}>
        <div className={appStyles.col_sm_12}>
          <div className={appStyles.pageHeading}>
            <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
              <Icon icon={Icons.ebooks} size="heading" color="darkBlue" iconName="E-books orders report" customClass={appStyles.heading__icon} />
              <h1 className={appStyles.heading__text}>{storeName.toUpperCase()}: E-books Orders Report</h1>
            </div>
          </div>
        </div>
      </div>

      <div className={appStyles.row}>
        <div className={appStyles.col_sm_12}>
          <div className={appStyles.filters}>
            <div className={classNames(appStyles.row, appStyles.filters_row)}>
              <div className={classNames(appStyles.col_md_3, appStyles.col_sm_6, appStyles.filters_cell)}>
                <DateSelector
                  elementId="dateSelectorFrom"
                  name="dateFrom"
                  labelText="Date from"
                  placeholder="Select start date"
                  dateFormat={adminAuth.datePickerFormat}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  value={DateOnly.toDate(form.dateFrom)}
                  handleChange={handleDateChange}
                />
              </div>
              <div className={classNames(appStyles.col_md_3, appStyles.col_sm_6, appStyles.filters_cell)}>
                <DateSelector
                  elementId="dateSelectorTo"
                  name="dateTo"
                  labelText="Date to"
                  placeholder="Select end date"
                  dateFormat={adminAuth.datePickerFormat}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  value={DateOnly.toDate(form.dateTo)}
                  handleChange={handleDateChange}
                />
              </div>
              <div className={classNames(appStyles.col_md_3, appStyles.col_sm_6, appStyles.filters_cell)}>
                <Select
                  elementId="storeId"
                  labelText="Store"
                  name="storeId"
                  options={stores}
                  required={true}
                  placeholder="Select your store"
                  disablePlaceholder={true}
                  value={form.storeId}
                  handleChange={handleSelectAsNumber}
                  validationErrors={form.fieldMessages['storeId']}
                />
              </div>
              <div className={classNames(appStyles.col_md_3, appStyles.col_sm_6, appStyles.filters_cell__button)}>
                <Button id="loadButton" handleClick={loadData} buttonStyle="secondary" size="md" customClass={appStyles.filters_button} inProgress={inProgress}>
                  Load
                </Button>
              </div>
            </div>
            <div className={classNames(appStyles.row, appStyles.filters_row, appStyles.filters_row__search)}>
              <div className={classNames(appStyles.col_md_3, appStyles.col_sm_6)}>
                <Input
                  elementId="searchBar"
                  inputType="search"
                  name="searchBar"
                  labelText="Search report"
                  labelHidden={true}
                  placeholder="Search report"
                  value={grid.searchKeyword}
                  handleChange={grid.handleSearch}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={appStyles.row}>
        <div className={appStyles.col_sm_12}>
          {inProgress ? (
            <Alert alertType="info">Please wait. We're loading the data...</Alert>
          ) : (
            <div id="mainGrid" className="grid-with-md-filter">
              <IntlProvider locale={locale}>
                <Grid
                  data={grid.getData()}
                  filter={grid.filter}
                  filterable={false}
                  pageable={grid.getPageable()}
                  skip={grid.skip}
                  take={grid.take}
                  total={grid.getTotal()}
                  onPageChange={grid.pageChange}
                  sortable={grid.getSortable()}
                  sort={grid.getSort()}
                  resizable={true}
                  onFilterChange={grid.filterChange}
                  onSortChange={grid.sortChange}>
                  {ColumnHelper.getGridColumns(
                    [
                      { field: 'orderId', title: 'Order ID', dataType: 'text', size: 'sm' },
                      { field: 'paymentReference', title: 'Payment Reference', dataType: 'text', size: 'md' },
                      { field: 'isbn', title: 'ISBN', dataType: 'text', size: 'md' },
                      { field: 'quantity', title: 'Quantity', dataType: 'integer', size: 'sm' },
                      { field: 'net', title: 'Net', dataType: currencyType, size: 'sm' },
                      { field: 'tax', title: 'Tax', dataType: currencyType, size: 'sm' },
                      { field: 'gross', title: 'Gross', dataType: currencyType, size: 'sm' },
                      { field: 'country', title: 'Country', dataType: 'text', size: 'md' },
                      { field: 'state', title: 'State', dataType: 'text', size: 'md' }
                    ],
                    grid.filter,
                    ColumnMenu
                  )}
                </Grid>
              </IntlProvider>
            </div>
          )}
        </div>
      </div>

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button id="buttonBack" buttonStyle="secondary" size="lg" handleClick={back}>
          Back to reports
        </Button>
        <Button id="buttonDownload" buttonStyle="primary" size="lg" handleClick={download} inProgress={downloadInProgress}>
          Download
        </Button>
      </BottomButtonContainer>
    </div>
  );
};
