import appStyles from 'App.module.scss';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { CheckBox, CheckBoxHelper } from 'components/Generic/FormElements/CheckBox/CheckBox';
import { Input, InputHelper } from 'components/Generic/FormElements/Input/Input';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import { Select, SelectHelper } from 'components/Generic/FormElements/Select/Select';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { messageContext } from 'App';
import { EditAdministratorForm } from 'models/forms/editAdministratorForm.model';
import { AppError } from 'models/generic/appError.model';
import { ListItem } from 'models/generic/listItem.model';
import { Administrator } from 'models/responses/administrator.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import validation from 'utils/validation';
import { useAdministrators } from 'hooks/useAdministrators';
import classNames from 'classnames';
import LoadingAlert from 'components/Generic/LoadingAlert/LoadingAlert';

export const EditAdministrator: FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [form, setForm] = useState(new EditAdministratorForm(null));
  const [inProgress, setInProgress] = useState(false);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [administrator, setAdministrator] = useState<Administrator | null>(null);
  const [isNew, setIsNew] = useState<boolean | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const dateFormatTypes = [new ListItem('uk', 'UK'), new ListItem('us', 'US')];
  const administratorId = params.id ? +params.id : null;

  const messages = useContext(messageContext);
  const administrators = useAdministrators();

  useEffect(() => {
    // load admin on init
    init();
  }, []);

  useEffect(() => {
    // reload form and validation rules on admin change
    reloadForm();
  }, [administrator]);

  const init = async () => {
    if (administratorId != null) {
      setAdministrator(await administrators.getAdministratorById(administratorId));
    } else {
      setAdministrator(null);
    }
    setIsLoaded(true);
  };

  const reloadForm = async () => {
    if (administrator) {
      setForm(new EditAdministratorForm(administrator));
      setIsNew(false);
      setValidationRules(await administrators.getEditAdministratorValidationRules());
    } else {
      setForm(new EditAdministratorForm(null));
      if (window.location.pathname === '/admin/administrators/new') {
        setIsNew(true);
      }
      setValidationRules(await administrators.getCreateAdministratorValidationRules());
    }
  };

  const handleInputAsString = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = InputHelper.baseHandleString(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = CheckBoxHelper.baseHandleBool(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const updatedForm = SelectHelper.baseHandleString(e, form);

    if (submitAttempted) {
      validate(updatedForm);
    } else {
      setForm(updatedForm);
    }
  };

  const validate = (form: EditAdministratorForm): boolean => validation.validateForm(form, validationRules, setForm);

  const save = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitAttempted(true);

    const isValid = validate(form);

    if (isValid) {
      setInProgress(true);
      if (administrator && administratorId) {
        const result = await administrators.updateAdministrator(form, administratorId);
        if (result instanceof AppError) {
          form.fieldMessages = result.fieldMessages;
          setForm(form);
          setInProgress(false);
        } else {
          messages.addSuccessAsString('Administrator updated successfully.');
          navigate('/admin/administrators');
        }
      } else {
        const result = await administrators.createAdministrator(form);
        if (result instanceof AppError) {
          form.fieldMessages = result.fieldMessages;
          setForm(form);
          setInProgress(false);
        } else {
          messages.addSuccessAsString('Administrator created successfully.');
          navigate('/admin/administrators');
        }
      }
    }
  };

  const back = () => {
    navigate('/admin/administrators');
  };

  const notFound = isLoaded && administrator === null && isNew !== true;

  return (
    <div className={appStyles.container} id="AdminEditAdministrator">
      {isLoaded === false ? (
        <LoadingAlert />
      ) : notFound ? (
        <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
          <Icon icon={Icons.warning} size="lg" color="darkBlue" iconName="Warning" customClass={appStyles.heading__icon} />
          <h1 id="NotFound" className={appStyles.heading__text}>
            Administrator not found
          </h1>
        </div>
      ) : (
        <>
          <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
            <Icon icon={Icons.people} size="lg" color="darkBlue" iconName="Administrator" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>{isNew === false ? 'Edit administrator' : 'Add administrator'}</h1>
          </div>

          <form className={appStyles.row}>
            <div className={appStyles.col_md_6}>
              <div id="adminDetailsCard" className={classNames(appStyles.card, appStyles.background_cardBlue, appStyles.card_form)}>
                <fieldset>
                  <Legend type="large" text="Administrator details" />
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="name"
                      inputType="text"
                      labelText="Name"
                      name="name"
                      placeholder="Enter name"
                      value={form.name}
                      handleChange={handleInputAsString}
                      validationErrors={form.fieldMessages['name']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="login"
                      inputType="text"
                      labelText="Email"
                      name="login"
                      placeholder="Enter email address"
                      value={form.login}
                      handleChange={handleInputAsString}
                      validationErrors={form.fieldMessages['login']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="password"
                      inputType="password"
                      labelText="Password"
                      name="password"
                      placeholder="Enter password"
                      value={form.password}
                      handleChange={handleInputAsString}
                      validationErrors={form.fieldMessages['password']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="confirmPassword"
                      inputType="password"
                      labelText="Confirm password"
                      name="confirmPassword"
                      placeholder="Re-enter password"
                      value={form.confirmPassword}
                      handleChange={handleInputAsString}
                      validationErrors={form.fieldMessages['confirmPassword']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Select
                      elementId="dateFormat"
                      labelText="Default store and date format"
                      name="dateFormat"
                      options={dateFormatTypes}
                      placeholder="Select store"
                      disablePlaceholder={true}
                      value={form.dateFormat}
                      handleChange={handleSelect}
                      validationErrors={form.fieldMessages['dateFormat']}
                    />
                  </div>

                  <div id="disabledCheck" className={administrator ? appStyles.form__row : appStyles.hidden}>
                    <label className={appStyles.form__label}>Disable?</label>
                    <CheckBox
                      customLabelClass={appStyles.form__label}
                      name="isDisabled"
                      options={[new ListItem(true, 'If selected, this user will be disabled and unable to access administrator features.')]}
                      selectedOptions={[form.isDisabled]}
                      handleChange={handleCheckBox}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </form>
        </>
      )}

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button id="buttonBack" buttonStyle="secondary" size="lg" handleClick={back}>
          Back
        </Button>
        {isLoaded && notFound === false ? (
          <Button id="buttonSave" buttonStyle="primary" size="lg" handleClick={save} inProgress={inProgress}>
            Save
          </Button>
        ) : null}
      </BottomButtonContainer>
    </div>
  );
};
