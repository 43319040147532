import { apiContext } from 'App';
import { EditProductForm } from 'models/forms/editProductForm.model';
import { AppError } from 'models/generic/appError.model';
import { Product } from 'models/responses/product.model';
import { ValidationRule } from 'models/validation/validationRule.model';
import { useContext } from 'react';

export interface IProductsHook {
  getProducts: () => Promise<Product[] | AppError>;
  getProductById: (productId: number) => Promise<Product | null>;
  createProduct: (form: EditProductForm) => Promise<Product | AppError>;
  updateProduct: (form: EditProductForm, id: number) => Promise<Product | AppError>;
  getEditProductValidationRules: () => Promise<ValidationRule[]>;
}

export function useProducts(): IProductsHook {
  const api = useContext(apiContext);

  const getProducts = async (): Promise<Product[]> => {
    const response = await api.get<Product[]>('product', 'Loading products failed.');
    if (response instanceof AppError) {
      return [];
    } else {
      return response;
    }
  };

  const getProductById = async (productId: number): Promise<Product | null> => {
    const product = null;
    const response = await api.get<Product>(`product/${productId}`, 'There was an error loading this product.');

    if (response instanceof AppError) {
    } else {
      return response;
    }

    return product;
  };

  const createProduct = async (form: EditProductForm): Promise<Product | AppError> => {
    const response = await api.post<Product>('product', form, 'Creating product failed.');

    return response;
  };

  const updateProduct = async (form: EditProductForm, id: number): Promise<Product | AppError> => {
    const response = await api.put<Product>(`product/${id}`, form, 'Updating product failed.');

    return response;
  };

  const getEditProductValidationRules = async (): Promise<ValidationRule[]> => api.getValidationRules('product/validation');

  return {
    getProducts,
    getProductById,
    createProduct,
    updateProduct,
    getEditProductValidationRules
  };
}
