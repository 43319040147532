import * as axe from '@axe-core/react';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { IAdminAuthHook, useAdminAuth } from 'hooks/useAdminAuth';
import { useAnalytics } from 'hooks/useAnalytics';
import { IApiHook, useApi } from 'hooks/useApi';
import { ICustomerAuthHook, useCustomerAuth } from 'hooks/useCustomerAuth';
import { useInsights } from 'hooks/useInsights';
import { IMessagesHook, useMessages } from 'hooks/useMessages';
import { IBaseProps } from 'models/generic/iBaseProps';
import { useEnvironment } from 'hooks/useEnvironment';
import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import utils from 'utils/utils';
import styles from './App.module.scss';
import './scss/static.scss';

export const browserHistory = createBrowserHistory();

const environment = useEnvironment();

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY || '',
    extensions: [reactPlugin as ITelemetryPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});

// load app insights if key is set
if (process.env.REACT_APP_INSIGHTS_KEY) {
  appInsights.loadAppInsights();
}

// export app insights
export default appInsights;

// when not working on accessibility this can be temporarily set to false in development to speed up compilation time
const includeAxe = environment.isDev;
if (includeAxe) {
  const config = {
    rules: [
      // below list based on https://github.com/dequelabs/axe-core/blob/develop/doc/rule-descriptions.md
      // WCAG 2.0 Level A & AA Rules
      { id: 'area-alt', enabled: true },
      { id: 'aria-allowed-attr', enabled: true },
      { id: 'aria-command-name', enabled: true },
      { id: 'aria-hidden-body', enabled: true },
      { id: 'aria-hidden-focus', enabled: true },
      { id: 'aria-input-field-name', enabled: true },
      { id: 'aria-meter-name', enabled: true },
      { id: 'aria-progressbar-name', enabled: true },
      { id: 'aria-required-attr', enabled: true },
      { id: 'aria-required-children', enabled: true },
      { id: 'aria-required-parent', enabled: true },
      { id: 'aria-roledescription', enabled: true },
      { id: 'aria-roles', enabled: true },
      { id: 'aria-toggle-field-name', enabled: true },
      { id: 'aria-tooltip-name', enabled: true },
      { id: 'aria-valid-attr-value', enabled: true },
      { id: 'aria-valid-attr', enabled: true },
      { id: 'audio-caption', enabled: true },
      { id: 'blink', enabled: true },
      { id: 'button-name', enabled: true },
      { id: 'bypass', enabled: true },
      { id: 'color-contrast', enabled: true },
      { id: 'definition-list', enabled: true },
      { id: 'dlitem', enabled: true },
      { id: 'document-title', enabled: true },
      { id: 'duplicate-id-active', enabled: true },
      { id: 'duplicate-id-aria', enabled: true },
      { id: 'duplicate-id', enabled: true },
      { id: 'form-field-multiple-labels', enabled: true },
      { id: 'frame-focusable-content', enabled: true },
      { id: 'frame-title', enabled: true },
      { id: 'html-has-lang', enabled: true },
      { id: 'html-lang-valid', enabled: true },
      { id: 'html-xml-lang-mismatch', enabled: true },
      { id: 'image-alt', enabled: true },
      { id: 'input-button-name', enabled: true },
      { id: 'input-image-alt', enabled: true },
      { id: 'label', enabled: true },
      { id: 'link-name', enabled: true },
      { id: 'list', enabled: true },
      { id: 'listitem', enabled: true },
      { id: 'marquee', enabled: true },
      { id: 'meta-refresh', enabled: true },
      { id: 'nested-interactive', enabled: true },
      { id: 'object-alt', enabled: true },
      { id: 'role-img-alt', enabled: true },
      { id: 'scrollable-region-focusable', enabled: true },
      { id: 'select-name', enabled: true },
      { id: 'server-side-image-map', enabled: true },
      { id: 'svg-img-alt', enabled: true },
      { id: 'td-headers-attr', enabled: true },
      { id: 'th-has-data-cells', enabled: true },
      { id: 'valid-lang', enabled: true },
      { id: 'video-caption', enabled: true },
      // WCAG 2.1 Level A & AA Rules
      { id: 'empty-table-header', enabled: false },
      { id: 'autocomplete-valid', enabled: false },
      { id: 'avoid-inline-spacing', enabled: false },
      // Best Practices Rules
      { id: 'accesskeys', enabled: false },
      { id: 'aria-allowed-role', enabled: false },
      { id: 'aria-dialog-name', enabled: false },
      { id: 'aria-text', enabled: false },
      { id: 'aria-treeitem-name', enabled: false },
      { id: 'empty-heading', enabled: false },
      { id: 'frame-tested', enabled: false },
      { id: 'frame-title-unique', enabled: false },
      { id: 'heading-order', enabled: false },
      { id: 'identical-links-same-purpose', enabled: false },
      { id: 'image-redundant-alt', enabled: false },
      { id: 'label-title-only', enabled: false },
      { id: 'landmark-banner-is-top-level', enabled: false },
      { id: 'landmark-complementary-is-top-level', enabled: false },
      { id: 'landmark-contentinfo-is-top-level', enabled: false },
      { id: 'landmark-main-is-top-level', enabled: false },
      { id: 'landmark-no-duplicate-banner', enabled: false },
      { id: 'landmark-no-duplicate-contentinfo', enabled: false },
      { id: 'landmark-no-duplicate-main', enabled: false },
      { id: 'landmark-one-main', enabled: false },
      { id: 'landmark-unique', enabled: false },
      { id: 'meta-viewport-large', enabled: false },
      { id: 'meta-viewport', enabled: false },
      { id: 'page-has-heading-one', enabled: false },
      { id: 'presentation-role-conflict', enabled: false },
      { id: 'region', enabled: false },
      { id: 'scope-attr-valid', enabled: false },
      { id: 'skip-link', enabled: false },
      { id: 'tabindex', enabled: false },
      { id: 'table-duplicate-name', enabled: false },
      // Experimental Rules
      { id: 'css-orientation-lock', enabled: false },
      { id: 'focus-order-semantics', enabled: false },
      { id: 'hidden-content', enabled: false },
      { id: 'label-content-name-mismatch', enabled: false },
      { id: 'link-in-text-block', enabled: false },
      { id: 'no-autoplay-audio', enabled: false },
      { id: 'p-as-heading', enabled: false },
      { id: 'table-fake-caption', enabled: false },
      { id: 'td-has-header', enabled: false }
    ]
  };

  axe(React, ReactDOM, 1000, config);
}

// initialise monitoring route changes
utils.routing.initialiseMonitoring();

export const messageContext = React.createContext<IMessagesHook>({} as IMessagesHook);
export const apiContext = React.createContext<IApiHook>({} as IApiHook);
export const adminContext = React.createContext<IAdminAuthHook>({} as IAdminAuthHook);
export const customerContext = React.createContext<ICustomerAuthHook>({} as ICustomerAuthHook);

export const App: FC = (props: IBaseProps) => {
  const messagess = useMessages();
  const analytics = useAnalytics();
  analytics.initialise();

  const isAdminRoute = window.location.href.toLowerCase().includes('/admin');

  const api = useApi({ messages: messagess });

  if (isAdminRoute) {
    const adminAuth = useAdminAuth({ api: api });
    api.initialise(adminAuth);
    const currentAdminUser = adminAuth.getCurrentUser();
    const insights = useInsights({ administrator: currentAdminUser, customer: null });
    messagess.initialiseInsights(insights);

    return (
      <messageContext.Provider value={messagess}>
        <apiContext.Provider value={api}>
          <adminContext.Provider value={adminAuth}>
            <div className={styles.app}>{props.children}</div>
          </adminContext.Provider>
        </apiContext.Provider>
      </messageContext.Provider>
    );
  } else {
    const customerAuth = useCustomerAuth({ api: api });
    api.initialise(customerAuth);
    const currentCustomerUser = customerAuth.getCurrentUser();
    const insights = useInsights({ administrator: null, customer: currentCustomerUser });
    messagess.initialiseInsights(insights);

    return (
      <messageContext.Provider value={messagess}>
        <apiContext.Provider value={api}>
          <customerContext.Provider value={customerAuth}>
            <div className={styles.app}>{props.children}</div>
          </customerContext.Provider>
        </apiContext.Provider>
      </messageContext.Provider>
    );
  }
};
