import { ListItem } from './listItem.model';

export class NumericIdListItem extends ListItem {
  id: number;

  constructor(id: number, text: string, visible = true) {
    super(id, text, visible);

    this.id = id;
    this.text = text;
    this.visible = visible;
  }
}
