import { PaymentSummary } from 'models/responses/paymentSummary.model';
import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class CompleteOrderForm extends BaseValidatedObject {
  orderId: number;
  message: string | null;
  callback: 'success' | 'error' | null = null;
  method: 'card' | 'google' | 'apple' | null = null;
  callbackJson: string | null = null;

  constructor(paymentSummary: PaymentSummary) {
    super();

    this.orderId = paymentSummary.orderId as number;
    this.message = paymentSummary.message;
    this.callback = paymentSummary.callback;
    this.method = paymentSummary.method;
    this.callbackJson = paymentSummary.callbackJson;
  }
}
