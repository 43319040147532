import appStyles from 'App.module.scss';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

interface IButtonProps {
  /**
   * @property {ReactNode} children Add elements inside this container
   */
  children: ReactNode;

  /**
   * @property {string} style Style of a button
   */
  buttonStyle: 'none' | 'primary' | 'secondary' | 'outline_primary' | 'outline_secondary' | 'outline_white';

  /**
   * @property {string} buttonType Type of a button. Defaults to 'button'
   */
  buttonType?: 'button' | 'submit';

  /**
   * @property {string} size Optional. Size of a button. Defaults to md
   */
  size?: 'lg' | 'md';

  /**
   * @property {boolean} inProgress Optional. Once set to true button becomes disabled and has a preloader embedded
   */
  inProgress?: boolean;

  /**
   * @property {boolean} disabled Optional. Once set to true button becomes disabled and has the disabled class applied. Default is false.
   */
  disabled?: boolean;

  /**
   * @property {(event: ChangeEvent<HTMLInputElement>) => void} handleChange Optional. Set the action onClick
   */
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

  /**
   * @property {string} customClass Optional. Use a custom class on the button - use styles from the current component or appStyles
   */
  customClass?: string;

  /**
   * @property {string} id Optional. Add an id to the button
   */
  id?: string;

  /**
   * @property {string} title Optional. Add a title to the button
   */
  title?: string;
}

/**
 * @description Add a button. Optional properties: buttonType, size, inProgress, handleClick, customClass, disabled, title
 */
export const Button: FC<IButtonProps> = (props: IButtonProps) => (
  <button
    title={props.title}
    id={props.id}
    type={props.buttonType || 'button'}
    className={classNames(
      { [appStyles.button]: props.buttonStyle !== 'none' }, // add default class unless 'none' is selected
      { [appStyles[`button_${props.buttonStyle}`]]: props.buttonStyle !== 'none' }, // add primary or secondary class
      { [appStyles.button_spinner]: props.inProgress }, // add spinner class if work in progress
      { [appStyles.button_lg]: props.size === 'lg' }, // add lg class if size is lg (no additional class for size md)
      { [props.customClass as string]: props.customClass }, // add custom class if defined
      { [appStyles.button_disabled]: props.disabled }
    )}
    disabled={props.inProgress || props.disabled}
    onClick={props.handleClick}>
    {props.children}
  </button>
);
