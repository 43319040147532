import { PaymentSession } from 'models/responses/paymentSession.model';
import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class LogPaymentFailedForm extends BaseValidatedObject {
  orderId: number | null = null;
  basketId: number | null = null;
  storeId: number | null = null;
  paymentMethod: 'card' | 'google' | 'apple' | null = null;
  paymentSession: string;
  callback: string;
  status: string | null = null;
  code: number | null = null;
  responseMessage: string | null = null;
  errorMessage: string | null = null;

  paymentConfigJson: string;
  errorObjectJson: string;
  callbackObjectJson: string;

  // eslint-disable-next-line complexity
  constructor(
    session: PaymentSession,
    method: 'card' | 'google' | 'apple',
    callback: 'success' | 'error',
    configObject: unknown,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorObject: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callbackObject: any
  ) {
    super();

    this.orderId = session.orderId;
    this.basketId = session.basketId;
    this.storeId = session.storeId;
    this.paymentMethod = method;
    this.paymentSession = session.session;
    this.callback = callback;
    this.paymentConfigJson = configObject ? JSON.stringify(configObject) : '';
    this.errorObjectJson = errorObject ? JSON.stringify(errorObject) : '';
    this.callbackObjectJson = callbackObject ? JSON.stringify(callbackObject) : '';

    switch (method) {
      case 'card':
        // get details from judopay callback
        if (errorObject) {
          // if error callback then get details from errorObject
          this.status = errorObject.status + '' || null;
          this.responseMessage = errorObject.message;

          if (errorObject.judoDetails) {
            if (errorObject.judoDetails.message) {
              this.errorMessage = errorObject.judoDetails.message;
            }

            if (errorObject.judoDetails.code) {
              this.code = errorObject.judoDetails.code;
            }

            if (errorObject.judoDetails.details && errorObject.judoDetails.details[0]) {
              const item = errorObject.judoDetails.details[0];

              this.errorMessage = item.message || null;
              this.code = item.code || null;
            }
          }
        } else if (callbackObject) {
          // if success callback then get details from callbackObject
          if (callbackObject.result === 'Declined') {
            this.errorMessage = 'Payment was declined';
          } else {
            this.errorMessage = callbackObject.message;
          }
        }
        break;

      case 'google':
        // get details from google pay callback
        if (errorObject) {
          if (errorObject.statusMessage) {
            this.errorMessage = errorObject.statusMessage;
          }

          if (errorObject.errorCode) {
            this.code = errorObject.errorCode;
          }

          if (errorObject.statusCode) {
            this.status = errorObject.statusCode + '';
          }

          if (errorObject.message) {
            this.errorMessage = errorObject.message;
          }
        }
        break;

      case 'apple':
        // get details from apple pay callback
        if (errorObject) {
          this.errorMessage = errorObject.message;
        }
        break;
    }
  }
}
