import { Grid } from '@progress/kendo-react-grid';
import { IntlProvider } from '@progress/kendo-react-intl';
import appStyles from 'App.module.scss';
import { BottomButtonContainer } from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { DateSelector, DateSelectorHelper } from 'components/Generic/FormElements/DateSelector/DateSelector';
import { Input } from 'components/Generic/FormElements/Input/Input';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { ColumnMenu } from 'components/Generic/Grid/ColumnMenu';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { useGrid } from 'hooks/useGrid';
import { adminContext, messageContext } from 'App';
import { AppError } from 'models/generic/appError.model';
import { DateOnly } from 'models/generic/dateOnly';
import { ReportTroveLine } from 'models/responses/reportTroveLine.model';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import utils from 'utils/utils';
import { useReports } from 'hooks/useReports';
import Alert from 'components/Generic/Alert/Alert';
import { useDownload } from 'hooks/useDownload';
import constants from 'utils/constants';
import { DateRangeReportForm } from 'models/forms/dateRangeReportForm.model';
import classNames from 'classnames';

export const ReportTrove: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const storeName = params.store || 'uk';
  const storeId = constants.stores.getSubscriptionStoreIdFromName(storeName);

  const [form, setForm] = useState(new DateRangeReportForm(storeId));
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [locale, setLocale] = useState('en-GB');
  const [currencyType, setCurrencyType] = useState<'currencyGbp' | 'currencyUsd'>('currencyGbp');

  const grid = useGrid<ReportTroveLine>([{ field: 'orderId', dir: 'asc' }]);
  const messages = useContext(messageContext);
  const reports = useReports();
  const adminAuth = useContext(adminContext);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setForm(form);

    setLocale(utils.currency.getLocale(storeId));
    setCurrencyType(utils.currency.getCurrencyType(storeId));

    await loadData();
  };

  const handleDateChange = (fieldName: string, date: Date | null | string, momentDate: moment.Moment | null, dateOnly: DateOnly | null) => {
    if (dateOnly) {
      setForm(DateSelectorHelper.baseHandleDateOnly(fieldName, dateOnly, form));
    }
  };

  const loadData = async () => {
    setInProgress(true);
    const result = await reports.getTroveReport(form);
    setInProgress(false);

    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      grid.setData(result);
    }
  };

  const download = async () => {
    setDownloadInProgress(true);

    const result = await reports.getTroveReportAsCsv(form);
    if (result instanceof AppError) {
    } else {
      useDownload(result.fileContent, result.fileName);
    }

    setDownloadInProgress(false);
  };

  const back = () => {
    navigate('/admin/reports');
  };

  return (
    <div className={appStyles.container} id="TroveReport">
      <div className={appStyles.row}>
        <div className={appStyles.col_sm_12}>
          <div className={appStyles.pageHeading}>
            <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
              <Icon icon={Icons.products} size="heading" color="darkBlue" iconName="Trove report" customClass={appStyles.heading__icon} />
              <h1 className={appStyles.heading__text}>{storeName.toUpperCase()}: Trove Report</h1>
            </div>
          </div>
        </div>
      </div>

      <div className={appStyles.row}>
        <div className={appStyles.col_sm_12}>
          <div className={appStyles.filters}>
            <div className={classNames(appStyles.row, appStyles.filters_row)}>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell)}>
                <DateSelector
                  elementId="dateSelectorFrom"
                  name="dateFrom"
                  labelText="Date from"
                  placeholder="Select start date"
                  dateFormat={adminAuth.datePickerFormat}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  value={DateOnly.toDate(form.dateFrom)}
                  handleChange={handleDateChange}
                />
              </div>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell)}>
                <DateSelector
                  elementId="dateSelectorTo"
                  name="dateTo"
                  labelText="Date to"
                  placeholder="Select end date"
                  dateFormat={adminAuth.datePickerFormat}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  value={DateOnly.toDate(form.dateTo)}
                  handleChange={handleDateChange}
                />
              </div>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell__button)}>
                <Button id="loadButton" handleClick={loadData} buttonStyle="secondary" size="md" customClass={appStyles.filters_button} inProgress={inProgress}>
                  Load
                </Button>
              </div>
            </div>
            <div className={classNames(appStyles.row, appStyles.filters_row, appStyles.filters_row__search)}>
              <div className={appStyles.col_sm_4}>
                <Input
                  elementId="searchBar"
                  inputType="search"
                  name="searchBar"
                  labelText="Search report"
                  labelHidden={true}
                  placeholder="Search report"
                  value={grid.searchKeyword}
                  handleChange={grid.handleSearch}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={appStyles.row}>
        <div className={appStyles.col_sm_12}>
          {inProgress ? (
            <Alert alertType="info">Please wait. We're loading the data...</Alert>
          ) : (
            <div id="mainGrid" className="grid-with-md-filter">
              <IntlProvider locale={locale}>
                <Grid
                  data={grid.getData()}
                  filter={grid.filter}
                  filterable={false}
                  pageable={grid.getPageable()}
                  skip={grid.skip}
                  take={grid.take}
                  total={grid.getTotal()}
                  onPageChange={grid.pageChange}
                  sortable={grid.getSortable()}
                  sort={grid.getSort()}
                  resizable={true}
                  onFilterChange={grid.filterChange}
                  onSortChange={grid.sortChange}>
                  {ColumnHelper.getGridColumns(
                    [
                      { field: 'orderDate', title: 'Order Date', dataType: adminAuth.dateFormat, size: 'md' },
                      { field: 'orderId', title: 'Order ID', dataType: 'integer', size: 'md' },
                      { field: 'samsAccountId', title: 'Sams Account ID', dataType: 'integer', size: 'lg' },
                      { field: 'sigmaId', title: 'Sigma ID', dataType: 'integer', size: 'md' },
                      { field: 'productCode', title: 'Product Code', dataType: 'text', size: 'md' },
                      { field: 'description', title: 'Description', dataType: 'text', size: 'lg' },
                      { field: 'period', title: 'Period', dataType: 'text', size: 'sm' },
                      { field: 'net', title: 'Net Amount', dataType: currencyType, size: 'md' },
                      { field: 'promotionCode', title: 'Promo Code', dataType: 'text', size: 'md' },
                      { field: 'orderStatus', title: 'Order Status', dataType: 'text', size: 'md' },
                      { field: 'university', title: 'University', dataType: 'text', size: 'lg' },
                      { field: 'universityCountry', title: 'University Country', dataType: 'text', size: 'lg' },
                      { field: 'billingCountry', title: 'Billing Country', dataType: 'text', size: 'md' }
                    ],
                    grid.filter,
                    ColumnMenu
                  )}
                </Grid>
              </IntlProvider>
            </div>
          )}
        </div>
      </div>

      <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
        <Button id="buttonBack" buttonStyle="secondary" size="lg" handleClick={back}>
          Back to reports
        </Button>
        <Button id="buttonDownload" buttonStyle="primary" size="lg" handleClick={download} inProgress={downloadInProgress}>
          Download
        </Button>
      </BottomButtonContainer>
    </div>
  );
};
