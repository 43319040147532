import { Grid } from '@progress/kendo-react-grid';
import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { Button } from 'components/Generic/FormElements/Button/Button';
import { DateSelector, DateSelectorHelper } from 'components/Generic/FormElements/DateSelector/DateSelector';
import { Input } from 'components/Generic/FormElements/Input/Input';
import { Select, SelectHelper } from 'components/Generic/FormElements/Select/Select';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { ColumnMenu } from 'components/Generic/Grid/ColumnMenu';
import { Icon, Icons } from 'components/Generic/Icon/Icon';
import { useGrid } from 'hooks/useGrid';
import { adminContext, messageContext } from 'App';
import { LoadOrdersForm } from 'models/forms/loadOrdersForm.model';
import { AppError } from 'models/generic/appError.model';
import { DateOnly } from 'models/generic/dateOnly';
import { ListItem } from 'models/generic/listItem.model';
import { Order } from 'models/responses/order.model';
import moment from 'moment';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLookups } from 'hooks/useLookups';
import constants from 'utils/constants';
import utils from 'utils/utils';
import { useOrders } from 'hooks/useOrders';
import Alert from 'components/Generic/Alert/Alert';

export const OrdersGrid: FC = () => {
  const [form, setForm] = useState(new LoadOrdersForm());
  const [stores, setStores] = useState<ListItem[]>([]);
  const [orderStatuses, setOrderStatuses] = useState<ListItem[]>([]);
  const [countries, setCountries] = useState<ListItem[]>([]);
  const [inProgress, setInProgress] = useState(false);
  const [currencyType, setCurrencyType] = useState<'currencyGbp' | 'currencyUsd'>('currencyGbp');

  const grid = useGrid<Order>([{ field: 'orderId', dir: 'asc' }]);
  const messages = useContext(messageContext);
  const orders = useOrders();
  const lookups = useLookups();
  const adminAuth = useContext(adminContext);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    // set default store to UK
    form.storeId = constants.stores.subscriptionsUk;

    // check if form selection in cache
    const cachedData = utils.session.getFromSession<LoadOrdersForm>(constants.storageKeys.orderForm);
    if (cachedData) {
      form.startDate = cachedData.startDate;
      form.endDate = cachedData.endDate;
      form.storeId = cachedData.storeId;
      form.countryId = cachedData.countryId;
      form.statusId = cachedData.statusId;
    }

    setForm(form);

    // set currency
    setCurrencyType(utils.currency.getCurrencyType(form.storeId));

    setStores(lookups.getStores('all', false));
    setCountries(await lookups.getCountriesAsListItems());
    setOrderStatuses(await lookups.getOrderStatusesAsListItems());

    await loadData();
  };

  const handleSelectAsNumber = (e: ChangeEvent<HTMLSelectElement>) => {
    setForm(SelectHelper.baseHandleNumber(e, form));
  };

  const handleDateChange = (fieldName: string, date: Date | null | string, momentDate: moment.Moment | null, dateOnly: DateOnly | null) => {
    setForm(DateSelectorHelper.baseHandleDateOnly(fieldName, dateOnly, form));
  };

  const loadData = async () => {
    setInProgress(true);
    const result = await orders.getOrders(form);
    setInProgress(false);

    if (result instanceof AppError) {
      messages.addErrorsFromFieldMessages(result.fieldMessages);
    } else {
      grid.setData(result);
    }
  };

  const load = async () => {
    await loadData();

    // set currency
    setCurrencyType(utils.currency.getCurrencyType(form.storeId));

    // save form selection to cache
    utils.session.saveToSession(constants.storageKeys.orderForm, form);
  };

  return (
    <div className={appStyles.container} id="AdminOrders">
      <div className={appStyles.row}>
        <div className={appStyles.col_sm_12}>
          <div className={appStyles.pageHeading}>
            <div className={classNames(appStyles.heading, appStyles.text_oxfordBlue)}>
              <Icon icon={Icons.orders} size="lg" color="darkBlue" iconName="Orders" customClass={appStyles.heading__icon} />
              <h1 className={appStyles.heading__text}>Orders</h1>
            </div>
          </div>
        </div>
      </div>

      <div className={appStyles.row}>
        <div className={appStyles.col_sm_12}>
          <div className={appStyles.filters}>
            <div className={classNames(appStyles.row, appStyles.filters_row)}>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell)}>
                <DateSelector
                  elementId="dateSelector"
                  name="startDate"
                  labelText="Start Date"
                  labelHidden={false}
                  placeholder="Select start date"
                  dateFormat={adminAuth.datePickerFormat}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  value={DateOnly.toDate(form.startDate)}
                  handleChange={handleDateChange}
                  validationErrors={form.fieldMessages['startDate']}
                />
              </div>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell)}>
                <DateSelector
                  elementId="dateSelector"
                  name="endDate"
                  labelText="End Date"
                  labelHidden={false}
                  placeholder="Select end date"
                  dateFormat={adminAuth.datePickerFormat}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  value={DateOnly.toDate(form.endDate)}
                  handleChange={handleDateChange}
                  validationErrors={form.fieldMessages['endDate']}
                />
              </div>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell)}>
                <Select
                  elementId="storeId"
                  labelText="Store"
                  name="storeId"
                  options={stores}
                  placeholder="Select your store"
                  disablePlaceholder={true}
                  value={form.storeId}
                  handleChange={handleSelectAsNumber}
                  validationErrors={form.fieldMessages['storeId']}
                />
              </div>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell)}>
                <Select
                  elementId="statusId"
                  labelText="Order Status"
                  name="statusId"
                  options={orderStatuses}
                  placeholder="Select Status"
                  disablePlaceholder={false}
                  value={form.statusId}
                  handleChange={handleSelectAsNumber}
                  validationErrors={form.fieldMessages['statusId']}
                />
              </div>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell)}>
                <Select
                  elementId="countryId"
                  labelText="Country"
                  name="countryId"
                  options={countries}
                  placeholder="Select your country"
                  disablePlaceholder={false}
                  value={form.countryId}
                  handleChange={handleSelectAsNumber}
                  validationErrors={form.fieldMessages['countryId']}
                />
              </div>
              <div className={classNames(appStyles.col_sm_4, appStyles.filters_cell__button)}>
                <Button id="loadButton" handleClick={load} buttonStyle="secondary" size="md" customClass={appStyles.filters_button} inProgress={inProgress}>
                  Load
                </Button>
              </div>
            </div>
            <div className={classNames(appStyles.row, appStyles.filters_row, appStyles.filters_row__search)}>
              <div className={appStyles.col_sm_4}>
                <Input
                  elementId="searchBar"
                  inputType="search"
                  name="searchBar"
                  labelText="Search orders"
                  labelHidden={true}
                  placeholder="Search orders"
                  value={grid.searchKeyword}
                  handleChange={grid.handleSearch}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={appStyles.row}>
        <div className={appStyles.col_sm_12}>
          {inProgress ? (
            <Alert alertType="info">Please wait. We're loading the data...</Alert>
          ) : (
            <div id="mainGrid" className="grid-with-lg-filter">
              <Grid
                data={grid.getData()}
                filter={grid.filter}
                filterable={false}
                pageable={grid.getPageable()}
                skip={grid.skip}
                take={grid.take}
                total={grid.getTotal()}
                onPageChange={grid.pageChange}
                sortable={grid.getSortable()}
                sort={grid.getSort()}
                resizable={true}
                onFilterChange={grid.filterChange}
                onSortChange={grid.sortChange}>
                {ColumnHelper.getGridColumns(
                  [
                    {
                      field: '',
                      title: 'View',
                      dataType: 'icon',
                      size: 'xs',
                      columnMenu: false,
                      cell: (props) => (
                        <td className="k-table-td gridColumn_xs gridColumn_button">
                          <Link to={'/admin/orders/' + props.dataItem.orderId} className="grid__button">
                            <Icon icon={Icons.view} iconName={'View order ' + props.dataItem.orderId} color="darkBlue" size="xs" />
                          </Link>
                        </td>
                      )
                    },
                    { field: 'orderId', title: 'Order ID', dataType: 'integer', size: 'sm' },
                    { field: 'msdOrderIds', title: 'MSD Order ID', dataType: 'text', size: 'sm' },
                    { field: 'orderDate', title: 'Date', dataType: adminAuth.dateFormat, size: 'sm' },
                    { field: 'orderStatus', title: 'Status', dataType: 'text', size: 'md' },
                    { field: 'total', title: 'Total', dataType: currencyType, size: 'sm' },
                    { field: 'fullName', title: 'Customer', dataType: 'text', size: 'md' }
                  ],
                  grid.filter,
                  ColumnMenu
                )}
              </Grid>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
