import { v4 as uuid } from 'uuid';

export class Message {
  text = '';
  type: 'error' | 'warning' | 'info' | 'success' | 'blank';
  id = '';

  constructor(text: string, type: 'error' | 'warning' | 'info' | 'success' | 'blank') {
    this.text = text;
    this.type = type;
    this.id = uuid();
  }
}
