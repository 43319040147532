import { apiContext } from 'App';
import { LoadEbooksForm } from 'models/forms/loadEbooksForm.model';
import { AppError } from 'models/generic/appError.model';
import { CustomerEbook } from 'models/responses/customerEbook.model';
import { Ebook } from 'models/responses/ebook.model';
import { useContext } from 'react';

export interface IEbooksHook {
  getEbooks: (form: LoadEbooksForm) => Promise<Ebook[] | AppError>;
  getCustomerEbooks: () => Promise<CustomerEbook[] | AppError>;
  getVitalSourceRedirectUrl: () => Promise<string>;
}

export function useEbooks(): IEbooksHook {
  const api = useContext(apiContext);

  const getEbooks = async (form: LoadEbooksForm): Promise<Ebook[]> => {
    const response = await api.post<Ebook[]>('ebook/all', form, 'Loading e-books failed.');
    if (response instanceof AppError) {
      return [];
    } else {
      return response;
    }
  };

  const getCustomerEbooks = async (): Promise<CustomerEbook[]> => {
    const response = await api.get<CustomerEbook[]>('ebook/my', 'Loading e-books failed.');
    if (response instanceof AppError) {
      return [];
    } else {
      response.forEach((ebook) => {
        ebook.startDate = ebook.startDate ? new Date(ebook.startDate) : '';
        ebook.endDate = ebook.endDate ? new Date(ebook.endDate) : '';
      });
    }

    return response;
  };

  const getVitalSourceRedirectUrl = async (): Promise<string> => {
    const response = await api.get<string>('ebook/redirect', 'Loading bookshelf URL failed.');
    if (response instanceof AppError) {
      return '';
    } else {
      return response;
    }
  };

  return {
    getEbooks,
    getCustomerEbooks,
    getVitalSourceRedirectUrl
  };
}
