import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class RegisterForm extends BaseValidatedObject {
  email = '';
  password = '';
  confirmPassword = '';
  title: string | null = null;
  firstName = '';
  lastName = '';
  address1 = '';
  address2 = '';
  city = '';
  postCode = '';
  county = '';
  countryId: number | null = null;
  provinceId: number | null = null;
  telephone = '';
  fax = '';
  mobileCountryCode = '';
  mobileNumber = '';
  optOutEmail = false;
}
