import { GridColumnMenuFilter, GridColumnMenuProps, GridColumnMenuSort } from '@progress/kendo-react-grid';
import React from 'react';

export class ColumnMenu extends React.Component<GridColumnMenuProps> {
  render() {
    return (
      <div>
        <GridColumnMenuSort {...this.props} />
        <GridColumnMenuFilter {...this.props} />
      </div>
    );
  }
}
