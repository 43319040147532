import { BaseValidatedObject } from 'models/validation/baseValidatedObject.model';

export class CreateOrderForm extends BaseValidatedObject {
  basketId: number;

  constructor(basketId: number) {
    super();

    this.basketId = basketId;
  }
}
